<template>
  <div>
    <Message v-if="automatic" :type="type" :message="obj.Message" :messageBtn="translate('btnWait')" :isList="true" />
    <div v-else>
      <b-message title="Info!" type="is-warning" :closable="false" aria-close-label="Close message">
        <div class="row">
          <div class="media-left">
            <i class="icons icons-warning1" style="font-size: 45px" />
          </div>
          <span>{{ obj.Message[0] }} </span>
        </div>

        <section class="modal-card-footer" style="padding-top: 10px;">
          <div style="float: right">
            <b-button @click.prevent="retry()" :label="translate('tabRetry')" type="is-primary"
              icon-left=".icons-white icons-undo" icon-pack="icons" />
          </div>
        </section>
      </b-message>
    </div>
  </div>
</template>

<script>
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import Message from "@/components/message/Message.vue";
import { clientCommands } from "@/js/ClientCommands.js";
import { mapState } from "vuex";
import { returnApp } from "@/js/resetApp.js";
import { classValue } from "@/mixins/enums.js";
export default {
  name: "ReturnApp",
  mixins: [en, es, nor, swe],
  computed: mapState(["lang"]),
  components: {
    Message
  },
  props: {
    obj: Object,
    automatic: Boolean,
  },
  data() {
    return {
      intervalReload: null,
      type: "",
      classValue: classValue
    };
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    retry() {
      returnApp(this);
      this.$emit("close");
    },
    restartToApp: function () {
      try {
        if (this.obj.TimeToRestart > 0) {
          this.obj.TimeToRestart -= 1;
          if (this.obj.TimeToRestart <= 0) {
            if (this.obj.RestartRequired) {
              this.$store.commit("changeCompleteLogin", false);
              this.$store.commit("changeValidatingLogin", false);
              localStorage.removeItem("ManagerOptions" + '/' + this.IP);
              localStorage.removeItem("CashierOptions" + '/' + this.IP);
              localStorage.removeItem("UserLoggedTemp" + '/' + this.IP)
              if (
                this.obj.CMD == clientCommands.ResetServerConfiguration.name
              ) {
                this.$router.push("/ini").catch((e) => {
                  console.log("Error in Route->Ini()" + e);
                });
              } else {                
                returnApp(this);
              }
              return;
            }
          } else {
            this.restartToApp();
          }

        }
      } catch (e) {
        console.log("Error in RestartToApp() " + e);
      }
    },
  },
  created() {
    //
  },
  mounted() {
    if (this.automatic) {
      switch (this.obj.MessageType) {
        case classValue.approved:
          this.type = "is-success";
          break;
        case classValue.warning:
          this.type = "is-warning";
          break;
        case classValue.error:
          this.type = "is-danger";
          break;
        case classValue.info:
          this.type = "is-info";
          break;
        case classValue.none:
        default:
          this.type = "is-success";
          console.log("returnTypeMessage unknown [" + this.obj.MessageType + "]");
          break;
      }
      setTimeout(
        () =>
          this.restartToApp(),
        1000
      );
    }


  },
  beforeDestroy() {
    console.log("/closed returnApp()");
    clearInterval(this.intervalReload);
  },
};
</script>

<style scoped></style>