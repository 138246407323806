<template>
  <form action="javascript:void(0);" style="overflow-y: hidden">
    <div class="modal-card" style="width: auto" v-if="!notModal">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ translate("authenticationTitle") }}</p>
        <button v-if="false" type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="mb-2" v-if="errorAuth" style="width: 100%; margin-top: 0.25rem">
          <Message type="is-danger" :message="errorAuthMessage" />
        </div>
        <div style="margin-bottom: 15px"></div>
        <b-field v-if="showLogin">
          <div class="col-sm-1">
            <button class="btn btn-outline-secondary" style="height: 40px" disabled tabindex="-1">
              <i class="icons-lg0 icons-user" style="font-size: 1.25em !important" />
            </button>
          </div>
          <div class="col-sm-10">
            <b-input v-model="user.login" :value="user.login" @input="onInputChange" :placeholder="translate('btnLogin')"
              @focus="(showKeyboardPassword = false), (showKeyboardPin = false)" maxlength="20" style="width: 100%"
              :disabled="blockLogin" id="lblLogin" name="lblLogin" tabindex="1001" @keydown.native="sendRequestInput">
            </b-input>
          </div>
          <div class="col-sm-1">
            <button v-if="!blockLogin" class="btn btn-outline-secondary" style="height: 40px" type="button"
              @click="showKeyboard('login')" id="btnKeyboardLogin" name="btnKeyboardLogin" tabindex="1003">
              <i class="icons-lg0 icons-keyboard" />
            </button>
          </div>
        </b-field>

        <div class="mb-2" v-if="showKeyboardLogin" style="width: 100%; margin-top: 0.25rem">
          <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="user.login" />
        </div>

        <b-field v-if="showPassword">
          <div class="col-sm-1">
            <button class="btn btn-outline-secondary" style="height: 40px" disabled tabindex="-1">
              <i class="icons-lg0 icons-key" />
            </button>
          </div>
          <div class="col-sm-10">
            <b-input type="password" :placeholder="translate('lblPassword')" password-reveal v-model="user.password"
              :value="user.password" maxlength="20" style="width: 100%"
              @focus="(showKeyboardLogin = false), (showKeyboardPin = false)" id="lblPassword" name="lblPassword"
              tabindex="1002" @keydown.native="sendRequestInput">
            </b-input>
          </div>
          <div class="col-sm-1">
            <button class="btn btn-outline-secondary" type="button" style="height: 40px" @click="showKeyboard('password')"
              id="btnKeyboardPassword" name="btnKeyboardPassword" tabindex="1004">
              <i class="icons-lg0 icons-keyboard" />
            </button>
          </div>
        </b-field>

        <div class="mb-2" v-if="showKeyboardPassword" style="width: 100%; margin-top: 0.25rem">
          <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="user.password" />
        </div>

        <b-field v-if="showPin">
          <div class="col-sm-1">
            <button class="btn btn-outline-secondary" style="height: 40px" disabled tabindex="-1">
              <i class="icons-1x icons-lock" />
            </button>
          </div>
          <div class="col-sm-10">
            <b-input placeholder="Pin" v-model="user.pin" :value="user.pin" type="number" maxlength="7" max="999999"
              min="0"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57" @focus="
                (showKeyboardLogin = false), (showKeyboardPassword = false)
                ">
            </b-input>
          </div>

          <button class="btn btn-outline-secondary" type="button" @click="showKeyboard('pin')">
            <i class="icons-1x icons-keyboard" />
          </button>
        </b-field>
        <div class="mb-2" v-if="showKeyboardPin" style="width: 100%; margin-top: 0.25rem">
          <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="user.pin" :isNumeric="true" :pin="7" />
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button :label="translate('btnCancel')" @click="$emit('close')" icon-left=".icons-white icons-times"
          icon-pack="icons" id="btnCancel" name="btnCancel" tabindex="1005" />
        <b-button :label="translate('btnOk')" @click.prevent="sendRequest()" icon-left=".icons-white icons-sign-in"
          icon-pack="icons" type="is-primary" id="btnOk" name="btnOk" tabindex="1006" />
      </footer>
    </div>
    <div class="iniFile" v-else>
      <div class="card">
        <div class="card-body">
          <div class="auth-content">
            <div class="auth-modal" style="width: 600px">
              <div class="auth-title">
                {{ translate("authenticationTitle") }}
              </div>
              <section class="modal-card-body" style="width: 100%">
                <div class="mb-2" v-if="errorAuth" style="width: 100%; margin-top: 0.25rem">
                  <Message type="is-danger" :message="errorAuthMessage" />
                </div>
                <div class="mb-2" v-else style="width: 100%; margin-top: 0.25rem">
                  <Message type="is-info" :message="this.translate('messageAuthReportManager')" />
                </div>
                <div style="margin-bottom: 15px"></div>
                <b-field v-if="showLogin">
                  <button class="btn btn-outline-secondary" style="height: 41px" disabled>
                    <i class="icons-lg0 icons-user" />
                  </button>
                  <div class="col-sm-10">
                    <b-input tabindex="0" v-model="user.login" :value="user.login" @input="onInputChange"
                      :placeholder="translate('btnLogin')" maxlength="20" @keydown.native="sendRequestInput" @focus="
                        (showKeyboardPassword = false),
                        (showKeyboardPin = false)
                        ">
                    </b-input>
                  </div>
                  <button tabindex="3" class="btn btn-outline-secondary" style="height: 41px" type="button"
                    @click="showKeyboard('login')">
                    <i class="icons-lg0 icons-keyboard" />
                  </button>
                </b-field>

                <div class="mb-2" v-if="showKeyboardLogin" style="width: 100%; margin-top: 0.25rem">
                  <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="user.login" />
                </div>

                <b-field v-if="showPassword">
                  <button class="btn btn-outline-secondary" style="height: 41px" disabled>
                    <i class="icons-lg0 icons-key" />
                  </button>

                  <div class="col-sm-10">
                    <b-input tabindex="1" type="password" :placeholder="translate('lblPassword')" password-reveal
                      v-model="user.password" :value="user.password" @keydown.native="sendRequestInput" maxlength="20"
                      @focus="
                        (showKeyboardLogin = false), (showKeyboardPin = false)
                        ">
                    </b-input>
                  </div>

                  <button tabindex="4" class="btn btn-outline-secondary" type="button" style="height: 41px"
                    @click="showKeyboard('password')">
                    <i class="icons-lg0 icons-keyboard" />
                  </button>
                </b-field>

                <div class="mb-2" v-if="showKeyboardPassword" style="width: 100%; margin-top: 0.25rem">
                  <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="user.password" />
                </div>

                <b-field label="Pin" v-if="showPin">
                  <button class="btn btn-outline-secondary" style="height: 41px" disabled>
                    <i class="icons-1x icons-lock" />
                  </button>

                  <div class="col-sm-10">
                    <b-input placeholder="Pin" v-model="user.pin" :value="user.pin" type="number" max="999999" min="0"
                      maxlength="7"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" @focus="
                        (showKeyboardLogin = false),
                        (showKeyboardPassword = false)
                        ">
                    </b-input>
                  </div>

                  <button class="btn btn-outline-secondary" type="button" @click.prevent="showKeyboard('pin')">
                    <i class="icons-1x icons-keyboard" />
                  </button>
                </b-field>
                <div class="mb-2" v-if="showKeyboardPin" style="width: 100%; margin-top: 0.25rem">
                  <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="user.pin" :isNumeric="true"
                    :pin="7" />
                </div>
              </section>
              <footer>
                <b-button tabindex="2" :label="translate('btnLogin')" @click.prevent="sendRequest()" type="is-primary"
                  icon-left=".icons-white icons-sign-in" icon-pack="icons" />
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { clientCommands } from "@/js/ClientCommands.js";
import { mapState } from "vuex";
import SimpleKeyboard from "../keyboard/SimpleKeyboard.vue";
import Message from "@/components/message/Message.vue";
import md5 from "@/js/md5";
import Socket from "@/config/webSocket.js";
import notify from "@/config/notify.js";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
export default {
  name: "Auth",
  mixins: [en, es, nor, swe],
  components: { SimpleKeyboard /*KeyboardNumeric*/, Message },
  computed: mapState([
    "dataModule",
    "userLogged",
    "hasControl",
    "userLoggedTemp",
    "lang",
    "IP",
    "completeLogin",
    "token"
  ]),
  data: function () {
    return {
      showKeyboardLogin: false,
      showKeyboardPassword: false,
      showKeyboardPin: false,
      errorAuth: false,
      errorAuthMessage: "",
      tabIndex: 0,
      blockLogin: true,
      user: {
        login: "",
        password: "",
        pin: "",
      },
    };
  },
  props: {
    route: String,
    showLogin: Boolean,
    showPassword: Boolean,
    showPin: Boolean,
    local: String,
    notModal: Boolean,
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    showKeyboard(type) {
      if (type === "login") {
        this.showKeyboardPassword = false;
        this.showKeyboardPin = false;
        this.showKeyboardLogin = this.showKeyboardLogin == true ? false : true;
      } else if (type === "password") {
        this.showKeyboardLogin = false;
        this.showKeyboardPin = false;
        this.showKeyboardPassword =
          this.showKeyboardPassword == true ? false : true;
      } else if (type === "pin") {
        this.showKeyboardLogin = false;
        this.showKeyboardPassword = false;
        this.showKeyboardPin = this.showKeyboardPin == true ? false : true;
      }
    },
    onChange(input) {
      if (this.showKeyboardLogin) {
        this.user.login = input;
      } else if (this.showKeyboardPassword) {
        this.user.password = input;
      } else if (this.showKeyboardPin) {
        this.user.pin = input;
      }
    },
    onKeyPress(button) {
      console.log("button", button);
      if (button === "{clear}") {
        if (this.showKeyboardLogin) {
          this.user.login = "";
        } else if (this.showKeyboardPassword) {
          this.user.password = "";
        } else if (this.showKeyboardPin) {
          this.user.pin = "";
        }
      }
    },
    onInputChange(input) {
      if (this.showKeyboardLogin) {
        this.user.login = input;
      } else if (this.showKeyboardPassword) {
        this.user.password = input;
      } else if (this.showKeyboardPin) {
        this.user.pin = input;
      }
    },

    sendRequest() {
      this.errorAuth = false;
      this.errorAuthMessage = "";
      let errorByPermission = false;
      let userSelected = null;
      const md5Password = md5.CalcuMD5(this.user.password);
      if (this.local == "/views/Dashboard/ReportManager") {
        this.dataModule.usersAll.forEach((user) => {
          const userAux = this.user.login
          if (
            user.Login.toLowerCase() === userAux.toLowerCase() &&
            user.Password == md5Password && !user.Deleted
          ) {
            if (user.ReportView || user.Root) {
              userSelected = user;
            } else {
              errorByPermission = true;
            }
          }
        });
      } else if (this.local == "Cashier/ManagerOptions" || this.local == "CashierDingo/ManagerOptions") {
        this.dataModule.usersAll.forEach((user) => {
          const userAux = this.user.login
          if (
            user.Login.toLowerCase() === userAux.toLowerCase() &&
            user.Password == md5Password && !user.Deleted
          ) {
            userSelected = user;
          }
        });
      } else if (this.local == "Header/RunOsAdmin" || this.local == "Header/Restart" || this.local == "Header/Shutdown") {
        this.dataModule.usersAll.forEach((user) => {
          const userAux = this.user.login
          if (
            user.Login.toLowerCase() === userAux.toLowerCase() &&
            user.Password == md5Password && !user.Deleted
          ) {
            if (this.local == "Header/RunOsAdmin") {
              if (user.Root || user.Technician) {
                userSelected = user;
              }
              else {
                errorByPermission = true;
              }
            } else {
              userSelected = user;
            }

          }
        });
      }

      else {
        if (
          this.userLogged.Login.toLowerCase() ===
          this.user.login.toLowerCase() &&
          this.userLogged.Password == md5Password
        ) {
          if (
            this.userLogged.Technician ||
            this.userLogged.Root ||
            this.userLogged.Cashier
          ) {
            userSelected = this.userLogged;
          } else {
            errorByPermission = true;
          }
        }
      }

      if (userSelected == null) {
        this.errorAuth = true;
        this.errorAuthMessage = errorByPermission
          ? this.translate("messageErrorByPermission")
          : this.translate("messageErrorPassword");
      } else {
        if (this.local === "/views/Dashboard/ReportManager") {
          if (!userSelected.ReportView && !userSelected.Root) {
            this.errorAuth = true;
            this.errorAuthMessage =
              this.translate("btnLogin") +
              " " +
              this.translate("messageErrorNotSet") +
              " " +
              this.translate("reportManager_Title") +
              "!";
          }
        } else if (this.local === "Cashier/ManagerOptions") {
          if (!userSelected.Manager && !userSelected.Root) {
            this.errorAuth = true;
            this.errorAuthMessage =
              this.translate("btnLogin") +
              " " +
              this.translate("messageErrorNotSet") +
              " " +
              this.translate("cashier_managerMenuTitle") +
              "!";
          }
        } else if (this.local === "Cashier/CashierOptions/btnBlockCashier") {
          if (this.userLogged.Id !== this.dataModule.cashier.IdUser) {
            this.errorAuth = true;
            this.errorAuthMessage = this.translate(
              "messageErroBlockUnblockCashier"
            );
          }
        }
      }
      if (!this.errorAuth) {
        this.sendCMD(userSelected);
      }
    },
    processSendCMD(userSelected) {
      Socket.send(clientCommands.LoggedOut.name + "|" + this.userLogged.Login + "|" + this.token);
      notify.message(
        "foo",
        "Alert",
        "user [" + this.userLogged.Login + " logged out]",
        "warn",
        2000
      );
      this.sendCMD(userSelected);
    },
    sendCMD(userSelected) {
      switch (this.local) {
        case "Cashier/CashierOptions/btnRegisterFill":
        case "Cashier/CashierOptions/btnRegisterDrop":
        case "Cashier/CashierOptions/btnBlockCashier":
          this.$emit("AfterAuthCashier", this.local);
          break;
        case "Configuration/Settings/ResetServerIni":
        case "Configuration/Settings/ResetClientIni":
        case "Configuration/Settings/ResetBothIni":
        case "Configuration/Settings/ResetBigOsAddress":
          this.$emit("AfterAuthConfigurations", this.local);
          break;
        case "Header/RunOsAdmin":
        case "Header/Restart":
        case "Header/Shutdown":
          this.$emit("AfterAuthHeader", this.local);
          break;
        case "Module/Operator":
          this.$emit("AfterAuthOperator", this.local);
          break;
        default:
          this.$store.commit("setUserLoggedTemp", userSelected);
          this.$emit("AfterAuth", this.local);
          if (this.local === "/views/Dashboard/ReportManager") {
            notify.message(
              "foo",
              "Success",
              "[" +
              this.translate("reportManager_Title") +
              "] " +
              this.translate("messageLoggedIn"),
              "success",
              2000
            );
          } else if (this.local === "Cashier/ManagerOptions" || this.local === "CashierDingo/ManagerOptions") {
            localStorage.setItem("UserLoggedTemp" + "/" + this.IP, JSON.stringify(userSelected))
            notify.message(
              "foo",
              "Success",
              "[" +
              this.translate("manager_Option") +
              "] " +
              this.translate("messageLoggedIn"),
              "success",
              2000
            );
          } else {
            notify.message(
              "foo",
              "Success",
              "[" +
              userSelected.Login +
              "] " +
              this.translate("messageLoggedIn"),
              "success",
              2000
            );
          }
          break;
      }

      this.$emit("close");
    },
    setTabIndex() {
      try {
        if (this.tabIndex > 5) {
          this.tabIndex = 0;
        }
        document.getElementById("lblLogin").blur();
        document.getElementById("lblPassword").blur();
        document.getElementById("btnCancel").blur();
        document.getElementById("btnOk").blur();
        document.getElementById("btnKeyboardLogin").blur();
        document.getElementById("btnKeyboardPassword").blur();
        switch (this.tabIndex) {
          case 0:
            document.getElementById("lblLogin").focus();
            break;
          case 1:
            document.getElementById("lblPassword").focus();
            break;
          case 2:
            document.getElementById("btnCancel").focus();
            break;
          case 3:
            document.getElementById("btnOk").focus();
            break;
          case 4:
            document.getElementById("btnKeyboardLogin").focus();
            break;
          case 5:
            document.getElementById("btnKeyboardPassword").focus();
            break;
        }
        console.log(this.tabIndex);
      } catch (e) {
        console.log("Error in setTabIndex() " + e);
      }
    },

    sendRequestInput(event) {
      if (event.which === 13) {
        this.sendRequest();
      }
    },
  },
  mounted() {
    try {
      this.$mousetrap.bind("enter", this.sendRequest);
      this.showKeyboardLogin = false;
      this.showKeyboardPassword = false;
      this.showKeyboardPin = false;
      this.errorAuth = false;
      this.errorAuthMessage = "";
      this.user = {
        login: "",
        password: "",
        pin: "",
      };
      switch (this.local) {
        case "/views/Dashboard/ReportManager":
        case "Cashier/ManagerOptions":
        case "CashierDingo/ManagerOptions":
          this.blockLogin = false
          break;
        case "Header/Restart":
        case "Header/Shutdown":
        case "Header/RunOsAdmin":
          if (this.completeLogin) {
            this.user.login = this.userLogged.Login;
          } else {
            this.blockLogin = false
          }
          break;
        default:
          this.user.login = this.userLogged.Login;
          break;
      }
    } catch (e) {
      console.log("Error in mounted()" + e);
    }
  },
  beforeDestroy() {
    console.log("unregistering enter event");
    this.$mousetrap.unbind("enter");
  },
  watch: {},
};
</script>

<style scoped src="@/css/styleModules.css">
#button {
  position: relative !important;
  float: right !important;
}

.modal-active {
  display: block;
}

.topnav button:hover {
  background-color: none !important;
  color: none !important;
  -webkit-animation: none !important;
  animation: none;
}
</style>
