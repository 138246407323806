<template>
  <form action="">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ translate("userManager_Title") }}</p>

        <button tabindex="-1" type="button" class="delete" @click.prevent="cancelUser()" v-if="!user.ChangePassword" />
      </header>

      <section class="modal-card-body">
        <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
          :can-cancel="false">
          <template #default="props">
            <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
              @ActionAfterLoadingUser="actionAfterLoadingUser" :route="route" :JsonObject="obj">
            </LoadingCMD>
          </template>
        </b-modal>
        <div class="mb-2" v-if="errorLogin" style="width: 100%; margin-top: 0.25rem">
          <Message type="is-danger" :message="errorLoginMessage" :isList="false" />
        </div>
        <div class="mb-2" v-if="user.ChangePassword" style="width: 100%; margin-top: 0.25rem">
          <Message type="is-warning" :message="this.translate('messageChangePassword')" :isList="false" />
        </div>
        <div class="row" v-if="!user.ChangePassword">
          <div class="col-lg-9">
            <Multiselect v-model="credetialsOptions" :tag-placeholder="translate('lblAddThisTag')"
              :placeholder="translate('lblSearchOrAddTag')" :selectLabel="translate('lblSelectALabel')"
              :deselectLabel="translate('lblDeselectLabel')" label="name" track-by="code" :options="options"
              :disabled="mode === 'view'" v-if="!isFromHeader" :multiple="true" tabindex="-1" :taggable="true"
              @tag="addTagOperator"></Multiselect>
          </div>

          <div class="col-lg-3">
            <b-field v-show="user.ReportView || (user.Root && mode === 'view')">
              <b-select :placeholder="translate('lblSelectALevel')" required v-if="!isFromHeader" v-model="user.Level"
                id="slReportView" tabindex="-1" :disabled="mode === 'view' || !user.ReportView" expanded>
                <option v-for="level in dataModule.reportLevels" v-bind:value="level.Name" :key="level.Name">
                  {{ level.Name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <br />

        <div class="row" v-if="!user.ChangePassword && user.Technician">
          <div class="col-lg-12">
            <Multiselect v-model="operatorOptions" :tag-placeholder="translate('lblAddThisOperator')"
              :placeholder="translate('lblSearchOrAddOperator')" :selectLabel="translate('lblSelectALabel')"
              :deselectLabel="translate('lblDeselectLabel')" label="name" track-by="code" :options="optionsOperator"
              :disabled="mode === 'view'" :multiple="true" tabindex="-1" :taggable="true" @tag="addTag"></Multiselect>
          </div>
        </div>
        <br />

        <b-field>
          <div class="col-sm-1">
            <button class="btn btn-outline-secondary" style="height: 41px" disabled>
              <i class="icons-lg0 icons-user" />
            </button>
          </div>
          <div class="col-sm-10">
            <b-input v-model="user.Login" :value="user.Login" @input="onInputChange" @focus="(showKeyboardConfirmPassword = false),
              (showKeyboardPassword = false)
              " :placeholder="translate('btnLogin')" maxlength="20" :disabled="mode !== 'add'" id="lblLogin"
              name="lblLogin" tabindex="1" @keydown.native="sendRequestInput">
            </b-input>
          </div>
          <button class="btn btn-outline-secondary" style="height: 41px" type="button" @click="showKeyboard('login')"
            v-if="mode === 'add'" id="btnKeyboardLogin" name="btnKeyboardLogin" tabindex="6">
            <i class="icons-lg0 icons-keyboard" />
          </button>
        </b-field>

        <div class="mb-2" v-if="showKeyboardLogin" style="width: 100%; margin-top: 0.25rem">
          <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="user.Login" />
        </div>

        <b-field>
          <div :class="availablePassword ? 'col-sm-1' : 'col-sm-5'" v-if="mode != 'view'">
            <button class="btn btn-outline-secondary" style="height: 41px" v-if="availablePassword" @click.prevent="availablePassword = availablePassword == true ? false : true
              " :disabled="mode !== 'edit' || user.ChangePassword || isFromHeader">
              <i class="icons-lg0 icons-key" />
            </button>
            <button class="btn btn-outline-secondary" style="height: 41px" v-else @click.prevent="availablePassword = availablePassword == true ? false : true
              ">
              <i class="icons-lg0 icons-key-change" /> {{ translate('changePassword') }}
            </button>
          </div>
          <div :class="availablePassword ? 'col-sm-10' : 'col-sm-5'">
            <b-field :type="{ 'is-success': validatePassword }" v-if="availablePassword && mode != 'view'">
              <b-input type="password" :placeholder="translate('lblPassword')" password-reveal required
                v-model="user.PrePassword" @input="checkValidatePassword()" @focus="(showKeyboardConfirmPassword = false),
                  (showKeyboardLogin = false)
                  " :value="user.PrePassword" maxlength="20" :disabled="mode === 'view'" id="lblPassword"
                name="lblPassword" @keydown.native="sendRequestInput" tabindex="2">
              </b-input>
            </b-field>
          </div>

          <button class="btn btn-outline-secondary" type="button" style="height: 41px" @click="showKeyboard('password')"
            v-if="mode !== 'view' && availablePassword" id="btnKeyboardPassword" name="btnKeyboardPassword" tabindex="7">
            <i class="icons-lg0 icons-keyboard" />
          </button>
        </b-field>

        <div class="mb-2" v-if="showKeyboardPassword && availablePassword" style="width: 100%; margin-top: 0.25rem">
          <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="user.PrePassword" />
        </div>
        <div v-if="mode !== 'view' && availablePassword">
          <b-field>
            <div class="col-sm-1">
              <button class="btn btn-outline-secondary" style="height: 41px" disabled tabindex="-1">
                <i class="icons-lg0 icons-key" />
              </button>
            </div>
            <div class="col-sm-10">
              <b-field :type="{ 'is-success': validatePassword }">
                <b-input type="password" :placeholder="translate('lblConfirmPassword')" password-reveal
                  @input="checkValidatePassword()" @focus="(showKeyboardLogin = false), (showKeyboardPassword = false)
                    " v-model="confirmPassword" :value="confirmPassword" maxlength="20" :disabled="mode === 'view'"
                  id="lblConfirmPassword" name="lblConfirmPassword" @keydown.native="sendRequestInput" tabindex="3">
                </b-input>
              </b-field>
            </div>

            <button class="btn btn-outline-secondary" type="button" style="height: 41px"
              @click="showKeyboard('confirmPassword')" v-if="mode !== 'view'" id="btnKeyboardConfirmPassword"
              name="btnKeyboardConfirmPassword" tabindex="8">
              <i class="icons-lg0 icons-keyboard" />
            </button>
          </b-field>

          <div class="mb-2" v-if="showKeyboardConfirmPassword" style="width: 100%; margin-top: 0.25rem">
            <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="confirmPassword" />
          </div>
        </div>

        <br />
        <div class="container" v-if="false">
          <div class="row">
            <div class="col">
              <div class="colLeft">
                <b-checkbox v-model="user.Cashier" :disabled="mode === 'view'">
                  {{ translate("cashier_Option") }}
                </b-checkbox>
                <b-checkbox v-model="user.Technician" :disabled="mode === 'view'">
                  {{ translate("technician_Option") }}
                </b-checkbox>
              </div>
            </div>
            <div class="col">
              <b-checkbox v-model="user.ReportView" :disabled="mode === 'view'" @input="setReportLevel()">
                {{ translate("reportView_Option") }}
              </b-checkbox>
              <b-checkbox v-model="user.Manager" :disabled="mode === 'view'">
                {{ translate("manager_Option") }}
              </b-checkbox>
            </div>

            <div class="col">
              <b-field>
                <b-select :placeholder="translate('lblSelectALevel')" required v-model="user.Level"
                  :disabled="mode === 'view' || !user.ReportView">
                  <option v-for="level in dataModule.reportLevels" v-bind:value="level.Name" :key="level.Name">
                    {{ level.Name }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button :label="translate('btnCancel')" icon-left=".icons-white icons-times" icon-pack="icons"
          @click.prevent="cancelUser()" id="btnCancel" name="btnCancel" v-if="!user.ChangePassword" tabindex="4">
        </b-button>
        <b-button @click.prevent="sendUser" :disabled="mode === 'view'" type="is-primary"
          icon-left=".icons-white icons-check" icon-pack="icons" id="btnAdd" name="btnAdd" tabindex="5">
          <span v-if="mode === 'add'">{{ translate("btnAdd") }}</span>
          <span v-else>{{ translate("btnSave") }}</span>
        </b-button>
      </footer>
    </div>
  </form>
</template>
<script>
import SimpleKeyboard from "@/components/keyboard/SimpleKeyboard";
import notify from "@/config/notify.js";
import md5 from "@/js/md5";
import validateField from "@/js/validateField";
import LoadingCMD from "@/components/processCMD/LoadingCMD.vue";
import Message from "@/components/message/Message.vue";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import Multiselect from "vue-multiselect";
import { returnApp } from "@/js/resetApp.js";
import { clientCommands } from "@/js/ClientCommands.js";
import { getUserPermissions, loggedStatus } from "@/mixins/enums.js";
import { countries } from "@/mixins/countryCode.js";
import { mapState } from "vuex";
export default {
  components: {
    SimpleKeyboard /*KeyboardNumeric*/,
    LoadingCMD,
    Multiselect,
    Message,
  },
  mixins: [en, es, nor, swe],
  props: {
    mode: String,
    user: {},
    isFromHeader: Boolean,
    users: [],
  },
  computed: mapState(["dataModule", "lang", "countryCode", "userLogged"]),
  data() {
    return {
      showKeyboardLogin: false,
      showKeyboardPassword: false,
      showKeyboardConfirmPassword: false,
      isLoading: false,
      sendingCMDMessage: "",
      countDown: 10,
      stopCountdown: false,
      errorLogin: false,
      errorLoginMessage: "",
      momentInstance: this.moment(),
      confirmPassword: "",
      validatePassword: false,
      availablePassword: false,
      userBackup: {},
      cmd: "",
      showLoadingCMD: false,
      obj: {},
      credetialsOptions: [],
      options: [],
      operatorOptions: [],
      optionsOperator: [],
      tabIndex: 0,
      route: "",
    };
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    setReportLevel() {
      if (this.mode === "add") {
        if (this.user.ReportView) {
          this.user.Level = this.dataModule.reportLevels[0].Name;
        } else {
          this.user.Level = "";
        }
      } else {
        if (this.user.ReportView) {
          this.user.Level =
            this.userBackup.Level == ""
              ? this.dataModule.reportLevels[0].Name
              : this.userBackup.Level;
        } else {
          this.user.Level = "";
        }
      }
    },
    cancelUser() {
      const newList = [];
      this.dataModule.users.forEach((userList) => {
        if (userList.Login === this.userBackup.Login) {
          newList.push(this.userBackup);
        } else {
          newList.push(userList);
        }
      });
      this.$store.commit("changeUsers", newList);
      this.user = JSON.parse(JSON.stringify(this.userBackup));
      this.users = newList;
      this.$emit("close");
    },
    sendUser() {      
      console.log("enter sendUser");
      this.errorLogin = false;
      try {
        if (validateField.regexLogin(this.user.Login)) {
          this.errorLogin = true;
          this.errorLoginMessage = this.translate("messageErrorInvalidName");
          return;
        }        
        if(this.user.Operators.length == 0 && this.user.Technician){
          this.errorLogin = true;
          this.errorLoginMessage = this.translate("messageErrorOperator");
          return;
        }

        if (this.mode === "add") {
          this.dataModule.usersAll.forEach((userList) => {
            if (userList.Login.toUpperCase() == this.user.Login.toUpperCase()) {
              this.errorLogin = true;
              this.errorLoginMessage =
                this.translate("messageUserExist1") +
                this.user.Login +
                this.translate("messageUserExist2");
              return;
            }
          });
        }

        const nameFunction = this.setFunction(this.user);
        if (nameFunction == "") {
          this.errorLogin = true;
          this.errorLoginMessage = this.translate("messageErrorOptions");
          return;
        }

        if (!this.validatePassword) {
          if (this.mode === "add") {
            this.errorLogin = true;
            this.errorLoginMessage =
              this.translate("errorMessageInvalidLogin") +
              ", " +
              this.translate("errorMessagePasswordAtLeast");
            return;
          } else if (this.mode === "edit") {
            if (this.availablePassword) {
              this.errorLogin = true;
              this.errorLoginMessage =
                this.translate("errorMessageInvalidLogin") +
                ", " +
                this.translate("errorMessagePasswordAtLeast");
              return;
            } else {
              this.user.Password == this.userBackup.Password;
            }
          }
        }

        if (!this.errorLogin) {
          if (this.user.Root && !this.user.ReportView) {
            this.user.Level = "Level 2";
          } else if (!this.user.Root && !this.user.ReportView) {
            this.user.Level = "";
          }

          this.user.CreateDate = this.moment(this.momentInstance).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.user.Function = nameFunction;
          if (this.user.PrePassword != "") {
            this.user.Password = md5.CalcuMD5(this.user.PrePassword);
          }
          const sendUser = JSON.parse(JSON.stringify(this.user));
          sendUser.PrePassword = "";
          sendUser.ChangePassword = false;
          if (this.mode === "edit") {
            this.sendingCMDMessage =
              this.translate("processEditUser") + " [" + sendUser.Login + "]";

            //Socket.send(
            //clientCommands.EditUser.name + "|" + JSON.stringify(sendUser)
            //);
            this.cmd = clientCommands.EditUser.name;
            this.countDown = clientCommands.EditUser.time;
            this.obj = sendUser;
            this.showLoadingCMD = true;
          } else {
            this.sendingCMDMessage =
              this.translate("processCreateUser") + " [" + sendUser.Login + "]";

            //Socket.send(
            //clientCommands.CreateUser.name + "|" + JSON.stringify(sendUser)
            //);
            this.cmd = clientCommands.CreateUser.name;
            this.countDown = clientCommands.CreateUser.time;
            this.obj = sendUser;
            this.showLoadingCMD = true;
          }

          //this.isLoading = true;
          //this.waitingForFinalCmd(this.user, cmd);
        }
      } catch (e) {
        console.log("Error in sendUserSettings() " + e);
      }
    },
    setFunction(user) {
      //
      let name = "";
      try {
        if (user.Cashier) {
          if (name == "") {
            name = this.translate("cashier_Option");
          } else {
            name = this.translate("lblMultipleFunctions");
          }
        }
        if (user.Technician) {
          if (name == "") {
            name = this.translate("technician_Option");
          } else {
            name = this.translate("lblMultipleFunctions");
          }
        }
        if (user.Manager) {
          if (name == "") {
            name = this.translate("lblSessionMangerFunction");
          } else {
            name = this.translate("lblMultipleFunctions");
          }
        }
        if (user.ReportView) {
          if (name == "") {
            name = this.translate("reportManager_Title");
          } else {
            name = this.translate("lblMultipleFunctions");
          }
        }
        if (user.Root) {
          if (name == "") {
            name = this.translate("root_Option");
          } else {
            name = this.translate("lblMultipleFunctions");
          }
        }
      } catch (e) {
        console.log("Error in setFunction() " + e);
      }

      return name;
    },
    closeLoading() {
      this.stopCountdown = true;
    },
    getUser() {
      alert(this.idUser);
    },
    beforeEnter() {
      console.log("antes");
    },
    checkPassword() {
      if (this.confirmPassword === this.user.PrePassword) {
        return "is-success";
      } else {
        return "is-error";
      }
    },
    showKeyboard(type) {
      if (type === "login") {
        this.showKeyboardPassword = false;
        this.showKeyboardPin = false;
        this.showKeyboardConfirmPassword = false;
        this.showKeyboardLogin = this.showKeyboardLogin == true ? false : true;
      } else if (type === "password") {
        this.showKeyboardLogin = false;
        this.showKeyboardPin = false;
        this.showKeyboardConfirmPassword = false;
        this.showKeyboardPassword =
          this.showKeyboardPassword == true ? false : true;
      } else if (type === "confirmPassword") {
        this.showKeyboardLogin = false;
        this.showKeyboardPin = false;
        this.showKeyboardPassword = false;
        this.showKeyboardConfirmPassword =
          this.showKeyboardConfirmPassword == true ? false : true;
      } else if (type === "pin") {
        this.showKeyboardLogin = false;
        this.showKeyboardPassword = false;
        this.showKeyboardConfirmPassword = false;
        this.showKeyboardPin = this.showKeyboardPin == true ? false : true;
      }
    },
    onChange(input) {
      if (this.showKeyboardLogin) {
        this.user.Login = input;
      } else if (this.showKeyboardPassword) {
        this.user.PrePassword = input;
      } else if (this.showKeyboardPin) {
        this.user.Pin = input;
      } else if (this.showKeyboardConfirmPassword) {
        this.confirmPassword = input;
      }
      this.checkValidatePassword()
    },
    onKeyPress(button) {
      console.log("button", button);
    },
    onInputChange(input) {
      if (this.showKeyboardLogin) {
        this.user.Login = input;
      } else if (this.showKeyboardPassword) {
        this.user.PrePassword = input;
      } else if (this.showKeyboardPin) {
        this.user.Pin = input;
      } else if (this.showKeyboardConfirmPassword) {
        this.confirmPassword = input;
      }
      this.checkValidatePassword()
    },

    actionAfterLoadingUser(data) {
      try {
        if (data.cmd === clientCommands.CreateUser.name) {
          this.$emit("close");
        } else if (data.cmd === clientCommands.EditUser.name) {
          this.dataModule.users.forEach((userList) => {
            if (userList.Login === this.user.Login) {
              userList = this.user;
            }
          });

          this.$store.commit("changeUsers", this.dataModule.users);

          if (this.user.ChangePassword) {
            this.$mousetrap.unbind("enter");
            this.$buefy.dialog.confirm({
              title: this.translate("dialog_Title"),
              message: this.translate("messageReLogin"),
              confirmText: this.translate("btnOk"),
              type: "is-primary",
              hasIcon: true,
              canCancel: false,
              onConfirm: () => this.sendLogout(),
            });
          } else {
            this.$emit("close");
          }
        } else if (data.cmd === clientCommands.LoggedOut.name) {
          this.signOut();
        }
      } catch (e) {
        console.log("Error in actionAfterLoadingUser() " + e);
      }
    },

    signOut() {
      try {
        notify.message(
          "foo",
          "Success",
          "user [" + this.userLogged.Login + " logged out]",
          "success",
          2000
        );
        //refresh();
        this.$store.commit("changehasControl", false);
        this.$store.commit("setUserLogged", {});
        this.$store.commit("changeCompleteLogin", false);
        this.$store.commit("setUserLoggedTemp", {});
        localStorage.removeItem("ManagerOptions" + '/' + this.IP);
        localStorage.removeItem("CashierOptions" + '/' + this.IP);
        localStorage.removeItem("UserLoggedTemp" + '/' + this.IP)
        this.$store.commit("changeIP", "");


        this.restart();
      } catch (e) {
        console.log("Error in SignOut " + e);
      }
    },

    restart() {
      this.$emit("close");
      returnApp(this);
    },

    sendLogout() {
      try {
        this.cmd = clientCommands.LoggedOut.name;
        this.user.Status = loggedStatus.NOT_LOGGED;
        const sendUser = JSON.parse(JSON.stringify(this.user));
        sendUser.PrePassword = "";
        sendUser.ChangePassword = false;
        this.obj = sendUser;
        this.countDown = clientCommands.LoggedOut.time;
        this.route = "/modules/UserSettings";
        this.sendingCMDMessage =
          this.translate("waiting") + " " + this.translate("sign_Out");
        this.showLoadingCMD = true;
      } catch (e) {
        console.log("Error in sendCmdLogout() " + e);
      }
    },

    waitingForFinalCmd: function (user, cmd) {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.processFinalCMD(user, cmd);
        }, 1000);
      }
    },

    processFinalCMD(user, cmd) {
      this.countDown -= 1;
      if (this.countDown == 0) {
        this.isLoading = false;
        notify.message(
          "foo",
          "Error",
          "Server did not respond to command [" + cmd + "] in 10 Seconds",
          "error",
          2000
        );
      } else {
        if (this.stopCountdown) {
          notify.message(
            "foo",
            "Success",
            "Server finished the command [" +
            cmd +
            "] User [ " +
            this.user.Login +
            "] ",
            "success",
            2000
          );
          this.countDown == 0;
          this.isLoading = false;
          this.$emit("close");
          if (this.mode === "add") {
            this.dataModule.users.push(this.user);
            this.$store.commit("changeUsers", this.dataModule.users);
          } else {
            //
            this.dataModule.users.forEach((userList) => {
              if (userList.Login === this.user.Login) {
                userList = this.user.Login;
              }
            });
            this.$store.commit("changeUsers", this.dataModule.users);
          }
        } else {
          this.waitingForFinalCmd(user, cmd);
        }
      }
    },
    checkValidatePassword() {
      if (this.confirmPassword == "" || this.user.PrePassword.length < 8) {
        this.validatePassword = false;
      } else {
        if (this.confirmPassword === this.user.PrePassword) {
          this.validatePassword = true;
        } else {
          this.validatePassword = false;
        }
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.credetialsOptions.push(tag);
    },

    addTagOperator(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.optionsOperator.push(tag);
      this.operatorOptions.push(tag);
    },
    checkCredentials() {
      this.user.ReportView = false;
      this.user.Cashier = false;
      this.user.Manager = false;
      this.user.Technician = false;
      this.user.Root = false;
      this.user.Operators = []
      this.credetialsOptions.forEach((option) => {
        if (option.code == "technician") {
          this.user.Technician = true;
        } else if (option.code == "cashier") {
          this.user.Cashier = true;
        } else if (option.code == "reportView") {
          this.user.ReportView = true;
          this.setReportLevel();
        } else if (option.code == "manager") {
          this.user.Manager = true;
        } else if (option.code == "root") {
          this.user.Root = true;
        }
      });

      this.options = getUserPermissions(
        countries,
        this.countryCode,
        this,
        this.userLogged,
        this.user
      );
    },

    checkOperations(){
      this.user.Operators = []      
      this.operatorOptions.forEach((user) => {
        this.user.Operators.push(user.name)
      } )
    },


    checkOperatorsEditOrView(){
      try{        
        this.user.Operators.forEach((op) => {
          this.operatorOptions.push({
            name: op,
            code: op,
          });
        })
      }catch(e){
        console.log("Error in checkOperatorsEditOrView() " + e);
      }
    },

    checkCredentialsEditOrView() {
      try {
        if (this.user.Technician == true) {
          this.credetialsOptions.push({
            name: this.translate("technician_Option"),
            code: "technician",
          });
        }
        if (this.user.Manager == true) {
          this.credetialsOptions.push({
            name: this.translate("manager_Option"),
            code: "manager",
          });
        }
        if (this.user.Cashier == true) {
          this.credetialsOptions.push({
            name: this.translate("cashier_Option"),
            code: "cashier",
          });
        }
        if (this.user.ReportView == true) {
          this.credetialsOptions.push({
            name: this.translate("reportView_Option"),
            code: "reportView",
          });
        }
        if (this.user.Root == true) {
          this.credetialsOptions.push({
            name: this.translate("root_Option"),
            code: "root",
          });
        }
      } catch (e) {
        console.log("Error in checkCredentialsEditOrView() " + e);
      }
    },
    setTabIndex() {
      if (this.tabIndex > 5) {
        this.tabIndex = 0;
      }

      document.getElementById("lblLogin").blur();
      document.getElementById("lblPassword").blur();
      document.getElementById("lblConfirmPassword").blur();
      document.getElementById("btnCancel").blur();
      document.getElementById("btnAdd").blur();
      document.getElementById("btnKeyboardLogin").blur();
      document.getElementById("btnKeyboardPassword").blur();
      document.getElementById("btnKeyboardConfirmPassword").blur();
      switch (this.tabIndex) {
        case 0:
          document.getElementById("lblLogin").focus();
          break;
        case 1:
          document.getElementById("lblPassword").focus();
          break;
        case 2:
          document.getElementById("lblConfirmPassword").focus();
          break;
        case 3:
          document.getElementById("btnCancel").focus();
          break;
        case 4:
          document.getElementById("btnOk").focus();
          break;
        case 5:
          document.getElementById("btnKeyboardLogin").focus();
          break;
        case 6:
          document.getElementById("btnKeyboardPassword").focus();
          break;
        case 7:
          document.getElementById("btnKeyboardConfirmPassword").focus();
          break;
      }
      this.tabIndex++;
    },
    sendRequestInput(event) {
      if (event.which === 13) {
        this.sendUser();
      }
    },
  },
  mounted() {
    //this.user = this.User;    
    this.availablePassword =
      (this.mode === "edit" && !this.user.ChangePassword) && !this.isFromHeader ? false : true;
    this.options = getUserPermissions(
      countries,
      this.countryCode,
      this,
      this.userLogged,
      this.user
    );    
    this.dataModule.Operator.forEach((op) => {
      const aux = {
        name: op,
        code: op
      }
      this.optionsOperator.push(aux)
    })

    if (this.mode == "edit") {
      this.user.PrePassword = "";
      this.checkCredentialsEditOrView();
      this.checkOperatorsEditOrView();
    } else if (this.mode == "view") {
      this.user.PrePassword = "xxxxxxxxxxxxxxx";
      this.checkCredentialsEditOrView();
      this.checkOperatorsEditOrView();
    }

    this.$mousetrap.bind("enter", this.sendUser);
    this.$mousetrap.bind("Tab", this.setTabIndex);
  },
  created() {
    this.userBackup = JSON.parse(JSON.stringify(this.user));
  },
  beforeDestroy() {
    console.log("unregistering enter event");
    this.$mousetrap.unbind("enter");
    this.$mousetrap.unbind("Tab");
  },
  watch: {
    credetialsOptions() {
      this.checkCredentials();
    },
    operatorOptions(){
      this.checkOperations();
    }
  },
};
</script>

<style>
.colLeft {
  float: left;
  width: 30px;
}

.help {
  display: none !important;
}
</style>
<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>