<template>
  <form action="" style="overflow-y: hidden">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ translate("token_Title") }}</p>
        <button tabindex="3" type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
          :can-cancel="false">
          <template #default="props">
            <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
              :JsonObject="tokenValidation">
            </LoadingCMD>
          </template>
        </b-modal>
        <Message v-if="!errorAuth" type="is-info" :message="translate('token_messageInfo')" :isList="false" />
        <div class="mb-2" v-else style="width: 100%; margin-top: 0.25rem">
          <Message type="is-info" :message="errorAuthMessage" :isList="false" />
        </div>
        <b-field v-if="showToken">
          <div class="col-sm-1">
            <button class="btn btn-outline-secondary" style="height: 41px" disabled>
              <i class="icons-1x icons-user" />
            </button>
          </div>
          <div class="col-sm-10">
            <b-input v-model="tokenValidation.Token" :value="tokenValidation.Token" @input="onInputChange"
              :placeholder="translate('token_Title')" maxlength="20" disabled>
            </b-input>
          </div>
          <div class="col-sm-1" v-if="isElectron">
            <button style="height: 41px" type="button" @click.prevent="copyToClipboard(tokenValidation.Token)">
              <i class="icons-1x icons-copy" />
            </button>
          </div>
        </b-field>

        <div class="mb-2" v-if="showKeyboardLogin" style="width: 100%; margin-top: 0.25rem">
          <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="user.login" />
        </div>

        <b-field v-if="showPassword">
          <div class="col-sm-1">
            <button class="btn btn-outline-secondary" style="height: 41px" disabled>
              <i class="icons-1x icons-key" />
            </button>
          </div>
          <div class="col-sm-10">
            <b-input tabindex="0" type="password" :placeholder="translate('lblPassword')" password-reveal
              v-model="tokenValidation.Password" :value="tokenValidation.Password" maxlength="8"
              @keydown.native="sendRequestInput">
            </b-input>
          </div>

          <button tabindex="4" class="btn btn-outline-secondary" type="button" style="height: 41px"
            @click="showKeyboard('password')">
            <i class="icons-1x icons-keyboard" />
          </button>
        </b-field>

        <div class="mb-2" v-if="showKeyboardPassword" style="width: 100%; margin-top: 0.25rem">
          <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="this.tokenValidation.Password" :pin="8" />
        </div>

        <b-field label="Pin" v-if="showPin">
          <div class="col-sm-1">
            <button class="btn btn-outline-secondary" style="height: 41px" disabled>
              <i class="icons-1x icons-lock" />
            </button>
          </div>
          <div class="col-sm-10">
            <b-input placeholder="Pin" v-model="user.pin" :value="user.pin" type="number" max="999999" min="0"
              maxlength="7"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57" @keydown.native="sendRequestInput">
            </b-input>
          </div>

          <button class="btn btn-outline-secondary" type="button" @click="showKeyboard('pin')">
            <i class="icons-1x icons-keyboard" />
          </button>
        </b-field>
        <div class="mb-2" v-if="showKeyboardPin" style="width: 100%; margin-top: 0.25rem">
          <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="user.pin" :isNumeric="true" :pin="7" />
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button :label="translate('btnCancel')" icon-left=".icons-white icons-times" icon-pack="icons"
          @click="$emit('close')" tabindex="1" />
        <b-button tabindex="2" @click="sendRequest()" :label="translate('btnOk')" type="is-primary"
          icon-left=".icons-white icons-check" icon-pack="icons" />
      </footer>
    </div>
  </form>
</template>

<script>
import { clientCommands } from "@/js/ClientCommands.js";
import { mapState } from "vuex";
import SimpleKeyboard from "../keyboard/SimpleKeyboard.vue";
import Socket from "@/config/webSocket.js";
import SocketDriver from "@/config/webSocketDriver.js";
import LoadingCMD from "@/components/processCMD/LoadingCMD";
import Message from "@/components/message/Message.vue";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
export default {
  name: "Token",
  mixins: [en, es, nor, swe],
  components: { SimpleKeyboard /*KeyboardNumeric*/, LoadingCMD, Message },
  computed: mapState(["dataModule", "lang", "token", "isElectron"]),
  data: function () {
    return {
      showKeyboardLogin: false,
      showKeyboardPassword: false,
      showKeyboardPin: false,
      errorAuth: false,
      errorAuthMessage: "",
      tokenValidation: {
        Password: "",
        Token: "",
      },
      cmd: "",
      countDown: 10,
      showLoadingCMD: false,
      sendingCMDMessage: "",
      tokenTry: 3,
    };
  },
  props: {
    route: String,
    showToken: Boolean,
    showPassword: Boolean,
    showPin: Boolean,
    local: String,
    Action: Function,
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    showKeyboard(type) {
      if (type === "login") {
        this.showKeyboardPassword = false;
        this.showKeyboardPin = false;
        this.showKeyboardLogin = this.showKeyboardLogin ? false : true;
      } else if (type === "password") {
        this.showKeyboardLogin = false;
        this.showKeyboardPin = false;
        this.showKeyboardPassword =
          this.showKeyboardPassword ? false : true;
      } else if (type === "pin") {
        this.showKeyboardLogin = false;
        this.showKeyboardPassword = false;
        this.showKeyboardPin = this.showKeyboardPin ? false : true;
      }
    },
    onChange(input) {
      if (this.showKeyboardLogin) {
        this.tokenValidation.Token = input;
      } else if (this.showKeyboardPassword) {
        this.tokenValidation.Password = input;
      }
    },
    onKeyPress(button) {
      console.log("button", button);
      if (button === "{clear}") {
        if (this.showKeyboardLogin) {
          this.tokenValidation.Token = "";
        } else if (this.showKeyboardPassword) {
          this.tokenValidation.Password = "";
        }
      }
    },
    onInputChange(input) {
      if (this.showKeyboardLogin) {
        this.tokenValidation.Token = input;
      } else if (this.showKeyboardPassword) {
        this.tokenValidation.Password = input;
      }
    },
    sendRequest() {
      Socket.send(clientCommands.ValidateToken.name + "|" + JSON.stringify(this.tokenValidation) + "|" + this.token);
    },
    getToken() {
      Socket.send(clientCommands.CreateToken.name + "||" + this.token);
    },
    setToken(tokenValidation) {
      this.tokenValidation.Token = tokenValidation.Token;
    },
    checkValidateToken(data) {
      if (data.Ok) {
        switch (this.route) {
          case "/modules/Bonus":
            this.$emit("ActionAfterTokenBonus");
            break;
          case "/modules/Progressive":
            this.$emit("ActionAfterTokenProgressive");
            break;
          case "/modules/cashier/promotion/generateCoupon":
            this.$emit("ActionAfterTokenGenerateCoupon");
            break;
          default:
            break;
        }
        this.$emit("close");
      } else {
        this.tokenTry--;
        this.errorAuth = true;
        this.errorAuthMessage =
          this.translate("token_messageError1") +
          this.tokenTry +
          this.translate("token_messageError2");
        if (this.tokenTry == 0) {
          this.$emit("close");
        }
      }
    },
    sendRequestInput(event) {
      if (event.which === 13) {
        this.sendRequest();
      }
    },
    copyToClipboard(text) {
      this.$copyText(text);
    }
  },
  mounted() {
    this.$mousetrap.bind("enter", this.sendRequest);
  },
  beforeDestroy() {
    console.log("unregistering enter event");
    this.$mousetrap.unbind("enter");
  },
  created() {
    this.getToken();
    SocketDriver.$on("finishedCMDGetToken", this.setToken);
    SocketDriver.$on("finishedCMDValidateToken", this.checkValidateToken);
  },
};
</script>