<template>
  <form action="" style="overflow-y: hidden">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ translate("reportLevelManager_Title") }}
        </p>
        <button type="button" class="delete" @click.prevent="cancelReport()" />
      </header>
      <section class="modal-card-body">
        <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
          :can-cancel="false">
          <template #default="props">
            <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
              @ActionAfterLoadingReport="actionAfterLoadingReport" :route="route" :JsonObject="obj">
            </LoadingCMD>
          </template>
        </b-modal>
        <div id="headerButtons">
          <div class="row">
            <div class="col mb-1">
              <b-field>
                <div class="col-sm-1">
                  <button class="btn btn-outline-secondary" style="height: 41px" disabled>
                    <i class="icons icons-id-card" aria-hidden="true"></i>
                  </button>
                </div>
                <div class="col-sm-10">
                  <b-input v-model="report.Name" :value="report.Name" placeholder="Name" maxlength="20" :disabled="true">
                  </b-input>
                </div>
              </b-field>
            </div>
          </div>
        </div>
        <div v-if="showModalMessage">
          <Message type="is-danger" :message="errorMessage" :isList="false" />
        </div>
        <br />
        <div class="row">
          <!-- Coluna 1 -->
          <div class="col-sm-4 mb-2" id="columns">
            <div id="columns1" class="mb-2">
              <div id="columnInside">
                <b-form-group>
                  <template #label>
                    <b-form-checkbox v-model="allSelectFinancial" aria-describedby="financials" aria-controls="financials"
                      @change="changeFinancials()" :disabled="mode === 'view'">
                      <b> {{ translate("lblFinancial") }}</b>
                    </b-form-checkbox>
                  </template>
                  <template v-for="(financial, index) in report.Financials">
                    <b-form-checkbox v-if="financial.Visible"
                       :key="index"
                      v-model="financial.Value" :disabled="financial.Disabled || mode === 'view'"
                      @change="changeAllFinancials()">
                      {{ translate(financial.Text) }}
                    </b-form-checkbox>
                  </template>
                </b-form-group>
              </div>
            </div>
            <div id="columns1" class="mb-2">
              <div id="columnInside">
                <b-form-group>
                  <template #label>
                    <b-form-checkbox v-model="allProgressive" aria-describedby="progressive" aria-controls="progressives"
                      @change="changeProgressive()" :disabled="mode === 'view'">
                      <b> {{ translate(isFBM?"lblProgressive":"lblProgressiveDNG") }} </b>
                    </b-form-checkbox>
                  </template>
                  <template v-for="(progressive, index) in report.Progressives">
                    <b-form-checkbox v-if="progressive.Visible"
                       :key="index"
                      v-model="progressive.Value" :disabled="progressive.Disabled || mode === 'view'"
                      @change="changeAllProgressives()">
                      {{ translate(progressive.Text) }}
                    </b-form-checkbox>
                  </template> 
                </b-form-group>
              </div>
            </div>
            <div id="columns1" class="mb-2">
              <div id="columnInside">
                <b-form-group>
                  <template #label>
                    <b-form-checkbox v-model="allJackoptGlobal" aria-describedby="jackpotGlobal"
                      aria-controls="jackpotGlobal" @change="changeJackpotGlobal()" :disabled="mode === 'view'">
                      <b>{{ translate("lblJackpotGlobal") }}</b>
                    </b-form-checkbox>
                  </template>
                  <template v-for="(jackpotGlobal, index) in report.JackpotsGlobal">
                    <b-form-checkbox v-if="jackpotGlobal.Visible"
                      :key="index" 
                      v-model="jackpotGlobal.Value"
                      :disabled="jackpotGlobal.Disabled || mode === 'view'" 
                      @change="changeAllJackpotGlobals()">
                      {{ translate(jackpotGlobal.Text) }}
                    </b-form-checkbox>
                  </template>
                </b-form-group>
              </div>
            </div>
          </div>
          <!-- Fim Coluna 1 -->
          <!-- Coluna 2 -->
          <div class="col-sm-4 mb-2" id="columns">
            <div id="columns1" class="mb-2">
              <div id="columnInside">
                <b-form-group>
                  <template #label>
                    <b-form-checkbox v-model="allSelectVoucher" aria-describedby="financials" aria-controls="financials"
                      @change="changeVouchers()" :disabled="mode === 'view'">
                      <b>{{ translate("lblVouchers") }}</b>
                    </b-form-checkbox>
                  </template>
                  <template v-for="(voucher, index) in report.Vouchers">
                    <b-form-checkbox v-if="voucher.Visible"
                      :key="index" 
                      v-model="voucher.Value" 
                      :disabled="voucher.Disabled || mode === 'view'"
                      @change="changeAllVouchers()">
                      {{ translate(voucher.Text) }}
                    </b-form-checkbox>
                  </template>
                </b-form-group>
              </div>
            </div>
            <div id="columns1" class="mb-2">
              <div id="columnInside">
                <b-form-group>
                  <template #label>
                    <b-form-checkbox v-model="allTerminal" aria-describedby="terminal" aria-controls="terminal"
                      @change="changeTerminal()" :disabled="mode === 'view'">
                      <b>{{ translate("lblTerminal") }}</b>
                    </b-form-checkbox>
                  </template>
                  <template v-for="(terminal, index) in report.Terminals">
                    <b-form-checkbox v-if="terminal.Visible"
                      :key="index" 
                      v-model="terminal.Value"
                      :disabled="terminal.Disabled || mode === 'view'" 
                      @change="changeAllTerminals()">
                      {{ translate(terminal.Text) }}
                    </b-form-checkbox>
                  </template>
                </b-form-group>
              </div>
            </div>

          </div>
          <!-- Fim Coluna 2 -->
          <!-- Coluna 3 -->
          <div class="col-sm-4 mb-2" id="columns">
            <div id="columns1" class="mb-2">
              <div id="columnInside">
                <b-form-group>
                  <template #label>
                    <b-form-checkbox v-model="allCashier" aria-describedby="cashier" aria-controls="cashier"
                      @change="changeCashier()" :disabled="mode === 'view'">
                      <b>{{ translate("cashier_cashierTitle") }}</b>
                    </b-form-checkbox>
                  </template>
                  <template v-for="(cashier, index) in report.Cashiers">
                    <b-form-checkbox v-if="cashier.Visible"
                      :key="index" 
                      v-model="cashier.Value"
                      :disabled="cashier.Disabled || mode === 'view'" 
                      @change="changeAllCashiers()">
                      {{ translate(cashier.Text) }}
                    </b-form-checkbox>
                  </template>  
                </b-form-group>
              </div>
            </div>
            <div id="columns1" class="mb-2">
              <div id="columnInside">
                <b-form-group>
                  <template #label>
                    <b-form-checkbox v-model="allBonus" aria-describedby="bonus" aria-controls="bonus"
                      @change="changeBonus()" :disabled="mode === 'view'">
                      <b>{{ translate("bonus_Title") }}</b>
                    </b-form-checkbox>
                  </template>
                  <template v-for="(bonus, index) in report.Bonus">
                    <b-form-checkbox v-if="bonus.Visible"
                      :key="index" 
                      v-model="bonus.Value"
                      :disabled="bonus.Disabled || mode === 'view'" 
                      @change="changeAllBonus()">
                      {{ translate(bonus.Text) }}
                    </b-form-checkbox>
                  </template>
                </b-form-group>
              </div>
            </div>
            <div id="columns1" class="mb-2">
              <div id="columnInside">
                <b-form-group>
                  <template #label>
                    <b-form-checkbox v-model="allGame" aria-describedby="games" aria-controls="games"
                      @change="changeGame()" :disabled="mode === 'view'">
                      <b>{{ translate("lblGame") }}</b>
                    </b-form-checkbox>
                  </template>
                  <template v-for="(game, index) in report.Games">
                    <b-form-checkbox v-if="game.Visible"
                      :key="index" 
                      v-model="game.Value"
                      :disabled="game.Disabled || mode === 'view'" 
                      @change="changeAllGames()">
                      {{ translate(game.Text) }}
                    </b-form-checkbox>
                  </template>
                </b-form-group>
              </div>
            </div>
          </div>
          <!-- Fim Coluna 3 -->
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button :label="translate('btnCancel')" icon-left=".icons-white icons-times" icon-pack="icons"
          @click.prevent="cancelReport()">
        </b-button>
        <b-button @click.prevent="sendReport" :disabled="mode === 'view'" type="is-primary"
          icon-left=".icons-white icons-check" icon-pack="icons">
          <span v-if="mode === 'add'">{{ translate("btnAdd") }}</span>
          <span v-else>{{ translate("btnSave") }}</span>
        </b-button>
      </footer>
    </div>
  </form>
</template>
<script>
import { clientCommands } from "@/js/ClientCommands.js";
import { mapState } from "vuex";
import { countries } from "@/mixins/countryCode.js";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import LoadingCMD from "@/components/processCMD/LoadingCMD.vue";
import ReportName from "@/js/ReportsName.js";
import Message from "@/components/message/Message.vue";
export default {
  components: { LoadingCMD, Message },
  mixins: [en, es, nor, swe],
  props: {
    mode: String,
    report: {},
    reports: [],
  },
  computed: mapState(["dataModule", "lang", "countryCode", "isFBM"]),
  data() {
    return {
      selected: [],
      allSelectFinancial: true,
      allSelectVoucher: true,
      allProgressive: true,
      allCashier: true,
      allTerminal: true,
      allGame: true,
      allJackoptGlobal: true,
      allBonus: true,
      isLoading: false,
      sendingCMDMessage: "",
      countDown: 10,
      stopCountdown: false,
      reportBackup: {},
      cmd: "",
      showLoadingCMD: false,
      obj: {},
      oneSelected: false,
      messageError: "",
      showModalMessage: false,
    };
  },
  methods: {

    translate(prop) {
      return this[this.lang][prop];
    },
    reportTranslateName(name) {
      return ReportName.translateName(name, this.lang);
    },
    cancelReport() {
      const newList = [];
      this.dataModule.reportLevels.forEach((reportLevelList) => {
        if (reportLevelList.Name === this.reportBackup.Name) {
          newList.push(this.reportBackup);
        } else {
          newList.push(reportLevelList);
        }
      });

      this.$store.commit("changeReports", newList);
      this.report = JSON.parse(JSON.stringify(this.reportBackup));
      this.reports = newList;
      this.$emit("close");
    },
    changeFinancials() {
      this.report.Financials.forEach((element) => {
        element.Value = this.allSelectFinancial;
      });
    },
    changeAllFinancials() {
      this.allSelectFinancial = true;
      this.report.Financials.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allSelectFinancial = false;
        }
      });
    },
    changeVouchers() {
      this.report.Vouchers.forEach((element) => {
        element.Value = this.allSelectVoucher;
      });
    },
    changeAllVouchers() {
      this.allSelectVoucher = true;
      this.report.Vouchers.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allSelectVoucher = false;
        }
      });
    },
    changeProgressive() {
      this.report.Progressives.forEach((element) => {
        element.Value = this.allProgressive;
      });
    },
    changeAllProgressives() {
      this.allProgressive = true;
      this.report.Progressives.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allProgressive = false;
        }
      });
    },
    changeCashier() {
      this.report.Cashiers.forEach((element) => {
        element.Value = this.allCashier;
      });
    },
    changeAllCashiers() {
      this.allCashier = true;
      this.report.Cashiers.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allCashier = false;
        }
      });
    },
    changeTerminal() {
      this.report.Terminals.forEach((element) => {
        element.Value = this.allTerminal;
      });
    },
    changeAllTerminals() {
      this.allTerminal = true;
      this.report.Terminals.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allTerminal = false;
        }
      });
    },
    changeJackpotGlobal() {
      this.report.JackpotsGlobal.forEach((element) => {
        element.Value = this.allJackoptGlobal;
      });
    },
    changeAllJackpotGlobals() {
      this.allJackoptGlobal = true;
      this.report.JackpotsGlobal.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allJackoptGlobal = false;
        }
      });
    },
    changeBonus() {
      this.report.Bonus.forEach((element) => {
        element.Value = this.allBonus;
      });
    },
    changeAllBonus() {
      this.allBonus = true;
      this.report.Bonus.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allBonus = false;
        }
      });
    },
    changeGame() {
      this.report.Games.forEach((element) => {
        element.Value = this.allGame;
      });
    },
    changeAllGames() {
      this.allGame = true;
      this.report.Games.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allGame = false;
        }
      });
    },

    changeLevel() {

      //this.report = this.dataModule.reportLevels[this.report.level]
    },
    sendReport() {
      //
      this.checkAll();
      this.showModalMessage = false;
      if (this.oneSelected) {
        if (this.mode === "add") {
          //
          this.sendingCMDMessage = this.translate("messageCreateReport");
          this.cmd = clientCommands.CreateReport.name;
          this.countDown = clientCommands.CreateReport.time;
          this.obj = JSON.parse(JSON.stringify(this.report));
          this.showLoadingCMD = true;
        } else if (this.mode === "edit") {
          //
          this.sendingCMDMessage = this.translate("messageEditReport");
          this.cmd = clientCommands.EditReport.name;
          this.countDown = clientCommands.EditReport.time;
          this.obj = JSON.parse(JSON.stringify(this.report));
          this.showLoadingCMD = true;
        }
      } else {
        this.showModalMessage = true;
        this.errorMessage = this.translate("errorMessageInvalidReport");
      }
    },

    actionAfterLoadingReport(data) {
      if (data.cmd === clientCommands.CreateReport.name) {
        this.dataModule.reportLevels.push(this.report);
        this.$store.commit("changeReports", this.dataModule.reportLevels);
        this.$emit("close");
      } else {
        this.dataModule.reportLevels.forEach((reportList) => {
          if (reportList.Name === this.report.Name) {
            reportList = this.report;
          }
        });
        this.$store.commit("changeReports", this.dataModule.reportLevels);
        this.$emit("close");
      }
    },

    checkAll() {
      this.allSelectFinancial = true;
      this.oneSelected = false;
      this.report.Financials.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allSelectFinancial = false;
        } else {
          this.oneSelected = true;
        }
      });
      this.allProgressive = true;
      this.report.Progressives.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allProgressive = false;
        } else {
          this.oneSelected = true;
        }
      });
      this.allJackoptGlobal = true;
      this.report.JackpotsGlobal.forEach((element) => {
        if (!element.Value && element.Visible)  {
          this.allJackoptGlobal = false;
        } else {
          this.oneSelected = true;
        }
      });
      this.allGame = true;
      this.report.Games.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allGame = false;
        } else {
          this.oneSelected = true;
        }
      });
      this.allSelectVoucher = true;
      this.report.Vouchers.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allSelectVoucher = false;
        } else {
          this.oneSelected = true;
        }
      });
      this.allTerminal = true;
      this.report.Terminals.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allTerminal = false;
        } else {
          this.oneSelected = true;
        }
      });
      this.allCashier = true;
      this.report.Cashiers.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allCashier = false;
        } else {
          this.oneSelected = true;
        }
      });
      this.allBonus = true;
      this.report.Bonus.forEach((element) => {
        if (!element.Value && element.Visible) {
          this.allBonus = false;
        } else {
          this.oneSelected = true;
        }
      });
    },
    closeLoading() {
      this.stopCountdown = true;
    },
    isSweden() {
      return this.countryCode == countries.Sweden
    },

    showBonus(text) {
      let ret = true
      if (text == 'Mystery Prize Report' && this.isSweden()) {
        ret = false
      }
      else if (text == 'Bonus Prize Report' && !this.isFBM) {
        ret = false
      }
      return ret
    }
  },
  mounted() {
    this.checkAll();
  },
  created() {
    this.reportBackup = JSON.parse(JSON.stringify(this.report));
  },
  watch: {
    report() {
      if (this.report != {}) this.checkAll();
    },
  },
};
</script>

<style >
#columns1 {
  border-width: 0.5px;
  border-radius: 10px;
  border-style: solid;
  border-color: #dad8d8;
}

#columnInside {
  padding-top: 10px;
  padding-left: 5px;
}
</style>