<template>
  <div class="iniFile">
    <br />
    <notifications group="foo" position="center right" />
    <div class="auth-content">
      <div class="auth-modal" style="width: 450px">
        <div class="auth-title">
          {{ this.translate("iniFront_Title") }}
        </div>

        <div class="form-row">
          <b-field label="">
            <label for="staticEmail" class="col-sm-4 col-form-label">{{
              this.translate("lblPreferencialIP")
            }}</label>
            <div class="col-sm-6 mb-2">
              <b-form-input
                list="connectionIPs"
                name="connectionIP"
                :placeholder="this.translate('lblPreferencialIP')"
                id="connectionIP"
                class="form-control"
                v-model="iniFile.IP"
                :state="errorConnectionIP ? false : null"
              />
              {{ path }}
              <div
                v-if="errorConnectionIP"
                style="
                  width: 100%;
                  margin-top: 0.25rem;
                  font-size: 80%;
                  color: #f86c6b;
                "
              >
                {{ this.translate("messageErrorIP") }}
              </div>
            </div>
            <div class="col-sm-1">
              <button
                tabindex="3"
                class="btn btn-outline-secondary"
                style="height: 40px"
                type="button"
                @click="showKeyboardIP = !showKeyboardIP"
              >
                <i class="icons-lg0 icons-keyboard" />
              </button>
            </div>
          </b-field>
          <div
            class="mb-2"
            v-if="showKeyboardIP"
            style="width: 100%; margin-top: 0.25rem"
          >
            <SimpleKeyboard
              @onChange="onChange"
              @onKeyPress="onKeyPress"
              :input="iniFile.IP"
              :isNumeric="true"
              :hasDot="true"
            />
          </div>
          <div style="float: left">
            <b-button
              :label="translate('btnExit')"
              icon-left=".icons-white icons-times"
              icon-pack="icons"
              style="margin-right: 10px"
              :disabled="disableBtn"
              @click="cancelIni()"
            ></b-button>
            <b-button
              :label="translate('btnOk')"
              type="is-primary"
              icon-left=".icons-white icons-check"
              icon-pack="icons"
              @click="processSaveIni()"
              :disabled="disableBtn"
            ></b-button>
          </div>
        </div>
        <b-modal
          v-model="showRestart"
          width="600px"
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="false"
        >
          <b-message type="is-success" has-icon>
            {{ message }}
            <div style="float: right">
              <b-button
                v-if="showRestart"
                type="is-primary"
                style="margin-right: 10px"
                disabled
              >
                {{ translate("btnWait") }}
              </b-button>
            </div>
          </b-message>
        </b-modal>
        <b-modal
          v-model="showLoadingCMD"
          width="600px"
          trap-focus
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal
          :can-cancel="false"
        >
          <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="false"
          >
            <div class="circle"></div>
            <div class="circle-small"></div>
            <div class="circle-big"></div>
            <div class="circle-inner-inner"></div>
            <div class="circle-inner"></div>
            <div class="textHeader" style="top: 65%">
              <span style="color: white">
                {{ translate("lblValidatinIP") }}
              </span>
            </div>
          </b-loading>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { closeWindow } from "@/js/screenAdjustment.js";
import validateField from "@/js/validateField";
import JsonFile from "@/js/jsonFile.js";
import notify from "@/config/notify.js";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";

import SimpleKeyboard from "@/components/keyboard/SimpleKeyboard.vue";
export default {
  name: "IniFileFront",
  mixins: [en, es, nor,swe],
  computed: mapState([
    "session",
    "communication",
    "hasDatabase",
    "listIps",
    "gistraDefault",
    "user",
    "lang",
  ]),
  components: { SimpleKeyboard },

  data() {
    return {
      showModalReset: false,
      firstTime: true,
      disableBtn: false,
      errorConnectionIP: false,
      iniFile: {
        IP: "",
      },
      showRestart: false,
      countDown: 10,
      message: "",
      isLoading: true,
      showLoadingCMD: false,
      responseFromTestConnection: false,
      showKeyboardIP: false,
    };
  },

  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    onChange(input) {
      this.iniFile.IP = input;
    },
    onKeyPress(button) {
      console.log("button", button);
      if (button === "{clear}") {
        this.iniFile.IP = "";
      }
    },
    onInputChange(input) {
      this.iniFile.IP = input;
    },
    processSaveIni() {
      //
      try {
        this.disableBtn = true;
        if (validateField.regexIP(this.iniFile.IP)) {
          this.errorConnectionIP = true;
          this.disableBtn = false;
        } else {
          this.errorConnectionIP = false;
          this.showLoadingCMD = true;
          let socket = new WebSocket(window.origin.includes('https') ? 'wss://' + this.iniFile.IP + ':4999/ws' : 'ws://' + this.iniFile.IP + ':5000/ws');
          JsonFile.testConnection(socket);
          this.responseFromTestConnection = false;
          this.checkResponse(socket);
        }
      } catch (e) {
        this.disableBtn = false;
        console.log("Error in processSaveIni() " + e);
      }
    },

    checkResponse(socket) {
      let time = 0;
      this.interval = setInterval(() => {
        try {
          console.log("/waiting reply from Test Connection [" + time + "]");

          if (this.responseFromTestConnection) {
            console.log("/closed checkResponse()");
            if (socket != null) {
              socket.close();
              socket = null;
            }
            clearInterval(this.interval);
          } else if (time == 10) {
            console.log("/no reply from Test Connection");
            this.saveIni(false);
            if (socket != null) {
              socket.close();
              socket = null;
            }
            clearInterval(this.interval);
          }
          time += 1;
        } catch (e) {
          console.log("Error in checkResponse() " + e);
          if (socket != null) {
            socket.close();
            socket = null;
          }
          clearInterval(this.interval);
        }
      }, 1000);
    },

    saveIni(check) {
      this.responseFromTestConnection = true;
      this.showLoadingCMD = false;
      if (check) {
        let ret = JsonFile.create(this.iniFile);
        if (ret == "") {
          //notify.message("foo", "Success", "Created iniFile", "success", 2000);
          this.$store.commit("changeIP", this.iniFile.IP);
          this.message = this.translate("messageCreatedIniFile");
          this.countDown = 1;
          this.showRestart = true;
          this.finishConfig();
        } else {
          this.disableBtn = false;
          notify.message("foo", "Error", ret, "error", 2000);
        }
      } else {
        this.disableBtn = false;
        notify.message(
          "foo",
          "Error",
          this.translate("messageNoConnection") + " [" + this.iniFile.IP + "]",
          "error",
          2000
        );
      }
    },
    cancelIni() {
      this.$buefy.dialog.confirm({
        title: this.translate("iniFile_Title"),
        message: this.translate("messageNeedsIPConfig"),
        confirmText: this.translate("btnExit"),
        cancelText: this.translate("btnBack"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.closeServer(),
      });
    },
    finishConfig() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          if (this.countDown <= 0) {
            if (this.$route.path != "/app") {
              {
                this.$router.push("/app").catch((e) => {
                  console.log("Error in Route->App()" + e);
                });
              }
            }
          } else {
            this.finishConfig();
          }
        }, 1000);
      }
    },
    closeServer() {
      closeWindow();
    },
  },
  created() {
    //
    this.$store.commit("setLang", "en");
    JsonFile.$on("saveIni", this.saveIni);
    this.$store.commit("changehasControl", false);
    this.$store.commit("setUserLogged", {});
    this.$store.commit("changeCompleteLogin", false);
    this.$store.commit("setUserLoggedTemp", {});
    this.$store.commit("changeIP", "");
  },
  beforeDestroy() {
    console.log("unregistering enter event");
    this.$mousetrap.unbind("enter");
  },
  mounted() {
    this.$mousetrap.bind("enter", this.processSaveIni);
  },
  watch: {},
};
</script>

<style scoped src="@/css/styleModules.css">
#button {
  position: relative;
  float: right;
}
.modal-active {
  display: block;
}
</style>