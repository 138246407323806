<template>
  <form action="javascript:void(0);" style="overflow-y: hidden">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ translate("language") }}
        </p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
          :can-cancel="false">
          <template #default="props">
            <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
              :JsonObject="obj" @ActionAfterLanguage="afterLanguage">
            </LoadingCMD>
          </template>
        </b-modal>
        <div class="col-sm-12">
          <b-field>
            <b-select v-model="newLang" required icon=".icons-white icons-globe" icon-pack="icons" style="width: 100%"
              expanded>
              <option value="en">{{ this['en']['languageName'] }}</option>
              <option value="es">{{ this['es']['languageName'] }}</option>
              <option value="nor">{{ this['nor']['languageName'] }}</option>
              <option value="swe">{{ this['swe']['languageName'] }}</option>

            </b-select>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button @click="$emit('close')" :label="translate('btnCancel')" icon-left=".icons-white icons-times"
          icon-pack="icons" />
        <b-button :label="translate('btnOk')" @click="changeLanguage()" type="is-primary"
          icon-left=".icons-white icons-check" icon-pack="icons" />
      </footer>
    </div>
  </form>
</template>

<script>
import LoadingCMD from "@/components/processCMD/LoadingCMD";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import { mapState } from "vuex";
import { clientCommands } from "@/js/ClientCommands";
export default {
  name: "Language",
  components: { LoadingCMD },
  mixins: [en, es, nor, swe],
  computed: mapState(["communication", "user", "lang", "locale"]),
  data() {
    return {
      cmd: "",
      countDown: 10,
      showLoadingCMD: false,
      sendingCMDMessage: "",
      locale: "",
      obj: {},
      newLang:"",
      data: {},
    };
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    changeLanguage() {
      try {
        this.cmd = clientCommands.SetLanguage.name;
        this.obj = {
          Value: this.lang,
        };
        this.countDown = clientCommands.SetLanguage.time;
        this.sendingCMDMessage = this[this.lang]["messageSetLanguage"]; // Mostra a mensagem de espera no idioma escolhido
        this.showLoadingCMD = true;
      } catch (e) {
        console.log("Error in changeLanguage() " + e);
      }
    },
    afterLanguage() {
      this.$store.commit("setLang", this.newLang);

      switch (this.newLang) {
        case "es":
          this.$store.commit("setLocale", "es-ES");
          break;
        case "nor":
          this.$store.commit("setLocale", "nn-NO");
          break;
        case "swe":
          this.$store.commit("setLocale", "sv-SE");
          break;
        case "en":
        default:
          this.$store.commit("setLocale", "en-US");
          break;
      }
      this.$emit("close");
    },
  },
  created() {
    this.newLang = this.lang;
  },
};
</script>

<style></style>
