import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import { getPermissionLevel } from "@/mixins/enums.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      name: "Usuario Mock",
      Login: "Client",
      email: "mock@cod3r.com.br",
      admin: false,
      countryCode: 0,
      Password: "e66d971c951967de3409441e60d44bdc",
      userToken: {},
    },
    establishmentName: "",
    envVars: "",
    session: false,
    sessionId: 0,
    communication: false,
    hasControl: false,
    hasDatabase: false,
    noConnection: false,
    licenseError: false,
    hasBigOsIp: false,
    showOsSettings: false,
    distinctCountryCode: false,
    errorMessage: "",
    listIps: [],
    gistraDefault: {},
    currentTab: "",
    openTabs: "",
    blockMachine: false,
    resetToken: false,
    closeCashier: true,
    blockCashier: true,
    completeLogin: false,
    validatingLogin: true,
    countryCode: 255,
    lang: "",
    typeSessionControl: 0, // Manual
    showHideBtns: false,
    errorS2S: false,
    showS2S: false,
    isGistra: false,
    errorGlobalClient: false,
    hasEmailToSend: false,
    processingLoading: false,
    showAlertPrintReport: true,
    IP: "",
    isFBM: true,
    tabs: [],
    statusFilter: [
      "Free",
      "Closed",
      "In Use",
      "Other",
      "Paying",
      "Expired",
      "Create",
    ],
    footer: {
      status: "Ready",
      sessionStatus: 0,
      sessionStatusText: "",
      version: "",
      footerMessage: "",
    },
    userLogged: {},
    userLoggedTemp: {},
    stopRequest: true,
    serverDataObjectFilter: {
      Status: [],
      InitialDate: null,
      FinalDate: null,
    },
    systemTime: null,
    locale: "",
    isTouchScreen: false,
    env: "",
    isElectronVar: false,
    isWindows: false,
    globalType: 3,
    firstPowerUp: true,
    actualRes: "",
    actualTimeZone: "",
    idEmailSender: 0,
    hasIniFile: false,
    operatorName: "",
    token: "",

    //noConnection
    showModalNoConnection: false,

    //Add Cash
    hasAddCash: false,
    showHashCode: false,

    //Modals Cashier
    showManagerOptions: false,
    showCashierOptions: false,
    showReportManagerOptions: false,

    //Data
    dataModule: {
      GlobalMode: 10,
      mysterys: [],
      pigBooms: [],
      listBonus: [],
      progressives: [],
      progShow: [],
      mystShow: [],
      pigShow: [],
      engines: [],
      terminals: [],
      users: [],
      usersAll: [],
      reportLevels: [],
      reportDefault: {},
      configInfo: [],
      GlobalData: [],
      cashier: {},
      sessionStatus: "",
      Email: [],
      Panels: [],
      DsLink: {},
      Session: {},
      Pins: {},
      Digis: {},
      OsSettings: {},
      WifiNetwork: {},
      Resolutions: [],
      TimeZones: [],
      Footers: [],
      Cashiers: [],
      Operator: [],
      OperatorFilter: [],
      Clients: [],
    },
  },
  mutations: {
    changeOperatorName(state, name) {
      state.operatorName = name
    },
    changeStopRequest(state, stopRequest) {
      state.stopRequest = stopRequest;
    },
    changeSession(state, session) {
      state.session = session;
    },
    changeSessionId(state, sessionId) {
      state.sessionId = sessionId;
    },
    changeCommunication(state, communication) {
      state.communication = communication;
    },
    changehasControl(state, control) {
      state.hasControl = control;
    },
    changeHasDatabase(state, HasDatabase) {
      state.hasDatabase = HasDatabase;
    },
    changeNoConnection(state, NoConnection) {
      state.noConnection = NoConnection;
    },
    changeFooterMessage(state, FooterMessage) {
      state.footer.footerMessage = FooterMessage;
    },
    changeLicenseError(state, LicenseError) {
      state.licenseError = LicenseError;
    },
    changeHasBigOsIp(state, HasBigOsIp) {
      state.hasBigOsIp = HasBigOsIp;
    },
    changeListIps(state, listIps) {
      state.listIps = listIps;
    },
    changeBlockMachine(state, blockMachine) {
      state.blockMachine = blockMachine;
    },
    changeCloseCashier(state, closeCashier) {
      state.closeCashier = closeCashier;
    },
    changeBlockCashier(state, blockCashier) {
      state.blockCashier = blockCashier;
    },
    changeIdEmailSender(state, idEmailSender) {
      state.idEmailSender = idEmailSender;
    },
    changeHasIniFile(state, value) {
      state.hasIniFile = value;
    },
    changeIP(state, ip) {
      state.IP = ip;
    },
    changeTabs(state, tabs) {
      state.tabs = tabs;
    },
    changeShowHideBtns(state, value) {
      state.showHideBtns = value;
    },
    changeHasEmailToSend(state, value) {
      state.hasEmailToSend = value;
    },

    changeStatusFilter(state, value) {
      state.statusFilter = value;
    },

    changeProcessingLoading(state, value) {
      state.processingLoading = value;
    },

    changeFirstPowerUp(state, value) {
      state.firstPowerUp = value;
    },

    changeIsTouchScreen(state, value) {
      state.isTouchScreen = value;
    },

    changeShowModalNoConnection(state, value) {
      state.showModalNoConnection = value;
    },

    changeIsElectron(state, isElectronVar) {
      state.isElectronVar = isElectronVar;
    },

    changeShowAlertPrintReport(state, showAlertPrintReport) {
      state.showAlertPrintReport = showAlertPrintReport;
    },

    changeActualRes(state, res) {
      state.actualRes = res;
    },

    changeTokenUser(state, res) {
      state.token = res
    },

    changeActualTimeZone(state, ActualTimeZone) {
      state.actualTimeZone = ActualTimeZone;
    },

    setEnv(state, env) {
      state.env = env;
    },

    setUser(state, user) {
      state.user = user;
    },
    setUserLogged(state, user) {
      state.userLogged = user;
    },
    setUserLoggedTemp(state, user) {
      state.userLoggedTemp = user;
    },
    setToken(state, token) {
      state.user.userToken = token;
      if (state.user) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `bearer ${token.token}`;
      } else {
        delete axios.defaults.headers.common["Authorization"];
      }
    },

    setSystemTime(state, time) {
      state.systemTime = time;
    },

    setLocale(state, locale) {
      state.locale = locale;
    },

    setCountryCode(state, countryCode) {
      state.countryCode = countryCode;
    },

    changeUsers(state, users) {
      if (state.userLogged != null) {
        state.dataModule.users = users.filter(
          (x) =>
            x.Deleted === false &&
            getPermissionLevel(state.userLogged) >= getPermissionLevel(x) &&
            x.Hidden === false
        );
      } else {
        state.dataModule.users = users;
      }
    },

    changeUsersAll(state, usersAll) {
      state.dataModule.usersAll = usersAll;
    },

    changeReports(state, reports) {
      state.dataModule.reportLevels = reports;
    },

    changeCashier(state, cashier) {
      state.dataModule.cashier = cashier;
    },

    changeCompleteLogin(state, complete) {
      state.completeLogin = complete;
    },

    changeValidatingLogin(state, complete) {
      state.validatingLogin = complete;
    },

    changeServerDataObjectFilter(state, serverDataObjectFilter) {
      state.serverDataObjectFilter = serverDataObjectFilter;
    },

    changeShowHashCode(state, showHashCode) {
      state.showHashCode = showHashCode;
    },

    setLang(state, lang) {
      if (lang != null) state.lang = lang;
    },


    setInitialConfigs(state, data) {
      try {
        state.hasDatabase = data.HasDatabase;
        state.noConnection = data.NoConnection;
        state.licenseError = data.LicenseError;
        state.hasBigOsIp = data.HasBigOsIp;        
        state.actualTimeZone = data.ActualTimeZone;
        state.showOsSettings = data.ShowOsSettings;
        state.session = data.Session;
        state.sessionId = data.SessionId;
        state.listIps = data.IPs;
        state.isFBM = data.IsFBM;
        state.tabs = data.Tabs;
        state.user.countryCode = data.CountryCode;
        state.countryCode = data.CountryCode;
        state.establishmentName =
          data.Establishment == null ? "" : "" + data.Establishment;
        state.envVars = data.EnvVars == null ? "" : data.EnvVars;
        state.footer.footerMessage =
          data.FooterMessage == null ? "" : data.FooterMessage;
        state.footer.version = data.Version;
        state.errorMessage = data.ErrorMessage;
        state.userLogged = data.LoggedUser;
        state.dataModule.users = data.Users.filter((x) => x.Deleted === false);
        state.dataModule.usersAll = data.Users;
        state.resetToken = data.ResetToken;
        state.currentTab = "";
        state.openTabs = 0;
        state.hasControl = data.LoggedUser != null ? true : false;
        state.dataModule.DsLink = {
          btnName: data.FbmDsName,
          URL: data.FbmDsUrl,
        };
        state.lang = data.Lang;
        state.typeSessionControl = data.SessionControl;
        state.globalType = data.GlobalType;
        state.isWindows = data.IsWindows;
        state.dataModule.Operator = data.Operators;
        state.operatorName = data.OperatorName;
        state.token = data.Token

      } catch (e) {
        console.log("Error in setInitialConfigs()" + e);
      }
    },

    setUpdateConfigs(state, data) {
      try {
        state.establishmentName =
          data.Establishment == null ? "" : "" + data.Establishment;
        state.envVars = data.EnvVars == null ? "" : data.EnvVars;
        state.resetToken = data.ResetToken;
        state.dataModule.DsLink = {
          btnName: data.FbmDsName,
          URL:
            data.FbmDsUrl != null && data.FbmDsUrl.includes("https://")
              ? data.FbmDsUrl
              : "https://" + data.FbmDsUrl,
        };
        state.typeSessionControl = data.SessionControl;
        state.hasAddCash = data.HasAddCash;
        state.showHashCode = data.ShowHashCode;
        state.isGistra = data.IsGistra;
      } catch (e) {
        console.log("Error in setUpdateConfigs() " + e);
      }
    },

    setUpdateData(state, data) {
      try {
        state.dataModule.progressives = data.Progressives;
        state.dataModule.engines = data.Engines;
        state.dataModule.configInfo = data.ConfigInfo;
        state.dataModule.GlobalData = data.GlobalData;
        state.dataModule.GlobalMode =
          data.GlobalData != null
            ? data.GlobalData.GlobalMode
            : state.dataModule.GlobalMode;
        state.dataModule.Email = data.Email;
        if (data.ListUsers != null)
          state.dataModule.users = data.ListUsers.filter(
            (x) =>
              x.Deleted === false &&
              getPermissionLevel(state.userLogged) >= getPermissionLevel(x) &&
              x.Hidden === false
          );
        state.dataModule.usersAll = data.ListUsers;
        state.footer.sessionStatus = data.SessionStatus;
        if (state.footer.sessionStatus == 0) {
          state.session = false;
        } else {
          state.session = true;
        }
        if (state.dataModule.GlobalMode === 1) {
          state.footer.sessionStatusText = "-";
        } else {
          state.footer.sessionStatusText = data.SessionStatusText;
        }
        state.envVars = data.EnvVars == null ? "" : data.EnvVars;
        state.footer.footerMessage =
          data.FooterMessage == null ? "" : data.FooterMessage;
        state.dataModule.Operator = data.ListOperator;
        state.dataModule.OperatorFilter = data.ListOperator;
        state.dataModule.Clients = data.Clients;
      } catch (e) {
        console.log("Error in setUpdateData() " + e);
      }
    },

    setSelectedTab(state, tab) {
      state.currentTab = tab;
    },

    setOpenTabs(state, openTabs) {
      state.openTabs = openTabs
    },

    showManagerOptions(state, show) {
      state.showManagerOptions = show;
      localStorage.setItem("ManagerOptions" + "/" + state.IP, show);
    },

    showCashierOptions(state, show) {
      state.showCashierOptions = show;
      localStorage.setItem("CashierOptions" + "/" + state.IP, show);
    },
    showReportManagerOptions(state, show) {
      state.showReportManagerOptions = show;
    },

    resetApp(state, valor) {
      state.resetApp = state.resetApp + valor;
    },

    resetState(state) {
      (state.establishmentName = ""),
        (state.envVars = ""),
        (state.session = false),
        (state.sessionId = 0),
        (state.communication = false),
        (state.hasControl = false),
        (state.hasDatabase = false),
        (state.noConnection = false),
        (state.licenseError = false),
        (state.hasBigOsIp = false),
        (state.actualTimeZone = ""),
        (state.showOsSettings = false),
        (state.distinctCountryCode = false),
        (state.errorMessage = ""),
        (state.listIps = []),
        (state.gistraDefault = {}),
        (state.currentTab = ""),
        (state.blockMachine = false),
        (state.resetToken = false),
        (state.closeCashier = true),
        (state.blockCashier = true),
        (state.completeLogin = false),
        (state.validatingLogin = true),
        (state.countryCode = 255),
        (state.lang = ""),
        (state.typeSessionControl = 0), //Manual
        (state.showHideBtns = false),
        (state.errorS2S = false),
        (state.showS2S = false),
        (state.hasEmailToSend = false),
        (state.errorGlobalClient = false),
        (state.globalType = 3),
        (state.processingLoading = false),
        (state.showAlertPrintReport = true),
        (state.isWindows = false),
        (state.firstPowerUp = true),
        (state.IP = ""),
        (state.operatorName = ""),
        (state.tabs = []),
        (state.statusFilter = [
          "Free",
          "Closed",
          "In Use",
          "Other",
          "Paying",
          "Expired",
          "Create",
        ]),
        (state.footer = {
          status: "Ready",
          sessionStatus: 0,
          sessionStatusText: "",
          version: "",
          footerMessage: "",
        }),
        (state.userLogged = {}),
        (state.userLoggedTemp = {}),
        (state.stopRequest = true),
        (state.serverDataObjectFilter = {
          Status: [],
          InitialDate: null,
          FinalDate: null,
        }),
        (state.systemTime = null),
        (state.locale = ""),
        (state.token = ""),
        //noConnection
        (state.showModalNoConnection = false),
        //Add Cash
        (state.hasAddCash = false),
        (state.showHashCode = false),
        //Modals Cashier
        (state.showManagerOptions = false),
        (state.showCashierOptions = false),
        (state.showReportManagerOptions = false),
        //Data
        (state.dataModule = {
          GlobalMode: 10,
          mysterys: [],
          pigBooms: [],
          listBonus: [],
          progressives: [],
          progShow: [],
          mystShow: [],
          pigShow: [],
          engines: [],
          terminals: [],
          users: [],
          usersAll: [],
          reportLevels: [],
          reportDefault: {},
          configInfo: [],
          GlobalData: [],
          cashier: {},
          sessionStatus: "",
          Email: [],
          Panels: [],
          DsLink: {},
          Session: {},
          Pins: {},
          Digis: {},
          OsSettings: {},
          WifiNetwork: {},
          Footers: [],
          Operator: []
        });
    },
  },

  actions: {},
  modules: {},
});
