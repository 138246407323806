export const countries = {
  Spain: 1,
  Philippines: 2,
  Mexico: 3,
  Ireland: 4,
  Norway: 5,
  Sweden: 6,
  DingoPHL: 254,
  ALL: 255,
};

export const countryList = [
  { id: countries.Spain, name: "Spain" },
  { id: countries.Philippines, name: "Philipines" },
  { id: countries.Mexico, name: "Mexico" },
  { id: countries.Ireland, name: "Irleand" },
  { id: countries.Norway, name: "Norway" },
  { id: countries.Sweden, name: "Sweden" },
  { id: countries.DingoPHL, name: "Dingo_PHL" },
  //{ id: countries.ALL, name: "ALL" },
];

export function returnCurrency(countryCode) {
  let Currency = "";
  if (countries.Spain == countryCode) {
    Currency = "€";
  } else if (
    countries.Philippines == countryCode ||
    countries.DingoPHL == countryCode
  ) {
    Currency = "P";
  } else if (
    countries.Norway == countryCode ||
    countries.Sweden == countryCode
  ) {
    Currency = "Kr";
  } else {
    Currency = "$";
  }

  return Currency;
}

export function isCents(countryCode) {
  let isCents = true;
  switch (countryCode) {
    case countries.Philippines:
    case countries.DingoPHL:
    case countries.Norway:
    case countries.Sweden:
      isCents = false;
      break;
    default:
      isCents = true;
      break;
  }
  return isCents;
}

export function returnLanguage(countryCode) {
  return countryCode == countries.Spain ? "es-ES" : "en-US";
}

export default {
  countries,
  countryList,
  returnCurrency,
  isCents,
  returnLanguage,
};
