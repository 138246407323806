export const clientCommands = {
    // Comando de confirmação de recebimento de outros comandos
    Confirmation: { name: "Confirmation", time: 10 }, // notConfirmation

    // Comando de configurações
    UploadConfig: { name: "UploadConfig", time: 3600 },
    InstallConfig: { name: "InstallConfig", time: 330 },
    RequestConfig: { name: "RequestConfig", time: 330 },
    DownloadConfig: { name: "DownloadConfig", time: 60 },
    SendFile: { name: "SendFile", time: 60 },

    // Comandos de Database
    DoBackup: { name: "DoBackup", time: 3600 },
    DoRestore: { name: "DoRestore", time: 3600 },
    RequestBackup: { name: "RequestBackup", time: 10 },
    GetPenDriveList: { name: "GetPenDriveList", time: 10 },
    DeleteDatabase: {name: "DeleteDatabase", time: 100},

    // Comandos de sistema
    ServerStop: { name: "ServerStop", time: 120 },
    ServerRestart: { name: "ServerRestart", time: 180 },

    // Comandos do front
    CreateIni: { name: "CreateIni", time: 30 },
    Login: { name: "Login", time: 60 }, // notConfirmation
    ConnectionPermission: { name: "ConnectionPermission", time: 60 }, // notConfirmation
    LoginRequired: { name: "LoginRequired", time: 10 },
    UpdateDatabase: { name: "UpdateDatabase", time: 10 },
    SetLanguage: { name: "SetLanguage", time: 10 },
    ExportReport: { name: "ExportReport", time: 30 },
    HideMenu: { name: "HideMenu", time: 10 },
    ShowMenu: { name: "ShowMenu", time: 10 },
    ForcedDisconnection: { name: "ForcedDisconnection", time: 10 },
    UpdateListUsers: { name: "UpdateListUsers", time: 10 },
    ToggleTouchscreen: { name: "ToggleTouchscreen", time: 10 },

    // Comandos de report
    ReportManagerData: { name: "ReportManagerData", time: 60 },
    GetReport: { name: "GetReport", time: 60 },
    FinishGetReport: { name: "FinishGetReport", time: 10 },
    GenerateServerReports: { name: "GenerateServerReports", time: 180 },
    GenerateCsv: { name: "GenerateCsv", time: 180 },
    ResendToQueue: { name: "ResendToQueue", time: 10 },
    GistraReport: { name: "GistraReport", time: 10 },
    NavisionReport: { name: "NavisionReport", time: 10 },
    NavisionReportCopyFiles: { name: "NavisionReportCopyFiles", time: 120 },
    VoucherViewer: { name: "VoucherViewer", time: 120 },

    // Comandos de login do usuário
    LoggedIn: { name: "LoggedIn", time: 10 }, // notConfirmation
    LoggedOut: { name: "LoggedOut", time: 10 }, // notConfirmation

    // Comandos de usuário
    CreateUser: { name: "CreateUser", time: 30 },
    EditUser: { name: "EditUser", time: 30 },
    DeleteUser: { name: "DeleteUser", time: 30 },

    // Comandos de token
    CreateToken: { name: "CreateToken", time: 60 }, // notConfirmation
    ValidateToken: { name: "ValidateToken", time: 60 }, // notConfirmation

    // Comandos de comunicação
    BlockCommunication: { name: "BlockCommunication", time: 30 },
    BlockCommunicationAll: { name: "BlockCommunicationAll", time: 30 },
    UnblockCommunication: { name: "UnblockCommunication", time: 30 },
    UnblockCommunicationAll: { name: "UnblockCommunicationAll", time: 30 },

    // Comandos de report level
    CreateReport: { name: "CreateReport", time: 30 },
    EditReport: { name: "EditReport", time: 30 },
    DeleteReport: { name: "DeleteReport", time: 30 },

    // Comandos de sessão
    OpenSession: { name: 'OpenSession', time: 30 },
    OpenSessionAll: { name: 'OpenSessionAll', time: 30 },
    CloseSession: { name: 'CloseSession', time: 300 },
    CloseSessionAll: { name: 'CloseSessionAll', time: 300 },
    UnblockMachines: { name: 'UnblockMachines', time: 30 },
    UnblockMachinesAll: { name: 'UnblockMachinesAll', time: 30 },
    BlockMachines: { name: 'BlockMachines', time: 120 },
    BlockMachinesAll: { name: 'BlockMachinesAll', time: 120 },
    AutoBlockMachines: { name: "AutoBlockMachines", time: 560 },
    CashierReport: { name: "CashierReport", time: 10 },
    CashierReprintTicket: { name: "CashierReprintTicket", time: 10 },
    PrintReport: { name: "PrintReport", time: 10 },

    // Comandos de Logs
    ServerLogs: { name: "ServerLogs", time: 10 },
    ServerReports: { name: "ServerReports", time: 10 },
    ServerLogsCopyFiles: { name: "ServerLogsCopyFiles", time: 120 },
    ServerReportsCopyFiles: { name: "ServerReportsCopyFiles", time: 120 },
    CertificateReport: { name: "CertificateReport", time: 10 },
    CopyCertificate: { name: "CopyCertificate", time: 120 },
    ShowLogs: { name: "ShowLogs", time: 10 },

    // Comandos de Licença
    GenerateLicense: { name: "GenerateLicense", time: 30 },
    AutomaticLicenseReq: { name: "AutomaticLicenseReq", time: 180 },
    RequestLicense: { name: "RequestLicense", time: 30 },
    ValidateLicense: { name: "ValidateLicense", time: 30 },
    CheckLicenseReq: { name: "CheckLicenseReq", time: 30 },
    CancelLicense: { name: "CancelLicense", time: 30 },
    CancelLicenseReq: { name: "CancelLicenseReq", time: 30 },

    // Comando de dados
    ServerData: { name: 'ServerData', time: 10 }, // notConfirmation

    // Comandos de caixa
    CashierSignIn: { name: "CashierSignIn", time: 10 },
    CashierSignOut: { name: "CashierSignOut", time: 10 },
    CashierBlock: { name: "CashierBlock", time: 10 },
    CashierUnblock: { name: "CashierUnblock", time: 10 },
    CashierAccountInfo: { name: "CashierAccountInfo", time: 10 },
    CashierCloseAccountOk: { name: "CashierCloseAccountOk", time: 10 },
    CashierCloseAccountCancel: { name: "CashierCloseAccountCancel", time: 10 },
    CashierCloseAccountRequest: { name: "CashierCloseAccountRequest", time: 10 },
    CashierRegisterFill: { name: "CashierFill", time: 10 },
    CashierRegisterDrop: { name: "CashierDrop", time: 10 },
    CashierAddNewPin: { name: "CashierAddNewPin", time: 10 },
    CashierCheck: { name: "CashierCheck", time: 10 },
    CashierAccounts: { name: "CashierAccounts", time: 10 },
    CashierTerminalStatus: { name: "CashierTerminalStatus", time: 10 },
    CashierCopyFiles: { name: "CashierCopyFiles", time: 120 },
    CashierAddCredits: { name: "CashierAddCredits", time: 10 },

    // Comandos de Reset Value
    ProgMultiReset: { name: "ProgMultiReset", time: 10 },
    ResetBonus: { name: "ResetBonus", time: 10 },

    // Comandos de Progressive
    SetTerminalProgressive: { name: "SetTerminalProgressive", time: 10 },

    // Comandos de Email
    SendManualEmail: { name: "SendManualEmail", time: 30 },
    SendTestEmail: { name: "SendTestEmail", time: 30 },
    HasEmailsToSend: { name: "HasEmailsToSend", time: 30 },
    DisableEmailSender: { name: "DisableEmailSender", time: 30 },
    GetMaxFileSize: { name: "GetMaxFileSize", time: 10 },
    SetMaxFileSize: { name: "SetMaxFileSize", time: 10 },

    // Comandos Terminais
    PaymentTerminal: { name: "PaymentTerminals", time: 10 },
    OpenTerminal: { name: "OpenTerminal", time: 10 },
    DeleteTerminal: { name: "DeleteTerminal", time: 10 },

    // Comandos Debug
    ForcePigBoom: { name: "ForcePigBoom", time: 10 },
    ForceMystery: { name: "ForceMystery", time: 10 },
    ForceMultiProgressiveLevel: { name: "ForceMultiProgressiveLevel", time: 10 },
    ClearForceLevelList: { name: "ClearForceLevelList", time: 10 },

    // Comandos Add Cahs
    GetHashCode: { name: "GetHashCode", time: 10 },
    ResetHashCode: { name: "ResetHashCode", time: 10 },
    ValidateHashCode: { name: "ValidateHashCode", time: 10 },
    AddCash: { name: "AddCash", time: 10 },
    RequestHashCodeFile: { name: "RequestHashCodeFile", time: 10 },

    //Promotions 
    GetPromotionsInfoAndPremiums: { name: "GetPromotionsInfoAndPremiums", time: 10 },
    ValidateCoupon: { name: "ValidateCoupon", time: 10 },
    UpdateCoupon: { name: "UpdateCoupon", time: 10 },
    ValidateErrorCode: { name: "ValidateErrorCode", time: 10 },
    GenerateCoupon: { name: "GenerateCoupon", time: 120 },
    FindByVlt: { name: "FindByVlt", time: 10 },
    CouponView: { name: "CouponView", time: 10 },
    PrintCoupon: { name: "PrintCoupon", time: 10 },
    ReprintCoupon: { name: "ReprintCoupons", time: 10 },
    PrintTestCoupon: { name: "PrintTestCoupon", time: 10 },

    //Alesis
    ValidateS2S: { name: "ValidateS2S", time: 10 },

    //BigOsSettings
    SaveBigOsSettings: { name: "SaveBigOsSettings", time: 10 },

    //Reset Settings
    ResetServerConfiguration: { name: "ResetServerConfiguration", time: 10 },
    ResetClientConfiguration: { name: "ResetClientConfiguration", time: 10 },
    ResetBothConfigurations: { name: "ResetBothConfigurations", time: 10 },

    //Machines
    RefreshMachinesPrintCoupon: { name: "RefreshMachinesPrintCoupon", time: 10 },

    //Digi
    ValidateDigi: { name: "ValidateDigi", time: 10 },

    //Comandos Machine
    MachineOperation: { name: "MachineOperation", time: 10 },

    //Global Settings
    GlobalSettings: { name: "GlobalSettings", time: 10 },

    //Notification
    DefaultNotification: { name: "DefaultNotification", time: 10 },

    //OS Admin
    RunOsAdmin: { name: "RunOsAdmin", time: 20 },

    //Footer
    SaveFooter: { name: "SaveFooter", time: 10 },
    EditFooter: { name: "EditFooter", time: 10 },
    DeleteFooter: { name: "DeleteFooter", time: 10 },

    //Playlist
    CreatePlaylist: { name: "CreatePlaylist", time: 10 },
    EditPlaylist: { name: "EditPlaylist", time: 10 },
    DeletePlaylist: { name: "DeletePlaylist", time: 10 },

    //Panel
    DisplayPanelName: { name: "DisplayPanelName", time: 10 },
    SetPanelData: { name: "SetPanelData", time: 10 },
    DeletePanel: { name: "DeletePanel", time: 10 },
    ResetFactoryPanel: { name: "ResetFactoryPanel", time: 10 },
    RestartPanel: { name: "RestartPanel", time: 10 },
    ShutdownPanel: { name: "ShutdownPanel", time: 10 },

    //Operator
    GetOperatorServer: { name: "GetOperatorServer", time: 10 },
    SelectOperator: {name: "SelectOperator", time: 10},
    DeleteOperator: {name: "DeleteOperator", time:  10},
}

export const webSocketStatus = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
}

export default { clientCommands, webSocketStatus }