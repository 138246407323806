<template>
  <div class="configurations" style="overflow-y: hidden !important;">
    <div class="animated fadeIn" style="overflow-y: hidden !important;">
      <div class="card">
        <header v-if="IsFromHeader" class="modal-card-head">
          <p class="modal-card-title">{{ translate("settings") }}</p>
          <button tabindex="-1" type="button" class="delete" @click.prevent="close()" v-if="!user.ChangePassword" />
        </header>
        <div class="card-body" style="padding: 0.75rem; !important">
          <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true">
          </b-loading>
          <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
            :can-cancel="false">
            <template #default="props">
              <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
                :JsonObject="JsonObject" @ActionAfterCMDConfig="afterCMD" :isAxios="isAxios"
                route="/modules/configurations">
              </LoadingCMD>
            </template>
          </b-modal>
          <b-modal v-model="showModalGlobalSettings" width="600px" trap-focus :destroy-on-hide="true" aria-role="dialog"
            aria-modal :can-cancel="false">
            <template #default="props">
              <GlobalSettings @close="props.close" />
            </template>
          </b-modal>
          <b-modal v-model="showBackupRestoreMessage" width="50%" trap-focus :destroy-on-hide="true" aria-role="dialog"
            aria-modal :can-cancel="false">
            <Message type="is-success" :message="message" :isList="true" />
          </b-modal>
          <b-modal v-model="showCopyReport" width="800px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
            :can-cancel="false" ref="modal-auth">
            <template #default="props">
              <CopyReport @close="props.close" :dataCopy="dataCopy" :isReport="false" :nameFile="copyReportNameShow"
                @RefreshPenDriveFiles="refreshPenDriveFiles" />
            </template>
          </b-modal>
          <b-modal v-model="showRequestFile" width="800px" trap-focus :destroy-on-hide="true" aria-role="dialog"
            aria-modal :can-cancel="false" ref="modal-auth">
            <template #default="props">
              <RequestFile @close="props.close" :penDriveList="dataRequestFile" :isConfig="isConfig"
                :showDatabasePassword="showDatabasePassword" :extensions="extensions" :cmdFromConfigurations="cmd"
                @ActionAfterChooseFile="uploadArquivo" @ActionAfterChooseFileBigOs="uploadArquivoBigOs"
                @ActionAfterChooseFileUpload="uploadArquivoFileUpload" @RefreshPenDriveFiles="refreshPenDriveFiles" />
            </template>
          </b-modal>
          <b-modal v-model="showModalErrorConf" width="800px" trap-focus :destroy-on-hide="true" aria-role="dialog"
            aria-modal :can-cancel="false">
            <Message type="is-danger" :message="message" :isList="true" :messageBtn="translate('btnWait')" :canClose="true" :title="translate('lblError')" @ActionAfterMessage="actionAfterMessage" />
          </b-modal>
          <b-modal v-model="showProcessAction" has-modal-card trap-focus :destroy-on-hide="true" aria-role="dialog"
            aria-modal :can-cancel="false">
            <form action="">
              <div class="modal-card" style="width: 500px; height: 100%">
                <header class="modal-card-head">
                  <p class="modal-card-title">{{ this.isCmdBackup ? translate("btnBackup") : translate("btnLicense") }}
                  </p>
                  <button type="button" class="delete" @click.prevent="closeProcessAction()" />
                </header>
                <section class="modal-card-body" style="overflow-x: hidden">
                  <b-modal v-model="showLoadingDoAction" width="auto" trap-focus :destroy-on-hide="true"
                    aria-role="dialog" aria-modal :can-cancel="false">
                    <template #default="props">
                      <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage"
                        :countDown="countDown" :JsonObject="objDoAction" @ActionAfterCMDConfig="afterCMD">
                      </LoadingCMD>
                    </template>
                  </b-modal>
                  <b-field>
                    <button class="btn btn-outline-secondary" disabled>
                      <i class="icons-lg0 icons-usb" />
                    </button>
                    &nbsp;
                    <b-select :placeholder="translate('lblSelectDevice')" v-model="penDrive" required expanded>
                      <option v-for="penDrive in penDriveList" v-bind:value="penDrive" :key="penDrive">
                        {{ penDrive.Name }}
                      </option>
                    </b-select>
                    &nbsp;
                    <button class="btn btn-outline-secondary" @click.prevent="refreshPenDriveFiles()">
                      <i class="icons-lg0 icons-sync" />
                    </button>
                  </b-field>
                  <footer class="modal-card-foot">
                    <b-button :label="translate('btnCancel')" icon-left=".icons-white icons-times" icon-pack="icons"
                      @click.prevent="closeProcessAction()" />
                    <b-button @click.prevent="doAction()" :disabled="penDrive == null" :label="translate('btnOk')"
                      type="is-primary" icon-left=".icons-white icons-check" icon-pack="icons" />
                  </footer>
                </section>
              </div>
            </form>
          </b-modal>

          <b-modal v-model="showIniFile" width="800px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
            :can-cancel="false" ref="modal-auth">
            <template #default="props">
              <IniFile @close="props.close" :openModal="openModalIni" :key="openModalIni"
                @ActionAfterSaveBigOsSettings="actionAfterSaveBigOsSettings" />
            </template>
          </b-modal>

          <b-modal v-model="showModalRestart" width="800px" trap-focus :destroy-on-hide="true" aria-role="dialog"
            aria-modal :can-cancel="false">
            <Message type="is-success" :message="message" :isList="true" :messageBtn="translate('btnWait')" />
          </b-modal>

          <b-modal v-model="showAutentication" width="800px" trap-focus :destroy-on-hide="true" aria-role="dialog"
            aria-modal :can-cancel="false" ref="modal-auth">
            <template #default="props">
              <Auth @close="props.close" route="/api/configuration/controls" :showLogin="true" :showPassword="true"
                :showPin="false" :local="local" @AfterAuthConfigurations="afterAuth" />
            </template>
          </b-modal>

          <div class="card-body" style="
                                overflow-x: hidden !important;
                                height: calc(102vh - 150px);
                                max-height: calc(102vh - 150px);
                                overflow-y: auto;
                                padding: 0.65rem !important;
                              ">
            <div class="" v-if="((!hasDatabase && !showSteps) || errorS2S) && !licenseError">
              <Message type="is-danger" :message="errorMessage" :isList="true" />
              <br />
            </div>
            <div class="" v-if="(licenseError)">
              <Message type="is-license" :message="errorMessage" :isList="true" />
              <br />
            </div>

            <div v-if="IsFromHeader">
              <div class="row" :style="displayDiv">
                <div :class="columnSize" style="
                                      padding-left: 0px !important;
                                      padding-right: 0px !important;
                                    ">
                  <div class="stats" style="
                                        padding-left: unset !important;
                                        width: 102% !important;
                                      ">
                    <div class="stats col-lg-12" id="serverInfo">
                      <div class="stat" style="min-width: 800px; height: 340px">
                        <div>
                          <div style="display: inline !important">
                            <div>
                              <i class="icons-3x icons-cogs" style="font-size: 4.1rem"></i>
                            </div>
                            <div style="
                                                  left: 26%;
                                                  position: relative;
                                                  top: -50px;
                                                  font-size: 1.2rem;
                                                ">
                              {{ translate("configurations_Title") }}
                            </div>
                          </div>
                          <div>
                            <div class="divConfiguration" style="display: flex; justify-content: center">
                              <b-field :class="{ 'has-name': !!file }" v-if="false">
                                <b-upload v-model="file" class="file-label" accept=".bcf, .xml" type="is-text"
                                  :disabled="showSteps">
                                  <span class="file-cta" style="background-color: white">
                                    <b-icon type="is-text" class="icons-1x icons-file_upload"></b-icon>
                                    <span class="file-label">{{
                                      translate("btnLoadSettings")
                                    }}</span>
                                  </span>
                                </b-upload>
                              </b-field>

                              <b-button v-if="IsFromHeader || !hasDatabase" :disabled="showSteps" class="button"
                                icon-left="icons-1x icons-file_upload" icon-pack="icons"
                                @click.prevent="processRequestConfig()">
                                <span>{{
                                  translate("btnLoadSettings")
                                }}</span>
                              </b-button>
                            </div>
                            <br />
                            <b-table v-if="!IsFromHeader && hasDatabase" :data="dataModule.configInfo.Configuration"
                              ref="table" aria-next-label="Next page" aria-previous-label="Previous page"
                              aria-page-label="Page" aria-current-label="Current page" striped>
                              <template #empty>
                                <div class="has-text-centered">
                                  <h1>
                                    <span class="tag is-danger">{{
                                      translate("lblNoDataFound")
                                    }}</span>
                                  </h1>
                                </div>
                              </template>
                              <b-table-column field="field" :label="''" v-slot="props" header-class="is-sticky-column-one"
                                width="200">
                                {{ props.row.Field }}
                              </b-table-column>
                              <b-table-column field="value" :label="''" v-slot="props" header-class="is-sticky-column-one"
                                width="200">
                                <span :class="[
                                  { 'tag is-success': props.row.Status === 1 },
                                  { 'tag is-warning': props.row.Status === 2 },
                                  { 'tag is-danger': props.row.Status === 3 },
                                  { 'tag is-info': props.row.Status === 4 },
                                  { '': props.row.Status === 0 },
                                ]" style="font-size: 13px !important;">
                                  {{ props.row.Value }}
                                </span>
                              </b-table-column>
                            </b-table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="stats" v-if="IsFromHeader" style="
                                        padding-left: unset !important;
                                        width: 102% !important;
                                      ">
                    <div class="stats col-lg-12" id="serverInfo">
                      <div class="stat" style="min-width: 800px; height: 340px">
                        <div>
                          <div style="display: inline !important">
                            <div>
                              <i class="icons-3x icons-server-config" style="font-size: 4.1rem"></i>
                            </div>
                            <div style="
                                                  left: 26%;
                                                  position: relative;
                                                  top: -50px;
                                                  font-size: 1.2rem;
                                                ">
                              {{ translate("deckEstablishment_Title") }}
                            </div>
                          </div>
                          <div>
                            <div class="divConfiguration" style="display: flex; justify-content: center">
                              <ResetSettings @ActionAfterResetSettings="actionAfterResetSettings
                                " v-if="false" />

                              <b-dropdown id="dropdownOpt" aria-role="list" v-model="selectedOptionReset">
                                <template #trigger>
                                  <b-button class="button" icon-left="icons-1x icons-dots-three-vertical"
                                    icon-pack="icons">
                                    <span>{{ translate("deckResetSettings_Title") }}</span>
                                  </b-button>
                                </template>                                

                                <b-dropdown-item aria-role="listitem" :value="'CopyLogs'">
                                  <div class="media">
                                    <div class="media-content">
                                      <a href style="" @click.prevent=""><i class="icons-1x icons-logs" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                        <span>
                                          {{
                                            translate(
                                              "btnCopyLogs"
                                            )
                                          }}</span>
                                      </a>
                                    </div>
                                  </div>
                                </b-dropdown-item>
                                <b-dropdown-item aria-role="listitem" :value="'CopyReport'" v-if="false">
                                  <div class="media">
                                    <div class="media-content">
                                      <a href @click.prevent=""><i class="icons-1x icons-file" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                        <span>
                                          {{
                                            translate(
                                              "btnCopyReport"
                                            )
                                          }}</span>
                                      </a>
                                    </div>
                                  </div>
                                </b-dropdown-item>
                                <b-dropdown-item aria-role="listitem" :value="'CopyCertificate'">
                                  <div class="media">
                                    <div class="media-content">
                                      <a href @click.prevent=""><i class="icons-1x icons-logs" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                        <span>
                                          {{
                                            translate(
                                              "btnCopyCert"
                                            )
                                          }}</span>
                                      </a>
                                    </div>
                                  </div>
                                </b-dropdown-item>
                                <b-dropdown-item aria-role="listitem" :value="'Configuration/Settings/ResetBigOsAddress'">
                                  <div class="media">
                                    <div class="media-content">
                                      <a href @click.prevent=""><i class="icons-1x icons-globe" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                        <span>
                                          {{
                                            translate(
                                              "btnResetBigOsAddress"
                                            )
                                          }}</span>
                                      </a>
                                    </div>
                                  </div>
                                </b-dropdown-item>
                                <b-dropdown-item aria-role="listitem" :value="'Configuration/Settings/ResetServerIni'" v-if="isElectronVar">
                                  <div class="media">
                                    <div class="media-content">
                                      <a href @click.prevent=""><i class="icons-1x icons-cogs" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                        <span>
                                          {{
                                            translate(
                                              "btnResetGlobalConfiguration"
                                            )
                                          }}</span>
                                      </a>
                                    </div>
                                  </div>
                                </b-dropdown-item>
                                <b-dropdown-item aria-role="listitem" v-if="false"
                                  :value="'Configuration/Settings/ResetClientIni'">
                                  <div class="media">
                                    <div class="media-content">
                                      <a @click.prevent="" v-if="false"><i class="icons-1x icons-network-wired" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                        <span>
                                          {{
                                            translate(
                                              "btnResetClientConfiguration"
                                            )
                                          }}</span>
                                      </a>
                                    </div>
                                  </div>
                                </b-dropdown-item>
                                <b-dropdown-item aria-role="listitem" v-if="false"
                                  :value="'Configuration/Settings/ResetBothIni'">
                                  <div class="media">
                                    <div class="media-content">
                                      <a href v-if="false"><i class="icons-1x icons-sync" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                        <span>
                                          {{
                                            translate(
                                              "btnResetBothConfiguration"
                                            )
                                          }}</span>
                                      </a>
                                    </div>
                                  </div>
                                </b-dropdown-item>
                                <b-dropdown-item aria-role="listitem" v-if="errorS2S" :value="'errorS2S'">
                                  <div class="media">
                                    <div class="media-content">
                                      <a href @click.prevent="" v-if="errorS2S"><i class="icons-1x icons-id-card" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                        <span>
                                          {{
                                            translate(
                                              "btnResetS2SConfiguration"
                                            )
                                          }}</span>
                                      </a>
                                    </div>
                                  </div>
                                </b-dropdown-item>
                                <b-dropdown-item aria-role="listitem" v-if="globalTypeEnum.ClientOnly ==
                                    globalType ||
                                    (globalTypeEnum.ClientAndServer ==
                                      globalType &&
                                      hasDatabase)
                                    " :value="'errorGlobalClientAddress'">
                                  <div class="media">
                                    <div class="media-content">
                                      <a href @click.prevent=""><i class="icons-1x icons-network-wired" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                        <span>
                                          {{
                                            translate(
                                              "btnResetGlobalClient"
                                            )
                                          }}</span>
                                      </a>
                                    </div>
                                  </div>
                                </b-dropdown-item>
                              </b-dropdown>

                            </div>
                            <br />
                            <b-table :data="dataModule.configInfo.Establishment" v-if="dataModule.configInfo.Establishment != undefined && dataModule.configInfo.Establishment.length > 0" ref="table" aria-next-label="Next page"
                              aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                              striped>
                              <b-table-column field="field" :label="''" v-slot="props" header-class="is-sticky-column-one"
                                width="200">
                                {{ props.row.Field }}
                              </b-table-column>
                              <b-table-column field="value" :label="''" v-slot="props" header-class="is-sticky-column-one"
                                width="200">
                                <span :class="[
                                  { 'tag is-success': props.row.Status === 1 },
                                  { 'tag is-warning': props.row.Status === 2 },
                                  { 'tag is-danger': props.row.Status === 3 },
                                  { 'tag is-info': props.row.Status === 4 },
                                  { '': props.row.Status === 0 },
                                ]" style="font-size: 13px !important;">
                                  {{ props.row.Value }}
                                </span>
                              </b-table-column>
                            </b-table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="stats" v-if="IsFromHeader" style="
                                        padding-left: unset !important;
                                        width: 102% !important;
                                      ">
                    <div class="stats col-lg-12" id="serverInfo">
                      <div class="stat" style="min-width: 800px; height: 340px">
                        <div>
                          <div style="display: inline !important">
                            <div>
                              <i class="icons-3x icons-database" style="font-size: 4.1rem"></i>
                            </div>
                            <div style="
                                                  left: 26%;
                                                  position: relative;
                                                  top: -50px;
                                                  font-size: 1.2rem;
                                                ">
                              {{ translate("deckDatabase_Title") }}
                            </div>
                          </div>
                          <div>
                            <div class="divDatabase" style="display: flex; justify-content: center">
                              <b-button v-if="isElectronVar" class="button" icon-left="icons-1x icons-database" icon-pack="icons" :disabled="!hasDatabase" 
                                @click.prevent="processBackup()">
                                <span>{{ translate("btnBackup") }}</span>
                              </b-button>
                              &nbsp;
                              <b-button v-if="isElectronVar" class="button" icon-left="icons-1x icons-undo" icon-pack="icons" :disabled="hasDatabase &&
                                  userLogged != undefined && !userLogged.Technician && !userLogged.Root" @click.prevent="processRequestBackup()">
                                <span>{{ translate("btnRestore") }}</span>
                              </b-button>
                            </div>
                            <br />
                            <b-table :data="dataModule.configInfo.Database" ref="table" aria-next-label="Next page"
                              aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                              striped>
                              <template #empty>
                                <div class="has-text-centered">
                                  <h1>
                                    <span class="tag is-danger">{{
                                      translate("lblNoDataFound")
                                    }}</span>
                                  </h1>
                                </div>
                              </template>
                              <b-table-column field="field" :label="''" v-slot="props" header-class="is-sticky-column-one"
                                width="200">
                                {{ props.row.Field }}
                              </b-table-column>
                              <b-table-column field="value" :label="''" v-slot="props" header-class="is-sticky-column-one"
                                width="200">
                                <span :class="[
                                  { 'tag is-success': props.row.Status === 1 },
                                  { 'tag is-warning': props.row.Status === 2 },
                                  { 'tag is-danger': props.row.Status === 3 },
                                  { 'tag is-info': props.row.Status === 4 },
                                  { '': props.row.Status === 0 },
                                ]" style="font-size: 13px !important;">
                                  {{ props.row.Value }}
                                </span>
                              </b-table-column>
                            </b-table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ResetSettings v-if="false" />
              </div>
            </div>
            <div v-else>
              <div class="row" :style="displayDiv">
                <div :class="columnSize" style="
                                      padding-left: 0px !important;
                                      padding-right: 0px !important;
                                    ">
                  <div class="stats" style="
                                        padding-left: unset !important;
                                        width: 102% !important;
                                      ">
                    <div class="stats col-lg-12" id="serverInfo">
                      <div class="stat" style="min-width: 700px; height: 340px">
                        <div>
                          <div style="display: inline !important">
                            <div>
                              <i class="icons-3x icons-cogs" style="font-size: 4.1rem"></i>
                            </div>
                            <div style="
                                                  left: 26%;
                                                  position: relative;
                                                  top: -50px;
                                                  font-size: 1.2rem;
                                                ">
                              {{ translate("configurations_Title") }}
                            </div>
                          </div>
                          <div>
                            <div class="divConfiguration" style="display: flex; justify-content: center">
                              <b-field :class="{ 'has-name': !!file }" v-if="false">
                                <b-upload v-model="file" class="file-label" accept=".bcf, .xml" type="is-text"
                                  :disabled="showSteps">
                                  <span class="file-cta" style="background-color: white">
                                    <b-icon type="is-text" class="icons-1x icons-file_upload"></b-icon>
                                    <span class="file-label">{{
                                      translate("btnLoadSettings")
                                    }}</span>
                                  </span>
                                </b-upload>
                              </b-field>

                              <b-button :disabled="showSteps || noConnection" class="button" icon-left="icons-1x icons-file_upload"
                                icon-pack="icons" @click.prevent="processRequestConfig()">
                                <span>{{
                                  translate("btnLoadSettings")
                                }}</span>
                              </b-button>
                            </div>
                            <br />
                            <b-table :data="dataModule.configInfo.Configuration" ref="table" aria-next-label="Next page"
                              aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                              striped>
                              <template #empty>
                                <div class="has-text-centered">
                                  <h1>
                                    <span class="tag is-danger">{{
                                      translate("lblNoDataFound")
                                    }}</span>
                                  </h1>
                                </div>
                              </template>
                              <b-table-column field="field" :label="''" v-slot="props" header-class="is-sticky-column-one"
                                width="200">
                                {{ props.row.Field }}
                              </b-table-column>
                              <b-table-column field="value" :label="''" v-slot="props" header-class="is-sticky-column-one"
                                width="200">
                                <span :class="[
                                  { 'tag is-success': props.row.Status === 1 },
                                  { 'tag is-warning': props.row.Status === 2 },
                                  { 'tag is-danger': props.row.Status === 3 },
                                  { 'tag is-info': props.row.Status === 4 },
                                  { '': props.row.Status === 0 },
                                ]" style="font-size: 13px !important;">
                                  {{ props.row.Value }}
                                </span>
                              </b-table-column>
                            </b-table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="stats" style="
                                        padding-left: unset !important;
                                        width: 102% !important;
                                      ">
                    <div class="stats col-lg-12" id="serverInfo">
                      <div class="stat" style="min-width: 700px; height: 340px">
                        <div>
                          <div style="display: inline !important">
                            <div>
                              <i class="icons-3x icons-database" style="font-size: 4.1rem"></i>
                            </div>
                            <div style="
                                                  left: 26%;
                                                  position: relative;
                                                  top: -50px;
                                                  font-size: 1.2rem;
                                                ">
                              {{ translate("deckDatabase_Title") }}
                            </div>
                          </div>
                          <div>
                            <div class="divDatabase" style="display: flex; justify-content: center">
                              <b-button :disabled="!hasDatabase || noConnection" class="button" v-if="isElectronVar" icon-left="icons-1x icons-database"
                                icon-pack="icons" @click.prevent="processBackup()">
                                <span>{{ translate("btnBackup") }}</span>
                              </b-button>
                              &nbsp;
                              <b-button class="button" icon-left="icons-1x icons-undo" icon-pack="icons" v-if="isElectronVar" :disabled="noConnection || hasDatabase &&
                                  userLogged != undefined &&
                                  !userLogged.Technician &&
                                  !userLogged.Root
                                  " @click.prevent="processRequestBackup()">
                                <span>{{ translate("btnRestore") }}</span>
                              </b-button>
                            </div>
                            <br />
                            <b-table :data="dataModule.configInfo.Database" ref="table" aria-next-label="Next page"
                              aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                              striped>
                              <template #empty>
                                <div class="has-text-centered">
                                  <h1>
                                    <span class="tag is-danger" v-if="!noConnection">{{
                                      translate("lblNoDataFound")
                                    }}</span>
                                  </h1>
                                </div>
                              </template>
                              <b-table-column field="field" :label="''" v-slot="props" header-class="is-sticky-column-one"
                                width="200">
                                {{ props.row.Field }}
                              </b-table-column>
                              <b-table-column field="value" :label="''" v-slot="props" header-class="is-sticky-column-one"
                                width="200">
                                <span :class="[
                                  { 'tag is-success': props.row.Status === 1 },
                                  { 'tag is-warning': props.row.Status === 2 },
                                  { 'tag is-danger': props.row.Status === 3 },
                                  { 'tag is-info': props.row.Status === 4 },
                                  { '': props.row.Status === 0 },
                                ]" style="font-size: 13px !important;">
                                  {{ props.row.Value }}
                                </span>
                              </b-table-column>
                            </b-table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div :class="columnSize" style="
                            padding-left: 0px !important;
                            padding-right: 0px !important;
                          ">
                  <div class="stats" style="
                              padding-left: unset !important;
                              width: 102% !important;
                            ">
                    <div class="stats col-lg-12" id="serverInfo" :style="displayDiv == ''
                      ? 'padding-left: 25px; !important'
                      : ''
                      ">
                      <div class="stat" style="min-width: 700px; height: 700px">
                        <div>
                          <div style="display: inline !important">
                            <div>
                              <i class="icons-3x icons-server-config" style="font-size: 4.1rem"></i>
                            </div>
                            <div style="
                                        left: 26%;
                                        position: relative;
                                        top: -50px;
                                        font-size: 1.2rem;
                                      ">
                              {{ translate("deckEstablishment_Title") }}
                            </div>
                          </div>
                          <div>
                            <div class="divConfiguration" style="display: flex; justify-content: center">
                              <ResetSettings @ActionAfterResetSettings="actionAfterResetSettings
                                " v-if="false" />
                              <div class="col-lg-1" style="text-align: center" v-else>
                                <b-dropdown id="dropdownOpt" aria-role="list" v-model="selectedOptionReset">
                                  <template #trigger>
                                    <b-button class="button" icon-left="icons-1x icons-dots-three-vertical"
                                      icon-pack="icons">
                                      <span>{{ translate("deckResetSettings_Title") }}</span>
                                    </b-button>
                                  </template>                                  
                                  <b-dropdown-item aria-role="listitem" :value="'CopyLogs'">
                                    <div class="media">
                                      <div class="media-content">
                                        <a href @click.prevent=""><i class="icons-1x icons-logs" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                          <span>
                                            {{
                                              translate(
                                                "btnCopyLogs"
                                              )
                                            }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </b-dropdown-item>
                                  <b-dropdown-item aria-role="listitem" :value="'CopyCertificate'">
                                    <div class="media">
                                      <div class="media-content">
                                        <a href @click.prevent=""><i class="icons-1x icons-logs" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                          <span>
                                            {{
                                              translate(
                                                "btnCopyCert"
                                              )
                                            }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </b-dropdown-item>
                                  <b-dropdown-item aria-role="listitem"
                                    :value="'Configuration/Settings/ResetBigOsAddress'">
                                    <div class="media">
                                      <div class="media-content">
                                        <a href @click.prevent=""><i class="icons-1x icons-globe" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                          <span>
                                            {{
                                              translate(
                                                "btnResetBigOsAddress"
                                              )
                                            }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </b-dropdown-item>
                                  <b-dropdown-item aria-role="listitem" :value="'Configuration/Settings/ResetServerIni'" v-if="isElectronVar">
                                    <div class="media">
                                      <div class="media-content">
                                        <a href @click.prevent=""><i class="icons-1x icons-cogs" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                          <span>
                                            {{
                                              translate(
                                                "btnResetGlobalConfiguration"
                                              )
                                            }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </b-dropdown-item>
                                  <b-dropdown-item aria-role="listitem" v-if="false"
                                    :value="'Configuration/Settings/ResetClientIni'">
                                    <div class="media">
                                      <div class="media-content">
                                        <a @click.prevent="" v-if="false"><i class="icons-1x icons-network-wired" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                          <span>
                                            {{
                                              translate(
                                                "btnResetClientConfiguration"
                                              )
                                            }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </b-dropdown-item>
                                  <b-dropdown-item aria-role="listitem" v-if="false"
                                    :value="'Configuration/Settings/ResetBothIni'">
                                    <div class="media">
                                      <div class="media-content">
                                        <a href v-if="false"><i class="icons-1x icons-sync" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                          <span>
                                            {{
                                              translate(
                                                "btnResetBothConfiguration"
                                              )
                                            }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </b-dropdown-item>
                                  <b-dropdown-item aria-role="listitem" v-if="errorS2S" :value="'errorS2S'">
                                    <div class="media">
                                      <div class="media-content">
                                        <a href @click.prevent="" v-if="errorS2S"><i class="icons-1x icons-id-card" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                          <span>
                                            {{
                                              translate(
                                                "btnResetS2SConfiguration"
                                              )
                                            }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </b-dropdown-item>
                                  <b-dropdown-item aria-role="listitem" v-if="globalTypeEnum.ClientOnly ==
                                      globalType ||
                                      (globalTypeEnum.ClientAndServer ==
                                        globalType &&
                                        hasDatabase)
                                      " :value="'errorGlobalClientAddress'">
                                    <div class="media">
                                      <div class="media-content">
                                        <a href @click.prevent=""><i class="icons-1x icons-network-wired" style="
                                                                margin-right: 5px; min-width: 20px; text-align: center;
                                                                color: var(--iconColor);
                                                              " />
                                          <span>
                                            {{
                                              translate(
                                                "btnResetGlobalClient"
                                              )
                                            }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </b-dropdown-item>
                                </b-dropdown>
                              </div>
                            </div>
                            <br />
                            <b-table :data="dataModule.configInfo.Establishment" ref="table" aria-next-label="Next page"
                              aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                              striped>
                              <b-table-column field="field" :label="''" v-slot="props" header-class="is-sticky-column-one"
                                width="200">
                                {{ props.row.Field }}
                              </b-table-column>
                              <b-table-column field="value" :label="''" v-slot="props" header-class="is-sticky-column-one"
                                width="200">
                                <span :class="[
                                  { 'tag is-success': props.row.Status === 1 },
                                  { 'tag is-warning': props.row.Status === 2 },
                                  { 'tag is-danger': props.row.Status === 3 },
                                  { 'tag is-info': props.row.Status === 4 },
                                  { '': props.row.Status === 0 },
                                ]" style="font-size: 13px !important;">
                                  {{ props.row.Value }}
                                </span>
                              </b-table-column>
                            </b-table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="" v-if="filePath != ''" id="loadConfig">
            <b-modal :active.sync="showSteps" :can-cancel="false">
              <b-modal v-model="showLoadingCMDfromShowSteps" width="auto" trap-focus :destroy-on-hide="true"
                aria-role="dialog" aria-modal :can-cancel="false">
                <template #default="props">
                  <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage"
                    :countDown="countDown" :JsonObject="JsonObject" @ActionAfterCMDConfig="afterCMD">
                  </LoadingCMD>
                </template>
              </b-modal>
              <b-steps v-model="activeStep" :has-navigation="false" animation="slide-prev" style="padding-top: 10px;">
                <b-step-item step="1" icon=".icons-white icons-file-eye-outline" icon-pack="icons"
                  :label="translate('stepLoadFile')" :clickable="false" :type="{ 'is-success': activeStep > 0 }">
                  <b-message type="is-warning" style="text-align: center">
                    <span v-for="messageAux in message" :key="messageAux">
                      {{ messageAux }}
                      <br />
                    </span>
                    <br />
                    <div style="float: right">
                      <b-button style="margin-right: 10px" @click.prevent="cancelShowSteps()"
                        :label="translate('btnCancel')" icon-left=".icons-white icons-times" icon-pack="icons"></b-button>
                      <b-button @click.prevent="nextStep()" :label="translate('btnOk')" type="is-primary"
                        icon-left=".icons-white icons-check" icon-pack="icons"></b-button>
                    </div>
                  </b-message>
                </b-step-item>

                <b-step-item step="2" :label="translate('stepCredentials')" icon=".icons-white icons-user-plus"
                  icon-pack="icons" :clickable="false" :type="{ 'is-success': activeStep > 1 }" v-if="showLogin">
                  <div class="mb-2" style="text-align: center">
                    {{ translate("lblTechnicianLogin") }}
                  </div>
                  <div class="mb-2" v-if="errorName || errorExecutionCode" style="width: 100%; margin-top: 0.25rem">
                    <Message type="is-danger" :message="errorMessageShowLogin" :isList="false" />
                  </div>

                  <b-field horizontal :label="translate('btnLogin')" :type="{ 'is-danger': errorName }">
                    <b-input :value="technican.login" v-model="technican.login" tabindex="200" expanded maxlength="20"
                      @focus="(inputCredentials = technican.login),
                        (typeCredentials = 'login')
                        " @keydown.native="sendRequestInput"></b-input>
                  </b-field>

                  <b-field horizontal :label="translate('lblPassword')" :type="{ 'is-danger': errorName }" max>
                    <b-input :value="technican.password" v-model="technican.password" type="password" tabindex="201"
                      password-reveal expanded maxlength="20" @focus="(inputCredentials = technican.password),
                        (typeCredentials = 'password')
                        " @keydown.native="sendRequestInput"></b-input>
                  </b-field>

                  <b-field horizontal :label="translate('lblExecutionCode')" :type="{ 'is-danger': errorExecutionCode }"
                    v-if="showLoginExecution">
                    <b-input :value="executionCode" v-model="executionCode" v-if="showLoginExecution" tabindex="202"
                      expanded maxlength="20" @focus="(inputCredentials = executionCode),
                        (typeCredentials = 'code')
                        " @keydown.native="sendRequestInput"></b-input>
                  </b-field>

                  <div class="mb-2" v-if="showKeyboard" style="width: 100%; margin-top: 0.25rem">
                    <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="inputCredentials"
                      :key="typeCredentials" :isNumeric="false" :pin="20" />
                  </div>

                  <footer class="modal-card-foot" style="height: 60px">
                    <div style="padding-botton: -4px">
                      <button class="btn btn-outline-secondary" type="button" tabindex="203"
                        @click.prevent="showKeyboard = !showKeyboard" style="margin-right: 650px">
                        <i class="icons-1x icons-keyboard" />
                      </button>
                      <b-button style="margin-right: 10px" @click.prevent="cancelShowSteps()"
                        :label="translate('btnCancel')" icon-left=".icons-white icons-times" icon-pack="icons"
                        tabindex="204"></b-button>
                      <b-button @keypress.enter.prevent type="is-primary" @click.prevent="checkCredentials()"
                        :label="translate('btnOk')" @focus="hideEnter()" icon-left=".icons-white icons-check"
                        icon-pack="icons" tabindex="205"></b-button>
                    </div>
                  </footer>
                </b-step-item>
                <b-step-item step="2" :label="translate('stepCredentials')" icon=".icons-white icons-user-plus"
                  icon-pack="icons" :clickable="false" :type="{ 'is-success': activeStep > 1 }"
                  v-if="showDatabasePassword">
                  <div class="auth-content">
                    <div class="auth-modal">
                      <div class="auth-title">
                        {{ translate("lblDatabasePassword") }}
                      </div>

                      <strong v-for="messageAux in message" :key="messageAux">
                        {{ messageAux }}
                        <br />
                      </strong>
                      <br />

                      <b-field horizontal :label="translate('lblPassword')" :type="{ 'is-danger': errorName }" :message="[
                        { 'Password is too short': errorName },
                        {
                          'Password must have at least 8 characters':
                            errorName,
                        },
                      ]">
                        <b-input :value="database.password" v-model="database.password"
                          @keydown.enter.native="checkCredentials()" type="password" password-reveal expanded
                          maxlength="20" @focus="(inputCredentials = database.password),
                            (typeCredentials = 'databasePassword')
                            "></b-input>
                      </b-field>
                      <div class="mb-2" v-if="showKeyboard" style="width: 100%; margin-top: 0.25rem">
                        <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="inputCredentials"
                          :key="typeCredentials" :isNumeric="false" :pin="20" />
                      </div>
                      <footer class="modal-card-foot" style="height: 56px">
                        <div style="padding-botton: -5px">
                          <button class="btn btn-outline-secondary" type="button"
                            @click.prevent="showKeyboard = !showKeyboard" style="margin-right: 70px">
                            <i class="icons-1x icons-keyboard" />
                          </button>
                          <b-button style="margin-right: 10px" @click.prevent="cancelShowSteps()"
                            :label="translate('btnCancel')" icon-left=".icons-white icons-times"
                            icon-pack="icons"></b-button>
                          <b-button type="is-primary" @click.prevent="checkCredentials()" :label="translate('btnOk')"
                            icon-left=".icons-white icons-check" icon-pack="icons">
                          </b-button>
                        </div>
                      </footer>
                    </div>
                  </div>
                </b-step-item>
                <b-step-item step="3" icon=".icons-white icons-file-eye-outline" icon-pack="icons"
                  :label="translate('stepInformation')" :clickable="false" :type="{ 'is-success': activeStep > 2 }"
                  v-if="showInfo">
                  <div class="container" style="overflow: auto; height: 300px">
                    <h4 v-for="data in fileData" :key="data.Title" style="position: static;">
                      <table class="table table-bordered">
                        <thead :class="data.SubTitle == null
                          ? 'thead-dark'
                          : 'thead-light'
                          ">
                          <tr>
                            <th style="width: 50%">
                              {{
                                data.Title == null
                                ? data.SubTitle.split("&")[0]
                                : data.Title
                              }}
                            </th>
                            <th style="width: 50%">
                              {{
                                data.Title == null
                                ? data.SubTitle.split("&")[1]
                                : ""
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="value in data.Values" :key="value.Field">
                            <td>{{ value.Field }}</td>
                            <td>{{ value.Value }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </h4>
                  </div>
                  <footer class="modal-card-foot" style="height: 56px">
                    <div style="padding-botton: -5px; text-align: right; width: 100%;">
                      <b-button style="margin-right: 10px" @click.prevent="cancelShowSteps()"
                        :label="translate('btnCancel')" icon-left=".icons-white icons-times"
                        icon-pack="icons"></b-button>
                      <b-button type="is-primary" @click.prevent="saveConfig()" :label="translate('btnOk')"
                        icon-left=".icons-white icons-check" icon-pack="icons">
                      </b-button>
                      &nbsp;&nbsp;&nbsp;
                    </div>
                  </footer>
                </b-step-item>
                <b-step-item step="4" icon=".icons-white icons-checkbox-checked-svgrepo-com-1" icon-pack="icons"
                  style="font-size: 1.5rem;" :clickable="false" :type="{ 'is-success': activeStep > 3 }"
                  :label="translate('stepFinish')">
                  <b-message type="is-success" has-icon>
                    <span v-for="messageAux in message" :key="messageAux">
                      {{ messageAux }}
                      <br />
                    </span>
                    <div style="float: right">
                      <b-button v-if="showRestart" type="is-primary" style="margin-right: 10px" disabled>{{
                        translate("btnWait")
                      }}</b-button>
                      <b-button v-else type="is-danger" style="margin-right: 10px" @click.prevent="cancelShowSteps()"
                        :label="translate('btnClose')" icon-left=".icons-white icons-ban" icon-pack="icons">
                      </b-button>
                    </div>
                  </b-message>
                </b-step-item>
              </b-steps>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MD5 from "crypto-js/md5";
import validateName from "@/js/validateField";
import notify from "@/config/notify.js";
import LoadingCMD from "@/components/processCMD/LoadingCMD";
import Auth from "@/components/auth/Auth.vue";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import CopyReport from "@/components/report/CopyReport.vue";
import RequestFile from "@/components/file/RequestFile.vue";
import IniFile from "@/components/ini/IniFile.vue";
import ResetSettings from "@/components/dropdown/ResetSetting.vue";
import SimpleKeyboard from "@/components/keyboard/SimpleKeyboard.vue";
import GlobalSettings from "@/components/global/GlobalSettings.vue";
import Message from "@/components/message/Message.vue";
import { classValue, statusConfigValues } from "@/mixins/enums.js";
import { getSize } from "@/js/screenAdjustment.js";
import { mapState } from "vuex";
import { clientCommands } from "@/js/ClientCommands.js";
import { clientAPIs } from "@/js/ClientAPIs.js";
import { returnApp } from "@/js/resetApp.js";
import { os, deleteFile } from "@/js/osType.js";
import { getRoute } from "@/js/downloadAndUploadFile.js";
import { globalType } from "@/mixins/enums";
import { countries } from "@/mixins/countryCode.js";

export default {
  name: "Configurations",
  mixins: [en, es, nor, swe],
  components: {
    LoadingCMD,
    CopyReport,
    RequestFile,
    IniFile,
    ResetSettings,
    Auth,
    SimpleKeyboard,
    GlobalSettings,
    Message,
  },
  computed: mapState([
    "session",
    "communication",
    "hasDatabase",
    "noConnection",
    "licenseError",
    "hasBigOsIp",
    "distinctCountryCode",
    "errorMessage",
    "dataModule",
    "lang",
    "userLogged",
    "errorS2S",
    "errorGlobalClient",
    "currentTab",
    "isElectronVar",
    "IP",
    "globalType",
    "idEmailSender",
    "establishmentName",
    "user",
  ]),
  props: {
    IsFromHeader: Boolean,
  },
  data() {
    return {
      activeStep: 0,
      showSteps: false,
      showLogin: false,
      showDatabasePassword: false,
      showLoginExecution: false,
      showRestart: true,
      showModalRestart: false,
      showModalErrorConf: false,
      showModalGlobalSettings: false,
      showInfo: false,
      infos: {},
      executionCode: "",
      executionCodeFile: "",
      errorExecutionCode: "",
      message: "",
      classValue: classValue,
      countries: countries,
      typeMessage: 0,
      countDown: 5,
      isLoading: false,
      isLoadingModal: false,
      isFullPage: true,
      errorName: false,
      errorPass: false,
      errorConfirmPass: false,
      errorMessageShowLogin: "",
      alreadyHaveDatabase: false,
      sendUserAxios: { user: null, password: "" },
      fileData: [{}],
      users: [],
      items: [
        { title: "DataBase Version", value: "1.00" },
        { title: "EngineVersion", value: "1.0.45" },
      ],

      file: null,
      technican: {
        login: "",
        password: "",
      },
      database: {
        password: "",
      },
      sendUser: {
        user: {},
        password: "",
      },
      cmd: "",
      showBackupRestoreMessage: false,
      showLoadingCMD: false,
      showLoadingCMDfromShowSteps: false,
      showLoadingDoAction: false,
      sendingCMDMessage: "",
      JsonObject: {
        data: "",
      },
      isAxios: false,
      showCopyReport: false,
      showRequestFile: false,
      showProcessAction: false,
      showIniFile: false,
      isConfig: false,
      penDriveList: [],
      penDrive: null,
      objDoAction: null,
      restartCommunication: false,
      dataCopy: {},
      dataRequestFile: {},
      filePath: "",
      copyReportNameShow: "",
      Os: os,
      local: "",
      showAutentication: false,
      columnSize: "col-lg-6",
      inputCredentials: "",
      typeCredentials: "",
      showKeyboard: false,
      openModalIni: "",
      extensions: "",
      momentInstance: this.moment(),
      intervalGetDatas: null,
      intervalReload: null,
      displayDiv: "",
      globalTypeEnum: globalType,
      selectedOptionReset: "",
      isCmdBackup: false,
      oldTab: "",
    };
  },

  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    onChange(input) {
      try {
        if (this.showKeyboard) {
          switch (this.typeCredentials) {
            case "login":
              this.technican.login = input;
              break;
            case "password":
              this.technican.password = input;
              break;
            case "code":
              this.executionCode = input;
              break;
            case "databasePassword":
              this.database.password = input;
              break;
            default:
              break;
          }
        }
      } catch (e) {
        console.log("Error in onChange IniFile " + e);
      }
    },
    onKeyPress(button) {
      console.log("button", button);
      try {
        if (button === "{clear}") {
          if (this.showKeyboard) {
            switch (this.typeCredentials) {
              case "login":
                this.technican.login = "";
                break;
              case "password":
                this.technican.password = "";
                break;
              case "code":
                this.executionCode = "";
                break;
              case "databasePassword":
                this.database.password = "";
                break;
              default:
                break;
            }
          }
        }
      } catch (e) {
        console.log("Error in onKeyPress IniFile " + e);
      }
    },

    onInputChange(input) {
      try {
        if (this.showKeyboard) {
          switch (this.typeCredentials) {
            case "login":
              this.technican.login = input;
              break;
            case "password":
              this.technican.password = input;
              break;
            case "code":
              this.executionCode = input;
              break;
            case "databasePassword":
              this.database.password = input;
              break;
            default:
              break;
          }
        }
      } catch (e) {
        console.log("Error in onInputChange " + e);
      }
    },
    close() {    
      this.$store.commit("setSelectedTab", this.oldTab);
      this.$emit('close')
    },
    nextStep() {
      this.activeStep = this.activeStep + 1;
      this.typeCredentials = "";
      this.showKeyboard = false;
      this.inputCredentials = "";
    },
    finishConfig: function (obj, forceRestart, cmd) {
      this.showInfo ? (this.activeStep = 3) : (this.activeStep = 2);
      if (this.countDown > 0) {
        this.intervalReload = setTimeout(() => {
          this.countDown -= 1;
          if (this.countDown <= 0) {
            this.cancelShowSteps(true);
            //this.sendServerRestart();
            if (obj.restartRequired) {
              console.log("Restarting system after config install");
              this.$store.commit("changeCompleteLogin", false);
              this.$store.commit("changeValidatingLogin", false);
              localStorage.removeItem("ManagerOptions" + '/' + this.IP);
              localStorage.removeItem("CashierOptions" + '/' + this.IP);
              localStorage.removeItem("UserLoggedTemp" + '/' + this.IP)

              returnApp(this);

              this.showModalRestart = false;
              return;
            }
          } else {
            this.finishConfig(obj, forceRestart, cmd);
          }
        }, 1000);
      }
    },
    sendFile() {
      if (this.file != null) {
        this.uploadArquivo();
      }
    },
    sendServerRestart() {
      this.$store.commit("changeStopRequest", true);
      this.sendCMD(
        clientCommands.ServerRestart.name,
        this.translate("waiting") + " " + this.translate("cmdRestartServer"),
        {},
        clientCommands.ServerRestart.time,
        this.restartCommunication
      );
    },

    //WebClient
    processDownloadFile(obj) {
      setTimeout(
        () => {
          try {
            this.JsonObject = obj;
            this.JsonObject.isDownload = true;
            this.JsonObject.item = {};
            this.JsonObject.method = "GET";
            this.cmd = obj.cmd;
            this.countDown = obj.countDown;
            this.showLoadingCMD = true;
            this.sendingCMDMessage = obj.message;
            //this.isAxios = true;
            this.sendCMD(
              this.cmd,
              this.translate("messageUploadConfig"),
              this.JsonObject,
              3600,
              this.restartCommunication);
          } catch (e) {
            console.log("Error in ProcessDownloadFile(Config)" + e);
          }
        },
        300
      );
    },

    processUploadFile(obj, url, message, cmd, countDown) {
      this.JsonObject.File = obj;
      this.JsonObject.isDownload = false;
      this.JsonObject.url = url;
      this.countDown = countDown;
      this.cmd = cmd;
      this.sendingCMDMessage = message;
      this.showLoadingCMD = true;
      this.sendCMD(
        cmd,
        this.translate("messageUploadConfig"),
        this.JsonObject,
        3600,
        this.restartCommunication);
    },

    sendCMD(cmd, message, object, countDown, isRestartCommunication = false) {
      try {
        this.countDown = countDown;
        this.cmd = cmd;
        this.isAxios = false;
        this.restartCommunication = isRestartCommunication;
        switch (cmd) {
          case clientCommands.UploadConfig.name:
          case clientCommands.DoRestore.name:
          case clientCommands.DeleteDatabase.name:
          case clientCommands.ServerRestart.name:
          case clientCommands.GetPenDriveList.name:
          case clientCommands.BlockCommunication.name:
          case clientCommands.UnblockCommunication.name:
          case clientCommands.DownloadConfig.name:
          case clientCommands.SaveBigOsSettings.name:
          case clientCommands.SendFile.name:
          case clientCommands.RunOsAdmin.name:
          case clientCommands.DisableEmailSender.name:
          case clientCommands.GenerateLicense.name:
          case clientCommands.AutomaticLicenseReq.name:
          case clientCommands.ValidateLicense.name:
          case clientCommands.RequestLicense.name:
          case clientCommands.CheckLicenseReq.name:
          case clientCommands.CancelLicense.name:
          case clientCommands.CancelLicenseReq.name:
            this.showLoadingCMD = true;
            break;
          case clientCommands.InstallConfig.name:
            this.showLoadingCMDfromShowSteps = true;
            break;
          default:
            //
            break;
        }
        this.sendingCMDMessage = message;
        if (clientCommands.SendFile.name == cmd) {
          this.JsonObject.Value = object;
        } else {
          this.JsonObject = object;
        }
      } catch (e) {
        console.log("Error in SendCMD Configurations() ", e);
      }
    },
    afterCMD(obj) {
      try {
        if (obj == undefined) {
          if (this.restartCommunication) {
            if (this.isElectronVar) {
              this.processRequestPenDriveList();
            } else {
              //
              let objAux = {
                url: getRoute(
                  this.IP,
                  clientAPIs.Path.name +
                  "/" +
                  clientAPIs.PathDownloadFile.name +
                  "/" +
                  clientAPIs.Backup.name
                ),
                message: this.translate("messageDoBackupCMD"),
                name: this.formatNameBackup(),
                cmd: clientCommands.DoBackup.name,
                countDown: clientCommands.Backup.time
              };
              setTimeout(() => this.processDownloadFile(objAux), 500);
            }
          }
        } else if (obj.ok) {
          switch (obj.cmd) {
            case clientCommands.UploadConfig.name:
            case clientCommands.DownloadConfig.name:
              if (obj.messageType === classValue.error) {
                this.showModalErrorConf = true;
                this.message = obj.message;
              } else {
                this.$store.commit("changeHasDatabase", false);
                this.changeShowSteps(obj);
              }
              break;
            case clientCommands.SendFile.name:
              this.$store.commit("changeHasDatabase", false);
              this.changeShowSteps(obj);
              break;
            case clientCommands.ResetClientConfiguration.name:
            case clientCommands.ResetBothConfigurations.name:
            case clientCommands.ResetServerConfiguration.name:
            case clientCommands.SaveBigOsSettings.name:
            case clientCommands.DeleteDatabase.name:
              this.$store.commit("changeStopRequest", true);
              this.showModalRestart = true;
              this.message = obj.message;
              this.countDown = obj.timeToRestart;
              this.finishConfig(obj, true, obj.cmd);
              break;
            case clientCommands.InstallConfig.name:
              this.message = obj.message;
              this.countDown = obj.timeToRestart;
              this.showRestart = obj.restartRequired ? true : false;
              this.finishConfig(obj, false, obj.cmd);
              break;
            case clientCommands.CertificateReport.name:
            case clientCommands.ServerLogs.name:
            case clientCommands.ServerReports.name:
              this.dataCopy = obj.object;
              this.showCopyReport = true;
              break;
            case clientCommands.RequestConfig.name:
              this.dataRequestFile = obj.object.PenDriveInfoList;
              this.showRequestFile = true;
              this.extensions = ".bcf, .xml";
              this.isConfig = true;
              break;
            case clientCommands.RequestBackup.name:
              this.dataRequestFile = obj.object.PenDriveInfoList;
              if (
                obj.object.StatusDatabaseValue ==
                statusConfigValues.ShowPassword
              ) {
                this.showDatabasePassword = true;
              } else {
                this.showDatabasePassword = false;
              }
              this.showRequestFile = true;
              this.extensions = ".zip";
              this.isConfig = false;
              break;
            case clientCommands.RequestLicense.name:
              this.penDrive = null;
              this.dataRequestFile = obj.object.PenDriveInfoList;
              this.showDatabasePassword = false;
              this.showRequestFile = true;
              this.extensions = ".lcs";
              this.isConfig = false;
              break;
            case clientCommands.GetPenDriveList.name:
              this.showProcessAction = true;
              this.penDriveList = obj.object.PenDriveInfoList;
              break;
            case clientCommands.DoBackup.name:
              this.message = obj.message;
              this.typeMessage = obj.messageType;
              this.countDown = obj.timeToRestart;
              this.showProcessAction = false;
              this.penDrive = null;
              console.log("DoBackup: " + this.message);
              if (this.typeMessage === classValue.approved && this.isElectronVar) {
                this.showBackupRestoreMessage = true;
              }
              setTimeout(
                () => (
                  (this.typeMessage = 0),
                  (this.showBackupRestoreMessage = false)
                ),
                this.countDown * 1000
              );
              if (this.restartCommunication) {
                setTimeout(
                  () => this.processUnblockCommunicationNoAsk(),
                  this.countDown * 1000
                );
              }
              break;
            case clientCommands.DoRestore.name:
              this.message = obj.message;
              this.typeMessage = obj.messageType;
              this.countDown = obj.timeToRestart;
              this.showRequestFile = false;
              this.penDrive = null;
              console.log("DoRestore: " + this.message);
              if (this.typeMessage === classValue.approved) {
                this.showBackupRestoreMessage = true;
              }
              setTimeout(
                () => (
                  (this.typeMessage = 0),
                  (this.showBackupRestoreMessage = false)
                ),
                this.countDown * 1000
              );
              this.$store.commit("changeIdEmailSender", obj.object.idEmailSender);
              break;
            case clientCommands.CopyCertificate.name:
              // TODO
              break;
            case clientCommands.DisableEmailSender.name:
              this.$store.commit("changeIdEmailSender", 0);
              break;
            case clientCommands.CheckLicenseReq.name:
              this.processLicense(true, obj.object, false, true);
              break;
            case clientCommands.CancelLicenseReq.name:
              this.processLicense(false);
              break;
            case clientCommands.GenerateLicense.name:
              this.showProcessAction = false;
              break;
            case clientCommands.AutomaticLicenseReq.name:
              this.showProcessAction = false;
              break;
            case clientCommands.ValidateLicense.name:
              this.showRequestFile = false;
              break;
            case clientCommands.ShowPauseGroups.name:
              this.processShowPauseGroups(obj.object);
              break;
            default:
              console.log("invalid cmd Config" + obj.cmd);
              break;
          }
        } else if (obj.restartRequired) {
          this.message = obj.message;
          this.countDown = obj.timeToRestart;
          this.showRestart = obj.restartRequired ? true : false;
          this.finishConfig(obj, false, obj.cmd);
        } else {
          switch (obj.cmd) {
            case clientCommands.GetPenDriveList.name:
            case clientCommands.DoBackup.name:
              this.showProcessAction = false;
              this.penDrive = null;
              if (this.restartCommunication) {
                setTimeout(() => this.processUnblockCommunication(), 500);
              }
              break;
            case clientCommands.GenerateLicense.name:
              this.penDrive = null;
              this.showProcessAction = false;
              break;
            case clientCommands.AutomaticLicenseReq.name:
              this.penDrive = null;
              this.processLicense(true, false, true)
              break;
            case clientCommands.DoRestore.name:
              this.showRequestFile = false;
              this.penDrive = null;
              break;
            case clientCommands.ValidateLicense.name:
              this.penDrive = null;
              this.showRequestFile = false;
              break;
            case clientCommands.SaveBigOsSettings.name:
              this.showIniFile = true;
              break;
            case clientCommands.InstallConfig.name:
              this.errorMessage = obj.message;
              this.cancelShowSteps();
              break;
          }
          this.file = null;
          this.filePath = "";
        }
      } catch (e) {
        console.log("Error in AfterCMD Configutations()", e);
      }
    },
    //WebClient
    uploadArquivoFileUpload(obj, password) {
      let url = "";
      this.filePath = obj.Name;      
      switch (this.cmd) {
        case clientCommands.RequestConfig.name:
          this.processUploadFile(
            obj,
            clientAPIs.Configuration.name,
            this.translate("messageUploadConfig"),
            clientCommands.UploadConfig.name,
            clientAPIs.Configuration.time
          );
          break;
        case clientCommands.RequestBackup.name:
          url =
            password == ""
              ? clientAPIs.Restore.name + "/" + "0xFF/false"
              : clientAPIs.Restore.name + "/" + password + "/true";
          this.processUploadFile(
            obj,
            url,
            this.translate("messageUploadDatabase"),
            clientCommands.DoRestore.name,
            clientAPIs.Restore.time
          );
          break;
        case clientCommands.RequestLicense.name:
          this.processUploadFile(
            obj,
            clientAPIs.License.name,
            this.translate("checkingLicense"),
            clientCommands.ValidateLicense.name,
            clientAPIs.License.time
          );
          break;
        default:
          break;
      }
    },
    uploadArquivoBigOs(obj) {
      this.filePath = obj.name;
      this.sendCMD(
        clientCommands.DownloadConfig.name,
        this.translate("messageDownloadConfig"),
        obj,
        clientCommands.DownloadConfig.time,
        this.restartCommunication
      );
    },
    uploadArquivo(path) {
      //this.isLoading = true;
      this.filePath = path;
      this.JsonObject.data = path;      
      switch (this.cmd) {
        case clientCommands.RequestBackup.name:
          this.sendCMD(
            clientCommands.DoRestore.name,
            this.translate("messageUploadDatabase"),
            this.JsonObject,
            clientCommands.DoRestore.time,
            this.restartCommunication
          );
          break;
        case clientCommands.RequestLicense.name:
          this.sendCMD(
            clientCommands.ValidateLicense.name,
            this.translate("checkingLicense"),
            this.JsonObject,
            clientCommands.ValidateLicense.time,
            this.restartCommunication
          );
          break;
        default:
          this.sendCMD(
            clientCommands.UploadConfig.name,
            this.translate("messageUploadConfig"),
            this.JsonObject,
            clientCommands.UploadConfig.time,
            this.restartCommunication
          );
          break;
      }
    },
    cancelShowSteps(afterInstall = false) {
      this.file = null;
      this.filePath = "";
      this.errorName = false;
      this.showSteps = false;
      this.technican = {};
      this.database = {};
      this.executionCode = "";
      this.errorExecutionCode = false;
      this.showLogin = false;
      this.showDatabasePassword = false;
      this.showLoginExecution = false;
      this.showInfo = false;
      if (!afterInstall)
        this.$store.commit("changeHasDatabase", this.alreadyHaveDatabase);
    },

    changeShowSteps(res) {

      this.activeStep = 0;
      this.showSteps = true;
      try {
        if (res.object.Status == statusConfigValues.ShowLogin) {
          this.showLogin = true;
          this.users = res.object.Object.Users;
          this.showDatabasePassword = false;

          if (res.object.Object.FileData != null) {
            this.fileData = res.object.Object.FileData;
            this.activeStep = 1;
            this.showInfo = true;
          } else {
            this.activeStep = 0;
          }
        } else if (res.object.Status == statusConfigValues.ShowPassword) {
          this.showDatabasePassword = true;
          this.activeStep = 1;
        } else if (res.object.Status == statusConfigValues.ShowLoginExecution) {
          this.showDatabasePassword = false;
          this.showLogin = true;
          this.showLoginExecution = true;
          this.users = res.object.Object.Users;
          this.executionCodeFile = res.object.Object.ExecutionCode;
          if (res.object.Object.FileData != null) {
            this.fileData = res.object.Object.FileData;
            this.showInfo = true;
          } else {
            this.activeStep = 0;
          }
        }
        if (res.object.Message[0] != "") {
          this.message = res.object.Message;
        } else {
          this.activeStep = 1;
        }
      } catch (e) {
        console.log("Error in changeShowSteps " + e);
      }
    },

    checkCredentials() {
      this.errorName = false;
      this.errorExecutionCode = false;
      this.errorMessageShowLogin = "";
      this.sendUserAxios.user = null;
      if (this.showLogin) {
        const passwordMD5 = MD5(this.technican.password).toString();
        this.users.forEach((user) => {
          if (
            user.Login == this.technican.login &&
            user.Password == passwordMD5
          ) {
            this.sendUserAxios.user = user;
          }
        });

        if (
          this.showLoginExecution &&
          this.executionCode != this.executionCodeFile
        ) {
          this.errorExecutionCode = true;
          this.errorMessageShowLogin = this.translate(
            "errorMessageInvalidExecutionCode"
          );
        }

        if (this.sendUserAxios.user === null) {
          this.errorName = true;
          if (this.errorExecutionCode) {
            this.errorMessageShowLogin = this.translate(
              "errorMessageInvalidLoginWithExecutionCode"
            );
          } else {
            this.errorMessageShowLogin = this.translate(
              "errorMessageInvalidLogin"
            );
          }
        }

        if (this.errorName || this.errorExecutionCode) {
          return;
        }
      } else if (this.showDatabasePassword) {
        this.sendUserAxios.password = this.database.password;
      }

      if (!this.showInfo) {
        this.saveConfig();
      } else {
        this.activeStep = this.activeStep + 1;
      }
    },

    saveConfig() {
      this.sendCMD(
        clientCommands.InstallConfig.name,
        this.translate("messageInstallingConfiguration"),
        this.sendUserAxios,
        clientCommands.InstallConfig.time,
        this.restartCommunication
      );
    },
    changeHasDatabase() {
      this.$store.commit("changeHasDatabase", true);
    },
    changeNoConnection() {
      this.$store.commit("changeNoConnection", true);
    },
    changeLicenseError() {
      this.$store.commit("changeLicenseError", true);
    },
    changeHasBigOsIp() {
      this.$store.commit("changeHasBigOsIp", true);
    },

    validate() {
      this.errorName = validateName.regexLogin(this.user.name);
    },

    processCancelLicenseReq() {
      this.cmd = clientCommands.CancelLicenseReq.name;
      this.sendingCMDMessage = this.translate("lblLoading");
      this.countDown = clientCommands.CancelLicenseReq.time;
      this.showLoadingCMD = true;
    },

    processLicense(isRet, hasLicenseReq, forceLocal, showConfirmMsg) {
      this.isCmdBackup = false;
      this.isAxios = false;
      if (!isRet) { // primeira chamada para verificar o status de licenca
        this.processCheckLicense();
      } else { // se ja veio um retorno do servidor
        if (!hasLicenseReq) { // se nao tiver requisicao de licenca
          if (showConfirmMsg != undefined && showConfirmMsg) {
            setTimeout(
              () =>
                this.$buefy.dialog.confirm({
                  title: this.translate("license_Title"),
                  message: this.translate("msgGenNewLicense"),
                  confirmText: this.translate("btnYes"),
                  cancelText: this.translate("btnNo"),
                  type: "is-primary",
                  hasIcon: true,
                  onConfirm: () => this.postProcessLicense(forceLocal),
                }),
              100
            );
          } else {
            setTimeout(() => this.postProcessLicense(forceLocal), 100);
          }
        } else { // se tiver requisicao de licenca
          this.processRequestLicenseFile();
        }
      }
    },

    processDelete() {
      this.$buefy.dialog.confirm({
        title: this.translate("btnDelete"),
        message: this.translate("msgDeleteDatabase"),
        confirmText: this.translate("btnYes"),
        cancelText: this.translate("btnNo"),
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => this.sendCMD(
          clientCommands.DeleteDatabase.name,
          (this.sendingCMDMessage = this.translate("msgDeletingDatabase")),
          {},
          (this.countDown = clientCommands.DeleteDatabase.time),
          false
        ),
      })

    },

    postProcessLicense(forceLocal) {
      if (!this.hasBigOsIp || forceLocal) { // se nao tiver endereco do BigOS configurado ou se for pra forcar escolha de pendrive
        if (this.isElectronVar) { // se for electron
          this.processRequestPenDriveList();
        } else { // se for web
          let objAux = {
            url: getRoute(
              this.IP,
              clientAPIs.Path.name +
              "/" +
              clientAPIs.PathDownloadFile.name +
              "/" +
              clientAPIs.License.name
            ),
            message: this.translate("messageGenerateLicenseCMD"),
            name: this.formatNameLicense(),
            cmd: clientCommands.GenerateLicense.name,
            countDown: clientAPIs.License.time
          };
          this.processDownloadFile(objAux);
        }
      } else { // se tiver endereco do BigOS configurado
        this.$buefy.dialog.confirm({
          title: this.translate("license_Title"),
          message: this.translate("msgCheckAutoLicense"),
          confirmText: this.translate("btnYes"),
          cancelText: this.translate("btnNo"),
          type: "is-primary",
          hasIcon: true,
          onConfirm: () => this.processRequestAutomaticLicense(), // usuario escolher fazer o processo automatico
          onCancel: () => this.processLicense(true, false, true), // usuario escolheu fazer processo escolhendo pendrive
        });
      }
    },

    formatNameLicense() {
      return (
        this.establishmentName + ".lcs"
      ).replace(/[-:]/g, "");
    },

    processShowCopyLogs() {
      this.copyReportNameShow = "Logs";
      this.isAxios = false;
      this.cmd = clientCommands.ServerLogs.name;
      this.sendingCMDMessage = this.translate("lblLoading");
      this.countDown = clientCommands.ServerLogs.time;
      this.showLoadingCMD = true;
    },

    processShowCopyReports() {
      this.copyReportNameShow = "Reports";
      this.isAxios = false;
      this.cmd = clientCommands.ServerReports.name;
      this.sendingCMDMessage = this.translate("lblLoading");
      this.countDown = clientCommands.ServerReports.time;
      this.showLoadingCMD = true;
    },

    processShowCopyCert() {
      this.copyReportNameShow = "Certificates";
      this.isAxios = false;
      this.cmd = clientCommands.CertificateReport.name;
      this.sendingCMDMessage = this.translate("lblLoading");
      this.countDown = clientCommands.CertificateReport.time;
      this.showLoadingCMD = true;
    },

    processRequestBackup() {
      this.isAxios = false;
      this.cmd = clientCommands.RequestBackup.name;
      this.sendingCMDMessage = this.translate("lblLoading");
      this.countDown = clientCommands.RequestBackup.time;
      this.showLoadingCMD = true;
    },

    actionAfterMessage() {
      this.showModalErrorConf = false;
    },

    processCheckLicense() {
      setTimeout(
        () =>
          this.sendCMD(
            clientCommands.CheckLicenseReq.name,
            (this.sendingCMDMessage = this.translate("checkingLicense")),
            {},
            (this.countDown = clientCommands.CheckLicenseReq.time),
            false
          ),
        300
      );
    },

    processRequestLicenseFile() {
      setTimeout(
        () =>
          this.sendCMD(
            clientCommands.RequestLicense.name,
            (this.sendingCMDMessage = this.translate("lblLoading")),
            {},
            (this.countDown = clientCommands.RequestLicense.time),
            false
          ),
        300
      );
    },

    processRequestConfig() {
      this.isAxios = false;
      this.cmd = clientCommands.RequestConfig.name;
      this.sendingCMDMessage = this.translate("lblLoading");
      this.countDown = clientCommands.RequestConfig.time;
      this.showLoadingCMD = true;
    },

    processBackup() {
      this.isCmdBackup = true;
      if (this.communication) {
        this.processBlockCommunication();
      } else {
        if (this.isElectronVar) {
          this.processRequestPenDriveList();
        } else {
          let objAux = {
            url: getRoute(
              this.IP,
              clientAPIs.Path.name +
              "/" +
              clientAPIs.PathDownloadFile.name +
              "/" +
              clientAPIs.Backup.name
            ),
            message: this.translate("messageDoBackupCMD"),
            name: this.formatNameBackup(),
            cmd: clientCommands.DoBackup.name,
            countDown: clientAPIs.Backup.time
          };
          this.processDownloadFile(objAux);
        }
      }
    },
    formatNameBackup() {
      return (
        "backup" +
        "_" +
        this.moment(this.momentInstance).format("YY-MM-DDHH:mm:ss") +
        ".zip"
      ).replace(/[-:]/g, "");
    },
    processBlockCommunication() {
      this.route = "/modules/Configurations/PenDriveList";
      this.$buefy.dialog.confirm({
        title: this.translate("dialog_Title"),
        message:
          this.translate("messageBlockCommunication") +
          " <br/> " +
          this.translate("messageAllMachinesDisconnect"),
        confirmText: this.translate("btnYes"),
        cancelText: this.translate("btnCancel"),
        type: "is-primary",
        hasIcon: true,
        onConfirm: () =>
          this.sendCMD(
            clientCommands.BlockCommunication.name,
            this.translate("waiting") +
            " " +
            this.translate("cmdBlockCommunication"),
            {},
            clientCommands.BlockCommunication.time,
            true
          ),
      });
    },
    processUnblockCommunication() {
      this.route = "/modules/Configurations/PenDriveList";
      this.$buefy.dialog.confirm({
        title: this.translate("dialog_Title"),
        message:
          this.translate("messageUnblockCommunication") +
          "<br/>" +
          this.translate("messageAllMachinesConnect"),
        confirmText: this.translate("btnOk"),
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => this.processUnblockCommunicationNoAsk(),
      });
    },
    processUnblockCommunicationNoAsk() {
      this.isAxios = false;
      this.route = "/modules/Configurations/PenDriveList";
      this.sendCMD(
        clientCommands.UnblockCommunication.name,
        this.translate("waiting") +
        " " +
        this.translate("cmdUnblockCommunication"),
        {},
        clientCommands.UnblockCommunication.time,
        this.restartCommunication
      );
    },

    processRequestPenDriveList() {
      setTimeout(
        () =>
          this.sendCMD(
            clientCommands.GetPenDriveList.name,
            (this.sendingCMDMessage = this.translate("lblLoading")),
            {},
            (this.countDown = clientCommands.GetPenDriveList.time),
            this.restartCommunication
          ),
        300
      );
    },

    processRequestAutomaticLicense() {
      setTimeout(
        () =>
          this.sendCMD(
            clientCommands.AutomaticLicenseReq.name,
            (this.sendingCMDMessage = this.translate("messageGenerateLicenseAutomaticCMD")),
            {},
            (this.countDown = clientCommands.AutomaticLicenseReq.time),
            this.restartCommunication
          ),
        300
      );
    },

    closeProcessAction() {
      this.penDriveList = [];
      this.penDrive = null;
      this.showLoadingCMDProcessBackup = false;
      this.showProcessAction = false;
      this.objDoAction = null;
      if (this.isCmdBackup) {
        if (this.restartCommunication) {
          this.processUnblockCommunication();
        }
        this.restartCommunication = false;
      }
    },
    doAction() {
      this.objDoAction = this.penDrive;
      this.isAxios = false;
      if (this.isCmdBackup) {
        this.cmd = clientCommands.DoBackup.name;
        this.sendingCMDMessage = this.translate("messageDoBackupCMD");
        this.countDown = clientCommands.DoBackup.time;
      } else {
        this.cmd = clientCommands.GenerateLicense.name;
        this.sendingCMDMessage = this.translate("messageGenerateLicenseCMD");
        this.countDown = clientCommands.GenerateLicense.time;
      }
      this.showLoadingDoAction = true;
    },

    processShowAuthentication(local) {
      //Pedir Senhar apenas se tiver com banco
      this.local = local;
      if (this.hasDatabase) {
        this.showAutentication = true;
      } else {
        this.afterAuth(local);
      }
    },

    processShowBigOsSettings() {
      this.openModalIni = "bigOsSettings";
      this.showIniFile = true;
    },

    afterAuth(value) {
      try {
        switch (value) {
          case "Configuration/Settings/ResetServerIni":
            this.sendResetServer();
            break;
          case "Configuration/Settings/ResetClientIni":
            this.sendResetIP();
            break;
          case "Configuration/Settings/ResetBothIni":
            this.sendResetBoth();
            break;
          case "Configuration/Settings/ResetBigOsAddress":
            this.processShowBigOsSettings();
            break;
          default:
            console.log("unkown Local: " + value);
            break;
        }
      } catch (e) {
        console.log("Error in afterAuth Configuration: " + e);
      }
    },

    actionAfterResetSettings(value) {
      switch (value) {
        case "errorS2S":
          this.openModalIni = "";
          this.showIniFile = true;
          break;
        case "errorGlobalClientAddress":
          this.showModalGlobalSettings = true;
          break;
        case "runOsAdmin":
          this.sendCMD(
            clientCommands.RunOsAdmin.name,
            this.translate("messageRunOsAdmin"),
            {},
            clientCommands.RunOsAdmin.time
          );
          break;
        case "ServerLicense":
          this.processLicense(false)
          break;
        case "CopyLogs":
          this.processShowCopyLogs();
          break;
        case "CopyReport":
          this.processShowCopyReports();
          break;
        case "CopyCertificate":
          this.processShowCopyCert();
          break;
        default:
          this.processShowAuthentication(value);
          break;
      }
      setTimeout(() => this.removeIsActiveClassFromList(), 500);
    },

    removeIsActiveClassFromList() {
      for (let element of document.getElementById("dropdownOpt").children.item(2).children.item(0).children) {
        if (element != undefined) {
          element.classList.remove("is-active");
        }
      }
    },

    sendResetServer() {
      this.isAxios = false;
      this.cmd = clientCommands.ResetServerConfiguration.name;
      this.sendingCMDMessage =
        this.translate("waiting") +
        " " +
        this.translate("btnResetGlobalConfiguration");
      this.countDown = clientCommands.ResetServerConfiguration.time;
      this.showLoadingCMD = true;
    },

    sendResetIP() {
      if (!deleteFile()) {
        notify.message(
          "foo",
          this.translate("lblError"),
          this.translate("lblErrorDeleteFile"),
          "error",
          3000
        );
        return;
      } else {
        this.isAxios = false;
        this.cmd = clientCommands.ResetClientConfiguration.name;
        this.sendingCMDMessage =
          this.translate("waiting") +
          " " +
          this.translate("btnResetClientConfiguration");
        this.countDown = clientCommands.ResetClientConfiguration.time;
        this.JsonObject = this.userLogged;
        this.showLoadingCMD = true;
      }
    },

    sendResetBoth() {
      if (!deleteFile()) {
        notify.message(
          "foo",
          this.translate("lblError"),
          this.translate("lblErrorDeleteFile"),
          "error",
          3000
        );
        return;
      } else {
        this.isAxios = false;
        this.cmd = clientCommands.ResetBothConfigurations.name;
        this.sendingCMDMessage =
          this.translate("waiting") +
          " " +
          this.translate("btnResetBothConfiguration");
        this.countDown = clientCommands.ResetBothConfigurations.time;
        this.showLoadingCMD = true;
      }
    },
    actionAfterSaveBigOsSettings(obj) {

      this.sendCMD(
        clientCommands.SaveBigOsSettings.name,
        this.translate("messageSaveBigOsSettings"),
        obj,
        clientCommands.SaveBigOsSettings.time
      );
    },

    sendRequestInput(event) {
      if (event.which === 13) {
        this.checkCredentials();
      }
    },

    sendDisableEmailConfig() {
      const obj = {
        Value: this.idEmailSender
      }
      this.sendCMD(
        clientCommands.DisableEmailSender.name,
        this.translate("messageDiscardEmailSender"),
        obj,
        clientCommands.DisableEmailSender.time
      );
    },

    checkHasEmailSenderConfig() {
      this.$buefy.dialog.confirm({
        title: this.translate("dialog_Title"),
        message: this.translate("messageHasEmailSenderPart1") + "<br/>" + this.translate("messageHasEmailSenderPart2") + "<br/>" + this.translate("messageHasEmailSenderPart3"),
        confirmText: this.translate("btnOk"),
        cancelText: this.translate("btnDiscard"),
        type: "is-primary",
        hasIcon: true,

        onConfirm: () => this.$store.commit("changeIdEmailSender", 0),
        onCancel: () => this.sendDisableEmailConfig(),
      });
    },

    refreshPenDriveFiles(command) {
      if (command == undefined) {
        command = this.cmd;
      }
      switch (command) {
        case clientCommands.RequestConfig.name:
          this.processRequestConfig()
          break;
        case clientCommands.RequestBackup.name:
          this.closeProcessAction()
          this.processRequestBackup()
          break;
        case clientCommands.RequestLicense.name:
          this.closeProcessAction()
          this.processRequestLicenseFile()
          break;
        case clientCommands.GetPenDriveList.name:
          this.closeProcessAction()
          if (this.isCmdBackup) {
            this.processBackup()
          } else {
            this.processLicense(true, false, true)
          }
          break;
        case clientCommands.ServerLogs.name:
          this.processShowCopyLogs()
          break;
        case clientCommands.ServerReports.name:
          this.processShowCopyReports()
          break;
        case clientCommands.CertificateReport.name:
          this.processShowCopyCert()
          break;
        case clientCommands.CancelLicenseReq.name:
          this.processCancelLicenseReq()
          break;
      }
    }
  },
  beforeDestroy() {
    console.log("unregistering enter event");
    this.$mousetrap.unbind("enter");
    console.log("/closed getConfiguration()");
    clearInterval(this.intervalGetDatas);
    console.log("/closed intervalReload");
    clearInterval(this.intervalReload);
    var r = document.querySelector(':root');
    r.style.setProperty('--cardBodyHeight', "105vh - 150px");
    if (this.IsFromHeader) {
      this.$store.commit("setSelectedTab", this.oldTab);
    }
  },
  watch: {
    file: function () {
      this.sendFile();
    },

    selectedOptionReset(value) {
      if (value != "") {
        this.actionAfterResetSettings(value);
        this.selectedOptionReset = "";
      }
    },

    activeStep(val) {
      this.$mousetrap.unbind("enter");
      if (val == 0) {
        this.$mousetrap.bind("enter", this.nextStep);
      } else if (val == 1) {
        this.$mousetrap.bind("enter", this.checkCredentials);
      } else if (val == 2) {
        this.$mousetrap.bind("enter", this.saveConfig);
      }
    },


  },
  mounted() {
    this.$mousetrap.bind("enter", this.nextStep);
    if (this.idEmailSender > 0) {
      this.checkHasEmailSenderConfig()
    }
  },
  created() {

    if (this.IsFromHeader) {
      this.oldTab = this.currentTab
      this.$store.commit("setSelectedTab", "Configuration");
    }

    var r = document.querySelector(':root');

    if (this.IsFromHeader)
      r.style.setProperty('--cardBodyHeight', "100vh - 150px");
    this.alreadyHaveDatabase = this.hasDatabase;
    if (this.errorS2S) {
      this.openModalIni = "";
      this.showIniFile = true;
    }
    if (this.errorGlobalClient) {
      this.showModalGlobalSettings = true;
    }
    this.intervalGetDatas = setInterval(() => {
      try {
        const screenSize = getSize();
        if (screenSize.width <= 1350) {
          this.columnSize = "col-lg-12";
          this.displayDiv = "display:Block;";
        } else {
          this.columnSize = "col-lg-6";
          this.displayDiv = "";
        }

        if (this.currentTab != "Configuration") {
          console.log("/closed getConfiguration()");
          clearInterval(this.intervalGetDatas);
        }
      } catch (e) {
        console.log("Error in getConfiguration() " + e);
      }
    }, 300);
  },
};
</script>

<style>
.textHeader {
  font-family: Arial;
  text-transform: uppercase;
  color: #888;
  animation: opaa 10s infinite;
  display: block;
  position: absolute;
  top: 62%;
}

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}


.col-lg-6 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
