<template>
    <div class="content">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'Content'
}
</script>

<style>
    .content {
        height: 100%;
        overflow: hidden;
        background-color: var(--background)
    }
</style>
