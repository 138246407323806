<template>
  <form action="javascript:void(0);" style="overflow-y: hidden">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ translate("logs_Title") }}
        </p>
        <button type="button" class="delete" @click.prevent="cancelModal()" />
      </header>
      <section class="modal-card-body">
        <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
          :can-cancel="false">
          <template #default="props">
            <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
              @ActionAfterShowLogsLog="actionAfterShowLogs" :route="route" :JsonObject="obj">
            </LoadingCMD>
          </template>
        </b-modal>
        <div v-if="showModalMessage">
          <Message :title="translate('lblError')" type="is-danger" :message="errorMessage" :isList="false" />
        </div>

        <div class="row">
          <!-- Coluna 1 -->
          <div id="columns1" class="col-lg-12">
            <div id="columnInside">
              <b-form-group>
                <template #label>
                  <b>{{ translate("clientLog_Title") }}</b>
                </template>
                <b-form-checkbox v-if="false" v-model="logs.Client.ScreenLog" @change="changeDataLogClient()">
                  {{ translate("screenLog_Option") }}
                </b-form-checkbox>
                <b-form-checkbox v-model="logs.Client.FileLog" @change="changeDataLogClient()">
                  {{ translate("file_Option") }}
                </b-form-checkbox>
                <b-form-checkbox v-model="logs.Client.DataLog" :disabled="disableClientDataLog">
                  {{ translate("data_Option") }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
        </div>

        <!-- Fim Coluna 1 -->

        <br />
        <div class="row">
          <b-form-checkbox v-model="logs.SavePrefs" style="margin-right: 20px">
            <b>{{ translate("savePrefs_Option") }}</b>
          </b-form-checkbox>
          <b-form-checkbox v-model="logs.Debug">
            <b>{{ translate("debug_Title") }}</b>
          </b-form-checkbox>
        </div>
        <br />
        <b-field :label="translate('lbl_DaysDeleteOldLogFiles')">
          <b-input :value="logs.DaysDeleteOldLogs" v-model="logs.DaysDeleteOldLogs" tabindex="200" expanded id="lblDaysDelete"
            type="number" max="999999" min="0" maxlength="7"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button :label="translate('btnClose')" icon-left=".icons-white icons-times" icon-pack="icons"
          @click.prevent="cancelModal()">
        </b-button>
        <b-button @click.prevent="sendLogs" type="is-primary" icon-left=".icons-white icons-check" icon-pack="icons">
          <span>{{ translate("btnSave") }}</span>
        </b-button>
      </footer>
    </div>
  </form>
</template>
<script>
import { clientCommands } from "@/js/ClientCommands.js";
import { mapState } from "vuex";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import LoadingCMD from "@/components/processCMD/LoadingCMD.vue";
import Message from "@/components/message/Message.vue";
export default {
  components: { LoadingCMD, Message },
  mixins: [en, es, nor, swe],
  props: {
    logs: Object,
  },
  computed: mapState(["dataModule", "lang"]),
  data() {
    return {
      selected: [],
      isLoading: false,
      sendingCMDMessage: "",
      countDown: 10,
      stopCountdown: false,
      reportBackup: {},
      cmd: "",
      showLoadingCMD: false,
      obj: {},
      oneSelected: false,
      messageError: "",
      showModalMessage: false,
      disableMachineDataLog: false,
      disablePanelDataLog: false,
      disableGlobalDataLog: false,
      disableClientDataLog: false,
      /*logs: {
        Machine: {
          ScreenLog: false,
          FileLog: false,
          DataLog: false,
        },
        Panel: {
          ScreenLog: false,
          FileLog: false,
          DataLog: false,
        },
        Global: {
          ScreenLog: false,
          FileLog: false,
          DataLog: false,
        },
        Client: {
          ScreenLog: false,
          FileLog: false,
          DataLog: false,
        },
        SavePrefs: false,
        Debug: false,
      },*/
    };
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    cancelModal() {
      this.$emit("close");
    },
    sendLogs() {
      this.sendingCMDMessage = this.translate("messageShowLogsEdt");
      this.cmd = clientCommands.ShowLogs.name;
      this.countDown = clientCommands.ShowLogs.time;
      this.obj = this.logs;
      this.showLoadingCMD = true;
    },
    changeDataLogMachine() {
      this.disableMachineDataLog = false;
      if (!this.logs.Machine.ScreenLog && !this.logs.Machine.FileLog) {
        this.logs.Machine.DataLog = false;
        this.disableMachineDataLog = true;
      }
    },
    changeDataLogPanel() {
      this.disablePanelDataLog = false;
      if (!this.logs.Panel.ScreenLog && !this.logs.Panel.FileLog) {
        this.logs.Panel.DataLog = false;
        this.disablePanelDataLog = true;
      }
    },
    changeDataLogGlobal() {
      this.disableGlobalDataLog = false;
      if (!this.logs.Global.ScreenLog && !this.logs.Global.FileLog) {
        this.logs.Global.DataLog = false;
        this.disableGlobalDataLog = true;
      }
    },
    changeDataLogClient() {
      this.disableClientDataLog = false;
      if (!this.logs.Client.ScreenLog && !this.logs.Client.FileLog) {
        this.logs.Client.DataLog = false;
        this.disableClientDataLog = true;
      }
    },
    actionAfterShowLogs() {
      this.cancelModal();
    },
  },
  mounted() {
    //
    this.changeDataLogMachine();
    this.changeDataLogPanel();
    this.changeDataLogGlobal();
    this.changeDataLogClient();
  },
  created() {
    //
  },
  watch: {
    //
  },
};
</script>

<style >
#columns1 {
  border-width: 0.5px;
  border-radius: 10px;
  border-style: solid;
  border-color: #dad8d8;
  margin-right: 5px;
}

#columnInside {
  padding-top: 10px;
  padding-left: 5px;
}

.row {
  flex-wrap: inherit !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
</style>