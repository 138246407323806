<template>
  <div class="globalSettings">
    <form action="javascript:void(0);">
      <b-modal
        v-model="showLoadingCMD"
        width="auto"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="false"
      >
        <template #default="props">
          <LoadingCMD
            @close="props.close"
            :CMD="cmd"
            :sendingCMDMessage="sendingCMDMessage"
            :countDown="countDown"
            @ActionAfterCMDGlobalSettings="actionAfterCMDGlobalSettings"
            :JsonObject="obj"
          >
          </LoadingCMD>
        </template>
      </b-modal>
      <b-modal
        v-model="showModalReturnApp"
        width="915px"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :can-cancel="false"
      >
        <template #default="props">
          <ReturnApp :automatic="true" @close="props.close" :obj="data" />
        </template>
      </b-modal>
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ this.translate("global_Title") }}
          </p>
        </header>
        <section class="modal-card-body">
          <div class="form-row">
            <label for="staticEmail" class="col-sm-10 col-form-label">{{
              this.translate("globalProgressiveMode")
            }}</label>
            <div class="col-sm-10 mb-2">
              <select
                id="inputState"
                class="form-control"
                v-model="globalType"
                disabled
              >
                <option value="0">
                  {{ this.translate("clientOnly_Option") }}
                </option>
                <option value="1">
                  {{ this.translate("serverOnly_Option") }}
                </option>
                <option value="2">
                  {{ this.translate("clientAndServer_Option") }}
                </option>
              </select>
            </div>
            <div class="col-sm-2 mb-2">
              <b-button
                type="is-info"
                @click="alertGlobal"
                icon-right=".icons-white icons-info-circle"
                icon-pack="icons"
              />
            </div>
            <label
              for="staticEmail"
              class="col-sm-10 col-form-label"
              v-if="globalType == 0"
              >{{ this.translate("lblGlobalProgressiveServerIP") }}</label
            >
            <div class="col-sm-10 mb-2">
              <b-form-input
                list="globalConnectionIPs"
                name="globalConnectionIP"
                id="globalConnectionIP"
                class="form-control"
                v-model="global.ConnectionIP"
                v-if="globalType == 0"
                :state="errorGlobalConnectionIP ? false : null"
              />
              <datalist id="globalConnectionIPs">
                <option v-for="ip in listIps" v-bind:value="ip" :key="ip">
                  {{ ip }}
                </option>
              </datalist>

              <div
                v-if="errorGlobalConnectionIP"
                style="
                  width: 100%;
                  margin-top: 0.25rem;
                  font-size: 80%;
                  color: #f86c6b;
                "
              >
                {{ this.translate("messageErrorIP") }}
              </div>
            </div>
            <div class="col-sm-2 mb-2" v-if="globalType == 0">
              <button
                class="btn btn-outline-secondary"
                style="height: 40px"
                type="button"
                @click="showKeyboardGlobalProg = !showKeyboardGlobalProg"
              >
                <i class="icons-lg0 icons-keyboard" />
              </button>
            </div>
            <div
              class="mb-2"
              v-if="showKeyboardGlobalProg"
              style="width: 100%; margin-top: 0.25rem"
            >
              <SimpleKeyboard
                @onChange="onChange"
                @onKeyPress="onKeyPress"
                :input="global.ConnectionIP"
                :isNumeric="true"
                :hasDot="true"
              />
            </div>
            <div class="form-group col-md-10 mb-2">
              <b-checkbox v-model="isGlobalIP">
                {{
                  isGlobalIP
                    ? this.translate("globalPreferencialIP_Option")
                    : this.translate("anotherLocalIP_Option")
                }}
              </b-checkbox>
            </div>
            <div class="col-sm-10 mb-2">
              <b-form-input
                list="globalGlobalConnectionIPs"
                name="globalGlobalConnectionIP"
                id="globalGlobalConnectionIP"
                class="form-control"
                v-model="global.GlobalConnectionIP"
                @focus="
                  (showKeyboardGlobalPref = false),
                    (showKeyboardGlobalProg = false)
                "
                v-if="isGlobalIP"
                :state="errorGlobalGlobalConnectionIP ? false : null"
              />
              <datalist id="globalGlobalConnectionIPs">
                <option v-for="ip in listIps" v-bind:value="ip" :key="ip">
                  {{ ip }}
                </option>
              </datalist>

              <div
                v-if="errorGlobalGlobalConnectionIP"
                style="
                  width: 100%;
                  margin-top: 0.25rem;
                  font-size: 80%;
                  color: #f86c6b;
                "
              >
                {{ this.translate("messageErrorIP") }}
              </div>
            </div>
            <div class="col-sm-2 mb-2" v-if="isGlobalIP">
              <button
                class="btn btn-outline-secondary"
                style="height: 40px"
                type="button"
                @click="showKeyboardGlobalPref = !showKeyboardGlobalPref"
              >
                <i class="icons-lg0 icons-keyboard" />
              </button>
            </div>
            <div
              class="mb-2"
              v-if="showKeyboardGlobalPref"
              style="width: 100%; margin-top: 0.25rem"
            >
              <SimpleKeyboard
                @onChange="onChange"
                @onKeyPress="onKeyPress"
                :input="global.GlobalConnectionIP"
                :isNumeric="true"
                :hasDot="true"
              />
            </div>
          </div>
          <!-- -->
        </section>
        <footer class="modal-card-foot">
          <b-button
            :label="translate('btnClose')"
            icon-left=".icons-white icons-ban"
            icon-pack="icons"
            @click.prevent="closeModalGlobal"
          >
            {{ translate("btnCancel") }}
          </b-button>
          <b-button
            :label="translate('btnSave') "
            type="is-primary"
            icon-left=".icons-white icons-check"
            icon-pack="icons"
            @click.prevent="saveGlobal"
          >
            {{ translate("btnSave") }}
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { clientCommands } from "@/js/ClientCommands.js";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import SimpleKeyboard from "@/components/keyboard/SimpleKeyboard";
import LoadingCMD from "../processCMD/LoadingCMD";
import ReturnApp from "@/components/reset/ReturnApp.vue";
import validateField from "@/js/validateField";
export default {
  components: { SimpleKeyboard, LoadingCMD, ReturnApp },
  computed: mapState(["lang", "globalType", "listIps"]),
  name: "GlobalSettings",
  mixins: [en, es, nor,swe],
  data() {
    return {
      global: {
        ConnectionIP: "",
        GlobalConnectionIP: "",
        isGlobalIP: false,
      },
      isGlobalIP: false,
      errorGlobalConnectionIP: false,
      errorGlobalGlobalConnectionIP: false,
      showKeyboardGlobalProg: false,
      showKeyboardGlobalPref: false,
      showModalReturnApp: false,
      cmd: "",
      showLoadingCMD: false,
      obj: {
        Value: "",
      },
      sendingCMDMessage: "",
      countDown: 15,
      data: {
        Ok: false,
        Message: [],
        Object: {},
        CMD: "",
        TimeToRestart: 0,
        RestartRequired: false,
        MessageType: 0,
      },
    };
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    closeModalGlobal() {
      this.$emit("close");
    },
    saveGlobal() {
      //
      
      let hasError = false;      
      if (validateField.regexIP(this.global.ConnectionIP) && this.globalType == 0) {
        hasError = true;
        this.errorGlobalConnectionIP = true;
      }
      if (this.isGlobalIP) {
        if (validateField.regexIP(this.global.GlobalConnectionIP)) {
          hasError = true;
          this.errorGlobalGlobalConnectionIP = true;
        }
      }

      if (hasError) {
        return;
      }

      this.cmd = clientCommands.GlobalSettings.name;      
      this.sendingCMDMessage =
        this.translate("waiting") + " " +
        this.translate("to") + " " +
        this.translate("isGlobal_Title");
      this.obj = this.global;
      this.countDown = clientCommands.GlobalSettings.time;
      this.showLoadingCMD = true;
    },
    alertGlobal() {
      this.$buefy.dialog.alert({
        title: "Global Progressive Modes",
        message:
          "<b>- Client Only</b><br/> The Server works normaly and wait for an external global progressive server Connection <br/> " +
          "<b>- Server Only</b><br/> The system will act as global progressive server only, without any other features <br/> " +
          "<b>- Client and Server</b><br/> The Server works with all features with global progressive server embedded",
        type: "is-info",
        hasIcon: true,

        icon: "info",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },

    actionAfterCMDGlobalSettings(data) {
      //
      if (data.ok) {
        if (data.restartRequired) {
          this.showModalReturnApp = true;
          this.data = {
            Ok: data.ok,
            Message: data.message,
            Object: data.object,
            CMD: data.cmd,
            TimeToRestart: data.timeToRestart,
            RestartRequired: data.restartRequired,
            MessageType: data.messageType,
          };
        } else {
          this.$emit("close");
        }
      }
    },

    onChange(input) {
      if (this.showKeyboardGlobalProg) {
        this.global.ConnectionIP = input;
      } else if (this.showKeyboardGlobalPref) {
        this.global.GlobalConnectionIP = input;
      }
    },
    onKeyPress(button) {
      console.log("button", button);
      if (button === "{clear}") {
        if (this.showKeyboardGlobalProg) {
          this.global.ConnectionIP = "";
        } else if (this.showKeyboardGlobalPref) {
          this.global.GlobalConnectionIP = "";
        }
      }
    },
    onInputChange(input) {
      if (this.showKeyboardGlobalProg) {
        this.global.ConnectionIP = input;
      } else if (this.showKeyboardGlobalPref) {
        this.global.GlobalConnectionIP = input;
      }
    },
  },
  created(){
    this.global.ConnectionIP = this.IP
  },
  watch: {
    isGlobalIP(val) {
      if (!val) {
        //
      }
    },
  },
};
</script>
<style scoped src="@/css/styleModules.css">
</style>
