<template>
  <div style="overflow-y: none; background-color: #000000">
    <div
      style="background-color: #feecf0; border-radius: 5px"
      v-if="showMessage"
    >
      <input
        tabindex="-1"
        v-model="hideShortcut"
        style="
          height: 0px;
          width: 0px;
          margin-left: 0px;
          margin-top: 0px;
          position: absolute;
        "
        id="lblHideShortcutLoading"
        name="lblHideShortcutLoading"
      />

      <Message
        :title="translate('lblError')"
        type="is-danger"
        :message="translate('messageNoConnectionFront')"
        :isList="false"
      />

      <div class="row">
        <div class="col-lg-5"></div>
        <div class="col-lg-2">
          <i
            class="icons-lg5 icons-spinner icons-spin"
            style="color: var(--iconColor);"
          ></i>
        </div>
      </div>

      <div style="text-align: center"></div>

      <br />
      <br />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { passwordLoading } from "@/global.js";
import { deleteFile } from "@/js/osType.js";
import { returnApp } from "@/js/resetApp.js";
import notify from "@/config/notify.js";
import Message from "@/components/message/Message.vue";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
export default {
  name: "noConnection",
  computed: mapState(["lang", "showModalNoConnection"]),
  mixins: [en, es, nor, swe],
  components: { Message },
  data: function () {
    return {
      showMessage: false,
      hideShortcut: "",
    };
  },
  props: {},
  methods: {
    close() {
      this.$emit("close");
    },
    translate(prop) {
      return this[this.lang][prop];
    },
    showProcessDeleteIni() {
      this.$buefy.dialog.confirm({
        title: this.translate("dialog_Title"),
        message: this.translate("messageResetClient"),
        confirmText: "Confirm",
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => this.resetClientFile(),
        onCancel: () => this.processBlurLbl(),
      });
    },
    processShowLbl() {
      this.hideShortcut = "";
      document.getElementById("lblHideShortcutLoading").focus();
    },
    processBlurLbl() {
      this.hideShortcut = "";
      document.getElementById("lblHideShortcutLoading").blur();
    },
    resetClientFile() {
      try {
        if (!deleteFile()) {
          notify.message(
            "foo",
            this.translate("lblError"),
            this.translate("lblErrorDeleteFile"),
            "error",
            3000
          );
          return;
        } else {
          this.$store.commit("changeStopRequest", true);
          console.log("Restarting system after config install");
          this.$store.commit("changeCompleteLogin", false);
          this.$store.commit("changeValidatingLogin", false);
          returnApp(this);
        }
      } catch (e) {
        console.log("Error in resetClientFile()" + e);
      }
    },
    setHideShortcut() {
      console.log("You pressed ctrl & 6");
      this.processShowLbl();
      setTimeout(function () {
        document.getElementById("lblHideShortcutLoading").blur();
        console.log("lblHideShortcutLoading Lost Focus");
      }, 10000);
    },
  },
  created() {
    setTimeout(() => (this.showMessage = true), 3000);
  },
  mounted() {
    this.$mousetrap.bind("ctrl+6", this.setHideShortcut);
  },
  beforeDestroy() {
    console.log("unregistering enter event");
    this.$mousetrap.unbind("ctrl+6");
  },
  watch: {
    hideShortcut(value) {
      console.log(value);
      if (passwordLoading.length == 4 && value == passwordLoading) {
        this.showProcessDeleteIni();
        this.processBlurLbl();
      } else if (value.length > 4) {
        this.processBlurLbl();
      }
    },
  },
};
</script>


<style scoped src="@/css/styleModules.css">
</style>
