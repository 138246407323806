
export const os = require('os');

export const pathIniWin = 'C:/FBM/iniFile.json';
export const pathIniLinux = '/opt/FBM/iniFile.json';

export function returnPath() {
    if (os.platform() == "win32") {
        return pathIniWin
    } else {
        return pathIniLinux
    }
}

export function getFile() {
    try {
        const fs = require('fs');
        return JSON.parse(fs.readFileSync(returnPath()));
    } catch (e) {
        console.log("Error in getFileIniFile" + e)
        return null
    }
}

export function deleteFile() {
    try {
        const fs = require('fs');
        fs.unlinkSync(returnPath());
        //File Removed
        return true;
    } catch (e) {
        console.log("Error in deleteFile" + e)
        return false
    }
}



export default { returnPath, os, pathIniWin, pathIniLinux, getFile, deleteFile }