<template>
  <div class="user">
    <div class="container-fluid">
      <div class="animated fadeIn">
        <div class="card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ translate("settings") }}</p>

            <button tabindex="-1" type="button" class="delete" @click.prevent="close()" v-if="!user.ChangePassword" />
          </header>
          <div class="card-body">
            <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog"
              aria-modal :can-cancel="false">
              <template #default="props">
                <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
                  @ActionAfterPageUser="actionAfterPageUser" :route="route" :JsonObject="obj">
                </LoadingCMD>
              </template>
            </b-modal>


            <div class="topnav col mb-2" v-if="userLogged.Root">
              <a href @click.prevent="
                (showModal = true),
                (mode = 'add'),
                ((user = resetUser()), (filterUser = ''))
                " :title="translate('btnAddUser')" id="btnAddUser">
                <i class="icons-1x icons-user-plus" id="iconMdi" aria-hidden="true"></i>
              </a>

            </div>
            <br v-if="!userLogged.Root" />
            <b-field grouped>
              <div class="control">
                <b-button class="button" icon-left="icons-1x icons-sync" icon-pack="icons" v-if="false"
                  @click="(filterUser = ''), filterTableUser()">
                  <span>{{ translate("btnRefresh") }}</span>
                </b-button>
              </div>
              <b-input :placeholder="translate('lblSearchByLogin')" expanded id="lblSearchByLogin" type="search"
                icon="magnify" @icon-right-click="filterUser = ''" icon-right="close-circle" icon-right-clickable
                v-model="filterUser" />
            </b-field>
            <div class="data" name="divTable" id="divTable" style="
                    height: calc(77vh - 150px);
                    max-height: calc(77vh - 150px);
                    overflow-y: auto;
                    width: 100%;
                  ">
              <b-table :data="users" ref="table" :paginated="false" :per-page="itensPerPage" pagination-position="top"
                :pagination-simple="true" :pagination-rounded="true" aria-next-label="Next page"
                aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                :striped="true" custom-row-key="id" default-sort="login">
                <template #empty>
                  <div class="has-text-centered">
                    <h1>
                      <span class="tag is-danger">{{
                        translate("lblNoDataFound")
                      }}</span>
                    </h1>
                  </div>
                </template>

                <b-table-column field="login" centered :label="translate('btnLogin')" v-slot="props"
                  header-class="is-sticky-column-one" width="200">
                  {{ props.row.Login }}
                </b-table-column>

                <b-table-column field="date" :label="translate('columnLastUpdated')" centered v-slot="props"
                  header-class="is-sticky-column-one">
                  <span class="tag is-success">
                    {{
                      new Date(props.row.CreateDate).toLocaleDateString()
                    }}
                  </span>
                </b-table-column>

                <b-table-column field="function" :label="translate('columnFunction')" centered
                  header-class="is-sticky-column-one" v-slot="props">
                  {{ returnFunction(props.row) }}
                </b-table-column>

                <b-table-column field="status" :label="translate('columnStatus')" centered v-slot="props"
                  header-class="is-sticky-column-one">
                  {{ returnStatus(props.row) }}
                </b-table-column>

                <b-table-column field="actions" :label="translate('columnActions')" width="300" centered v-slot="props"
                  header-class="is-sticky-column-one">
                  <b-button type="is-warning" class="mr-2" :title="translate('btnEdit')" id="btnEdit" :disabled="props.row.Status == loggedStatus.LOGGED ||
                    (!userLogged.Root && !userLogged.Technician)
                    " @click="
    (showModal = true),
    (mode = 'edit'),
    (selected = props.row)
    ">
                    <i class="icons-black icons-lg1 icons-pencil"></i>
                  </b-button>
                  <b-button type="is-danger" class="mr-2" :title="translate('btnDelete')" id="btnDelete" :disabled="props.row.Status == loggedStatus.LOGGED ||
                    props.row.Root
                    || props.row.Id == 1
                    " @click="
    confirmDeleteUser(props.row), (selected = props.row)
    ">
                    <i class="icons-white icons-lg1 icons-trash"></i>
                  </b-button>

                  <b-button type="is-info is-light" :title="translate('btnView')" id="btnView" @click="
                    (showModal = true),
                    (mode = 'view'),
                    (selected = props.row)
                    ">
                    <i class="icons-blue icons-lg1 icons-eye"></i>
                  </b-button>
                </b-table-column>
              </b-table>
            </div>

          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showModal" has-modal-card width="900px" trap-focus auto-focus :destroy-on-hide="true"
      aria-role="dialog" :aria-modal="true" :can-cancel="false">
      <template #default="props">
        <UserSettings @close="props.close" :user="mode != 'add' ? selected : user" :mode="mode" :users="users">
        </UserSettings>
      </template>
    </b-modal>
    <b-modal v-model="showModalReport" width="900px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
      :can-cancel="false">
      <template #default="props">
        <ReportAccessConfiguration @close="props.close" :report="mode != 'add' ? selected : report"
          :reportBackup="mode != 'add' ? selected : report" :mode="mode" :reports="reports"></ReportAccessConfiguration>
      </template>
    </b-modal>
  </div>
</template>
<script>
import "@/css/icons.css";
import { mapState } from "vuex";
import { clientCommands } from "@/js/ClientCommands.js";
import { loggedStatus } from "@/mixins/enums.js";
import UserSettings from "../Modules/UserPages/UserSettings";
import ReportAccessConfiguration from "../Modules/UserPages/ReportAccessConfiguration";
import notify from "@/config/notify.js";
import SocketDriver from "@/config/webSocketDriver.js";
import LoadingCMD from "@/components/processCMD/LoadingCMD.vue";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
export default {
  name: "User",
  mixins: [en, es, nor, swe],
  components: { UserSettings, ReportAccessConfiguration, LoadingCMD },
  computed: mapState([
    "session",
    "communication",
    "dataModule",
    "userLogged",
    "lang",
    "currentTab",
    "isFBM"
  ]),
  data: function () {
    return {
      showModal: false,
      showModalReport: false,
      data: [{}],
      columns: [{}],
      object: {},
      stickyHeaders: true,
      selected: {},
      mode: "",
      users: [],
      reports: [],
      sendingCMDMessage: "",
      countDown: 10,
      showLoadingCMD: false,
      cmd: "",
      obj: {},
      stopCountdown: false,
      isLoading: false,
      user: {},
      report: {},
      filterUser: "",
      filterReport: "",
      itensPerPage: 15,
      intervalGetDatas: null,
      loggedStatus: loggedStatus,
    };
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    resetUser() {
      this.user = {
        Login: "",
        Password: "",
        PrePassword: "",
        Cashier: false,
        Technician: false,
        Manager: false,
        ReportView: false,
        Root: false,
        Level: "",
        CreateDate: null,
        Operators: [],
        Function: "",
        Status: loggedStatus.NOT_LOGGED,
        Deleted: false,
        FromFilter: false,
      };

      return this.user;
    },
    resetReport() {
      this.report = this.dataModule.reportDefault;
      this.report.Name = this.getNameLevel();
      return this.report;
    },

    getNameLevel() {
      let number = 0;
      if (this.reports != []) {
        this.reports.forEach((reportList) => {
          const split = reportList.Name.split(" ");
          const newNumber = parseInt(split[1]);

          if (newNumber > number) number = newNumber;
        });
      }
      number = number + 1;
      return this.translate("columnLevel") + " " + number;
    },

    confirmDeleteUser(user) {
      let userSelected = null;

      if (user.Login === this.userLogged.Login) {
        userSelected = user;
      }

      if (userSelected != null) {
        notify.message(
          "foo",
          "Error",
          "user [" +
          userSelected.Login +
          "] cannot be deleted, user [" +
          userSelected.Login +
          "] is logged in",
          "error",
          2000
        );
      } else {
        this.$buefy.dialog.confirm({
          title: this.translate("processDeleteUserTitle"),
          message:
            this.translate("processDeleteUserPart1") +
            user.Login +
            this.translate("processDeleteUserPart2"),
          confirmText: this.translate("btnOk"),
          cancelText: this.translate("btnCancel"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.deleteUser(),
        });
      }
    },
    deleteUser() {
      this.sendingCMDMessage =
        this.translate("messageDeleteUser") + " [" + this.selected.Login + "]";

      this.cmd = clientCommands.DeleteUser.name;
      this.countDown = clientCommands.DeleteUser.time;
      this.obj = JSON.parse(JSON.stringify(this.selected));
      this.obj.Deleted = true;
      this.showLoadingCMD = true;
    },

    confirmDeleteReport(report) {
      const usersName = [];
      this.dataModule.users.forEach((userList) => {
        if (userList.Level === report.name) {
          usersName.push(userList.Login);
        }
      });
      if (usersName.length > 0) {
        notify.message(
          "foo",
          "Error",
          "Report Level [" +
          report.name +
          "] cannot be deleted, Report is being used by user's [" +
          usersName +
          "]",
          "error",
          2000
        );
      } else {
        this.$buefy.dialog.confirm({
          title: this.translate("processDeleteReportTitle"),
          message:
            this.translate("processDeleteReportPart1") +
            report.Name +
            this.translate("processDeleteReportPart2"),
          confirmText: this.translate("btnOk"),
          cancelText: this.translate("btnCancel"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.deleteReport(),
        });
      }
    },
    deleteReport() {
      this.sendingCMDMessage =
        this.translate("messageDeleteReportLevel") +
        " [" +
        this.selected.Name +
        "]";

      this.cmd = clientCommands.DeleteReport.name;
      this.countDown = clientCommands.DeleteReport.time;
      this.obj = this.selected;
      this.showLoadingCMD = true;
    },

    filterTableUser() {
      this.users = [];
      this.fromFilter = true;
      this.dataModule.users.forEach((user) => {
        if (
          user.Login.toLowerCase().includes(this.filterUser.toLowerCase()) ||
          this.filterUser == ""
        ) {
          this.users.push(user);
        }
      });
    },

    filterTableReport() {
      this.reports = [];
      this.fromFilter = true;
      this.dataModule.reportLevels.forEach((report) => {
        if (
          report.Name.toLowerCase().includes(this.filterReport.toLowerCase()) ||
          this.filterReport == ""
        ) {
          this.reports.push(report);
        }
      });
    },

    returnLevelName(levelName) {
      let aux = levelName.split(" ");
      return this.translate("columnLevel") + " " + aux[1];
    },

    returnStatus(user) {
      let status = this.translate("columnNotLogged");
      if (user.Status == loggedStatus.LOGGED) {
        status = this.translate("columnLogged");
      }
      return status;
    },

    returnFunction(user) {
      let name = "";
      try {
        if (user.Root) {
          if (name == "") {
            name = this.translate("root_Option");
          } else {
            name = this.translate("lblMultipleFunctions");
          }
        }
        if (user.Technician) {
          if (name == "") {
            name = this.translate("technician_Option");
          } else {
            name = this.translate("lblMultipleFunctions");
          }
        }
        if (user.Manager) {
          if (name == "") {
            name = this.translate("lblSessionMangerFunction");
          } else {
            name = this.translate("lblMultipleFunctions");
          }
        }
        if (user.Cashier) {
          if (name == "") {
            name = this.translate("cashier_Option");
          } else {
            name = this.translate("lblMultipleFunctions");
          }
        }
        if (user.ReportView) {
          if (name == "") {
            name = this.translate("reportManager_Title");
          } else {
            name = this.translate("lblMultipleFunctions");
          }
        }
      } catch (e) {
        console.log("Error in setFunction() " + e);
      }

      return name;
    },

    refreshTable() {
      //Atualizar a Tabela
    },
    finishedDeleteUserCMD() {
      this.stopCountdown = true;
    },
    actionAfterPageUser(data) {
      const newList = [];
      if (data.cmd === clientCommands.DeleteReport.name) {
        this.dataModule.reportLevels.forEach((reportList) => {
          if (reportList.Name !== this.selected.Name) {
            newList.push(reportList);
          }
        });

        this.$store.commit("changeReports", newList);
      } else {
        this.dataModule.usersAll.forEach((userList) => {
          if (
            userList.Login !== this.selected.Login &&
            userList.Deleted == false
          ) {
            newList.push(userList);
          } else {
            userList.Deleted = true;
          }
        });
        this.$store.commit("changeUsers", newList);
        this.$store.commit("changeUsersAll", this.dataModule.usersAll);
      }
    },
    filterTable() {
      this.dataModule.users.forEach((user) => {
        if (!user.Deleted) {
          this.users.push(user);
        }
      });
    },
    close() {
      this.$emit('close')
    }
  },
  created() {
    var r = document.querySelector(':root');
    r.style.setProperty('--cardBodyHeight', "100vh - 150px");
    SocketDriver.$on("finishedDeleteUserCMD", this.finishedDeleteUserCMD);
    SocketDriver.$on("finishedCMDDeleteReport", this.finishedDeleteUserCMD);
    this.users = this.dataModule.users;
    //this.reports = this.dataModule.reportLevels;    
    this.intervalGetDatas = setInterval(() => {
      try {
        if (this.currentTab != "User") {
          console.log("/closed getUser()");
          clearInterval(this.intervalGetDatas);
          return;
        }
        this.users = this.dataModule.users;
        //this.reports = this.dataModule.reportLevels;
        this.filterTableUser()
        //this.filterTableReport()
        const height = document.getElementById("divTable").offsetHeight;
        if (height <= 500) {
          this.itensPerPage = 5;
        } else {
          this.itensPerPage = 15;
        }
      } catch (e) {
        console.log("Error in getUser() " + e);
        if (this.currentTab != "User") {
          console.log("/closed getUser()");
          clearInterval(this.intervalGetDatas);
        }
      }
    }, 300);
  },
  mounted() {
    //

    if (this.userLogged.ChangePassword) {
      this.showModal = true;
      this.mode = "edit";
      this.selected = this.userLogged;
    }
  },
  beforeDestroy() {
    console.log("/closed getUser()");
    clearInterval(this.intervalGetDatas);
    var r = document.querySelector(':root');
    r.style.setProperty('--cardBodyHeight', "105vh - 150px");
  },
  updated() {
    if (!this.fromFilter) {
      this.users = this.dataModule.users;
      this.reports = this.dataModule.reportLevels;
    }
    this.fromFilter = false;
  },
  watch: {
    filterUser() {
      this.filterTableUser();
    },
    filterReport() {
      this.filterTableReport();
    },
  },
};
</script>

<style scoped src="@/css/styleModules.css">
table tbody {
  height: 300px;
  overflow-y: scroll;
  display: block;
}

table thead {
  display: block;
}
</style>
