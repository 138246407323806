<template>
  <div class="message">
    <article v-if="type == 'is-warning'" class="is-warning">      
      <header class="message-header" v-if="title != undefined">
        <p>{{ title }}</p>
        <!---->
        <button
          v-if="canClose != undefined || canClose"
          type="button"
          aria-label="Close message"
          class="delete"
          @click.prevent="close()"
        ></button>
      </header>
      <section class="message-body">
        <div class="media">
          <div class="media-left">
            <i class="icons icons-exclamation-triangle" style="font-size: 45px" />
          </div>
          <div class="media-content" v-if="!isList">
            {{ message }}
          </div>
          <div class="media-content" v-else>
            <span v-for="mes in message" :key="mes">
              {{ mes }}
              <br />
            </span>
          </div>
        </div>
      </section>
    </article>

    <article v-if="type == 'is-danger'" class="is-danger">
      <header class="message-header" v-if="title != undefined">
        <p>{{ title }}</p>
        <!---->        
        <button
          v-if="canClose != undefined && canClose "
          type="button"
          aria-label="Close message"
          class="delete"
          @click.prevent="close()"
        ></button>
      </header>
      <section class="message-body">
        <div class="media">
          <div class="media-left">
            <i class="icons icons-error-hexagon" style="font-size: 45px" />
          </div>
          <div class="media-content" v-if="!isList">
            {{ message }}
          </div>
          <div class="media-content" v-else>
            <span v-for="mes in message" :key="mes">
              {{ mes }}
              <br />
            </span>
          </div>
        </div>
      </section>
    </article>

    <article v-if="type == 'is-license'" class="is-warning">
      <header class="message-header" v-if="title != undefined">
        <p>{{ title }}</p>
        <!---->        
        <button
          v-if="canClose != undefined && canClose "
          type="button"
          aria-label="Close message"
          class="delete"
          @click.prevent="close()"
        ></button>
      </header>
      <section class="message-body">
        <div class="media">
          <div class="media-left">
            <i class="icons icons-key" style="font-size: 45px" />
          </div>
          <div class="media-content" v-if="!isList">
            {{ message }}
          </div>
          <div class="media-content" v-else>
            <span v-for="mes in message" :key="mes">
              {{ mes }}
              <br />
            </span>
          </div>
        </div>
      </section>
    </article>

    <article v-if="type == 'is-info'" class="is-info">
      <header class="message-header" v-if="title != undefined">
        <p>{{ title }}</p>
        <!---->
        <button
          v-if="canClose != undefined || canClose"
          type="button"
          aria-label="Close message"
          class="delete"
          @click.prevent="close()"
        ></button>
      </header>
      <section class="message-body">
        <div class="media">
          <div class="media-left">
            <i class="icons icons-info-circle" style="font-size: 45px" />
          </div>
          <div class="media-content" v-if="!isList">
            {{ message }}
          </div>
          <div class="media-content" v-else>
            <span v-for="mes in message" :key="mes">
              {{ mes }}
              <br />
            </span>
          </div>
        </div>
      </section>
    </article>

    <article v-if="type == 'is-success'" class="is-success">
      <header class="message-header" v-if="title != undefined">
        <p>{{ title }}</p>
        <!---->
        <button
          v-if="canClose != undefined || canClose"
          type="button"
          aria-label="Close message"
          class="delete"
          @click.prevent="close()"
        ></button>
      </header>
      <section class="message-body">
        <div class="media">
          <div class="media-left">
            <i class="icons icons-check" style="font-size: 45px" />
          </div>
          <div class="media-content" v-if="!isList">
            {{ message }}
            <div style="float: right">
              <b-button type="is-primary" style="margin-right: 10px" disabled
                >{{ messageBtn }}
              </b-button>
            </div>
          </div>
          <div class="media-content" v-else>
            <span v-for="mes in message" :key="mes">
              {{ mes }}
              <div style="float: right">
                <b-button
                  type="is-success"
                  style="margin-right: 10px"
                  disabled
                  v-if="messageBtn != undefined"
                  >{{ messageBtn }}
                </b-button>
              </div>
              <br />
            </span>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  name: "Message",
  data() {
    return {};
  },
  methods: {
    close() {      
      this.$emit("ActionAfterMessage");
    },
  },
  props: {
    message: String,
    type: String,
    isList: Boolean,
    title: String,
    canClose: Boolean,
    messageBtn: String,
  },
};
</script>

<style scoped>
.is-warning {
  background-color: #fffaeb;
}

.is-warning .message-body {
  border-color: #ffe08a;
  color: #946c00;
}

.is-warning .message-header {
  border-color: #ffe08a;
  color: #946c00;
  background-color: #ffe08a;
}

.is-danger {
  background-color: #feecf0;
}

.is-danger .message-body {
  border-color: #f14668;
  color: #cc0f35;
}

.is-danger .message-header {
  border-color: #f14668;
  color: #fff;
  background-color: #f14668;
}

.is-info {
  background-color: #ecf4fe;
}

.is-info .message-body {
  border-color: #167df0;
  color: #0d68ce;
}

.is-info .message-header {
  border-color: #167df0;
  color: #fff;
  background-color: #167df0;
}

.is-success {
  background-color: #effaf5;
}

.is-success .message-body {
  border-color: #48c78e;
  color: #257953;
}

.is-success .message-header {
  border-color: #48c78e;
  color: #fff;
  background-color: #48c78e;
}

.media-left {
  margin-right: 1rem;
}

.media-content {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  text-align: inherit;
}
</style>