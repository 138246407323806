export const clientAPIs = {
    Path: { name: "/api/files" },
    PathDownloadFile: { name: "downloadFile" },
    PathUploadFile: { name: "uploadFile" },

    // Comandos de Database
    Backup: { name: "backup", time: 330 },
    Restore: { name: "restore", time: 330 },
    //Comandos de HashCode
    HashCode: { name: "hashCode", time: 10 },
    //Comandos de Report
    Report: { name: "report", time: 30 },
    ServerLogs: { name: "serverLogs", time: 60 },
    ServerReports: { name: "serverReports", time: 60 },
    NavisionReport: {name: "navisionReport", time: 60},
    Certificate: { name: "certificates", time: 60 },
    Files: {name: "files", time: 60},
    //Comandos de Configuration
    Configuration: {name: "configuration", time: 60},
    //Comandos de Licensa
    License: {name: "license", time: 330},
}



export default { clientAPIs }