<template>
  <div class="user-dropdown">
    <div class="topnav">
      <a id="btnControl">
        <i class="icons-white icons-1x icons-switch"></i>
      </a>
    </div>
    <div class="user-dropdown-content" style="width: 195px">
      <a href @click.prevent="processLogOff()" v-if="false"
        ><i class="icons-1x icons-sign-out" />{{ translate("btnLogoff") }}</a
      >
      <a href @click.prevent="processForcedLogOff()" v-if="false"
        ><i class="icons icons-target" /> {{ translate("btnForcedLogOff") }}</a
      >
      <a href @click.prevent="processRestart()"
        ><i style="color: var(--iconColor)" class="icons icons-sync" />
        {{ translate("btnRestart") }}</a
      >
      <a href @click.prevent="processShutdown()" v-if="isElectronVar"
        ><i style="color: var(--iconColor)" class="icons icons-switch" />
        {{ translate("btnShutdown") }}</a
      >
      <a
        href
        @click.prevent="processRunOsAdmin('runOsAdmin')"
        v-if="verifyOsAdmin()"
        ><i style="color: var(--iconColor)" class="icons icons-window" />
        {{ translate("btnManageSystem") }}</a
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { os } from "@/js/osType.js";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
export default {
  computed: mapState([
    "lang",
    "showHashCode",
    "isElectronVar",
    "userLogged",
    "noConnection",
    "hasControl",
    "isWindows",
  ]),
  name: "Settings",
  mixins: [en, es, nor,swe],
  data() {
    return {
      Os: os,
    };
  },
  components: {},
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    verifyOsAdmin() {
      let ret = false;

      if (this.isElectronVar && !this.isWindows) {
        if (this.hasDatabase) {
          if (this.userLogged.Technician || this.userLogged.Root) {
            ret = true;
          }
        } else {
          ret = true;
        }
      }
      return ret;
    },
    processLogOff() {
      this.$buefy.dialog.confirm({
        title: this.translate("dialog_Title"),
        message: this.translate("messageProcessLogOff"),
        confirmText: this.translate("btnOk"),
        cancelText: this.translate("btnCancel"),
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => this.sendSettings("logOff"),
      });
    },
    processForcedLogOff() {
      this.$buefy.dialog.confirm({
        title: this.translate("dialog_Title"),
        message: this.translate("messageProcessForcedLogOff"),
        confirmText: this.translate("btnOk"),
        cancelText: this.translate("btnCancel"),
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => this.sendSettings("forcedLogOff"),
      });
    },
    processRestart() {
      const confirMessage = this.hasEmailToSend
        ? this.translate("messageProcessRestart") +
          " <br/> <br/> " +
          "<b>" +
          this.translate("messageHasEmailToSend")
        : this.translate("messageProcessRestart") + "</b>";
      this.$buefy.dialog.confirm({
        title: this.translate("dialog_Title"),
        message: confirMessage,
        confirmText: this.translate("btnOk"),
        cancelText: this.translate("btnCancel"),
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => this.sendSettings("restart"),
      });
    },
    processShutdown() {
      const confirMessage = this.hasEmailToSend
        ? this.translate("messageProcessShutdown") +
          " <br/> <br/> " +
          "<b>" +
          this.translate("messageHasEmailToSend")
        : this.translate("messageProcessShutdown") + "</b>";
      this.$buefy.dialog.confirm({
        title: this.translate("dialog_Title"),
        message: confirMessage,
        confirmText: this.translate("btnOk"),
        cancelText: this.translate("btnCancel"),
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => this.sendSettings("shutdown"),
      });
    },
    processRunOsAdmin(local) {
      this.$buefy.dialog.confirm({
        title: this.translate("dialog_Title"),
        message: this.translate("messageTurnOffServer"),
        confirmText: "Confirm",
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => this.sendSettings(local),
      });
    },

    sendSettings(action) {
      this.$emit("ActionAfterSettings", action);
    },
  },
};
</script>

<style scoped src="@/css/componentDropdown.css">
</style>
