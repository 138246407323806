<template>
  <div class="iniFile">
    <br />
    <notifications group="foo" position="center right" v-if="!errorS2S" />
    <div class="auth-content">
      <div class="auth-modal" style="width: 450px">
        <div class="auth-title">
          {{ this.translate("iniGlobal_Title") }}
        </div>
        <form action="javascript:void(0);" class="needs-validation" novalidate>
          <b-modal v-model="showModalReturnApp" width="915px" trap-focus :destroy-on-hide="true" aria-role="dialog"
            aria-modal :can-cancel="false">
            <template #default="props">
              <ReturnApp :automatic="true" @close="props.close" :obj="data" />
            </template>
          </b-modal>
          <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
            :can-cancel="false">
            <template #default="props">
              <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
                route="/modules/Inifile" @ActionAfterCMDCreateIni="afterCMDCreateIni" :JsonObject="obj">
              </LoadingCMD>
            </template>
          </b-modal>

          <b-modal v-model="modalShowGlobalSettings" width="600px" trap-focus :destroy-on-hide="true" aria-role="dialog"
            aria-modal :can-cancel="false">
            <template #default="props">
              <GlobalSettings @close="props.close" />
            </template>
          </b-modal>

          <b-modal v-model="showModalNoConnection" width="600px" trap-focus :destroy-on-hide="true" aria-role="dialog"
            aria-modal :can-cancel="false">
            <template #default="props">
              <NoConnection @close="props.close" />
            </template>
          </b-modal>

          <div class="form-row">
            <label v-if="isFBM" for="validationCountryCode" class="col-sm-4 col-form-label">{{
              this.translate("lblCountryCode")
            }}</label>
            <div class="col-sm-6 mb-2" v-if="isFBM">
              <b-form-select id="validationCountryCode" class="form-control" v-model="iniFile.countryCode"
                :disabled="true" @change="changeModal(iniFile.countryCode)" :state="errorCountryCode ? false : null">
                <option v-for="country in countrys" :value="country.id" :key="country.id">
                  {{ country.name }}
                </option>
              </b-form-select>

              <div v-if="errorCountryCode" style="
                    width: 100%;
                    margin-top: 0.25rem;
                    font-size: 80%;
                    color: #f86c6b;
                  ">
                {{ this.translate("messageErrorCountryCode") }}
              </div>
            </div>

            <label for="staticEmail" class="col-sm-4 col-form-label">{{
              this.translate("lblGlobalIP")
            }}</label>
            <div class="col-sm-6 mb-2">
              <b-form-input list="connectionIPs" name="connectionIP" id="connectionIP" class="form-control"
                v-model="iniFile.connectionIP" :state="errorConnectionIP ? false : null" />

              <div v-if="errorConnectionIP" style="
                    width: 100%;
                    margin-top: 0.25rem;
                    font-size: 80%;
                    color: #f86c6b;
                  ">
                {{ this.translate("messageErrorIP") }}
              </div>
            </div>
            <div class="col-sm-1">
              <button class="btn btn-outline-secondary" style="height: 40px" type="button"
                @click="showKeyboardIP = !showKeyboardIP">
                <i class="icons-lg0 icons-keyboard" />
              </button>
            </div>
            <div class="mb-2" v-if="showKeyboardIP" style="width: 100%; margin-top: 0.25rem">
              <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="iniFile.connectionIP"
                :isNumeric="true" :hasDot="true" />
            </div>
            <label v-if="false" for="staticEmail" class="col-sm-4 col-form-label">{{
              this.translate("lblDatabaseIP")
            }}</label>
            <div class="col-sm-8 mb-2" v-if="false">
              <b-form-input list="databaseIPs" name="databaseIP" id="databaseIP" class="form-control"
                v-model="iniFile.databaseIP" :state="errorDatabaseIP ? false : null" />
              <datalist id="databaseIPs">
                <option v-for="ip in listIps" v-bind:value="ip" :key="ip">
                  {{ ip }}
                </option>
              </datalist>

              <div v-if="errorDatabaseIP" style="
                    width: 100%;
                    margin-top: 0.25rem;
                    font-size: 80%;
                    color: #f86c6b;
                  ">
                {{ this.translate("messageErrorIP") }}
              </div>
            </div>
            <!-- Modal Global -->
            <div class="form-group col-md-12 mb-2" v-if="btnShowGlobal">
              <button :class="[
                'button',
                { 'is-success': iniFile.global.isGlobal == true },
                { 'is-primary': iniFile.global.isGlobal == false },
              ]" type="button" @click="modalShowGlobalSettings = !modalShowGlobalSettings">
                Global
              </button>
            </div>
            <section>
              <b-modal :active.sync="modalShowGlobal" has-modal-card :can-cancel="false">
                <form action="javascript:void(0);">
                  <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                      <p class="modal-card-title">
                        {{ this.translate("global_Title") }}
                      </p>
                    </header>
                    <section class="modal-card-body">
                      <div class="form-row">
                        <div class="col-sm-11 mb-2">
                          <b-checkbox v-model="iniFile.global.isGlobal" aria-describedby="flavours"
                            aria-controls="flavours">
                            {{
                              iniFile.global.isGlobal
                              ? this.translate("isGlobal_Title")
                              : this.translate("isGlobalProgressive")
                            }}
                          </b-checkbox>
                        </div>
                        <!-- -->

                        <label for="staticEmail" class="col-sm-10 col-form-label" v-if="iniFile.global.isGlobal">{{
                          this.translate("globalProgressiveMode")
                        }}</label>
                        <div class="col-sm-10 mb-2">
                          <select id="inputState" class="form-control" v-model="iniFile.global.Mode"
                            v-if="iniFile.global.isGlobal">
                            <option value="0">
                              {{ this.translate("clientOnly_Option") }}
                            </option>
                            <option value="1">
                              {{ this.translate("serverOnly_Option") }}
                            </option>
                            <option value="2">
                              {{ this.translate("clientAndServer_Option") }}
                            </option>
                          </select>
                        </div>
                        <div class="col-sm-2 mb-2" v-if="iniFile.global.isGlobal">
                          <b-button type="is-info" @click="alertGlobal" icon-right="icons-1x icons-info-circle"
                            icon-pack="icons" />
                        </div>
                        <label for="staticEmail" class="col-sm-10 col-form-label" v-if="iniFile.global.Mode == 0 && iniFile.global.isGlobal
                          ">{{
                              this.translate("lblGlobalProgressiveServerIP")
                            }}</label>
                        <div class="col-sm-10 mb-2">
                          <b-form-input list="globalConnectionIPs" name="globalConnectionIP" id="globalConnectionIP"
                            class="form-control" v-model="iniFile.global.ConnectionIP" @focus="
                              (showKeyboardGlobalPref = false),
                              (showKeyboardGlobalProg = false)
                              " v-if="iniFile.global.Mode == 0 &&
                              iniFile.global.isGlobal
                              " :state="errorGlobalConnectionIP ? false : null" />

                          <div v-if="errorGlobalConnectionIP" style="
                                width: 100%;
                                margin-top: 0.25rem;
                                font-size: 80%;
                                color: #f86c6b;
                              ">
                            {{ this.translate("messageErrorIP") }}
                          </div>
                        </div>
                        <div class="col-sm-2 mb-2" v-if="iniFile.global.Mode == 0 && iniFile.global.isGlobal
                          ">
                          <button class="btn btn-outline-secondary" style="height: 40px" type="button" @click="
                            showKeyboardGlobalProg = !showKeyboardGlobalProg
                            ">
                            <i class="icons-lg0 icons-keyboard" />
                          </button>
                        </div>
                        <div class="mb-2" v-if="showKeyboardGlobalProg" style="width: 100%; margin-top: 0.25rem">
                          <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress"
                            :input="iniFile.global.ConnectionIP" :isNumeric="true" :hasDot="true" />
                        </div>
                        <!-- -->
                        <div class="form-group col-md-10 mb-2" v-if="iniFile.global.Mode == 0 && iniFile.global.isGlobal
                          ">
                          <b-checkbox v-model="iniFile.global.isGlobalIP">
                            {{
                              iniFile.global.isGlobalIP
                              ? this.translate("globalPreferencialIP_Option")
                              : this.translate("anotherLocalIP_Option")
                            }}
                          </b-checkbox>
                        </div>
                        <div class="col-sm-10 mb-2">
                          <b-form-input list="globalGlobalConnectionIPs" name="globalGlobalConnectionIP"
                            id="globalGlobalConnectionIP" class="form-control" v-model="iniFile.global.GlobalConnectionIP"
                            @focus="
                              (showKeyboardGlobalPref = false),
                              (showKeyboardGlobalProg = false)
                              " v-if="iniFile.global.isGlobalIP &&
                              iniFile.global.isGlobal &&
                              iniFile.global.Mode == 0
                              " :state="errorGlobalGlobalConnectionIP ? false : null
                              " />
                          <datalist id="globalGlobalConnectionIPs">
                            <option v-for="ip in listIps" v-bind:value="ip" :key="ip">
                              {{ ip }}
                            </option>
                          </datalist>

                          <div v-if="errorGlobalGlobalConnectionIP" style="
                                width: 100%;
                                margin-top: 0.25rem;
                                font-size: 80%;
                                color: #f86c6b;
                              ">
                            {{ this.translate("messageErrorIP") }}
                          </div>
                        </div>
                        <div class="col-sm-2 mb-2" v-if="iniFile.global.isGlobalIP &&
                          iniFile.global.isGlobal &&
                          iniFile.global.Mode == 0
                          ">
                          <button class="btn btn-outline-secondary" style="height: 40px" type="button" @click="
                            showKeyboardGlobalPref = !showKeyboardGlobalPref
                            ">
                            <i class="icons-lg0 icons-keyboard" />
                          </button>
                        </div>
                        <div class="mb-2" v-if="showKeyboardGlobalPref" style="width: 100%; margin-top: 0.25rem">
                          <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress"
                            :input="iniFile.global.GlobalConnectionIP" :isNumeric="true" :hasDot="true" />
                        </div>
                      </div>
                    </section>
                    <footer class="modal-card-foot">
                      <b-button :label="translate('btnClose')" icon-left=".icons-white icons-ban" icon-pack="icons"
                        @click="closeModalGlobal">
                        Close
                      </b-button>
                      <b-button :label="translate('btnOk')" type="is-primary" icon-left=".icons-white icons-check"
                        icon-pack="icons" @click="saveGlobal">
                        Save
                      </b-button>
                    </footer>
                  </div>
                </form>
              </b-modal>
            </section>
            <!-- close Modal Global -->

            <!-- Modal Gistra -->
            <div class="form-group col-md-12 mb-2" v-if="btnShowGistra">
              <button class="button is-primary" type="button" @click="openModalGistra">
                Gistra
              </button>
            </div>
            <section>
              <b-modal :active.sync="modalShowGistra" has-modal-card :can-cancel="false">
                <form action="javascript:void(0);">
                  <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                      <p class="modal-card-title">
                        {{ this.translate("gistra_Title") }}
                      </p>
                    </header>
                    <section class="modal-card-body">
                      <div class="form-row">
                        <div class="form-group col-md-12 mb-2">
                          <label for="input-live"><b>{{ this.translate("lblServerIP") }}: </b></label>
                          <b-form-input id="input-live" v-model="iniFile.gistra.ServerIP"
                            aria-describedby="input-live-help input-live-feedback" trim @focus="
                              inputKeyboard = '';
                            isNumeric = false;
                            hasDot = false;
                            inputType = 'serverIp';
                            iniFile.gistra.ServerIP == undefined
                              ? (inputKeyboard = '')
                              : (inputKeyboard = iniFile.gistra.ServerIP);
                            " :state="errorServerIP ? false : null"></b-form-input>

                          <div v-if="errorServerIP" style="
                                width: 100%;
                                margin-top: 0.25rem;
                                font-size: 80%;
                                color: #f86c6b;
                              ">
                            {{ this.translate("messageErrorIP") }}
                          </div>

                          <label for="input-live"><b>{{ this.translate("btnLogin") }}:</b></label>
                          <b-form-input id="input-live" v-model="iniFile.gistra.Login"
                            aria-describedby="input-live-help input-live-feedback" trim @focus="
                              inputKeyboard = '';
                            isNumeric = false;
                            hasDot = false;
                            inputType = 'login';
                            iniFile.gistra.Login == undefined
                              ? (inputKeyboard = '')
                              : (inputKeyboard = iniFile.gistra.Login);
                            " :state="iniFile.gistra.Login == '' && errorLogin
    ? false
    : null
  "></b-form-input>
                          <div v-if="iniFile.gistra.Login == '' && errorLogin" style="
                                width: 100%;
                                margin-top: 0.25rem;
                                font-size: 80%;
                                color: #f86c6b;
                              ">
                            {{ this.translate("messageErrorSelectLogin") }}
                          </div>

                          <label for="input-live"><b>{{ translate("lblPort") }}:</b></label>
                          <b-form-input id="input-live" v-model="iniFile.gistra.Port" type="number"
                            aria-describedby="input-live-help input-live-feedback" min="1" max="9999" maxlength="4" trim
                            @focus="
                              inputKeyboard = '';
                            isNumeric = true;
                            hasDot = false;
                            inputType = 'port';
                            iniFile.gistra.Port == undefined
                              ? (inputKeyboard = '')
                              : (inputKeyboard = iniFile.gistra.Port);
                            " :state="iniFile.gistra.Port == '' && errorPort
    ? false
    : null
  "></b-form-input>
                          <div v-if="iniFile.gistra.Port == '' && errorPort" style="
                                width: 100%;
                                margin-top: 0.25rem;
                                font-size: 80%;
                                color: #f86c6b;
                              ">
                            {{ this.translate("messageErrorPort") }}
                          </div>
                        </div>
                        <b-field :label="this.translate('lblPassword')" class="form-group col-md-12 mb-3" :type="{
                          'is-danger':
                            errorPasswordGistra &&
                            iniFile.gistra.Password == '',
                        }" :message="[
  {
    'Password is too short':
      errorPasswordGistra &&
      iniFile.gistra.Password == '',
  },
  {
    'Password must have at least 8 characters':
      errorPasswordGistra &&
      iniFile.gistra.Password == '',
  },
]">
                          <b-input v-model="iniFile.gistra.Password" password-reveal type="password" maxlength="20"
                            @focus="
                              inputKeyboard = '';
                            isNumeric = true;
                            hasDot = false;
                            inputType = 'password';
                            iniFile.gistra.Password == undefined
                              ? (inputKeyboard = '')
                              : (inputKeyboard = iniFile.gistra.Password);
                            "></b-input>
                        </b-field>

                        <br /><br />
                        <div class="col-sm-11 mb-2">
                          <b-checkbox v-model="iniFile.gistra.SSL" aria-describedby="flavours" aria-controls="flavours">
                            <b>SSL</b>
                          </b-checkbox>
                        </div>
                      </div>
                      <div class="mb-2" v-if="showKeyboardGistra" style="width: 100%; margin-top: 0.25rem">
                        <hr />
                        <SimpleKeyboard :key="inputType" @onChange="onChange" @onKeyPress="onKeyPress"
                          :input="inputKeyboard" :isNumeric="isNumeric" :hasDot="hasDot" />
                      </div>
                    </section>
                    <footer class="modal-card-foot">
                      <div class="col-lg-10">
                        <b-button :label="translate('btnClose')" @click="$emit('close')"
                          icon-left=".icons-white icons-ban" icon-pack="icons" @click.prevent="closeModalGistra">
                        </b-button>
                        <b-button :label="translate('btnSave')" type="is-primary" icon-left=".icons-white icons-check"
                          icon-pack="icons" @click="saveGistra">
                        </b-button>
                      </div>
                      <div class="col-lg-2">
                        <button class="btn btn-outline-secondary" style="height: 40px; float: right" type="button"
                          @click="showKeyboardGistra = !showKeyboardGistra">
                          <i class="icons-lg0 icons-keyboard" />
                        </button>
                      </div>
                    </footer>
                  </div>
                </form>
              </b-modal>
            </section>
            <!-- close Modal Gistra -->

            <!-- Modal Alesis -->
            <div class="form-group col-md-12 mb-2" v-if="btnShowAlesis">
              <button :class="[
                'button',
                { 'is-success': alesisValidated == true },
                { 'is-danger': alesisValidated == false },
              ]" type="button" @click="openModalAlesis" style="padding-bottom: 5px">
                S2S {{ translate("configurations_Title") }}
              </button>
              <br />
              <div></div>
              <span v-if="!alesisValidated" style="color: red">
                - {{ translate("lblFillAlesis") }}
              </span>
              <br />
            </div>
            <section>
              <b-modal :active.sync="modalShowAlesis" has-modal-card :can-cancel="false">
                <Message v-if="alesisMessage != ''" type="is-danger" :message="alesisMessage" :isList="false" />
                <form action="javascript:void(0);">
                  <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                      <p class="modal-card-title">
                        S2S {{ translate("configurations_Title") }}
                      </p>
                    </header>
                    <section class="modal-card-body">
                      <div class="form-row">
                        <div class="form-group col-md-12 mb-2">
                          <label for="input-live"><b>{{ translate("lblPreferencialIP") }}</b></label>
                          <b-form-input id="input-live" v-model="iniFile.alesis.DbIP"
                            aria-describedby="input-live-help input-live-feedback" trim @focus="
                              inputKeyboard = '';
                            isNumeric = true;
                            hasDot = true;
                            inputType = 'preferencialIp';
                            iniFile.alesis.DbIP == undefined
                              ? (inputKeyboard = '')
                              : (inputKeyboard = iniFile.alesis.DbIP);
                            " :state="errorDbIP ? false : null"></b-form-input>
                          <div v-if="errorDbIP" style="
                                width: 100%;
                                margin-top: 0.25rem;
                                font-size: 80%;
                                color: #f86c6b;
                              ">
                            {{ translate("messageErrorIP") }}
                          </div>
                          <label for="input-live"><b>{{ translate("lblSeller") }}</b></label>
                          <b-form-input id="input-live" v-model="iniFile.alesis.VendorID"
                            aria-describedby="input-live-help input-live-feedback" trim @focus="
                              inputKeyboard = '';
                            isNumeric = false;
                            hasDot = false;
                            inputType = 'vendorID';
                            iniFile.alesis.VendorID == undefined
                              ? (inputKeyboard = '')
                              : (inputKeyboard = iniFile.alesis.VendorID);
                            " :state="errorVendorID ? false : null"></b-form-input>
                          <div v-if="errorVendorID" style="
                                width: 100%;
                                margin-top: 0.25rem;
                                font-size: 80%;
                                color: #f86c6b;
                              ">
                            {{ translate("messageErrorlblSeller") }}
                          </div>
                          <label for="input-live"><b>{{ translate("lblSqlUser") }}</b></label>
                          <b-form-input id="input-live" v-model="iniFile.alesis.UserSQL"
                            aria-describedby="input-live-help input-live-feedback" trim @focus="
                              inputKeyboard = '';
                            isNumeric = false;
                            hasDot = false;
                            iniFile.alesis.UserSQL == undefined
                              ? (inputKeyboard = '')
                              : (inputKeyboard = iniFile.alesis.UserSQL),
                              (inputType = 'userSQL');
                            " :state="errorUserSQL ? false : null"></b-form-input>
                          <div v-if="errorUserSQL" style="
                                width: 100%;
                                margin-top: 0.25rem;
                                font-size: 80%;
                                color: #f86c6b;
                              ">
                            {{ translate("messageErrorlblSqlUser") }}
                          </div>

                          <label for="input-live"><b>{{ translate("lblDataBaseName") }}</b></label>
                          <b-form-input id="input-live" v-model="iniFile.alesis.Database"
                            aria-describedby="input-live-help input-live-feedback" trim @focus="
                              inputKeyboard = '';
                            isNumeric = false;
                            hasDot = false;
                            iniFile.alesis.Database == undefined
                              ? (inputKeyboard = '')
                              : (inputKeyboard = iniFile.alesis.Database),
                              (inputType = 'databaseName');
                            " :state="errorDatabase ? false : null"></b-form-input>
                          <div v-if="errorDatabase" style="
                                width: 100%;
                                margin-top: 0.25rem;
                                font-size: 80%;
                                color: #f86c6b;
                              ">
                            {{ translate("messageErrorlblDataBaseName") }}
                          </div>
                        </div>
                        <div class="form-group col-md-12 mb-2">
                          <b-field :label="'' + translate('lblSqlPassword')" :type="{
                            'is-danger': errorSqlPassword,
                          }" :message="[
  {
    'Password must have at least 4 characters':
      errorSqlPassword &&
      iniFile.alesis.Password.length < 4,
  },
]">
                            <b-input type="password" v-model="iniFile.alesis.Password" password-reveal maxlength="20"
                              @focus="
                                inputKeyboard = '';
                              iniFile.alesis.Password == undefined
                                ? (inputKeyboard = '')
                                : (inputKeyboard = iniFile.alesis.Password),
                                (inputType = 'password');
                              ">
                            </b-input>
                          </b-field>
                        </div>
                        <div class="form-group col-md-12 mb-2">
                          <b-field :label="'' + translate('lblConfirmSqlPassword')" :type="{
                            'is-danger':
                              iniFile.alesis.ConfirmPassword !=
                              iniFile.alesis.Password,
                          }" :message="[
  {
    'The password confirmation does not match.':
      iniFile.alesis.ConfirmPassword !=
      iniFile.alesis.Password,
  },
]">
                            <b-input type="password" v-model="iniFile.alesis.ConfirmPassword" password-reveal
                              maxlength="20" @focus="
                                inputKeyboard = '';
                              isNumeric = false;
                              hasDot = false;
                              iniFile.alesis.ConfirmPassword == undefined
                                ? (inputKeyboard = '')
                                : (inputKeyboard =
                                  iniFile.alesis.ConfirmPassword),
                                (inputType = 'confirmPassword');
                              ">
                            </b-input>
                          </b-field>
                        </div>
                        <div class="col-sm-12 mb-0" v-if="false">
                          <b-form-group v-slot="{ ariaDescribedby }">
                            <b-form-checkbox-group id="checkbox-group-1" v-model="iniFile.alesis.Provider"
                              :options="optionsProvider" :aria-describedby="ariaDescribedby"
                              required></b-form-checkbox-group>
                          </b-form-group>
                          <div v-if="errorProvider && iniFile.alesis.Provider === false
                            " style="
                                width: 100%;
                                margin-top: 0.25rem;
                                font-size: 80%;
                                color: #f86c6b;
                              ">
                            {{ translate("messageErrorProvider") }}
                          </div>
                        </div>
                      </div>
                      <div class="mb-2" v-if="showKeyboardS2S" style="width: 100%; margin-top: 0.25rem">
                        <hr />
                        <SimpleKeyboard :key="inputType" @onChange="onChange" @onKeyPress="onKeyPress"
                          :input="inputKeyboard" :isNumeric="isNumeric" :hasDot="hasDot" />
                      </div>
                    </section>
                    <footer class="modal-card-foot">
                      <div class="col-lg-10">
                        <b-button :label="translate('btnClose')" @click="$emit('close')"
                          icon-left=".icons-white icons-ban" icon-pack="icons" @click.prevent="closeModalAlesis">
                        </b-button>
                        <b-button :label="translate('btnSave')" type="is-primary" icon-left=".icons-white icons-check"
                          icon-pack="icons" @click.prevent="saveAlesis">
                        </b-button>
                      </div>
                      <div class="col-lg-2">
                        <button class="btn btn-outline-secondary" style="height: 40px; float: right" type="button"
                          @click="showKeyboardS2S = !showKeyboardS2S">
                          <i class="icons-lg0 icons-keyboard" />
                        </button>
                      </div>
                    </footer>
                  </div>
                </form>
              </b-modal>
            </section>
            <!-- close Modal Alesis -->

            <!-- modal BigOS Settings -->

            <div class="form-group col-md-12 mb-2">
              <button :class="[
                'button',
                {
                  'is-success': iniFile.BigOsSettings.BigosAddress != '',
                },
                { '': iniFile.BigOsSettings.BigosAddress == '' },
              ]" type="button" @click.prevent="openModalBigOsSettings()">
                BigOS {{ translate("settings") }}
              </button>
            </div>
            <section>
              <b-modal :active.sync="modalShowBigOsSettings" has-modal-card :can-cancel="false">
                <form action="javascript:void(0);">
                  <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                      <p class="modal-card-title">
                        BigOS {{ translate("settings") }}
                      </p>
                    </header>

                    <section class="modal-card-body">
                      <div class="message">
                        <article class="is-warning" style="background-color: #fffaeb">
                          <section class="message-body" style="border-color: #ffe08a; color: #946c00">
                            <div class="media">
                              <div class="media-left">
                                <i class="icons icons-warning1" style="font-size: 45px" />
                              </div>
                              <div>
                                {{
                                  translate("messageConfiguratorSettingsPart1")
                                }}
                                <br />
                                <br />
                                <b>SystemConfigurator:</b>
                                {{
                                  translate("messageConfiguratorSettingsPart2")
                                }}
                                <br />
                                <br />
                                <b>BigOS:</b>
                                {{
                                  translate("messageConfiguratorSettingsPart3")
                                }}
                                <br />
                                <br />
                                {{
                                  translate("messageConfiguratorSettingsPart4")
                                }}
                              </div>
                            </div>
                          </section>
                        </article>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-md-12 mb-2">
                          <label for="input-live"><b>{{ translate("btnBigosAddress") }}</b></label>
                          <b-form-input id="input-live" v-model="iniFile.BigOsSettings.BigosAddress"
                            aria-describedby="input-live-help input-live-feedback" trim maxlength="80" @focus="
                              inputKeyboard = '';
                            isNumeric = false;
                            hasDot = false;
                            inputType = 'bigosAddress';

                            iniFile.BigOsSettings.BigosAddress == undefined
                              ? (inputKeyboard = '')
                              : (inputKeyboard =
                                iniFile.BigOsSettings.BigosAddress);
                            " :state="errorBigosAddress ? false : null"></b-form-input>
                          <div v-if="errorBigosAddress" style="
                                width: 100%;
                                margin-top: 0.25rem;
                                font-size: 80%;
                                color: #f86c6b;
                              ">
                            {{ translate("messageErrorIP") }}
                          </div>

                          <b-checkbox style="margin-top: 15px" v-model="iniFile.BigOsSettings.CheckAutomaticConfig">{{
                            translate("checkAutomatic")
                          }}</b-checkbox>
                          <br />

                          <label for="input-live"><b>{{ translate("btnCheckTime") }}</b></label>
                          <b-form-input id="input-live" v-model="iniFile.BigOsSettings.CheckTime"
                            aria-describedby="input-live-help input-live-feedback" maxlength="7"
                            :disabled="!iniFile.BigOsSettings.CheckAutomaticConfig" max="999999" type="number" min="1"
                            @focus="
                              inputKeyboard = '';
                            isNumeric = true;
                            hasDot = false;
                            inputType = 'checkTime';
                            iniFile.BigOsSettings.CheckTime == undefined
                              ? (inputKeyboard = '')
                              : (inputKeyboard =
                                iniFile.BigOsSettings.CheckTime);
                            " :state="errorCheckTime ? false : null"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"></b-form-input>
                          <div v-if="errorCheckTime" style="
                                width: 100%;
                                margin-top: 0.25rem;
                                font-size: 80%;
                                color: #f86c6b;
                              ">
                            {{ translate("messageErrorCheckTime") }}
                          </div>

                          <b-checkbox style="margin-top: 15px" v-model="iniFile.BigOsSettings.CheckAutomaticTerminals">{{
                            translate("checkAutomaticTerminal")
                          }}</b-checkbox>
                          <br />

                          <label for="input-live"><b>{{ translate("btnCheckTime") }}</b></label>
                          <b-form-input id="input-live" v-model="iniFile.BigOsSettings.CheckTimeTerminals"
                            aria-describedby="input-live-help input-live-feedback" maxlength="7" max="999999"
                            type="number" min="1" :disabled="!iniFile.BigOsSettings.CheckAutomaticTerminals" @focus="
                              inputKeyboard = '';
                            isNumeric = true;
                            hasDot = false;
                            inputType = 'checkTimeTerminals';
                            iniFile.BigOsSettings.CheckTimeTerminals == undefined
                              ? (inputKeyboard = '')
                              : (inputKeyboard =
                                iniFile.BigOsSettings.CheckTimeTerminals);
                            " :state="errorCheckTimeTerminals ? false : null"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"></b-form-input>
                          <div v-if="errorCheckTimeTerminals" style="
                                width: 100%;
                                margin-top: 0.25rem;
                                font-size: 80%;

                                color: #f86c6b;
                              ">
                            {{ translate("messageErrorCheckTime") }}
                          </div>


                        </div>
                      </div>
                      <div class="mb-2" v-if="showKeyboardBigos" style="width: 100%; margin-top: 0.25rem">
                        <hr />
                        <SimpleKeyboard :key="inputType" @onChange="onChange" @onKeyPress="onKeyPress"
                          :input="inputKeyboard" :isNumeric="isNumeric" :hasDot="hasDot" :withoutZero="false" />
                      </div>
                    </section>
                    <footer class="modal-card-foot">
                      <div class="col-lg-10">
                        <b-button :label="translate('btnClose')" icon-left=".icons-white icons-ban" icon-pack="icons"
                          @click.prevent="closeModalBigOsSettigs">
                        </b-button>
                        <b-button :label="translate('btnSave')" type="is-primary" icon-left=".icons-white icons-check"
                          icon-pack="icons" @click.prevent="saveBigOsSettings">
                        </b-button>
                      </div>
                      <div class="col-lg-2">
                        <button class="btn btn-outline-secondary" style="height: 40px; float: right" type="button"
                          @click="showKeyboardBigos = !showKeyboardBigos">
                          <i class="icons-lg0 icons-keyboard" />
                        </button>
                      </div>
                    </footer>
                  </div>
                </form>
              </b-modal>
            </section>
            <!-- close BigOs Settings -->

            <div class="form-group col-md-12">
              <b-checkbox v-model="iniFile.runAtWindowsStartup" v-if="isElectronVar && showBtns()">
                {{ this.translate(isFBM ? "fbmServer_Option" : "dingoServer_Option") }}
              </b-checkbox>              
            </div>
          </div>
          <div style="float: left">
            <b-button :label="translate('btnCancel')" icon-left=".icons-white icons-times" icon-pack="icons"
              style="margin-right: 10px" @click.prevent="cancelIni()" v-if="showBtns()"></b-button>
            <b-button type="is-primary" :label="translate('btnOk')" icon-left=".icons-white icons-check" icon-pack="icons"
              @click.prevent="sendIni()" :disabled="validateBtnSaveIni()"></b-button>
          </div>
        </form>
        <b-modal :active.sync="showModalReset" :can-cancel="false">
          <Message type="is-success" :message="translate(isFBM ? 'fbmServerWillRestart' : 'dingoServerWillRestart')"
            :messageBtn="translate('btnWait')" :isList="false" />
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import validateField from "@/js/validateField";
import SimpleKeyboard from "@/components/keyboard/SimpleKeyboard.vue";
import GlobalSettings from "@/components/global/GlobalSettings.vue";
import LoadingCMD from "../processCMD/LoadingCMD";
import Socket from "@/config/webSocket.js";
import ReturnApp from "@/components/reset/ReturnApp.vue";
import NoConnection from "@/components/connection/noConnection.vue";
import Message from "@/components/message/Message.vue";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import { mapState } from "vuex";
import { countryList, countries } from "@/mixins/countryCode.js";
import { closeWindow } from "@/js/screenAdjustment.js";
import { clientCommands } from "@/js/ClientCommands.js";
import { returnApp } from "@/js/resetApp.js";
import { globalType } from "@/mixins/enums";
export default {
  name: "Configurations",
  mixins: [en, es, nor, swe],
  computed: mapState([
    "session",
    "communication",
    "hasDatabase",
    "listIps",
    "gistraDefault",
    "user",
    "errorS2S",
    "errorGlobalClient",
    "lang",
    "showS2S",
    "isElectronVar",
    "globalType",
    "isWindows",
    "showModalNoConnection",
    "isFBM"
  ]),
  components: {
    LoadingCMD,
    SimpleKeyboard,
    ReturnApp,
    GlobalSettings,
    NoConnection,
    Message,
  },
  props: {
    openModal: String,
  },
  data() {
    return {
      showModalReturnApp: false,
      data: {},
      globalTypeEnum: globalType,
      showModalReset: false,
      modalShowGlobal: false,
      modalShowGistra: false,
      modalShowAlesis: false,
      modalShowGlobalSettings: false,
      modalShowBigOsSettings: false,
      btnShowGlobal: false,
      btnShowGistra: false,
      btnShowAlesis: false,

      errorCountryCode: false,
      errorConnectionIP: false,
      errorDatabaseIP: false,

      errorServerIP: false,
      errorLogin: false,
      errorPort: false,
      errorPasswordGistra: false,

      errorGlobalConnectionIP: false,
      errorGlobalGlobalConnectionIP: false,

      errorDbIP: false,
      errorVendorID: false,
      errorUserSQL: false,
      errorDatabase: false,
      errorSqlPassword: false,
      errorConfirmPassword: false,
      errorProvider: false,
      errorCheckTime: false,
      errorCheckTimeTerminals: false,
      errorBigosAddress: false,

      countrys: [],
      optionsProvider: [
        { text: "Alesis", value: 0 },
        { text: "WinSystem", value: 1 },
        { text: "GGS", value: 2 },
      ],
      iniFile: {
        countryCode: 0,
        connectionIP: "",
        databaseIP: "",
        runSpmaster: false,
        runAtWindowsStartup: false,
        global: {
          isGlobal: false,
          Mode: 0,
          ConnectionIP: "",
          GlobalConnectionIP: "",
          globalIP: "",
        },
        gistra: {},
        alesis: {
          DbIP: "",
          VendorID: "",
          UserSQL: "",
          Password: "",
          ConfirmPassword: "",
          Database: "",
          Provider: 0,
        },
        BigOsSettings: {
          BigosAddress: "",
          CheckTime: 120,
          CheckAutomaticConfig: false,
          CheckAutomaticTerminals: false,
          CheckTimeTerminals: 120,
        },
      },
      globalBackup: {},
      gistraBackup: {},
      alesisBackup: {},
      bigosBackup: {},
      cmd: "",
      showLoadingCMD: false,
      sendingCMDMessage: "",
      countDown: 15,
      obj: {},
      alesisMessage: "",
      alesisValidated: false,
      showKeyboardIP: false,
      showKeyboardGlobalProg: false,
      showKeyboardGlobalPref: false,
      showKeyboardS2S: false,
      showKeyboardGistra: false,
      showKeyboardBigos: false,
      inputKeyboard: "",
      inputType: "",
      isNumeric: false,
      hasDot: false,
    };
  },

  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    onChange(input) {
      try {
        if (this.showKeyboardIP) {
          this.iniFile.connectionIP = input;
        } else if (this.showKeyboardGlobalProg) {
          this.iniFile.global.ConnectionIP = input;
        } else if (this.showKeyboardGlobalPref) {
          this.iniFile.global.GlobalConnectionIP = input;
        } else if (this.showKeyboardS2S) {
          switch (this.inputType) {
            case "preferencialIp":
              this.iniFile.alesis.DbIP = input;
              break;
            case "vendorID":
              this.iniFile.alesis.VendorID = input;
              break;
            case "userSQL":
              this.iniFile.alesis.UserSQL = input;
              break;
            case "databaseName":
              this.iniFile.alesis.Database = input;
              break;
            case "password":
              this.iniFile.alesis.Password = input;
              break;
            case "confirmPassword":
              this.iniFile.alesis.ConfirmPassword = input;
              break;
            default:
              break;
          }
        } else if (this.showKeyboardGistra) {
          switch (this.inputType) {
            case "serverIp":
              this.iniFile.gistra.ServerIP = input;
              break;
            case "login":
              this.iniFile.gistra.Login = input;
              break;
            case "port":
              this.iniFile.gistra.Port = input;
              break;
            case "password":
              this.iniFile.gistra.Password = input;
              break;
          }
        } else if (this.showKeyboardBigos) {
          switch (this.inputType) {
            case "bigosAddress":
              this.iniFile.BigOsSettings.BigosAddress = input;
              break;
            case "checkTime":
              this.iniFile.BigOsSettings.CheckTime = input;
              break;
            case "checkTimeTerminals":
              this.iniFile.BigOsSettings.CheckTimeTerminals = input;
              break;
          }
        }
      } catch (e) {
        console.log("Error in onChange IniFile " + e);
      }
    },
    onKeyPress(button) {
      console.log("button", button);
      try {
        if (button === "{clear}") {
          if (this.showKeyboardIP) {
            this.iniFile.connectionIP = "";
          } else if (this.showKeyboardGlobalProg) {
            this.iniFile.global.ConnectionIP = "";
          } else if (this.showKeyboardGlobalPref) {
            this.iniFile.global.GlobalConnectionIP = "";
          } else if (this.showKeyboardS2S) {
            switch (this.inputType) {
              case "preferencialIp":
                this.iniFile.alesis.DbIP = "";
                break;
              case "vendorID":
                this.iniFile.alesis.VendorID = "";
                break;
              case "userSQL":
                this.iniFile.alesis.UserSQL = "";
                break;
              case "databaseName":
                this.iniFile.alesis.Database = "";
                break;
              case "password":
                this.iniFile.alesis.Password = "";
                break;
              case "confirmPassword":
                this.iniFile.alesis.ConfirmPassword = "";
                break;
              default:
                break;
            }
          } else if (this.showKeyboardGistra) {
            switch (this.inputType) {
              case "serverIp":
                this.iniFile.gistra.ServerIP = "";
                break;
              case "login":
                this.iniFile.gistra.Login = "";
                break;
              case "port":
                this.iniFile.gistra.Port = "";
                break;
              case "password":
                this.iniFile.gistra.Password = "";
                break;
            }
          } else if (this.showKeyboardBigos) {
            switch (this.inputType) {
              case "bigosAddress":
                this.iniFile.BigOsSettings.BigosAddress = "";
                break;
              case "checkTime":
                this.iniFile.BigOsSettings.CheckTime = "";
                break;
              case "checkTimeTerminals":
                this.iniFile.BigOsSettings.CheckTimeTerminals = "";
                break;
            }
          }
        }
      } catch (e) {
        console.log("Error in onKeyPress IniFile " + e);
      }
    },
    onInputChange(input) {
      try {
        if (this.showKeyboardIP) {
          this.iniFile.connectionIP = input;
        } else if (this.showKeyboardGlobalProg) {
          this.iniFile.global.ConnectionIP = input;
        } else if (this.showKeyboardGlobalPref) {
          this.iniFile.global.GlobalConnectionIP = input;
        } else if (this.showKeyboardS2S) {
          switch (this.inputType) {
            case "preferencialIp":
              this.iniFile.alesis.DbIP = input;
              break;
            case "vendorID":
              this.iniFile.alesis.VendorID = input;
              break;
            case "userSQL":
              this.iniFile.alesis.UserSQL = input;
              break;
            case "databaseName":
              this.iniFile.alesis.Database = input;
              break;
            case "password":
              this.iniFile.alesis.Password = input;
              break;
            case "confirmPassword":
              this.iniFile.alesis.ConfirmPassword = input;
              break;
            default:
              break;
          }
        } else if (this.showKeyboardGistra) {
          switch (this.inputType) {
            case "serverIp":
              this.iniFile.gistra.ServerIP = input;
              break;
            case "login":
              this.iniFile.gistra.Login = input;
              break;
            case "port":
              this.iniFile.gistra.Port = input;
              break;
            case "password":
              this.iniFile.gistra.Password = input;
              break;
          }
        } else if (this.showKeyboardBigos) {
          switch (this.inputType) {
            case "bigosAddress":
              this.iniFile.BigOsSettings.BigosAddress = input;
              break;
            case "checkTime":
              this.iniFile.BigOsSettings.CheckTime = input;
              break;
            case "checkTimeTerminals":
              this.iniFile.BigOsSettings.CheckTimeTerminals = input;
              break;
          }
        }
      } catch (e) {
        console.log("Error in onInputChange " + e);
      }
    },
    changeModal(value) {
      this.btnShowGistra = false;
      this.btnShowGlobal = false;
      this.btnShowAlesis = false;
      if (value == 1 || value == 4) {
        this.btnShowGistra = true;
      } else if (
        value == 2 &&
        this.globalType == this.globalTypeEnum.ClientOnly
      ) {
        //this.btnShowGlobal = true;
      } else if (value == 3) {
        this.btnShowAlesis = this.showS2S;
      }
      this.errorCountryCode = false;
    },

    closeKeyboard() {
      this.showKeyboardIP = false;
      this.showKeyboardGlobalProg = false;
      this.showKeyboardGlobalPref = false;
      this.showKeyboardS2S = false;
      this.showKeyboardGistra = false;
      this.showKeyboardBigos = false;
      this.inputKeyboard = "";
      this.inputType = "";
    },

    alertGlobal() {
      this.$buefy.dialog.alert({
        title: "Global Progressive Modes",
        message:
          "<b>- Client Only</b><br/> The Server works normaly and wait for an external global progressive server Connection <br/> " +
          "<b>- Server Only</b><br/> The system will act as global progressive server only, without any other features <br/> " +
          "<b>- Client and Server</b><br/> The Server works with all features with global progressive server embedded",
        type: "is-info",
        hasIcon: true,

        icon: "info",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },

    openModalBigOsSettings() {
      this.modalShowBigOsSettings = true;
      this.closeKeyboard();
      this.restoreBigOsSettings(this.bigosBackup, this.iniFile.BigOsSettings);
    },
    closeModalBigOsSettigs() {
      this.restoreErrorsBigOsSettings();
      if (this.openModal == "bigOsSettings") {
        this.$emit("close");
      } else {
        this.modalShowBigOsSettings = false;
        this.restoreBigOsSettings(this.iniFile.BigOsSettings, this.bigosBackup);
        this.closeKeyboard();
      }
    },

    restoreBigosSettings(backup, newFile) {
      backup.BigosAddress = newFile.BigosAddress;
      backup.CheckTime = newFile.CheckTime;
      backup.CheckAutomaticConfig = newFile.CheckAutomaticConfig;
      backup.CheckAutomaticTerminals = newFile.CheckAutomaticTerminals;
      backup.CheckTimeTerminals = newFile.CheckTimeTerminals;
    },

    saveBigOsSettings() {
      this.errorCheckTime = false;
      this.errorCheckTimeTerminals = false;
      let error = false
      if (this.iniFile.BigOsSettings.CheckAutomaticConfig && this.iniFile.BigOsSettings.CheckTime <= 0) {
        error = true
        this.errorCheckTime = true;
      }
      if (this.iniFile.BigOsSettings.CheckAutomaticTerminals && this.iniFile.BigOsSettings.CheckTimeTerminals <= 0) {
        error = true
        this.errorCheckTimeTerminals = true
      }
      if (!error) {
        if (this.openModal != "bigOsSettings") {
          this.modalShowBigOsSettings = false;
        } else {
          this.modalShowBigOsSettings = false;
          this.closeKeyboard();
          this.$emit(
            "ActionAfterSaveBigOsSettings",
            this.iniFile.BigOsSettings
          );
          this.$emit("close");
        }
      }
    },

    restoreErrorsBigOsSettings() {
      this.errorCheckTime = false;
      this.errorBigosAddress = false;
    },

    openModalGlobal() {
      this.restoreGlobal(this.globalBackup, this.iniFile.global);
      this.closeKeyboard();
      this.modalShowGlobal = true;
    },
    closeModalGlobal() {
      this.restoreErrorsGlobal();
      this.restoreGlobal(this.iniFile.global, this.globalBackup);
      this.closeKeyboard();
      this.modalShowGlobal = false;
    },
    restoreGlobal(backup, newFile) {
      //
      backup.isGlobal = newFile.isGlobal;
      backup.Mode = newFile.Mode;
      backup.ConnectionIP = newFile.ConnectionIP;
      backup.isGlobalIP = newFile.isGlobalIP;
      backup.GlobalConnectionIP = newFile.GlobalConnectionIP;
    },

    restoreErrorsGlobal() {
      this.errorGlobalConnectionIP = false;
      this.errorGlobalGlobalConnectionIP = false;
    },

    saveGlobal() {
      this.restoreErrorsGlobal();

      let hasError = false;
      if (this.iniFile.global.isGlobal) {
        if (this.iniFile.global.Mode == 0) {
          if (validateField.regexIP(this.iniFile.global.ConnectionIP)) {
            hasError = true;
            this.errorGlobalConnectionIP = true;
          }
          if (this.iniFile.global.isGlobalIP) {
            if (validateField.regexIP(this.iniFile.global.GlobalConnectionIP)) {
              hasError = true;
              this.errorGlobalGlobalConnectionIP = true;
            }
          }
        }
      }

      if (hasError) {
        return;
      }

      this.modalShowGlobal = false;
      this.closeKeyboard();
    },

    openModalGistra() {
      if (this.iniFile.gistra == {}) {
        this.iniFile.gistra = this.gistraDefault;
        //
      }
      try {
        this.modalShowGistra = true;
        this.restoreGistra(this.gistraBackup, this.iniFile.gistra);
        this.closeKeyboard();
      } catch (e) {
        alert(e.message);
      }
    },

    restoreErrorsGistra() {
      this.errorServerIP = false;
      this.errorLogin = false;
      this.errorPort = false;
      this.errorPasswordGistra = false;
    },

    saveGistra() {
      this.restoreErrorsGistra();

      let hasError = false;

      if (this.iniFile.gistra.ServerIP == "") {
        this.errorServerIP = true;
        hasError = true;
      }
      if (this.iniFile.gistra.Login == "") {
        this.errorLogin = true;
        hasError = true;
      }

      if (this.iniFile.gistra.Port == "") {
        this.errorPort = true;
        hasError = true;
      }

      if (this.iniFile.gistra.Password.length < 4) {
        this.errorPasswordGistra = true;
        hasError = true;
      }

      if (hasError) {
        return;
      }

      this.modalShowGistra = false;
      this.closeKeyboard();
    },
    closeModalGistra() {
      this.restoreErrorsGistra();
      this.restoreGistra(this.iniFile.gistra, this.gistraBackup);
      this.modalShowGistra = false;
      this.closeKeyboard();
    },
    restoreGistra(backup, newFile) {
      //

      try {
        backup.serverIP = newFile.serverIP;
        backup.login = newFile.login;
        backup.password = newFile.password;
        backup.port = newFile.port;
        backup.ssl = newFile.ssl;
      } catch (e) {
        alert(e.message);
      }
    },

    openModalAlesis() {
      try {
        this.modalShowAlesis = true;
        this.restoreAlesis(this.alesisBackup, this.iniFile.alesis);
        this.closeKeyboard();
      } catch (e) {
        alert(e.message);
      }
    },

    restoreErrosAlesis() {
      this.errorDbIP = false;
      this.errorVendorID = false;
      this.errorUserSQL = false;
      this.errorSqldDatabaseName = false;
      this.errorSqlPassword = false;
      this.errorConfirmPassword = false;
      this.errorProvider = false;
      this.errorDatabase = false;
    },

    saveAlesis() {
      this.restoreErrosAlesis();
      let hasError = false;

      if (this.iniFile.alesis.DbIP == "") {
        this.errorDbIP = true;
        hasError = true;
      }
      if (this.iniFile.alesis.VendorID == "") {
        this.errorVendorID = true;
        hasError = true;
      }

      if (this.iniFile.alesis.UserSQL == "") {
        this.errorUserSQL = true;
        hasError = true;
      }
      if (this.iniFile.alesis.Database == "") {
        this.errorDatabase = true;
        hasError = true;
      }
      if (this.iniFile.alesis.Password.length < 4) {
        this.errorSqlPassword = true;
        hasError = true;
      }

      if (this.iniFile.alesis.Password != this.iniFile.alesis.ConfirmPassword) {
        hasError = true;
        this.errorConfirmPassword = true;
      }

      if (this.iniFile.alesis.Provider === false) {
        this.errorProvider = true;
        hasError = true;
      }

      if (hasError) {
        return;
      }
      this.alesisValidated = false;
      this.modalShowAlesis = false;
      this.closeKeyboard();
      this.iniFile.S2S = this.iniFile.alesis;
      this.sendCMD(
        clientCommands.ValidateS2S.name,
        this.translate("messageCreatedIniFile"),
        this.iniFile.alesis,
        clientCommands.ValidateS2S.time
      );
    },
    closeModalAlesis() {
      this.restoreErrosAlesis();
      this.restoreAlesis(this.iniFile.alesis, this.alesisBackup);
      this.modalShowAlesis = false;
      this.closeKeyboard();
    },
    restoreAlesis(backup, newFile) {
      //
      try {
        backup.DbIP = newFile.DbIP;
        backup.UserSQL = newFile.UserSQL;
        backup.VendorID = newFile.VendorID;
        backup.Password = newFile.Password;
        backup.Database = newFile.Database;
        backup.Provider = newFile.Provider;
        backup.ConfirmPassword = newFile.ConfirmPassword;
      } catch (e) {
        alert(e.message);
      }
    },

    restoreErrors() {
      this.errorCountryCode = false;
      this.errorConnectionIP = false;
      this.errorDatabaseIP = false;
    },

    sendIni() {
      try {
        this.restoreErrors();
        let hasError = false;
        if (this.iniFile.countryCode == 0) {
          this.errorCountryCode = true;
          hasError = true;
        }

        if (this.iniFile.connectionIP == "") {
          hasError = true;
          this.errorConnectionIP = true;
        }

        if (hasError) {
          return;
        }
        if (
          this.iniFile.global.GlobalConnectionIP == "" &&
          this.iniFile.global.isGlobal
        ) {
          this.iniFile.global.GlobalConnectionIP = this.iniFile.connectionIP;
        }

        if (
          this.iniFile.countryCode == countries.Spain ||
          this.iniFile.countryCode == countries.Norway ||
          this.iniFile.countryCode == countries.Sweden
        ) {
          this.iniFile.global = {};
          this.iniFile.alesis = {};
        } else if (this.iniFile.countryCode == countries.Philippines) {
          this.iniFile.gistra = {};
          this.iniFile.alesis = {};
        } else if (this.iniFile.countryCode == countries.Mexico) {
          this.iniFile.global = {};
          this.iniFile.gistra = {};
        }

        console.log("Send /Ini");
        this.sendCMD(
          clientCommands.CreateIni.name,
          this.translate("messageCreatedIniFile"),
          this.iniFile,
          clientCommands.CreateIni.time
        );
        /*await axios
        .post(`${baseApiUrl}/api/configuration/createIni`, this.iniFile)
        .then((resp) => {
          
          notify.message("foo", "Success", resp.data.message, "success", 2000);

          return;
        })
        .catch((e) => {
          if (e.response.data != null) {
            notify.message(
              "foo",
              "Error",
              e.response.data.message,
              "error",
              2000
            );
          } else {
            notify.message(
              "foo",
              "Error",
              e.response.data.message,
              "error",
              2000
            );
          }
        });
      */
        //this.validatingToken = false;
      } catch (e) {
        console.log("Error in sendIni() " + e);
      }
    },
    sendCMD(cmd, message, obj, countDown) {
      this.cmd = cmd;
      this.sendingCMDMessage = message;
      this.obj = obj;
      this.countDown = countDown;
      this.showLoadingCMD = true;
    },
    afterCMDCreateIni(obj) {
      switch (this.cmd) {
        case clientCommands.CreateIni.name:
          if (obj.restartRequired) {
            this.countDown = obj.timeToRestart + 5;
            if (this.countDown <= 0) {
              returnApp(this);
            } else {
              this.showModalReset = true;
              this.finishConfig();
            }
          } else {
            //
          }
          //this.user.countryCode = this.iniFile.countryCode;
          //this.$store.commit("setUser", this.user);
          //this.$router.push({ name: "Home" });
          break;
        case clientCommands.ValidateS2S.name:
          if (obj.ok) {
            if (obj.restartRequired && this.errorS2S) {
              this.countDown = obj.timeToRestart + 5;
              if (this.countDown <= 0) {
                returnApp(this);
              } else {
                this.showModalReset = true;
                this.finishConfig();
              }
            } else {
              this.modalShowAlesis = false;
              this.alesisValidated = true;
            }
          } else {
            this.modalShowAlesis = true;
            this.alesisMessage = obj.message;
          }
          break;
        default:
          break;
      }
    },
    finishConfig: function () {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          if (this.countDown == 0) {
            returnApp(this);
          } else {
            this.finishConfig();
          }
        }, 1000);
      }
    },
    cancelIni() {
      this.$buefy.dialog.confirm({
        title: "Ini File",
        message: this.translate("messageNeedsIPConfig"),
        confirmText: this.translate("btnCancel"),
        cancelText: this.translate("btnBack"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.closeServer(),
      });
    },
    closeServer() {
      if (this.errorS2S) {
        this.$emit("close");
      } else {
        closeWindow();
      }
    },
    validateBtnSaveIni() {
      //
      if (this.btnShowAlesis) {
        return !this.alesisValidated;
      } else {
        return false;
      }
    },
    forcedDisconnection(data) {
      if (data != undefined) {
        this.showModalReturnApp = true;
        this.data.CMD = clientCommands.ForcedDisconnection.name;
        this.data = data;
      }
    },
    showBtns() {
      if (this.isElectronVar && !this.isWindows) {
        return false;
      }
      return true;
    },
  },
  created() {

    countryList.forEach(country => {
      if (country.id != countries.DingoPHL) {
        this.countrys.push(country)
      }
    });

    this.iniFile.countryCode = countries.Philippines
    this.iniFile.gistra = this.gistraDefault;
    if (this.errorS2S) {
      this.modalShowAlesis = true;
    }
    if (this.errorGlobalClient) {
      this.modalShowGlobalSettings = true;
    } else {
      this.modalShowGlobalSettings = false;
    }
    switch (this.openModal) {
      case "bigOsSettings":
        this.modalShowBigOsSettings = true;
        break;
      default:
        this.$store.commit("setLang", "en");
        break;
    }
    Socket.$on("forcedDisconnectionCMD", this.forcedDisconnection);
  },
  watch: {
    showKeyboardGlobalProg(value) {
      if (value) {
        this.showKeyboardGlobalPref = false;
      }
    },
    showKeyboardGlobalPref(value) {
      if (value) {
        this.showKeyboardGlobalProg = false;
      }
    },
    inputKeyboard() {
      //
    },
  },
};
</script>

<style scoped src="@/css/styleModules.css">
#button {
  position: relative;
  float: right;
}

.modal-active {
  display: block;
}
</style>
