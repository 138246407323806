import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./config/axios.js";
import "./config/bootstrap.js";
import "./config/buefy.js";
import "./config/clipboard2.js";
import "./config/msgs.js";
import "./config/msgsPrimeVue.js";
import "./config/moment.js";
import "./config/notify.js";
import "./config/mouseTrap.js";
import "./config/webSocket.js";

import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
