<template>
  <div class="email">
    <div class="container-fluid">
      <div class="animated fadeIn">
        <div class="card" style="height: 100%; overflow-y: auto; width: 100%">
          <div class="card-body">
            <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog"
              aria-modal :can-cancel="false">
              <template #default="props">
                <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
                  @ActionSend="actionAfterEmail" :JsonObject="obj">
                </LoadingCMD>
              </template>
            </b-modal>
            <div class="topnav col mb-2">
              <a href @click.prevent="showData()" :title="translate('btnAccountConfig')">
                <span class="icons-stack" style="bottom: 5px">
                  <i class="icons-email icons-stack-1x" style="font-size: 1.95rem"></i>
                  <i class="icons-white icons-xs icons icons-cog icons-stack-1x" style="left: 7px; top: 5px"></i>
                </span>
              </a>
              <a href @click.prevent="sendFilesNow()" :title="translate('btnSendFilesNow')"
                :class="{ disabled: email.Mails.length == 0 }">
                <i class="icons-1x icons-email-send" style="left: -8px"></i>
              </a>
              <a href @click.prevent="showFileConfig()" :title="translate('btnSizeConfig')"
                :class="{ disabled: email.Mails.length == 0 }">
                <i class="icons-1x icons-cog" style="left: -8px"></i>
              </a>
            </div>
            <b-field grouped>
              <div class="control">
                <b-button class="button" icon-left="icons-1x icons-sync" icon-pack="icons" v-if="false"
                  @click="(filterEmail = ''), filterTableEmail()">
                  <span>{{ translate("btnRefresh") }}</span>
                </b-button>
              </div>
              <b-input :placeholder="translate('lblSearchEmail')" expanded id="lblSearchEmail" type="search"
                icon="magnify" @icon-right-click="filterEmail = ''" icon-right="close-circle" icon-right-clickable 
                v-model="filterEmail" />
            </b-field>
            <div class="data" name="divTable" id="divTable">
              <b-table :data="email.Mails" ref="table" :paginated="true" :per-page="itensPerPage"
                pagination-position="top" :pagination-simple="true" :pagination-rounded="true" aria-next-label="Next page"
                aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                :striped="true" custom-row-key="Name" default-sort="name">
                <template #empty>
                  <div class="has-text-centered">
                    <h1>
                      <span class="tag is-danger">{{
                        translate("lblNoDataFound")
                      }}</span>
                    </h1>
                  </div>
                </template>
                <b-table-column field="name" :label="translate('columnName')" centered width="200" v-slot="props"
                  class="is-sticky-column-one" header-class="is-sticky-column-one">
                  {{ props.row.Name }}
                </b-table-column>

                <b-table-column field="Email" :label="translate('columnEmail')" centered v-slot="props"
                  header-class="is-sticky-column-one">
                  {{ props.row.Email }}
                </b-table-column>
                <b-table-column field="actions" :label="translate('columnActions')" width="300" centered v-slot="props"
                  header-class="is-sticky-column-one">
                  <b-button type="is-info is-light" @click="(showModalReport = true), (selected = props.row)">
                    <i class="icons-lg1 icons-eye"></i>
                  </b-button>
                </b-table-column>
              </b-table>              
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- modal Report List -->
    <b-modal v-model="showModalReport" width="900px" trap-focus :destroy-on-hide="true" :can-cancel="false"
      aria-role="dialog" aria-modal>
      <template>
        <form action="" style="overflow-y: hidden">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">
                {{ translate("reportListTitle") }} - {{ selected.Name }}
              </p>
              <button type="button" class="delete" @click="showModalReport = false" />
            </header>
            <section class="modal-card-body">
              <b-table class="has-background-color-dark" :data="selected.ReportEmail" :paginated="true"
                pagination-position="top" :pagination-simple="true" :pagination-rounded="true" aria-next-label="Next page"
                aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page">
                <template #empty>
                  <div class="has-text-centered">
                    <h1>
                      <span class="tag is-danger">{{
                        translate("lblNoDataFound")
                      }}</span>
                    </h1>
                  </div>
                </template>
                <b-table-column field="field" :label="translate('columnName')" v-slot="props"
                  header-class="is-sticky-column-one" width="200" centered>
                  {{ props.row.Name }}
                </b-table-column>
              </b-table>
            </section>
          </div>
        </form>
      </template>
    </b-modal>
    <!-- fim Modal Report -->

    <!-- modal Account Config -->
    <b-modal v-model="showModalAccountConfig" width="900px" trap-focus :destroy-on-hide="true" :can-cancel="false"
      aria-role="dialog" aria-modal>
      <template>
        <form action="" style="overflow-y: hidden">
          <div class="modal-card" style="width: 100%; height: 100%">
            <header class="modal-card-head">
              <p class="modal-card-title">
                {{ translate("emailConfigTitle") }}
              </p>
              <button type="button" class="delete" @click="showModalAccountConfig = false" />
            </header>
            <div v-if="!showEmailError">
              <section class="modal-card-body">
                <b-modal v-model="showLoadingModalAccountCMD" width="auto" trap-focus :destroy-on-hide="true"
                  aria-role="dialog" aria-modal :can-cancel="false">
                  <template #default="props">
                    <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage"
                      :countDown="countDown" @ActionAfterEmail="actionAfterEmail()">
                    </LoadingCMD>
                  </template>
                </b-modal>
                <b-tabs type="is-toggle-rounded" position="is-centered" class="block">
                  <b-tab-item :label="translate('account')" icon-pack="icons" icon="icons-lg1-noeffect icons-at">
                    <b-table :data="emailObj.data" ref="table" :paginated="true" :per-page="20" pagination-position="top"
                      :pagination-simple="true" :pagination-rounded="true" aria-next-label="Next page"
                      aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page" striped
                      custom-row-key="id">
                      <template #empty>
                        <div class="has-text-centered">
                          <h1>
                            <span class="tag is-danger">{{
                              translate("lblNoDataFound")
                            }}</span>
                          </h1>
                        </div>
                      </template>
                      <b-table-column field="field" :label="translate('columnField')" v-slot="props"
                        header-class="is-sticky-column-one">
                        {{ props.row.field }}
                      </b-table-column>
                      <b-table-column field="Value" centered :label="translate('columnValue')" v-slot="props"
                        header-class="is-sticky-column-one">
                        <span :class="[
                          'tag',
                          { 'is-success': props.row.value === true },
                          { 'is-danger': props.row.value === false },
                        ]" v-if="props.row.type === 'checkbox'">
                          <b-icon icon="check" v-if="props.row.value == true"></b-icon>
                          <b-icon icon="close" v-else></b-icon>
                          <span>{{
                            props.row.value === true
                            ? translate("btnYes")
                            : translate("btnNo")
                          }}</span>
                        </span>
                        <span v-if="props.row.type === 'string'">
                          {{ props.row.value }}
                        </span>
                      </b-table-column>
                    </b-table>
                    <br />
                    <footer class="card-footer" style="height: 50px">
                      <div class="col-lg-12">
                        <b-button class="is-info" expanded @click="testSend" icon-left=".icons-white icons-email-sync"
                          icon-pack="icons">
                          {{ translate("btnTestSend") }}
                        </b-button>
                      </div>
                    </footer>
                  </b-tab-item>
                  <b-tab-item :label="translate('tabOthers')" icon-pack="icons" icon="icons-lg3-noeffect icons-cog">
                    <b-table :data="emailObj.others" ref="table" :paginated="true" :per-page="20"
                      pagination-position="top" :pagination-simple="true" :pagination-rounded="true"
                      aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                      aria-current-label="Current page" striped custom-row-key="id">
                      <template #empty>
                        <div class="has-text-centered">
                          <h1>
                            <span class="tag is-danger">{{
                              translate("lblNoDataFound")
                            }}</span>
                          </h1>
                        </div>
                      </template>
                      <b-table-column field="field" :label="translate('columnField')" v-slot="props"
                        header-class="is-sticky-column-one">
                        {{ props.row.field }}
                      </b-table-column>
                      <b-table-column field="Value" centered :label="translate('columnValue')" v-slot="props"
                        header-class="is-sticky-column-one">
                        <span :class="[
                          'tag',
                          { 'is-success': props.row.value === true },
                          { 'is-danger': props.row.value === false },
                        ]" v-if="props.row.type === 'checkbox'">
                          <b-icon icon="check" v-if="props.row.value == true"></b-icon>
                          <b-icon icon="close" v-else></b-icon>
                          <span>{{
                            props.row.value === true
                            ? translate("btnYes")
                            : translate("btnNo")
                          }}</span>
                        </span>
                        <span v-if="props.row.type === 'string'">
                          {{ props.row.value }}
                        </span>
                      </b-table-column>
                    </b-table>
                  </b-tab-item>
                  <b-tab-item :label="translate('tabRetry')" icon-pack="icons"
                    icon="icons-lg1-noeffect icons-share-square-solid">
                    <b-table :data="emailObj.retry" ref="table" :paginated="true" :per-page="20" pagination-position="top"
                      :pagination-simple="true" :pagination-rounded="true" aria-next-label="Next page"
                      aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page" striped
                      custom-row-key="id">
                      <template #empty>
                        <div class="has-text-centered">
                          <h1>
                            <span class="tag is-danger">{{
                              translate("lblNoDataFound")
                            }}</span>
                          </h1>
                        </div>
                      </template>
                      <b-table-column field="field" :label="translate('columnField')" v-slot="props"
                        header-class="is-sticky-column-one">
                        {{ props.row.field }}
                      </b-table-column>
                      <b-table-column field="Value" centered :label="translate('columnValue')" v-slot="props"
                        header-class="is-sticky-column-one">
                        <span :class="[
                          'tag',
                          { 'is-success': props.row.value === true },
                          { 'is-danger': props.row.value === false },
                        ]" v-if="props.row.type === 'checkbox'">
                          <b-icon icon="check" v-if="props.row.value == true"></b-icon>
                          <b-icon icon="close" v-else></b-icon>
                          <span>{{
                            props.row.value === true
                            ? translate("btnYes")
                            : translate("btnNo")
                          }}</span>
                        </span>
                        <span v-if="props.row.type === 'string'">
                          {{ props.row.value }}
                        </span>
                      </b-table-column>
                    </b-table>
                  </b-tab-item>
                </b-tabs>
              </section>
            </div>
            <div v-else>
              <section class="modal-card-body">
                <Message type="is-danger" :message="errorMsg" :isList="false" />
              </section>
            </div>
          </div>
        </form>
      </template>
    </b-modal>
    <!-- fim Modal Report -->

    <!-- Modal File Size -->
    <b-modal v-model="showModalFileSizeConfig" has-modal-card trap-focus :destroy-on-hide="true" :can-cancel="false"
      aria-role="dialog" aria-modal>
      <template>
        <form action="" style="overflow-y: hidden">
          <div class="modal-card" style="width: auto" trap-focus :destroy-on-hide="true">
            <header class="modal-card-head">
              <p class="modal-card-title">Config</p>
              <button type="button" class="delete" @click="showModalFileSizeConfig = false" />
            </header>
            <section class="modal-card-body">
              <b-field label="Max File Size (MB)">
                <b-numberinput min="1" max="6" v-model="maxFileSize" :value=dataModule.Email.MaxFileZipEmail>
                </b-numberinput>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <b-button label="Cancel" type="is-warning" @click="showModalFileSizeConfig = false" />
              <b-button label="Save" @click="setMaxFileSize(maxFileSize)" type="is-primary" />
            </footer>
          </div>
        </form>
      </template>
    </b-modal>
    <b-modal v-model="fileLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
      :can-cancel="false">
      <template #default="props">
        <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
          @ActionAfterEmail="actionAfterEmail()" :JsonObject="obj">
        </LoadingCMD>
      </template>
    </b-modal>
    <!-- fim Modal File Size -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { clientCommands } from "@/js/ClientCommands.js";
import Message from "@/components/message/Message.vue";
import LoadingCMD from "@/components/processCMD/LoadingCMD.vue";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import "@/css/icons.css";
export default {
  rate: 4.6,
  name: "Email",
  mixins: [en, es, nor, swe],
  computed: mapState([
    "session",
    "communication",
    "dataModule",
    "currentTab",
    "hasControl",
    "resetToken",
    "userLogged",
    "lang",
  ]),
  components: {
    LoadingCMD,
    Message
  },
  data() {
    return {
      email: {
        Mails: [],
      },
      emailObj: {
        data: []
      },
      accountConfig: {},
      retryConfig: {},
      selected: {},
      showModalReport: false,
      showModalAccountConfig: false,
      showModalFileSizeConfig: false,
      showEmailError: false,
      errorMsg: "",
      filterEmail: "",
      showLoadingCMD: false,
      showLoadingModalAccountCMD: false,
      cmd: "",
      countDown: 60,
      route: "",
      sendingCMDMessage: "",
      itensPerPage: 15,
      intervalGetDatas: null,
      obj: {},
      maxFileSize: 5,
      fileLoadingCMD: false,

    };
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },

    showFileConfig() {
      try {
        this.showModalFileSizeConfig = true;        
        this.maxFileSize = this.dataModule.Email.MaxFileZipEmail
      } catch (e) {
        console.log("Error in showFileConfig() " + e);
        this.showEmailError = true;
        this.errorMsg = this.translate("msgErrorEmail");
      }
    },


    showData() {
      try {

        this.showModalAccountConfig = true;
        if (
          this.dataModule.Email == null ||
          this.dataModule.Email.Mails == undefined ||
          this.dataModule.Email.Mails.length === 0
        ) {
          this.showEmailError = true;
          this.errorMsg = this.translate("msgErrorEmail");
          return;
        }
        this.emailObj.data = [];
        this.emailObj.others = [];
        this.emailObj.retry = [];
        this.emailObj = {
          data: [
            {
              field: this.translate("account"),
              value: this.dataModule.Email.EmailAccount,
              type: "string",
            },
            {
              field: this.translate("SMTPServer"),
              value: this.dataModule.Email.SmtpServer,
              type: "string",
            },
            {
              field: this.translate("SMTPAuthenticate"),
              value: this.dataModule.Email.SmtpAuthenticate,
              type: "string",
            },
            {
              field: this.translate("SMTPPort"),
              value: this.dataModule.Email.SmtpServerPort,
              type: "string",
            },
            {
              field: this.translate("useStartTLS"),
              value: this.dataModule.Email.SmtpSsl,
              type: "checkbox",
            },
            {
              field: this.translate("SMTPTimeout"),
              value: this.dataModule.Email.SmtpTimeoutSec + " (Seconds)",
              type: "string",
            },
          ],
          others: [
            {
              field: this.translate("automaticEmailSender"),
              value: this.dataModule.Email.SendEmailType,
              type: "checkbox",
            },
            {
              field: this.translate("mobileInternet"),
              value: this.dataModule.Email.MobileInternet,
              type: "checkbox",
            },
            {
              field: this.translate("serialPortName"),
              value: this.dataModule.Email.SerialPort,
              type: "string",
            },
            {
              field: this.translate("commDialUpName"),
              value: this.dataModule.Email.DialUp,
              type: "string",
            },
          ],
          retry: [
            {
              field:
                this.translate("firstRetry") +
                " (" +
                this.translate("minutes") +
                ")",
              value: this.dataModule.Email.Min1stAttempt,
              type: "string",
            },
            {
              field:
                this.translate("secondRetry") +
                " (" +
                this.translate("minutes") +
                ")",
              value: this.dataModule.Email.Min2ndAttempt,
              type: "string",
            },
            {
              field:
                this.translate("thirdRetry") +
                " (" +
                this.translate("minutes") +
                ")",
              value: this.dataModule.Email.Min3rdAttempt,
              type: "string",
            },
            {
              field:
                this.translate("subsequentRetry") +
                " (" +
                this.translate("minutes") +
                ")",
              value: this.dataModule.Email.MinSubAttempt,
              type: "string",
            },
            {
              field: this.translate("delayNotification"),
              value:
                this.dataModule.Email.DelayNotification +
                " - " +
                (this.dataModule.Email.BaseDelayNotification === 0
                  ? this.translate("minutes")
                  : this.dataModule.Email.BaseDelayNotification === 1
                    ? this.translate("hours")
                    : this.translate("days")),
              type: "string",
            },
            {
              field: this.translate("expirationTimeout"),
              value:
                this.dataModule.Email.ExpirationTimeout +
                " - " +
                (this.dataModule.Email.BaseExpirationTimeout === 0
                  ? this.translate("minutes")
                  : this.dataModule.Email.BaseExpirationTimeout === 1
                    ? this.translate("hours")
                    : this.translate("days")),
              type: "string",
            },
          ],
        };
      } catch (e) {
        console.log("Error in showDataEmail() " + e);
        this.showEmailError = true;
        this.errorMsg = this.translate("msgErrorEmail");
      }
    },
    filterTableEmail() {
      this.fromFilter = true;
      this.email.Mails = [];

      this.dataModule.Email.Mails.forEach((mail) => {
        if (
          mail.Email.toLowerCase().includes(this.filterEmail.toLowerCase()) ||
          this.filterEmail == ""
        ) {
          this.email.Mails.push(mail);
        }
      });
    },
    testSend() {
      this.cmd = clientCommands.SendTestEmail.name;
      this.countDown = clientCommands.SendTestEmail.time;
      this.sendingCMDMessage = this.translate("messageTestEmail");
      this.route = "Email/SendTestEmail";
      this.showLoadingModalAccountCMD = true;
    },
    sendFilesNow() {
      this.cmd = clientCommands.SendManualEmail.name;
      this.countDown = clientCommands.SendManualEmail.time;
      this.sendingCMDMessage = this.translate("messageSendManualEmail");
      this.route = "Email/SendManualEmail";
      this.showLoadingCMD = true;
    },

    actionAfterEmail() {
      //
    },

    setMaxFileSize(maxFileSize) {      
      try {
        this.cmd = clientCommands.SetMaxFileSize.name;
        this.countDown = clientCommands.SetMaxFileSize.time;
        this.obj = maxFileSize;
        this.sendingCMDMessage = this.translate("messageSetMaxFileSize");
        this.fileLoadingCMD = true;
      } catch (e) {
        console.log("Error in setMaxFileSize()")
      }
      //},
    },
  },

  created() {

    this.intervalGetDatas = setInterval(() => {
      try {
        if (this.currentTab != "E-mail") {
          console.log("/closed emails()");
          clearInterval(this.intervalGetDatas);
          return;
        }
        const height = document.getElementById("divTable").offsetHeight;
        if (height <= 500) {
          this.itensPerPage = 5;
        } else {
          this.itensPerPage = 15;
        }
        this.email.Mails = [];
        if (this.dataModule != undefined) {          
          if (this.dataModule.Email != undefined) {
            if (this.dataModule.Email.Mails != undefined) {
              if (this.dataModule.Email.Mails != null) {
                this.email.Mails = [];
                this.dataModule.Email.Mails.forEach((mail) => {
                  if (
                    mail.Email.toLowerCase().includes(
                      this.filterEmail.toLowerCase()
                    ) ||
                    this.filterEmail == ""
                  ) {
                    this.email.Mails.push(mail);
                  }
                });
              }
            }
          }else{
            //
          }
        }
      } catch (e) {        
        console.log("Error in getEmails() " + e);
        if (this.currentTab != "E-mail") {
          console.log("/closed emails()");
          clearInterval(this.intervalGetDatas);
        }
      }
    }, 300);
    this.retryConfig = {
      data: [
        {
          field: this.translate("firstRetry"),
          value: 15,
          time: "minutes",
        },
        {
          field: this.translate("secondRetry"),
          value: 15,
          time: "minutes",
        },
        {
          field: this.translate("delayNotification"),
          value: 5,
          time: "seconds",
        },
      ],
    };
  },
  beforeDestroy() {
    console.log("/closed emails()");
    clearInterval(this.intervalGetDatas);
  },
  watch: {
    filterEmail() {
      this.filterTableEmail();
    },
  },
};    
</script>

<style scoped src="@/css/styleModules.css"></style>