<template>
    <div class="client">
        <div class="container-fluid">
            <div class="animated fadeIn">
                <div class="card">
                    <div class="card-body">
                        <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog"
                            aria-modal :can-cancel="false">
                            <template #default="props">
                                <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage"
                                    :countDown="countDown" :route="route">
                                </LoadingCMD>
                            </template>
                        </b-modal>
                        <b-field grouped>
                            <div class="col-lg-10 searchTerminal">
                                <b-input :placeholder="translate('lblSearchByName')" expanded type="search" icon="magnify"
                                    @icon-right-click="searchByName = ''" icon-right="close-circle" icon-right-clickable
                                    id="lblSearchByName" v-model="searchByName" />
                            </div>
                            <div class="col-lg-2">
                                <b-select v-if="false" v-model="searchByConnected" :placeholder="translate('columnStatus')">
                                    <option v-for="option in clientStatus" :value="option.value" :key="option.value">
                                        {{ translate(option.name) }}
                                    </option>
                                </b-select>
                                <b-dropdown aria-role="list" scrollable max-height="200" trap-focus v-model="searchByConnected">
                                    <template #trigger>
                                    <a class="navbar-item" role="button">
                                        <span v-if="searchByConnected.name == '' || searchByConnected.name == 'All'">
                                            {{ translate("lblSearchStatus") }}
                                        </span>
                                        <span v-else>
                                            {{ translate("columnStatus") }}: {{ translate(searchByConnected.name) }}
                                        </span>
                                        <b-icon icon="menu-down"></b-icon>
                                    </a>
                                    </template>
                                    <b-dropdown-item v-for="option in clientStatus" :value="option" :key="option">
                                        <span>{{ translate(option.name) }}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </b-field>
                        <div style="overflow-y: auto; height: 100% !important">
                            <div class="terminal-tab" style="
                                                  overflow-y: auto;
                                                  overflow-x: hidden;
                                                  padding-left: 14px;
                                                  font-size: 5px !important;
                                                ">
                                &nbsp;
                                <span>
                                    {{ translate("activeClients") }} [{{ activeClient }}]
                                    | {{ translate("inactiveClients") }} [{{ inactiveClient }}]
                                    | {{ translate("InFilter") }} [{{ inFilter }}]
                                </span>
                                <br />
                                <br />
                                <button class="stat-terminal" v-for="client in clients" :key="client.Name"
                                    :title="client.Name" data-toggle="tooltip" data-placement="top"
                                    style="min-width: 150px; min-height: 150px; margin-bottom: 10px; " disabled
                                    @click.prevent="processShowClient(client)" v-show="verifyNameClient(client)">

                                    <div class="stat-icon">
                                        <div class="row" style="padding-bottom: 10px;">
                                            <div>
                                                <i class="icons-lg icons-server-config" :style="getStyle(client)"></i>
                                                <br />
                                            </div>
                                        </div>
                                        <span class="stat-title-terminal" style="font-size: 11px !important">
                                            {{ client.Name }}
                                            <br />
                                            <br />
                                        </span>
                                        <span class="stat-title-terminal" style="font-size: 10px !important">
                                            <i class="icons-lg icons-calendar" style="color: #777777"></i>&nbsp;{{ client.DateUpdate }} (UTC)
                                        </span>
                                    </div>
                                    <br />
                                </button>
                            </div>
                            <div :class="'col-lg-' + (12 - 2 * ((clients.length + 1) % 4))" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { clientStatus } from "@/mixins/enums.js";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import LoadingCMD from "@/components/processCMD/LoadingCMD.vue";
export default {
    name: "Client",
    mixins: [en, es, nor, swe],
    components: { LoadingCMD },
    computed: mapState([
        "lang",
        "dataModule",
        "currentTab",
        "completeLogin"
    ]),

    data: function () {
        return {
            name: "",
            showLoadingCMD: false,
            cmd: "",
            countDown: 60,
            route: "",
            intervalGetDatas: null,
            showClientModal: false,
            searchByName: "",
            searchByConnected: { value: 0, name: "" },
            clientStatus: clientStatus,
            clients: [],
            activeClient: 0,
            inactiveClient: 0,
            inFilter: 0,
        }
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        getStyle(client) {
            return client.Connected ? "color: #339933; font-size: xxx-large;" : "color : #CCCCCC; font-size: xxx-large;";
        },
        getDatas() {
            try {
                this.intervalGetDatas = setInterval(() => {
                    if (
                        this.currentTab != "Client" ||
                        !this.completeLogin
                    ) {
                        console.log("/closed getDatas Client()");
                        clearInterval(this.intervalGetDatas);
                    }
                    this.clients = this.dataModule.Clients
                    let activeClient = 0;
                    let inactiveClient = 0;
                    let inFilter = 0;
                    this.clients.forEach((client) => {
                        if (client.Connected) {
                            activeClient = activeClient + 1;
                        } else {
                            inactiveClient = inactiveClient + 1;
                        }
                        if (this.verifyNameClient(client)) {
                            inFilter = inFilter + 1;
                            console.log("/mountedDataClient()");
                        }
                    });
                    this.activeClient = activeClient;
                    this.inactiveClient = inactiveClient;
                    this.inFilter = inFilter;
                }, 1000);
            } catch (e) {
                console.log("Error in GetDatas() Client " + e);
            }
        },
        verifyNameClient(client) {
            let ret = false;
            if ((client.Name.toLowerCase()).includes(this.searchByName.toLowerCase())) {
                if (this.searchByConnected.value == 0) {
                    ret = true;
                } else if (this.searchByConnected.value == 1 && client.Connected) {
                    ret = true;
                } else if (this.searchByConnected.value == 2 && !client.Connected) {
                    ret = true;
                }
            }
            return ret;
        },
    },
    created() {
        this.clients = this.dataModule.Clients
        if (this.currentTab == "Client")
            this.getDatas();
    },
    mounted() {
        this.searchByConnected = clientStatus[0]
    },
    beforeDestroy() {
        console.log("/closed getDatas()");
        clearInterval(this.intervalGetDatas);
    },
}
</script>
<style scoped src="@/css/styleModules.css"></style>
