<template>
  <div class="user-dropdown">
    <div class="topnav">
      <a id="btnControl" class="btnControl">
        <i class="icons-white icons-1x icons-user"></i>
      </a>
    </div>

    <div class="user-dropdown-content" style="width: 195px">
      <span style="padding-left: 10px; color: var(--quinary)"><i
          style="color: var(--iconColor); margin-right: 5px; min-width: 20px; text-align: center;"
          class="icons icons-user" />

        {{ userLogged.Login }}</span>
      <a href v-if="!hasControl"><i
          style="color: var(--iconColor); margin-right: 5px; min-width: 20px; text-align: center;"
          class="icons icons-account-lock-open" />{{
            translate("sign_In")
          }}</a>
      <a href v-else @click.prevent="sendAction()"><i
          style="color: var(--iconColor); margin-right: 5px; min-width: 20px; text-align: center;"
          class="icons icons-account-lock" />
        {{ translate("sign_Out") }}</a>
      <a href @click.prevent="changePassword()"><i
          style="color: var(--iconColor); margin-right: 5px; min-width: 20px; text-align: center;"
          class="icons icons-key" />
        {{ translate("changePassword") }}</a>
      <a href @click.prevent="changeLanguage()"><i
          style="color: var(--iconColor); margin-right: 5px; min-width: 20px; text-align: center;"
          class="icons icons-language" />
        {{ translate("language") }}</a>

      <a href @click.prevent="showSettings()"><i
          style="color: var(--iconColor); margin-right: 5px; min-width: 20px; text-align: center;"
          class="icons icons-cogs" />
        {{ translate("settings") }}</a>
    </div>
  </div>
</template>

<script>
//import { userKey } from '@/global'

import { mapState } from "vuex";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
export default {
  computed: mapState([
    "user",
    "userLogged",
    "hasControl",
    "completeLogin",
    "lang",
  ]),
  name: "CountryDropdown",
  mixins: [en, es, nor, swe],
  data() {
    return {};
  },
  components: {},
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    sendAction() {
      this.$emit("ActionFromUserDrop", "signOut");
    },
    changePassword() {
      this.$emit("ActionFromUserDrop", "changePassword");
    },
    changeLanguage() {
      this.$emit("ActionFromUserDrop", "changeLanguage");
    },
  },
};
</script>

<style scoped src="@/css/componentDropdown.css"></style>
