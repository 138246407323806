<template>
  <Tree
    :data="treeData"
    :options="treeOptions"
    :filter="treeFilter"
    ref="tree"
    v-model="treeData"
  >
    <span class="tree-text" style="font-size: 11px" slot-scope="{ node }">
      <template v-if="!node.hasChildren()">
        <i :class="getStyle(node)" style="vertical-align: bottom;" />
        {{ node.text }}
      </template>
      <template v-else>
        <i :class="getStyle(node)" style="vertical-align: bottom;" />
        {{ node.text }}
      </template>
    </span>
  </Tree>
</template>
<script>
import Tree from "liquor-tree";
import { Store } from "@/store/TreeStore.js";
import "@/css/icons.css";
export default {
  name: "PenDriveTree",
  components: { Tree },
  data: function () {
    return {
      name: "",
      ip: "",
      treeFilter: "",
      games: [],
      treeData: null,
      showContent: false,
      data: [{}],
      columns: [{}],
      object: {
        data: [],
        columns: [],
      },
      store: Store,
      treeOptions: {
        checkbox: this.checkPath(),
        deletion(node) {
          return node.checked();
        },
        checkOnSelect: true,
        parentSelect: true,        
        propertyNames: { text: "name" },
        filter: { emptyText: "Categoria não encontrada" },
        dnd: true,
      },
    };
  },
  props: {
    filesTree: Object,
    path: String,
  },
  methods: {
    checkPath() {
      return this.path === "path" ? true : false;
    },
    getStyle(node) {      
      try {        
        const type = node.id.split("#");                
        if(type[0] == "OldSessions"){
          return "icons-lg icons-folder icons-stack";   
        }
        if (type[1].includes("Session")) {
          return "icons-lg icons-calendar icons-stack";
        } else if (type[1].includes("File")) {
          return "icons-lg icons-file icons-stack";
        } else if (type[1].includes("Report") || type[1] == "Logs" || type[1] == "Certificates" || type[1] == "Navision" ) {
          return "icons-lg icons-folder icons-stack";        
        } else if (type[1] == "Log") {
          return "icons-lg icons-logs icons-stack";
        } else if (type[1] == "Certificate") {
          return "icons-lg icons-document-table icons-stack";
        }

        
        
      } catch (e) {
        console.log("Error in getStyleCopyReporsst() " + e);
      }
    },
    checkTreeData(){            
      this.$emit("ActionAfterTreeData", this.treeData);
    }
  },

  mounted() {
    this.name = "";
    this.ip = "";
    this.$refs.tree.$on("node:selected", this.onNodeSelect);
  },

  watch: {
    treeData() {      
      this.checkTreeData();
    },
  },

 created() {    
    this.treeData = this.filesTree;
  },
};
</script>
<style scoped>
</style>