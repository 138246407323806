<template>
  <div class="user-dropdown">
    <div class="user-button">
      <div class="user-dropdown-img">
        <img
          class="option__image"
          src="../../img/country/all.png"
          v-if="user.countryCode == 0"
          title="Undefined"
          alt="Undefined"
          style="width: 30px; height: 30px; float: left"
        />
        <img
          class="option__image"
          src="../../img/country/Spain.png"
          v-if="user.countryCode == 1"
          title="Spain"
          alt="Spain"
          style="width: 30px; height: 30px; float: left"
        />
        <img
          class="option__image"
          src="../../img/country/Philippines.png"
          v-if="user.countryCode == 2"
          title="Philippines"
          alt="Philippines"
          style="width: 30px; height: 30px; float: left"
        />
        <img
          class="option__image"
          src="../../img/country/Mexico.png"
          v-if="user.countryCode == 3"
          title="Mexico"
          alt="Mexico"
          style="width: 30px; height: 30px; float: left"
        />
        <img
          class="option__image"
          src="../../img/country/Ireland.png"
          v-if="user.countryCode == 4"
          title="Ireland"
          alt="Ireland"
          style="width: 30px; height: 30px; float: left"
        />
        <img
          class="option__image"
          src="../../img/country/Norway.png"
          v-if="user.countryCode == 5"
          title="Norway"
          alt="Norway"
          style="width: 30px; height: 30px; float: left"
        />
        <img
          class="option__image"
          src="../../img/country/Sweden.png"
          v-if="user.countryCode == 6"
          title="Sweden"
          alt="Sweden"
          style="width: 30px; height: 30px; float: left"
        />
        <img
          class="option__image"
          src="../../img/country/Dingo PHL.png"
          v-if="user.countryCode == 254"
          title="Dingo PHL"
          alt="Dingo PHL"
          style="width: 30px; height: 30px; float: left"
        />
      </div>
      <i class="fa fa-angle-down"></i>
    </div>
  </div>
</template>

<script>
//import { userKey } from '@/global'

import { mapState } from "vuex";
export default {
  computed: mapState(["user"]),
  name: "CountryDropdown",
  data() {
    return {};
  },
  components: {},
  methods: {},
};
</script>

<style>
.user-dropdown {
  position: relative;
  height: 100%;
}

.user-button {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 100;
  height: 100%;
  padding: 0px 20px;
}

.user-dropdown:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.user-dropdown-img {
  margin: 0px 10px;
}

.user-dropdown-img > img {
  max-height: 37px;
  border-radius: 5px;
}

.user-dropdown-content {
  position: absolute;
  right: 0px;
  background-color: #f9f9f9;
  min-width: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.user-dropdown:hover .user-dropdown-content {
  visibility: visible;
  opacity: 1;
}

.user-dropdown-content a {
  text-decoration: none;
  color: #000;
  padding: 10px;
}

.user-dropdown-content a:hover {
  text-decoration: none;
  color: #000;
  background-color: #ededed;
}
</style>
