export default {
    data() {
        return {
            nor: {
                //Cashier + CashierOptions
                cashier_cashierTitle: "Kasserer",
                cashier_managerMenuTitle: "Leder",
                cashier_promotionsTitle: "Kampanjer",
                cashier_cashierOptionsTitle: "Lederalternativer",
                cashier_managerOptionsTitle: "Kassealternativer",
                sign_In: "Logg på",
                sign_Out: "Logg ut",
                cashierAmount: "Kasserer STARTBELØP",
                lblBlocked: "Blokkert",
                btnBlockCashier: "Blokk kasserer",
                btnUnblockCashier: "Oppheve kasserer",
                btnCashierCheck: "Kasserer Sjekk",
                btnAccInfo: "Billett info",
                btnCloseAcc: "Lukk konto",
                btnCloseAccs: "Lukk kontoer",
                btnRegisterFill: "Fyll kasse",
                btnRegisterDrop: "Tøm kasse",
                btnTerminalStatus: "Terminaler Status",
                btnReports: "Rapporter",
                btnPaidAccounts: "Betalte Kontoer",
                btnInformesGistra: "GISTRA Rapport",
                btnNavision: "Navision",
                btnAddCredits: "Legg til kontanter",
                btnNewPin: "Ny Konto",
                drawerAmount: "SKRIV INN STARTBELØP FOR KASSERER",
                drawerFinalAmount: "SKRIV INN SLUTTBELØP FOR KASSERER",
                drawerFillCashier: "SKRIV INN FYLLKBELØP FOR KASSERER",
                drawerDropCashier: "SKRIV INN STARTBELØP FOR KASSERER",
                drawnerBtnNewPin: "SKRIV INN VERDI",
                drawnerCashierCheck: "SKRIV INN SJEKK FOR KASSERER",
                btnEdit: "Rediger",
                btnDelete: "Slett",
                btnView: "Vis",
                btnClose: "Lukk",
                btnOk: "Ok",
                btnTest: "Prøve",
                btnAdd: "legge til",
                btnReturn: "Retur",
                btnBack: "Kom tilbake",
                btnCancel: "Avbryt",
                btnLogin: "Pålogging",
                btnYes: "Ja",
                btnNo: "Nei",
                btnExit: "Utgang",
                btnRefresh: "Forfriske",
                btnReset: "Nullstille",
                btnWait: "Vent...",
                btnSave: "Lagre",
                btnConfirm: "Bekrefte",
                btnDiscard: "Kassere",
                btnApply: "Bruke",
                btnReprintTicket: "Skrive ut billett på nytt",
                lblPassword: "Passord",
                lblConfirmPassword: "Bekreft passord",
                lblValue: "Verdi",
                lblNewValue: "Tilbakestill Reserve",
                lblReserveValue: "Reset Reserve",
                waiting: "Venter",
                checkingLicense: "Bekreftelse av lisens",
                cmdStartCashier: "Start Kasserer",
                cmdFinshCashier: "Fullfør kasserer",
                cmdCashierBlock: "Blokk Kasserer",
                cmdCashierUnblock: "Oppheve Kasserer",
                cmdAccountInfo: "Kasserer Billett info",
                cmdCloseAccounts: "Kasserer Lukk kontoer",
                cmdCloseAccountsOK: "Stenger billett",
                cmdCloseAccountsCancel: "Avbestill  Ticket",
                cmdCashierRegisterFill: "Kasserer Fyll kasse",
                cmdCashierRegisterDrop: "Kasserer Tøm kasse",
                cmdCashierAddNewPin: "Kasserer Ny Konto",
                messageSignOutCashier: "Er du sikker på at du vil lukke Kasserer?",
                messageBlockCashier: "Er du sikker på at du vil blokkere Kasserer?",
                messageUnblockCashier: "Er du sikker på at du vil oppheve blokkeringen av Kasserer?",
                messageErroBlockUnblockCashier: "Bare Kasserer bruker kan låse eller låse opp Kasserer",
                ticketTitle: "BILLETTINFORMASJON Billet Informasjon Skriv inn BilletNummer",
                authenticationTitle: "Godkjenning",
                columnTicket: "Billett",
                lblSearchAccount: "Søk etter forretningsforbindelse",
                reprintAccount_Title: "Skrive ut konto på nytt",
                btnReprint: "Opptrykk",
                messageSessionClose: "Økten er ikke åpen...",
                lblWarning: "Advarsel",
                cashier_Data: "Kassedata",
                columnStartCash: "Start Cash",
                columnTotalFill: "Total Fyll",
                columnTotalDrop: "Totalt fall",
                columnTotalAccountIn: "Total Account In",
                columnTotalAccountOn: "Total Account Out",  
                columnTotalCompAccount: "Total Complementary Account",
                columnFinalCash: "Final Cash",
                columnData: "Data",
                messageSignOutPart1: "Bare bruker[",
                messageSignOutPart2: "] kan logge ut",

                //ManagerOptions
                btnUnblockMachines: "Lås opp maskiner",
                btnBlockMachines: "Blokker maskiner",
                btnAutoBlockMachines: "Automatiske maskiner",
                messageBlockMachines: "Er du sikker på at du vil blokkere maskiner?",
                messageUnblockMachines: "Er du sikker på at du vil oppheve blokkeringen av maskiner?",
                btnCloseSession: "Lukk økt",
                btnOpenSession: "Åpne økt",
                messageOpenSession: "ØNSKER DU VIRKELIG Å ÅPNE ØKTEN?",
                messageCloseSession: "ØNSKER DU VIRKELIG Å LUKKE ØKTEN?",
                messageCloseSessionAlert: "Klikk på skriv ut Rapport-Knapp\n"+
                "for å skrive uu øktrapport",
                
                messageOpenSessionAlert: "Den siste øktrapporten ble ikke skrevet ut\n"+
                "Hvis kassereren lukkes nå vil det føre til at\n"+
                "data går tapt og kan ikke skrives ut igjen senere\n\n\n\n"+
                "Ønsker du å skrive ut rapporten nå?",
                
                btnCopyReport: "Kopier Rapport",
                btnPartialReport: "Delrapport",
                btnPrintReport: "Skriv ut rapport",
                messageCashierOpen: "Kassererøkten er åpen, logg av økten!",

                //Header
                messageHasEmailToSend: "Det finnes filer som skal sendes via avsender via e-post",
                massageRestartServer: "Vil du virkelig starte Megapot Server på nytt?",
                cmdRestartServer: "Start server på nytt",
                messageCloseServer: "Vil du virkelig lukke Megapot Server?",
                cmdServerStop: "Lukk program",
                messageSignOut: "Vil du virkelig logge av?",
                cmdBlockCommunication: "Blokker kommunikasjon",
                messageBlockCommunication: "Vil du virkelig blokkere kommunikasjon?",
                cmdUnblockCommunication: "Fjern blokkeringen av kommunikasjon",
                messageUnblockCommunication: "Vil du virkelig oppheve blokkeringen av kommunikasjon?",
                messageAllMachinesConnect: "Alle maskiner vil koble til fra serveren.",
                messageAllMachinesDisconnect: "Alle maskiner vil koble fra serveren.",
                changePassword: "Endre passord",
                btnAddCash: "Legg til kreditt",
                btnLock: "Lås meny",
                btnResetClient: "Tilbakestill Klient",
                btnCloseClient: "Lukk Klient",
                language: "Språk",
                messageTurnOffServer: "Denne handlingen vil avslutte serveren.",
                languageName: "Norsk",

                //Settings
                btnLogoff: "Avlogging",
                btnForcedLogOff: "Tvungen pålogging",
                btnRestart: "Omstart",
                btnShutdown: "Avslutnings",
                messageProcessLogOff: "Vil du virkelig logge av?",
                messageProcessForcedLogOff: "Vil du virkelig tvinge avlogging?",
                messageProcessRestart: "Vil du virkelig starte på nytt?",
                messageProcessShutdown: "Vil du virkelig avslutte?",

                //Langauge
                messageSetLanguage: "Venter på å endre språk ...",

                //Login
                messageErrorLogin: "Feil pålogging eller passord",
                messageErrorPassword: "Feil passord!",
                messageErrorByPermission: "Brukeren har ikke tillatelse",

                //Reset Value
                messageErrorValue: "Verdien må være Fra",
                messageErrorReserveValue: "Reserveverdi må være fra",
                messageErrorGrandValue: "Verdien [Grand] må være fra",
                messageErrorMinorValue: "Verdien [Minor] må være fra",
                messageErrorMajorValue: "Verdien [Major] må være fra",
                to: "Til",
                cmdResetBonus: "Venter på å tilbakestille",
                columnChange: "Forandre",
                lblNotChanged: "Ikke endret",
                confirmReset: "Angi nye verdier",
                messageConfirm: "Er du sikker?",
                lblValueWillBe: "Value will be",   
                lblResetType: "Tilbakestill type",
                lblPayNoConnection: "Betaling uten kommunikasjon" ,
                lblAdjustmentSystem: "Justering på grunn av systemfeil",
                lblPaidValue: "Betalt verdi",             

                //Terminals
                terminalsTitle: "Terminaler",                
                terminalInfoTitle: "Terminalinformasjon",
                lblCounter_Title: "Tellere",
                lblGameData: "Spilldata",
                lblTotalTerminals: "Totalt terminaler",
                lblLoggedTerminals: "Loggførte terminaler",
                lblFinishedLogin: "Ferdig innlogging",
                lblGamesAvailable: "Spill tilgjengelig",
                lblInLogin: "I påloggingsprosess",

                //columnTerminals: 
                columnTerminalNumber: "Terminalnummer",
                columnRealMachNum: "Ekte Maskinnummer",
                columnTerminalIP: "Terminal IP",
                columnLocalization: "Lokalisering",
                columnGameName: "Navn på spill",
                columnGameGroup: "Game Group",
                columnGameNumber: "Game Number(Last Play)",
                columnProgressiveGroup: "Progressiv Gruppe",
                columnMoney: "Penger",
                columnSoftwareVersion: "Programvareversjon",
                columnCredit: "Kreditt",
                columnTotalCoinIn: "Total mynt i",
                columnTotalCoinOut: "Total mynt ut",
                columnTotalGamesPlayed: "Totalt antall spill som spilles",
                columnTotalCashIn: "Sum innbetalinger",
                columnTotalCashOut: "Sum kontanter",
                columnTotalHandPays: "Totalt antall håndbetalinger",

                //CopyReport: 
                availableFiles: "Tilgjengelige filer",
                addFiles: "Filer som skal behandles",
                messageNoFiles: "Finner ingen filer",
                messageLargeFiles1: "Valgt filstørrelse: ",
                messageLargeFiles2: "Filer større enn 500 MB kan ta en stund å kopiere",
                messageLargeFiles3: "Merk: Filer vil bli komprimert før kopiering",
                btnViewErrors: "Vis feil",
                btnResend: "Sende",
                btnCopyFile: "Kopier Fil",

                //Email
                emails: "E-post",
                email: "E-post",
                btnAccountConfig: "Konfigurasjon av konto",
                btnSendFilesNow: "Send filer nå",
                btnSizeConfig: "Filstørrelse konfig",
                messageSetMaxFileSize: "Lagre maksimal filstørrelse",
                lblSearchEmail: "Søk via e-post...",
                account: "Konto",
                SMTPServer: "SMTP-Server",
                SMTPAuthenticate: "SMTP-Godkjenning",
                SMTPPort: "SMTP-Port",
                useStartTLS: "Bruke Start TLS (SSL)",
                SMTPTimeout: "SMTP-Tidsavbrudd",
                btnTestSend: "Test sending",
                tabOthers: "Andre",
                tabRetry: "Prøve",
                automaticEmailSender: "Automatisk avsender av e-post",
                mobileInternet: "Mobilt Internett",
                serialPortName: "Navn på seriell port",
                commDialUpName: "Navn på ekstern pålogging",
                firstRetry: "Intervall for første nytt forsøk",
                secondRetry: "Intervall for andre nye forsøk",
                thirdRetry: "Intervall for tredje nytt forsøk",
                subsequentRetry: "Etterfølgende intervall for nye forsøk",
                delayNotification: "Forsinkelsesvarsling",
                expirationTimeout: "Tidsavbrudd for utløp",
                reportListTitle: "Rapportliste",
                emailConfigTitle: "Konfigurasjon av e-post",
                msgErrorEmail: "E-post ikke konfigurert",
                messageSendManualEmail: "Venter på å sende e-post...",
                messageTestEmail: "Vent til du tester e-postserveren...",

                //Coluns name
                columnName: "Navn",
                columnEmail: "E-Post",
                columnActions: "Handlinger",
                columnField: "Felt",
                columnValue: "Verdi",
                columnPlatform: "Plattform",
                columnTimeToRestart: "Tid for å starte på nytt (minutter)",

                //Time
                hours: "Timer",
                minutes: "Minutter",
                seconds: "Sekunder",
                days: "Dager",

                //Panels
                panelsTitle: "Paneler",
                panelTitle: "Paneler",
                lblSearchPanels: "Søk etter panel ...",
                lblSearchFooter: "Søk etter bunntekst ......",
                columnText: "Tekst",
                btnDisplayName: "Navn på visningspanel",
                messageDisplayName1: "Denne handlingen viser panelets navn",
                messageDisplayName2: "Er du sikker på at du vil fortsette?",
                messageDisplayNameCMD: "Sende visningsnavn på hvert panel ...",
                messageDisplayNameFalse1: "En melding med panelnavnet vises for øyeblikket i <b>Alle</b> paneler.",
                messageDisplayNameFalse2: "<b>Hvis du vil stoppe denne skjermen, kan du trykke på 'Avbryt visning' -knappen",
                btnCancelDisplay: "Avbryt visning",
                btnSetGroups: "Angi grupper",
                btnSetFooterPlaylist: "Angi bunntekstspilleliste",
                btnSetPlaylist: "Angi spilleliste",
                processDeletePanelPart1: "Er du sikker på at du vil <b>slette</b> panelet ditt <b>(",
                processDeletePanelPart2: ")</b>? Denne handlingen kan ikke angres.",
                messageDeletePanel: "Slette panel ...",
                processDeletePanelTitle: "Slette panel",
                btnRestartPanel: "Start panelet på nytt",
                processRestartPanelPart1: "Er du sikker på at du vil <b>starte panelet på nytt</b> <b>(",
                processRestartPanelPart2: ")</b>? Denne handlingen kan ikke angres.",
                messageRestartPanel: "Omarbeide panel...",
                processRestartPanelTitle: "Omarbeide panel",
                btnShutdownPanel:"Nedleggelse Panel",
                processShutdownPanelPart1: "Er du sikker på at du vil <b>slå av</b> panelet <b>(",
                processShutdownPanelPart2: ")</b>? Denne handlingen kan ikke angres.",
                messageShutdownPanel: "Slå av panelet...",
                processShutdownPanelTitle: "Slå av panelet",
                btnResetFactoryPanel: "Tilbakestill fabrikkpanel",
                processResetFactoryPanelPart1: "Er du sikker på at du vil <b>tilbakestille fabrikkpanelet </b> panelet <b>(",
                processResetFactoryPanelPart2: ")</b>? Denne handlingen kan ikke angres.",
                messageResetFactoryPanel: "Hvilende panel...",
                processResetFactoryPanelTitle: "Hvilende panel",

                //Coluns name
                columnVersion: "Versjon",
                columnPanel: "Panel",
                columnCommVersion: "CommVersion",
                columnStatus: "Status",
                lblConnected: "Tilkoblet",
                lblDisconnected: "Frakoblet",

                //Progressive
                progressive_Title: "Progressiv",
                multiProgressives_Title: "MultiProgressives",
                multiProgressive_Title: "MultiProgressive",
                progressive_TabName: "Progressive Data",
                machines_Title: "Maskiner",
                lblSearchIPorMachNum: "Søk etter IP eller MachNum ...",
                btnResetProgressive: "Tilbakestill Progressiv",
                btnShowProgressive: "Vis Progressiv",
                btnDeleteTerminal: "Slett Terminal",
                btnPaymentTerminal: "Betalingsterminal",
                btnOpenMonitor: "Åpen Skjerm",
                lblSearchProgressive: "Søk etter progressiv",
                lblSearchGames: "Søk etter spill",
                lblSearchStatus: "Søk etter status",
                lblSearchTerminalInNone: "Terminaler i INGEN",
                lblSearchAllProgressive: "Alle progressive",
                lblSearchMultiProgressive: "Søk etter MultiProgressive",
                lblSearchAllInThisProgressive: "Alt i denne Progressive",
                lblTypeProgressive: "Type Progressiv",
                btnMoveProgressive: "Flytt terminal til progressiv",
                switchSelectAll: "Merk alt",
                messageSetTerminalProgressive: "Venter på å flytte progressiv(er) ...",
                messagePaymentTerminal: "Venter på betalingsterminal(er) ...",
                messageOpenTerminal: "Venter på å åpne terminal(er) ...",
                messageDeleteTerminal: "Venter på å slette terminal(er) ...",
                messageNoTerminalsAvailable: "Ingen terminaler er tilgjengelige",
                messageBtnOkMoveTermnalToProgressive: "Velg en terminal for å slippe knappen",
                messageBtnShowTerminalOptions: "Alternativer for terminal",
                massageConfigTerminalOptionPayment: "Vil du betale for denne maskinen?",
                massageConfigTerminalOptionOpen: "Vil du åpne innstillingene for denne maskinen?",
                massageConfigTerminalOptionDelete: "Vil du slette denne maskinen?",
                lblSelectOption: "Velg et alternativ",
                lblSelectOperator: "Velg et Operator",
                lblGroupMachines: "Gruppe maskiner",
                lblClosed: "Lukket",
                lblOpen: "Åpen",
                fractioned_Title: "Brøk",
                lblTableEnginesDataProg: "Motorer i denne progressive",
                columnTotalBet: "Total innsats",
                columnPercentage: "Prosent",
                lblWarnings: "Advarsler",
                msgWarnings: "Det er advarsler fra serveren, klikk her for å se dem",

                //columnNameProg
                columnProgressiveType: "Progressiv type",
                columnProgressiveConfig: "Progressiv Config",
                columnConfig: "Konfigurasjon",   
                columnCoinValue: "Myntverdi",
                columnPlayJackpotSoundOn: "Spill Jackpot Lyd på, Bidrag",
                columnContribution: "Bidrag",
                columnLevel: "Nivå",
                columnIP: "IP",
                columnInitialValue: "Startverdi",
                columnMaxValue: "Maks verdi",
                columnMinBetToPartipate: "Min Innsats for å delta",
                columnMaxProportionalBet: "Maks proporsjonal innsats",
                columnEnableIncSound: "Spill av økningslyd",
                columnIncrementSoundValue: "Øk lydverdi",
                columnReservePerc: "Reserveprosent",
                columnReserveValue: "Reserveverdi",
                columnReserveMax: "Reserver Maksimal Verdi",
                columnMidasFortuneBonus: "Midas Fortune Bonus",

                //columnNameMultiProg
                columnMultiProgressiveSettings: "MultiProgressive Innstillinger",
                columnMultiProgressiveType: "MultiProgressive Type",
                columnMultiProgressiveConfig: "MultiProgressive Config",
                columnTotalContribution: "Totalt bidrag",
                settings: "Innstillinger",

                //Users
                users_Title: "Brukere",
                user_Title: "Brukere",
                user_tabName: "Brukerdata",
                report_tabName: "Rapportdata",
                btnAddUser: "Legg til bruker",
                btnAddReportLevel: "Legg til rapportnivå",
                lblSearchByLogin: "Søk etter pålogging...",
                lblSearchByName: "Søk etter navn...",
                messageDeleteUser: "Venter på å slette bruker",
                processDeleteUserTitle: "Slette konto",
                processDeleteUserPart1: "Er du sikker på at du vil <b>delete</b> kontoen din <b>(",
                processDeleteUserPart2: ")</b>? Denne handlingen kan ikke angres.",

                messageDeleteReportLevel: "Venter på å slette rapportnivå",
                processDeleteReportTitle: "Sletter rapportnivå",
                processDeleteReportPart1: "Er du sikker på at du vil <b>delete</b> rapportnivået <b>(",
                processDeleteReportPart2: ")</b>? Denne handlingen kan ikke angres.",

                //Column User
                columnLastUpdated: "Sist oppdatert",
                columnFunction: "Funksjon",
                columnLogged: "Logget",
                columnNotLogged: "Ikke logget",

                //User Manager
                userManager_Title: "Brukerbehandling",
                usersManagement: "Administrasjon av brukere",
                cashier_Option: "Kasserer",
                technician_Option: "Tekniker",
                reportView_Option: "Rapportvisning",
                manager_Option: "Leder",
                root_Option: "Rot",
                lblSelectALevel: "Velg et nivå",
                messageErrorInvalidName: "Ugyldig navn. Bare bokstaver og tall er tillatt.",
                messageUserExist1: "Logg inn [",
                messageUserExist2: "] finnes allerede",
                messageErrorOptions: "Kontroller minst ett alternativ",
                messageErrorOperator: "Välj minst en operatör",
                processCreateUser: "Venter på å opprette bruker",
                processEditUser: "Venter på å redigere bruker",
                lblMultipleFunctions: "Flere funksjoner",
                lblSessionMangerFunction: "Behandling av økt",
                lblSearchOrAddTag: "Søke etter eller legge til en kode",
                lblAddThisTag: "Legg til dette som ny kode",
                lblSearchOrAddOperator: "Søk eller legg til en operator",
                lblAddThisOperator: "Legg til dette som ny operatør",
                lblSelectALabel: "Trykk ENTER for å velge",
                lblDeselectLabel: "Trykk ENTER for å fjerne",
                messageReLogin: "Klikk OK for å logge inn med det nye passordet",
                messageChangePassword: "Vennligst oppdater passordet ditt!",

                //Report Level Manager
                reportLevelManager_Title: "Konfigurasjoner for rapporttilgang",
                errorMessageInvalidReport: "Velg minst én rapport.",
                messageCreateReport: "Venter på å opprette rapport...",
                messageEditReport: "Venter på å redigere rapport...",

                //Configurations
                configurations_Title: "Konfigurasjoner",
                deckDatabase_Title: "Database",
                deckEstablishment_Title: "Serverinformasjon",
                deckResetSettings_Title: "Alternativer",
                btnLicense: "Serverlisens",
                btnCopyLogs: "Kopierlogger",
                btnCopyCert: "Kopier Sertifikat",
                btnLoadSettings: "Last inn innstillinger",
                btnBackup: "Sikkerhetskopiering",
                btnRestore: "Gjenopprett",
                stepLoadFile: "Last inn fil",
                stepCredentials: "Legitimasjon",
                lblTechnicianLogin: "Teknikerpålogging",
                lblExecutionCode: "kjøringskode",
                lblDatabasePassword: "Databasepassord",
                errorMessagePasswordTooShort: "Passordet er for kort",
                errorMessagePasswordAtLeast: "Passordet må ha minst 8 tegn",
                stepInformation: "Informasjon",
                stepFinish: "Fullføring",
                messageDownloadConfig: "Laster ned konfigurasjon...",
                messageUploadConfig: "Laster opp konfigurasjon, vent litt...",
                messageUploadDatabase: "Laster opp databasen...",
                errorMessageInvalidExecutionCode: "Ugyldig kjøringskode",
                errorMessageInvalidLoginWithExecutionCode: "Ugyldig kjøringskode, brukernavn eller passord",
                errorMessageInvalidLogin: "Brukernavn eller passord",
                messageInstallingConfiguration: "Installerer konfigurasjoner, vent litt...",
                messageRequestConfig: "Be om konfigurasjoner...",
                messageRequestFiles: "Be om filer...",
                messageRequestPenDriveList: "Be om PenDrive-liste",
                messageDoBackupCMD: "Tar sikkerhetskopi av logger og database...",
                btnResetServerConfiguration: "Tilbakestill Serverkonfigurasjon",
                btnResetGlobalConfiguration: "Tilbakestill Megapot konfigurasjon",
                btnResetClientConfiguration: "Tilbakestill Klientkonfigurasjon",
                messageResetClient: "Vil du virkelig starte klientkonfigurasjonen på nytt?",
                btnResetBothConfiguration: "Tilbakestill server- og klientkonfigurasjon",
                btnResetS2SConfiguration: "Tilbakestill S2S-innstillinger",
                btnResetBigOsAddress: "Tilbakestill BigOS-adresse",
                btnResetGlobalClient: "Tilbakestill Megapot adresse",
                btnManageSystem: "Administrere system",
                messageRunOsAdmin: "Vent, åpne Os Admin ...", 
                messageHasEmailSenderPart1: "Det er en tidligere konfigurasjon av e-postavsender.",
                messageHasEmailSenderPart2: "Vil du beholde den?",
                messageHasEmailSenderPart3: "(Obs.:Konfigurasjonen av e-posten, hvis den beholdes, kan rapportene sendes til Reportalen).",
                messageDiscardEmailSender: "Vent, slette e-post avsender ...",
                messageGenerateLicenseCMD: "Genererer rekvisisjon...",
                messageGenerateLicenseAutomaticCMD: "Ber om lisens...",
                msgCheckAutoLicense: "Du har konfigurert BigOS-adressen og kan prøve å generere lisensen din automatisk, vil du fortsette? Hvis du velger 'NEI' vil du kunne utføre den manuelle prosessen",
                msgCancelLicenseReq: "Generer ny lisensrekvisisjon",
                msgCancelLicenseQuest: "Generering av en ny lisensrekvisisjon vil ugyldiggjøre enhver tidligere åpnet rekvisisjon. Vil du fortsette?",
                msgGenNewLicense: "Denne handlingen vil generere en ny lisensrekvisisjon, som ikke kansellerer gjeldende lisens, hvis du har en fungerende. Vil du fortsette?",
                msgDeleteDatabase: "Denne handlingen sletter databasen. Vil du fortsette?",
                msgDeletingDatabase: "Slette database...",

                //Session
                stepChooseReport: "Velg Rapport",
                messageChooseReport: "Velg typerapport",
                btnCurrent: "Gjeldende",
                btnBySession: "Etter økt",
                stepReportType: "Rapport type",
                messageReportType: "En ny rapport genereres Velg hvilken type rapport du vil generere.",
                btnFinal: "Endelig",
                btnPartial: "Delvis",
                stepGenerateCSV: "Generer CSV",
                messageGenerateCSV: "Generer CSV etter økt eller Dato",
                btnGenerate: "Generer",
                messageReportTypeFinal: "Venter på å rapportere type endelig...",
                messageReportTypePartial: "Venter på å rapportere type delvis...",
                messageInformationBySession: "Venter på informasjon etter økt...",
                messageGenerateCSVRequest: "Venter på å generere CSV...",
                btnManualCashierSignOut: "Manuell kasserer Logg av",
                lblSearchByTerminal: "Søk etter terminal...",
                session_Title: "Sesjon",
                lblStartedAt: "Startet den",
                lblClosedAt: "Lukket den",
                lblType: "Type",
                lblLastSessionClose: "Lukk forrige økt",
                lblSessionControl: "Øktkontroll",
                lblSessionControlManual: "Håndbok",
                lblSessionControlAutomatic: "Automatisk",
                lblSessionControlSemiAutomatic: "Halvautomatisk",
                lblSearchCashier: "Søk etter kasserer...",

                //Footer
                serverVersion: "Server-versjon",
                sessionStatus: "Status for økt",

                //Token
                token_Title: "Merke",
                token_messageInfo: "Informer passords belgen til sentralen for å fortsette",
                token_messageError1: "Feil passord! du har ",
                token_messageError2: " forsøk til",

                //Auth
                messageAuthReportManager: "Hvis du vil ha tilgang til rapportområde, logger du på som en bruker som er aktivert for -modulen.",
                messageErrorNotSet: "er ikke angitt for",
                messageLoggedIn: "logget inn midlertidig",

                //ReportManager
                reportManager_Title: "Rapporter",

                //Bonus
                bonus_Title: "Bonus",
                bonusData_tabName: "Bonusdata",
                engineData_tabName: "Spill Data",
                lblResetBonus: "Tilbakestill Bonus",
                lblTableEnginesData: "Spill i denne bonusen",

                //ColumnBonus
                columnMrChampionConfig: "Mr Champion Konfigurasjon",
                columnMinPlayersMystery: "Min. spillere til Mystery",
                columnPigBoomConfig: "Pig Boom! Konfigurasjon",
                columnMinPlayersBonus: "Min. spillere til Bonus",
                columnPrizeValue1: "Premieverdi 1",
                columnPrizeValue2: "Premieverdi 2",
                columnPrizeValue3: "Premieverdi 3",
                columnPrizeMode: "Premiemodus",

                //IniFront
                iniFront_Title: "Konfigurasjon av klient",
                lblPreferencialIP: "Fortrinnsvis IP",
                messageErrorIP: "Vennligst skriv inn en gyldig adresse",
                messageCreatedIniFile: "Opprettet iniFile, vent med å starte på nytt...",
                messageNoConnection: "Ingen forbindelse med IP",
                iniFile_Title: "Ini-fil",
                messageNeedsIPConfig: "FBM Server trenger disse konfigurasjonene for å kjøre.",
                lblValidatinIP: "Validerer IP...",
                lblErrorDeleteFile: "Feil ved sletting av klientfil",
                messageSaveBigOsSettings: "Lagrer BigOS-innstillinger, vent litt...",

                //IniServer
                iniServer_Title: "Serverkonfigurasjoner",
                lblCountryCode: "Landkode",
                messageErrorCountryCode: "Velg ett land",
                lblDatabaseIP: "IP-adresse for database",
                fbmServer_Option: "Kjør Megapot Server ved oppstart av Windows",
                spMaster_Option: "Kjør SP-Master ved oppstart av Windows",
                fbmServerWillRestart: "Megapot Server starter på nytt...",
                lblFillAlesis: "Fyll ut Alesis-innstillingene",
                btnCheckTime: "Kontroller intervall (minutter)",
                btnBigosAddress: "BigOS Adresse",
                checkAutomatic: "Se automatisk etter nye konfigurasjoner",   
                checkAutomaticTerminal: "Send terminalstatus",             
                messageConfiguratorSettingsPart1: "Vær oppmerksom på at avhengig av miljøet som brukes, kan URL-adressen endres.",
                messageConfiguratorSettingsPart2: "Hvis du bruker SystemConfigurator, må du fullføre adressen med SystemConfigurator, eksempel https://example.com/SystemConfigurator.",
                messageConfiguratorSettingsPart3: "Hvis du bruker BigOS uten https, må du informere 9000-porten, for eksempel http://example.com:9000, hvis du bruker https, fullfør adressen med backend, eksempel https://example.com/backend.",
                messageConfiguratorSettingsPart4: "Den første delen av adressen http(s):// kreves i alle scenarier",

                //GlobalForm
                global_Title: "Megapot innstillinger",
                isGlobal_Title: "Megapot innstillinger",
                isGlobalProgressive: "Bruk Megapot",
                globalProgressiveMode: "Megapot modus",
                clientOnly_Option: "Bare klient",
                serverOnly_Option: "Bare server",
                clientAndServer_Option: "Klient og server",
                lblGlobalProgressiveServerIP: "IP for Megapot server",
                globalPreferencialIP_Option: "Megapot fortrinnsvis IP-tilkobling",
                anotherLocalIP_Option: "Bruk en annen lokal IP for å koble til",

                //GistraForm
                gistra_Title: "Gistra-innstillinger",
                lblServerIP: "SERVER-IP",
                messageErrorSelectLogin: "Velg pålogging",
                lblPort: "Havn",
                messageErrorPort: "Velg én port",

                //AlesisForm
                alesis_Title: "Alesis-innstillinger",
                lblSeller: "Leverandør-ID",
                messageErrorlblSeller: "Leverandør-ID er tom",
                lblSqlUser: "SQL-bruker",
                messageErrorlblSqlUser: "SQL-bruker er tom",
                lblDataBaseName: "Navn på database",
                messageErrorlblDataBaseName: "Alesis-databasenavnet er tomt",
                lblSqlPassword: "SQL-passord",
                lblConfirmSqlPassword: "bekrefte SQL-passord",
                messageErrorProvider: "Velg én leverandør",

                //BigOs
                messageErrorCheckTime: "Verdien kan ikke være 0 eller mindre",

                //App
                hideMenuMessage: "Venter på å skjule meny...",
                hideShowMessage: "Venter på å vise meny...",

                //Promotions 
                menuPromotions_Title: "Meny Kampanjer",
                validateCoupon_Title: "Valider kupong",
                generateCoupon_Title: "Generer kupong",
                couponManagement_Title: "Kupong Ledelse",
                messageGetPromotionsInfo: "Venter til du får informasjon om kampanjen og premiene",
                lblPromoNoAvailable: "Ingen kampanjer tilgjengelig",
                messageValidateCoupon: "Validerer strekkode",
                messageErrorCode: "Validerer Feilkode",
                confirmation_Title: "Bekreftelse",
                messageUpdateCoupon: "Bekrefte og oppdatere kupong",
                messageGenerateCoupon: "Generere kuponger",
                messageFindByVlt: "Bekrefter VLT",
                btnRefreshMachines: "Oppdater maskiner",
                messageRefreshMachines: "Søkemaskiner...",                

                //ValidateCoupon
                lblCurrentPromotion: "Gjeldende kampanje",
                lblStarts: "Starter",
                columnDrawn: "Tegnet",
                columnOrder: "Orden",
                columnAward: "Pris",
                columnCoupon: "Kupong",
                columnDate: "Daddel",
                btnValidate: "Validere",

                //GenerateCoupon
                lblNoCodeAvailable: "Ingen kode tilgjengelig",
                lblContactCentral: "Kontakt Sentral",
                btnSearchMachine: "Søk i maskin",
                lblMachineOutOfService: "Maskinen er ute av drift",
                lblCriterionBet: "Kriterium + Men",
                lblAvailableMachine: "Tilgjengelige maskiner",
                lblCoupons: "Kuponger",

                //CouponManagement
                lblEnterPromotions: "Skriv inn kampanjekoden",
                lblPromotionCode: "Kampanjekode",
                lblUserInformation: "Brukerinformasjon",
                lblAddress: "Adresse",
                lblTelefon: "Telefon",
                lblIdentification: "Identifikasjon",
                columnCouponCode: "Kupongkode",
                columnMachine: "Machine",
                columnPromotion: "Promotion",
                columnCriterion: "Kriterium",
                columnPrinted: "Trykt",
                btnPrintCoupons: "Skriv ut kuponger",
                btnReprintCoupons: "Skriv ut kuponger på nytt",
                btnPrintTest: "Skriv ut test",
                messagePrintTestCoupon: "Skriver ut testkupong...",

                //PlayersAccount
                lblStatusFilter: "Statusfilter",
                lblCreationDateFilter: "Filter for opprettelsesdato",
                free_Option: "Fri",
                used_Option: "Brukt",
                other_Option: "Annen",
                paying_Option: "Betalende",
                expired_Option: "Utløpt",
                created_Option: "Skape",
                canceled_Option: "Avbrutt",
                lblInitialDate: "Opprinnelig ",
                lblFinalDate: "Endelig dato",
                lblChooseADate: "Velg en dato",
                on_Option: "På",
                lblErrorSelectDates: "Begge datoene må velges.",
                columnBalance: "Balanse",
                columnExpires: "Utløper kl.",
                playersAccount_title: "Spillerkonto",

                lblNoDataFound: "Finner ingen data...",
                lblSearchByPin: "Søk etter pinne ...",
                lblLimit: "Grense",

                //Keyboard
                btnBackspace: "Tilbake",
                btnClear: "Klar",
                lblOpenKeyboard: "Åpne tastatur",

                //Report Level
                lblFinancial: "Økonomisk",
                reportSessionBalanceTot: "Totalrapport for øktsaldo",
                reportSessionReport: "Øktrapport",
                reportPrize: "Premie",
                reportPayOutSummary: "Rapport om utbetalingssammendrag",
                reportProgressiveGroupReport: "Rapport for progressiv gruppe",
                lblJackpotGlobal: "Megapot",
                reportStatusReport: "Statusrapport",
                reportPrizeReport: "Premierapport",
                lblGame: "Spill",
                reportGameListReport: "Rapport for spillliste",
                reportGameGroupReport: "Spillgrupperapport",
                lblVouchers: "Bilag",
                reportIssuance: "Utstedelse",
                reportRedemption: "Forløsning",
                reportUnclaimed: "Uavhentet",
                reportCashOut: "Avslutt penger",
                lblTerminal: "Terminal",
                lblLiability: "Ansvar",
                reportGameTerminalReport: "Rapport om spillterminal",
                reportTerminalEventReport: "Rapport for terminalhendelse",
                reportGameTerminalCountsReport: "Rapport om antall antall spillterminaler",
                reportReconcilationReport: "Avstemmingsrapport",
                reportReportAccountingFinal: "Endelig rapportregnskap",
                reportRamClearHistReport: "Ram Clear Hist-rapport",
                reportPlayHistReport: "Spill Hist Report",
                reportBillIn: "Fakturer inn",
                lblGenReports: "Gen-rapporter",
                lblServerReports: "Serverrapporter",
                reportTerminalSalesReport: "Terminalsalg-rapport",
                reportReport: "Rapport",
                reportTerminalAuditReport: "Terminalsalg-rapport",
                reportAuditReport: "Rapport for sporing av endringer",
                reportTerminalReport: "Terminalrapport",
                lblMystery: "Mysterium",
                lblPigBoom: "Pig Boom!",
                lblMannyBucks: "Manny Bucks",
                reportMysteryPrizeReport: "Mystery Prize-rapport",
                reportMannyBucksPrizeReport: "Manny Bucks Prize-rapport",
                reportBonusPrizeReport: "Bonuspremierapport",

                //Report Manager
                bySession: 'Etter økt',
                byPeriod: "Etter periode",
                byMachines: "Av maskiner",
                current: "Strøm",
                perUser: "Per bruker",
                perCashier: "Per kasserer",
                lifeTime: "Levetid",
                summary: "Sammendrag",
                hist: "Historie",
                lblExport: "Eksport",
                lblSelectType: "Velg type",
                lblSelectDevice: "Velg en enhet",
                lblFilter: "Filter",
                lblSelectUser: "Velg en bruker",
                lblSelectCashier: "Velg kasserer",
                lblSelectMachine: "Velg Maskin",
                lblCurrentSession: "Gjeldende økt",
                lblSelectDate: "Velg en dato",
                lblClickToSelect: "Klikk for å merke...",
                lblSelectSession: "Velg en økt",
                messageErrorGetReport: "Failed to Get Report",
                checkFieldsReport: "Kontroller feltene for Generer rapport.",
                messageExportReport: "Venter på å eksportere rapport...",

                //License
                license_Title: "Lisens",

                //Debug
                debug_Title: "Feilsøke",
                pigBoom_card_Title: "PigBoom! Kontroller",
                mystery_card_Title: "Mystery Kontroller",
                multiProgressive_card_Title: "MultiProgressive Kontroller",
                btnForcePigBoom: "Kraft PigBoom!",
                btnForceMystery: "Kraft Mystery",
                btnForceMultiProgressive: "Kraft MultiProgressive Nivå",
                btnClearForceLevelList: "Tøm kraftnivåliste",
                lblAllPigBoomGroups: "All Pig Boom! Grupper",
                lblAllMysteryGroups: "All Mystery Grupper",
                lblAllMannyBucksGroups: "All Manny Bucks Grupper",
                lblAllProgressivesGroups: "All Progressive Grupper                ",
                lblSelectMultiProgressive: "Velg MultiProgressive",
                lblSelectPigBoom: "Velg PigBoom!",
                lblSelectMystery: "Velg  Mystery",
                lblSelectMannyBucks: "Velg Manny Bucks",
                lblSelectProgressive: "Velg en progressiv",
                lblSelectLevel: "Velg et nivå",
                deckPigBoom_Title: "Pig Boom! Kontroller",
                deckMystery_Title: "Mystery Kontroller",
                deckMannyBucks_Title: "Manny Bucks Kontroller",
                deckMultiProgressive_Title: "MultiProgressive Kontroller",

                //noConnection
                messageNoConnectionFront: "Mistet kommunikasjon med serveren, prøver ny tilkobling...",
                lblError: "Feil",

                //Loading
                messageServerDidNotRespond: "Serveren svarte ikke på kommandoen",
                messageServerAttempts: "i 3 forsøk",
                messageServerRecive: "Mottok svar fra kommando",
                messageLimitTime: "i grensetid",
                messageServerFinishedCMD: "Serveren fullførte kommandoen",
                lblSucess: "Suksess",

                //GlobalProgressive
                globalData_tabName: "Megapot data",
                lblServerInfo: "Informasjon om server",
                lblGlobalServerInfo: "Megapot Server IP",
                lblNotFound: "Ikke funnet",
                lblConectedServers: "Tilkoblede servere",
                resetGlobalProgTitle: "Angi progressiv verdi",
                globalProgressive_Title: "Megapot",
                btnShowEstablishment: "Vis operatør og server",
                messageGetOperatorServer: "Operatør og server",
                columnOperator: "Operator",
                columnServerName: "Server navn",
                columnShortName: "Kort navn",
                columnAddress: "Adresse",
                columnCity: "By",
                columnDateUpdate: "Datooppdatering",
                messageInfoDeleteOperator: "Ved å klikke Slett, vil systemet slette operatøren fra databasen og den slutter å fungere",

                //Request File
                lblRequestFile: "Be om fil",
                messageRestoreBackup: "Alle dine faktiske bingodata vil gå tapt og erstattes av sikkerhetskopidataene Vil du virkelig fortsette?",
                messageLoadConfig: "Vil du virkelig laste inn denne konfigurasjonen?",
                btnDownload: "Laste ned",
                btnDonwloadConfig: "Last ned konfigurasjon!",
                btnDownloadFullConfig: "Last ned full konfigurasjon! Dette vil installere etableringsdatabasen på nytt med den nyeste konfigurasjonen!",
                lblPenDrive: "PenDrive",
                lblUploadFile: "Last opp fil",
                lblSelectItems: "Velg elementer",
                messageErrorFileSize: "er større enn 10 MB, vennligst velg en mindre fil.", 

                //Add Cash
                btnCopy: "Kopi",
                messageCopy: "Du kopierte nettopp",
                messageCopyError: "Kan ikke kopiere teksterFailed to copy texts",
                messageNewAddCasPassword: "Bruk denne koden til å opprette nytt passord",
                messageResetAddCashPassword: "Vil du virkelig tilbakestille passordet? Vær oppmerksom på at du må be systemansvarlig om et nytt passord",
                btnResetPassoword: "Tilbakestill passord",
                messageValidateHashCode: "Validere hash-kode",
                messageAddCash: "Venter på å legge til kreditt",
                messageSaveHashCode: "Venter på å lagre hash-kodefil",
                btnResetMach: "Tilbakestill Mach",
                btnCreateHashCode: "Opprett nummerkode",
                messageRequestHashCode: "Ber om hash-kodefiler",

                //Digi
                messageValidateDigi: "Vent til digi ip validerer",
                btnSearch: "Søke",

                //Logs
                logs_Title: "Logger",
                screenLog_Option: "Alternativ for skjermlogg",
                file_Option: "Fillogg",
                data_Option: "Datalogg",
                machineLog_Title: "Maskinlogg",
                panelLog_Title: "Panellogg",
                globalLog_Title: "Megapot logg",
                clientLog_Title: "Logg for klient",
                messageShowLogs: "Vis logger",
                messageShowLogsEdt: "Venter på å redigere vis logger",
                savePrefs_Option: "Lagre innstillinger",
                lbl_DaysDeleteOldLogFiles : "Dager Slett gamle loggfiler",

                //Dialog
                dialog_Title: "System",    

                //Playlist
                playlistFooter_Title: "Bunntekst spilleliste",
                playlist_Title: "Bunntekst spilleliste",
                lblSearchByPlaylist: "Søk etter spilleliste...",
                btnAddPlayList: "Legg til spilleliste",
                columnMessage: "Budskap",                
                processCreatePlaylist: "Venter på å lage spilleliste",
                processEditPlaylist: "Venter på å redigere spilleliste",
                processDeletePlaylistPart1: "Er du sikker på at du vil <b>slette</b> spillelisten din <b>(",
                processDeletePlaylistPart2: ")</b>? Denne handlingen kan ikke angres.",
                messageDeletePlaylist: "Venter på å slette spilleliste",
                processDeletePlaylistTitle: "Slette spilleliste",
                lblSelectPlaylist: "Velg spilleliste",
                messageErrorDate: "Dato allerede valgt",
                messageErrorDeletePlaylist: "Feil spilleliste som brukes i paneler",
                messagePlaylistExist1: "Spilleliste [",
                messagePlaylistExist2: "] eksisterer allerede",

                footer_Title: "Bunntekst",                
                lblSearchByFooter: "Søk etter bunntekst...",
                btnAddFooter: "Legg til bunntekst",                
                processCreateFooter: "Venter på å lage bunntekst...",
                processEditFooter: "Venter på å redigere bunntekst...",
                processDeleteFooterPart1: "Er du sikker på at du vil <b>slette</b> bunnteksten <b>(",
                processDeleteFooterPart2: ")</b>? Denne handlingen kan ikke angres.",
                messageDeleteFooter: "Venter på å slette bunntekst...",
                processDeleteFooterTitle: "Slette bunntekst",
                messageErrorFooter: "Meldingen finnes allerede!",
                messageErrorOption: "Velg minst ett alternativ",
                messageErrorDeleteFooter: "Feilmelding som brukes i spillelister",

                //Loading
                lblLoading: "Cargando",

                //terminalStatus
                All: "Alt",
                Logging: "Logging",
                WithCredit: "Med Kreditt",
                Playing: "Lek",
                InPayment: "I Betaling",
                AdminScreen: "Administrator-skjerm",
                JackpotPayment: "Jackpot Betaling",
                DoorOpen: "Av Open",
                BillError: "Bill Feil",
                PrinterErr: "Skriveren Feiler",
                MysteryElegible: "Mystery Kvalifisert",
                Blocked: "Blokkert",
                PigBoomElegible: "PigBoom! Kvalifisert",
                Disconnected: "Frakoplet",
                Connected: "Tilkoblet",
                FinishedLogin: "Ferdig Innlogging",
                InLogin: "I innlogging",
                InFilter: "I filter",

                //Cashier Dingo
                cashier_DingoTitle: "Dingo kasserer",
                btnManagerIn: "Leder in",
                btnManagerOut: "Leder ut",
                btnCashierIn: "Kasserer i",
                btnCashierOut: "Kasserer ut",
                btnPower: "Kraft",
                btnCloseVoucher: "Lukk kupongen",
                btnCloseVouchers: "Lukk bilag",
                btnVoucherInfo: "Kupong Info",
                btnVoucherViewer: "Kupongvisningsprogram",
                voucher_Title: "Kupong alternativer",
                register_Title: "Registrer Alternativer",                
                promotions_Title: "Kampanjer Alternativer",
                manager_Title: "Alternativer for leder",
                btnReportManager: "Rapportbehandling",
                btnUnlock: "Lås opp",
                message_Promotions_Info_Coupon: "- Kuponghåndteringsknappen vil være tilgjengelig ved siden av Kasserer-alternativer", 
                message_Promotions_Info_Validate: "- Valider kupongknappen vil være tilgjengelig ved siden av Manager-alternativer",
                message_Error_Reprint: "Velg minst én kupong for å skrive ut på nytt",
                lblEnter_Coupon_Code: "Skriv inn kupongkoden",

                //Operator
                operator_Title: "Operator",
                messageDeleteOperator: "Er du sikker på at du vil <b>slette</b> denne operatøren?" , 
                lblConfiguration: "Konfigurasjon",

                //Client
                client_Title: "Kunder",
                servers_Title: "Servere",
                activeClients: "Aktive Kunder",
                inactiveClients: "Inaktive Kunder",
            }
        }
    }
}