<template>
  <form action="javascript:void(0);" style="overflow-y: hidden">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ translate("btnAddCash") }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
          :can-cancel="false">
          <template #default="props">
            <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
              :JsonObject="obj" @ActionAfterAddCash="actionAfterAddCash" :isAxios="isAxios">
            </LoadingCMD>
          </template>
        </b-modal>
        <div v-if="showDivHashCode">
          <div class="col-sm-12" v-if="false">
            <Message type="is-info" :message="translate('messageNewAddCasPassword')" :isList="false" />
            <b-input-group>
              <b-form-input type="hashCode" v-model="hashCode" :value="hashCode" disabled></b-form-input>

              <b-input-group-append>
                <b-button variant="outline-secondary" :title="translate('btnCopy')" icon-left=".icons-white icons-copy"
                  icon-pack="icons" v-clipboard:copy="hashCode" v-clipboard:success="onCopy"
                  v-clipboard:error="onError"></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-lg-12">
            <b-field>
              <button class="btn btn-outline-secondary" disabled>
                <i class="icons-lg0 icons-usb" />
              </button>
              &nbsp;
              <b-select :placeholder="translate('lblSelectDevice')" v-model="penDrive" required expanded>
                <option v-for="penDrive in penDriveList" v-bind:value="penDrive" :key="penDrive">
                  {{ penDrive.Name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div v-else-if="showDivAddCash">
          <b-field label="" style="padding-bottom: 10px">
            <div class="col-sm-1">
              <button class="btn btn-outline-secondary" style="height: 39px" disabled>
                <i class="icons-1x icons-sack-money" />
              </button>
            </div>
            <div class="col-sm-10">
              <b-input-group :prepend="getCurrency()">
                <money class="form-control" id="valueReserve" type="text" name="valueReserve" v-bind="money"
                  v-validate="'numeric'" v-model="valueAddCash" :placeholder="translate('lblValue')" maxlength="11"
                  :max="maxLenghtValue" min="0" :precision="this.precision"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
              </b-input-group>
              <div v-if="errorMaxLenghtValue" style="
                    width: 100%;
                    margin-top: 0.25rem;
                    font-size: 80%;
                    color: #f86c6b;
                  ">
                {{ translate("messageErrorValue") }} 1 {{ translate("to") }}
                {{ maxLenghtValue }}
              </div>
            </div>
            <button class="btn btn-outline-secondary" type="button"
              @click.prevent="showKeyboardAddCash = !showKeyboardAddCash">
              <i class="icons-1x icons-keyboard" />
            </button>
          </b-field>
          <div class="mb-2" v-if="showKeyboardAddCash" style="width: 100%; margin-top: 0.25rem">
            <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="valueAddCash" :isNumeric="true"
              :pin="11" />
          </div>
          <div>
            <b-checkbox v-model="resetMach" style="padding-left: 15px">
              {{ translate("btnResetMach") }}
            </b-checkbox>
          </div>
        </div>
        <div v-else>
          <div v-if="false">
            <Message type="is-danger" :message="translate('token_messageInfo') + ' ' + errorAuthMessage"
              :isList="false" />
            <b-field>
              <div class="col-sm-1">
                <button class="btn btn-outline-secondary" style="height: 41px" disabled>
                  <i class="icons-1x icons-key" />
                </button>
              </div>
              <div class="col-sm-9">
                <b-input type="password" :placeholder="translate('lblPassword')" password-reveal v-model="password"
                  :value="password" maxlength="20">
                </b-input>
              </div>
              <div class="col-sm-1">
                <button class="btn btn-outline-secondary" type="button" style="height: 41px; padding-"
                  @click="showKeyboard()" :title="translate('Open Keyboard')">
                  <i class="icons-1x icons-keyboard" />
                </button>
              </div>
              <div class="col-sm-1">
                <button class="btn btn-outline-secondary" type="button" style="height: 41px"
                  :title="translate('btnResetPassoword')" @click.prevent="processResetPassword()">
                  <i class="icons-1x icons-question-circle" />
                </button>
              </div>
            </b-field>

            <div class="mb-2" v-if="showKeyboardPassword" style="width: 100%; margin-top: 0.25rem">
              <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="password" />
            </div>
          </div>
          <div v-else>
            <div v-if="isElectronVar">
              <Message v-if="penDriveList.length == 0" @click.prevent="showModalAlert = false" type="is-warning"
                :message="translate('messageNoFiles')" :isList="false" />
              <b-field>
                <button class="btn btn-outline-secondary" disabled style="height: 38px">
                  <i class="icons-lg0 icons-usb" />
                </button>
                &nbsp;
                <b-select :placeholder="translate('lblSelectDevice')" v-model="penDriveSelected" required expanded>
                  <option v-for="penDrive in penDriveList" v-bind:value="penDrive" :key="penDrive">
                    {{ penDrive.Name }}
                  </option>
                </b-select>
                &nbsp;
                <button class="btn btn-outline-secondary" @click.prevent="refreshPenDrive()">
                  <i class="icons-lg0 icons-sync" />
                </button>
                &nbsp;
                <button class="btn btn-outline-secondary" type="button" style="height: 38px"
                  :title="translate('btnResetPassoword')" @click.prevent="processResetPassword()">
                  <i class="icons-1x icons-question-circle" />
                </button>
              </b-field>
              <div class="form-group row" v-if="penDriveSelected != null">
                <div class="col-lg-12">
                  <div style="text-align: center">
                    <span class="stat-title">{{
                      translate("availableFiles")
                    }}</span>
                  </div>

                  <div class="stat" style="100%">
                    <div class="terminal-tab" style="font-size: 5px !important" v-if="penDriveSelected != null">
                      <b-input :placeholder="translate('lblSearchByName')" expanded type="search" icon="magnify"
                        v-model="searchFile" v-if="penDriveSelected.FileDataList.length > 0" />
                      <Message v-if="penDriveList.length == 0" @click.prevent="showModalAlert = false" type="is-warning"
                        :message="translate('messageNoFiles')" :isList="false" />
                      <br />
                      <div style="
                            height: 430px;
                            overflow-y: auto;
                            padding-left: 12px !important;
                          ">
                        <button style="
                              border-radius: 5px;
                              margin-right: 0.25%;
                              margin-top: 0.25%;
                              background-color: #ffffff;
                              box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
                              border: 1px solid rgba(0, 0, 0, 0.2);
                              box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
                              min-width: 150px;
                              max-width: 150px;
                              margin-bottom: 5px;
                            " class="stat-terminal" v-for="file in penDriveSelected.FileDataList" :key="file.FileName"
                          :title="file.Name" data-toggle="tooltip" data-placement="top"
                          @click.prevent="validateHashCode(file)" v-show="file.Name.toLowerCase().includes(
                            searchFile.toLowerCase()
                          )
                            ">
                          <div class="stat-icon">
                            <div class="row" style="align-items: end !important">
                              <div style="
                             padding-left: 70px !important;
                            padding-bottom: 10px !important;
                            padding-top: 7px; !important;
                          ">
                                <i class="icons-lg icons-file"> </i>
                              </div>

                              &nbsp; &nbsp;&nbsp;&nbsp;
                            </div>
                            <div style="min-height: 50px">
                              <span class="stat-title-terminal" style="
                                    font-size: 11px !important;
                                    padding-left: 5px;
                                    padding-right: 5px;
                                  ">
                                {{ file.Name }}
                              </span>
                            </div>
                          </div>
                        </button>
                      </div>

                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="divConfiguration" style="display: flex; justify-content: center">
                <b-field :class="{ 'has-name': !!uploadFile }">
                  <b-upload v-model="uploadFile" class="file-label" accept=".adf" type="is-text">
                    <span class="file-cta" style="background-color: white">
                      <b-icon type="is-text" class="icons-1x icons-file_upload"></b-icon>
                      <span class="file-label">{{
                        translate("lblUploadFile")
                      }}</span>
                    </span>
                  </b-upload>
                </b-field>
                &nbsp;
                <button class="btn btn-outline-secondary" type="button" style="height: 38px"
                  :title="translate('btnResetPassoword')" @click.prevent="processResetPassword()">
                  <i class="icons-1x icons-question-circle" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div v-if="showDivHashCode">
          <b-button style="margin-right: 10px" @click.prevent="showDivHashCode = showDivHashCode = false"
            :label="translate('btnReturn')" icon-left=".icons-white icons-undo" icon-pack="icons" v-if="false"></b-button>
          <b-button style="margin-right: 10px" @click.prevent="saveHashCodeFile()" :label="translate('btnCreateHashCode')"
            type="is-primary" icon-left=".icons-white icons-check" icon-pack="icons"
            :disabled="penDrive == null"></b-button>
        </div>
        <div v-else-if="showDivAddCash">
          <b-button tabindex="302" @click.prevent="processAddCash()" :label="translate('btnAddCash')" type="is-primary"
            icon-left=".icons-white icons-check" icon-pack="icons" />
        </div>
        <div>
          <b-button :label="translate('btnCancel')" icon-left=".icons-white icons-times" icon-pack="icons"
            @click.prevent="$emit('close')" tabindex="301" />
          <b-button tabindex="302" @click.prevent="validateHashCode()" :label="translate('btnOk')" type="is-primary"
            icon-left=".icons-white icons-check" icon-pack="icons" v-if="false" />
        </div>
      </footer>
    </div>
  </form>
</template>

<script>
import SimpleKeyboard from "../keyboard/SimpleKeyboard.vue";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import Socket from "@/config/webSocket.js";
import notify from "@/config/notify.js";
import LoadingCMD from "@/components/processCMD/LoadingCMD";
import maskara from "@/mixins/maskara.js";
import Message from "@/components/message/Message.vue";
import { mapState } from "vuex";
import { clientCommands } from "@/js/ClientCommands.js";
import { clientAPIs } from "@/js/ClientAPIs.js";
import { returnCurrency } from "@/mixins/countryCode.js";
import { Money } from "v-money";
import { countries } from "@/mixins/countryCode.js";
import { getRoute } from "@/js/downloadAndUploadFile.js";
export default {
  name: "AddCash",
  mixins: [en, es, nor, swe, maskara],
  components: { SimpleKeyboard, LoadingCMD, Money, Message },
  computed: mapState(["lang", "showHashCode", "user", "isElectronVar", "IP"]),
  data() {
    return {
      showKeyboardPassword: false,
      showKeyboardAddCash: false,
      hashCode: "",
      showDivHashCode: false,
      showDivAddCash: false,
      valueAddCash: 0,
      cmd: "",
      countDown: 10,
      showLoadingCMD: false,
      showRequestFile: true,
      sendingCMDMessage: "",
      obj: {
        Value: 0,
        ResetMach: false,
      },
      errorAuth: false,
      errorAuthMessage: false,
      precision: 0,
      countries: countries,
      tokenTry: 3,
      resetMach: false,
      password: "",
      penDrive: null,
      penDriveSelected: null,
      searchFile: "",
      maxLenghtValue: 50000,
      errorMaxLenghtValue: false,
      isAxios: false,
      uploadFile: null,
    };
  },
  props: {
    penDriveList: Object,
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    getCurrency() {
      return returnCurrency(this.user.countryCode);
    },
    showKeyboard() {
      this.showKeyboardPassword =
        this.showKeyboardPassword == true ? false : true;
    },
    onChange(input) {
      if (this.showKeyboardAddCash) {
        this.valueAddCash = input;
      } else {
        this.password = input;
      }
    },
    onKeyPress(button) {
      console.log("button", button);
      if (button === "{clear}") {
        if (this.showKeyboardAddCash) {
          this.valueAddCash = "";
        } else {
          this.password = "";
        }
      }
    },
    onInputChange(input) {
      if (this.showKeyboardAddCash) {
        this.valueAddCash = input;
      } else {
        this.password = input;
      }
    },
    sendRequest() {
      this.$store.commit("changehasControl", true);
      this.$emit("close");
    },

    afterSaveHashCode() {
      try {
        //this.showDivHashCode = true;
        //this.hashCode = data.Object.Token;
      } catch (ex) {
        console.log("Error in setHashCode()");
      }
    },
    onCopy: function (e) {
      notify.message(
        "foo",
        this.translate("lblSucess"),
        this.translate("messageCopy") + e.text,
        "success",
        3000
      );
    },
    onError: function () {
      notify.message(
        "foo",
        this.translate("lblError"),
        this.translate("messageCopyError"),
        "error",
        3000
      );
    },
    processResetPassword() {
      this.$buefy.dialog.confirm({
        title: this.translate("dialog_Title"),
        message: this.translate("messageResetAddCashPassword"),
        confirmText: this.translate("btnYes"),
        cancelText: this.translate("btnCancel"),
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => this.resetPassword(),
      });
    },
    saveHashCodeFile() {
      this.cmd = clientCommands.GetHashCode.name;
      this.countDown = clientCommands.GetHashCode.time;
      this.sendingCMDMessage = this.translate("messageSaveHashCode");
      this.obj = this.penDrive;
      this.showLoadingCMD = true;
    },
    resetPassword() {
      this.cmd = clientCommands.ResetHashCode.name;
      this.countDown = clientCommands.ResetHashCode.time;
      this.sendingCMDMessage = this.translate("cmdResetBonus");
      this.showLoadingCMD = true;
    },
    validateHashCode(file) {
      this.cmd = clientCommands.ValidateHashCode.name;
      this.countDown = clientCommands.ValidateHashCode.time;
      this.sendingCMDMessage = this.translate("messageValidateHashCode");
      this.obj.Value = file.FileName;
      this.showLoadingCMD = true;
    },
    processAddCash() {
      this.errorMaxLenghtValue = false;
      if (this.valueAddCash <= 0 || this.valueAddCash > this.maxLenghtValue) {
        this.errorMaxLenghtValue = true;
      } else {
        this.addCash();
      }
    },
    addCash() {
      this.cmd = clientCommands.AddCash.name;
      this.countDown = clientCommands.AddCash.time;
      this.sendingCMDMessage = this.translate("messageAddCash");
      this.obj.Value = this.valueAddCash;
      this.obj.ResetMach = this.resetMach;
      this.showLoadingCMD = true;
    },
    requestHashCodeFile() {
      Socket.send(clientCommands.RequestHashCodeFile.name + "|" + "" + "|" +
        this.token);
    },
    actionAfterAddCash(data) {
      //
      this.obj = {
        Value: 0,
        ResetMach: false,
      };
      try {
        switch (data.cmd) {
          case clientCommands.ResetHashCode.name:
            this.$store.commit("changeShowHashCode", false);
            if (this.isElectronVar) {
              this.showDivHashCode = true;
            } else {
              setTimeout(() => this.createAddCashFileAxios(), 500);
            }

            break;
          case clientCommands.ValidateHashCode.name:
            if (data.ok) {
              this.showDivAddCash = true;
              this.resetMach = false;
            } else {
              this.tokenTry--;
              this.errorAuth = true;
              this.errorAuthMessage =
                this.translate("token_messageError1") +
                this.tokenTry +
                this.translate("token_messageError2");
              if (this.tokenTry == 0) {
                this.errorAuthMessage = "";
                this.tokenTry = 3;
                this.errorAuth = false;
                this.password = "";
              }
            }
            break;
          case clientCommands.AddCash.name:
            this.$emit("close");
            break;
          case clientCommands.GetHashCode.name:
            this.showDivHashCode = false;
            this.penDrive = null;
            this.penDriveSelected = null;
            this.$store.commit("changeShowHashCode", false);
            this.$emit("OpenAddCash", {});
            this.$emit("close");
            break;
          default:
            break;
        }
      } catch (e) {
        console.log("Error in actionAfterAddCash() ", e);
      }
    },
    processDownloadFile(obj) {
      try {
        this.obj = obj;
        this.obj.isDownload = true;
        this.obj.item = {};
        this.obj.method = "GET";
        this.cmd = clientCommands.GetHashCode.name;
        this.countDown = clientCommands.GetHashCode.time;
        this.showLoadingCMD = true;
        this.sendingCMDMessage = obj.message;
      } catch (e) {
        console.log("Error in ProcessDownloadFile(AddCash)" + e);
      }
    },
    processSendFile(file) {
      if (file == undefined) {
        file = this.file;
      }
      const message = this.isConfig
        ? this.translate("messageLoadConfig") + " [" + file.Name + "]"
        : this.translate("messageRestoreBackup");
      this.$buefy.dialog.confirm({
        title: this.translate("dialog_Title"),
        message: message,
        confirmText: this.translate("btnYes"),
        cancelText: this.translate("btnCancel"),
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => this.sendFile(file),
      });
    },

    //WebClient
    sendFile(obj, url, message, cmd) {
      try {
        this.obj.File = obj;
        this.obj.isDownload = false;
        this.cmd = cmd;
        this.obj.url = getRoute(
          this.IP,
          clientAPIs.Path.name +
          "/" +
          clientAPIs.PathUploadFile.name +
          "/" +
          url
        );
        this.showLoadingCMD = true;
        this.sendingCMDMessage = message;
        this.isAxios = true;
      } catch (e) {
        console.log("Error in sendFile Add Cash: " + e);
        this.uploadFile = null;
      }
    },
    createAddCashFileAxios() {
      let objAux = {
        url: getRoute(
          this.IP,
          clientAPIs.Path.name +
          "/" +
          clientAPIs.PathDownloadFile.name +
          "/" +
          clientAPIs.HashCode.name
        ),
        message: this.translate("messageSaveHashCode"),
        name: "hashCodeFile.adf",
      };
      this.isAxios = true;
      this.processDownloadFile(objAux);
    },
    refreshPenDrive() {
      this.$emit("RefreshPenDriveFilesAddCash");
      this.$emit('close')
    },
  },
  mounted() {
    //
  },
  created() {
    if (this.showHashCode) {
      if (this.isElectronVar) {
        this.showDivHashCode = true;
      } else {
        //
        this.createAddCashFileAxios();
      }
    }
    //SocketDriver.$on("finishedCMDGetHashCode", this.afterSaveHashCode);
  },

  watch: {
    uploadFile: function () {
      this.sendFile(
        this.uploadFile,
        clientAPIs.HashCode.name,
        this.translate("messageValidateHashCode"),
        clientCommands.ValidateHashCode.name
      );
    },
  },
};
</script>

<style >
.stat {
  flex: 1;
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
}

.stat-terminal {
  border-radius: 5px;
  margin-right: 0.25%;
  margin-top: 0.25%;
  background-color: #ffffff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  width: 8.83% !important;
}
</style>