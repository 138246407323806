export default {
    //techinico
    regexLogin(el) {
        const regex = /^[a-zA-Z0-9]{2}[a-zA-Z0-9_.-]*$/;
        if (!regex.test(el)) {
            return true;
        } else {
            return false;
        }
    },
    regexPass(el) {
        const regex = /^[^\s]{6}[^\s]*$/;
        if (!regex.test(el)) {
            return true;
        } else {
            return false;
        }
    },

    //ips
    regexIP(el) {
        const regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;        
        if (!regex.test(el)) {
            return true;
        } else {
            return false;
        }
    },

    formatNumberCen(value, isCents = true) {
        let valor = value;
        const oldValue = value
        if (value == '') {
            console.log("Value Empty!")
            return value;
        }
        if (isCents) {
            valor = parseInt(valor.replace(/[\D]+/g, ""));
            valor = valor + "";

            if (valor.length == 1) {
                valor = "0,0" + valor
            } else if (valor.length == 2) {
                valor = "0," + valor
            }

            else {
                if (valor.length > 2)
                    valor = valor.replace(/([0-9]{2})$/g, ",$1");

                if (valor.length > 6) {
                    valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
                }
                if (valor.length > 10) {
                    valor = valor.substr(0, 1) + "." + valor.substr(1);
                }
            }
            value = valor
            if (valor === "NaN") {
                value = "";
            }
        } else {
            value = value.replace(',', '')
            value = value.replace('.', '')
            value = value.replace('.', '')
            valor = parseInt(value);
            value = valor.toLocaleString('en')
            value = value.replace(/,/g, '.')
        }

        console.log("NewValue [" + value + "] OldValue[" + oldValue + ']')
        return value;
    },

    numberWithDots(value) {
        let newValue = value
        newValue = parseInt(value).toLocaleString('en')
        newValue = newValue.replace(',', '.')
        newValue = newValue.toString();
        console.log("NewValue [" + newValue + "] OldValue[" + value + ']')
        return newValue;
    }

}