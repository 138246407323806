<template>
  <div style="padding-top: 5px">
    <div class="user-dropdown">
      <div class="topnav">
        <b-button
          class="button"
          icon-left="icons-1x icons-dots-three-vertical"
          icon-pack="icons"
        >
          <span>{{ translate("settings") }}</span>
        </b-button>
      </div>
      <div class="user-dropdown-content" style="width: 315px">
        <a
          href
          @click.prevent="
            sendSettings('Configuration/Settings/ResetBigOsAddress')
          "
          ><i
            class="icons icons-globe"
            style="margin-right: 1px; color: var(--iconColor)"
          />
          <span> {{ translate("btnResetBigOsAddress") }}</span>
        </a>
        <a
          href
          @click.prevent="sendSettings('Configuration/Settings/ResetServerIni')"
          ><i
            class="icons icons-cogs"
            style="margin-right: 1px; color: var(--iconColor)"
          />
          <span> {{ translate("btnResetGlobalConfiguration") }}</span>
        </a>
        <a
          href
          @click.prevent="sendSettings('Configuration/Settings/ResetClientIni')"
          v-if="false"
          ><i
            class="icons icons-network-wired"
            style="margin-right: 1px; color: var(--iconColor)"
          />
          <span> {{ translate("btnResetClientConfiguration") }}</span>
        </a>
        <a
          href
          @click.prevent="sendSettings('Configuration/Settings/ResetBothIni')"
          v-if="false"
          ><i
            class="icons icons-sync"
            style="margin-right: 1px; color: var(--iconColor)"
          />
          <span> {{ translate("btnResetBothConfiguration") }}</span>
        </a>
        <a href v-if="errorS2S" @click.prevent="sendSettings('errorS2S')"
          ><i
            class="icons icons-id-card"
            style="margin-right: 1px; color: var(--iconColor)"
          />
          <span> {{ translate("btnResetS2SConfiguration") }}</span>
        </a>
        <a
          href
          v-if="
            globalTypeEnum.ClientOnly == globalType ||
            (globalTypeEnum.ClientAndServer == globalType && hasDatabase)
          "
          @click.prevent="sendSettings('errorGlobalClientAddress')"
          ><i
            class="icons icons-network-wired"
            style="margin-right: 1px; color: var(--iconColor)"
          />
          <span> {{ translate("btnResetGlobalClient") }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import { mapState } from "vuex";
import { os } from "@/js/osType.js";
import { globalType } from "@/mixins/enums";
export default {
  computed: mapState([
    "lang",
    "showHashCode",
    "globalType",
    "hasDatabase",
    "userLogged",
    "isElectronVar",
    "errorS2S",
  ]),
  name: "ResetSetting",
  mixins: [en, es, nor, swe],
  data() {
    return {
      Os: os,
      globalTypeEnum: globalType,
    };
  },
  components: {},
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    sendSettings(action) {
      this.$emit("ActionAfterResetSettings", action);
    },
  },
};
</script>

<style scoped src="@/css/componentDropdown.css">
</style>
