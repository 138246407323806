<template>
    <div class="admin-pages">     
    <h1>Admin Page</h1>
    

        
    </div>
</template>

<script>




export default {
    name: 'AdminPages',
    
}
</script>
<style scoped>

</style>


