import Vue from "vue";
//import VueRouter, { RouteConfig } from "vue-router";
import VueRouter from 'vue-router'
import Home from "../views/Home.vue";
import AdminPages from '../views/admin/AdminPages.vue'
import Ini from '../components/ini/IniFile.vue'
import IniFront from "@/components/ini/IniFileFront.vue"
import Login from "@/components/auth/Login.vue";
import App from "@/App.vue";



Vue.use(VueRouter);

  const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home
  },  
  {
    path: '/admin',
    name: 'adminPages',
    component: AdminPages
  },
  {
    path: '/ini',
    name: 'ini',
    component: Ini
  },
  {
    path: '/iniFront',
    name: 'iniFront',
    component: IniFront
  }, 
  {
    path: '/login',
    name: 'Login',
    component: Login
  }, 

  {
    path: '/app',
    name: 'App',
    component: App
  }, 

];

const router = new VueRouter({
  mode: "history",  
  routes
});

export default router;
