import axios from 'axios'
import notify from "@/config/notify.js";
export const port = "5001"
export async function downloadFile(obj, loadingCMD, time) {
    try {
        const url = obj.url
        console.log("Send " + url);        
        await axios({
            url: url,
            data: obj.item,
            method: obj.method,
            responseType: 'blob',
            timeout: 1000 * time, 
        }).then((res) => {
            if (res.status != 200) {
                notify.message("foo", "Error", res.data.message, "error", 2000);
                loadingCMD.respFromAxiosFalse(res.data.message)
            }
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', obj.name);
            document.body.appendChild(fileLink);

            fileLink.click();

            loadingCMD.respFromAxiosOK()
        })
    } catch (error) {
        console.log("Error in donwloadFile" + error);
        if (error.res != undefined) {
            notify.message(
                "foo",
                "Error",
                error,
                "error",
                3000
            );
            loadingCMD.respFromAxiosFalse(error.res.data.message)
        } else {
            try {
                loadingCMD.respFromAxios(JSON.parse(error.response.data.object))
            } catch (e) {
                loadingCMD.respFromAxiosFalse("")
            }
        }

    }
}

export function uploadFile(file, url, loadingCMD, time) {
    try {
        let error = false;
        let formData = new FormData();
        formData.append("file", file);        
        axios.post(url, formData, {
            timeout: 1000 * time,
        }).then((res) => {
            console.log(res)
            error = false;
            loadingCMD.respFromAxios(JSON.parse(res.data.object))
        }).catch((error) => {
            console.log(error);
            if (error.response.status == 500 || error.response.status == 404 || error.response.status == 401 || error.response.status == 403) {
                //
            }
            try {
                loadingCMD.respFromAxios(JSON.parse(error.response.data.object))
            } catch (e) {
                loadingCMD.respFromAxiosFalse("")
            }

            error = true;
        }).finally(() => { console.log("finished Upload File () Axios") })
        return error;
    } catch (e) {
        console.log("Error in uploadFile " + e);

    }
}

export function getRoute(ip, route) {
    try {
        return "http://" + ip + ":" + port + route
    } catch (e) {
        console.log("Error in getRoute " + e);
    }
}


export default { downloadFile, uploadFile }