<template>
  <form action="javascript:void(0);" style="overflow-y: auto">
    <div class="modal-card" style="width: 100%; height: 100%">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ isReport ? translate("btnCopyReport") : nameFile }}
        </p>
        <button type="button" class="delete" @click="closeModal()" />
      </header>
      <section class="modal-card-body">
        <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
          :can-cancel="false">
          <template #default="props">
            <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
              :JsonObject="obj" @ActionAfterCMDCashierCopyReport="actionAfterCMDCashierCopyReport" :isAxios="isAxios">
            </LoadingCMD>
          </template>
        </b-modal>

        <Message v-if="dataCopy.FilesList.length == 0" type="is-warning" @click="showModalAlert = false"
          :message="translate('messageNoFiles')" :isList="false" />

        <Message v-if="this.totalSize > 0" type="is-info" @click="showModalAlert = false" :isList="true"
          :message="[translate('messageLargeFiles1') + Math.round(this.totalSize / 1024 / 1024) + ' MB', translate('messageLargeFiles2'), translate('messageLargeFiles3')]" />

        <b-field v-if="isElectronVar">
          <button class="btn btn-outline-secondary" disabled>
            <i class="icons-lg0 icons-usb" />
          </button>
          &nbsp;
          <b-select :placeholder="translate('lblSelectDevice')" v-model="penDriveSelected" required expanded>
            <option v-for="penDrive in dataCopy.PenDriveList" v-bind:value="penDrive" :key="penDrive">
              {{ penDrive.Name }}
            </option>
          </b-select>
          &nbsp;
          <button class="btn btn-outline-secondary" @click.prevent="refreshPenDrive()">
            <i class="icons-lg0 icons-sync" />
          </button>
        </b-field>
        <div class="form-group row">
          <div class="col-lg-12" style="text-align: center">
            <span class="stat-title">{{ translate("availableFiles") }}</span>
            <div class="stat" style="height: 100%; overflow-y: hidden; text-align: center">
              <TreePath :filesTree="treeData" path="path" :key="treeData" @ActionAfterTreeData="actionAfterTreeData" />
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button :label="translate('btnViewErrors')" @click="sendRequest()" type="is-primary" v-if="false"
          icon-left=".icons-white icons-search" icon-pack="icons" />

        <b-button :label="translate('btnResend')" @click="resendToQueue()" type="is-success"
          :disabled="filesList.length == 0" v-if="(nameFile != 'Certificates')" icon-left=".icons-white icons-email-sync"
          icon-pack="icons" />
        <b-button :label="translate('btnCopyFile')" @click="sendRequest()" v-if="isElectronVar"
          :disabled="filesList.length == 0 || penDriveSelected == null" type="is-primary"
          icon-left=".icons-white icons-copy" icon-pack="icons" />
        <b-button :label="translate('btnDownload')" @click="sendRequestAxios()" v-else :disabled="filesList.length == 0"
          type="is-primary" icon-left=".icons-white icons-download" icon-pack="icons" />

      </footer>
    </div>
  </form>
</template>

<script>
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import LoadingCMD from "@/components/processCMD/LoadingCMD";
import TreePath from "@/components/report/TreePath.vue";
import Message from "@/components/message/Message.vue";
import { mapState } from "vuex";
import { Store } from "@/store/TreeStore.js";
import { clientCommands } from "@/js/ClientCommands";
import { clientAPIs } from "@/js/ClientAPIs.js";
import { getRoute } from "@/js/downloadAndUploadFile.js";

export default {
  name: "CopyReport",
  mixins: [en, es, nor, swe],
  components: { LoadingCMD, TreePath, Message },
  computed: mapState(["lang", "isElectronVar", "IP", "isFBM"]),
  props: {
    dataCopy: Object,
    isReport: Boolean,
    nameFile: Object,
  },
  data() {
    return {
      cmd: "",
      countDown: 10,
      showLoadingCMD: false,
      sendingCMDMessage: "",
      obj: {},
      leftItems: [],
      rightItems: [],
      filesList: [],
      treeFilter: "",
      files: [],
      treeData: null,
      treeDataPenDrive: null,
      store: Store,
      totalSize: 0,

      treeOptions: {
        propertyNames: { text: "name" },
        filter: { emptyText: "no files Found" },
        dnd: true,
        store: {
          store: Store,
          getter: () => {
            return Store.getters.tree;
          },
          dispatcher() {
            //Store.dispatch("updateTree", tree);
          },
        },
      },
      showPathTree: true,
      showPenDriveTree: false,
      PenDriveList: [],
      penDriveSelected: null,
      isAxios: false,
    };
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    closeModal() {
      if (this.isFBM) {
        this.$emit("CloseModal");
      }
      this.$emit("close");
    },
    getTreeData() {
      return this.files;
    },
    onNodeSelect() {
      //
    },
    getFullPath(node) {
      const fullPath = [node.text];
      node.recurseUp((parentEl) => fullPath.unshift(parentEl.text));
      return fullPath.join("->");
    },

    actionAfterTreeData(treeData) {
      this.filesList = [];
      this.totalSize = 0;
      let data = [];
      try {
        if (treeData.checked != undefined) {
          for (let i = 0; i < treeData.checked.length; i++) {
            const node = treeData.checked[i];
            data = node.id.split("#");
            if (
              (data != null && data[1] == "File") ||
              data[1] == "Log" ||
              data[1] == "Certificate"
            ) {
              this.filesList.push(data[0]);
              if (data[2] != null) {
                this.totalSize = Number(this.totalSize) + Number(data[2]);
              }
            }
          }
        }
      } catch (e) {
        console.log("Error in actionAfterTreeData() " + e);
      }
    },
    //WebClient
    sendRequestAxios() {
      try {
        let route = "";
        let fileName = "";
        let message = "";
        if (this.isReport) {
          this.cmd = clientCommands.CashierCopyFiles.name;
          this.countDown = clientCommands.CashierCopyFiles.time;
          message =
            this.translate("waiting") +
            " " +
            this.translate("btnCopyFile") +
            "...";
          route = clientAPIs.Files.name;
          fileName = "Sessions.zip";
        } else {
          if (this.nameFile === "Logs") {
            this.cmd = clientCommands.ServerLogsCopyFiles.name;
            this.countDown = clientCommands.ServerLogsCopyFiles.time;
            message =
              this.translate("waiting") + " " + this.translate("btnCopyFile");
            route = clientAPIs.ServerLogs.name;
            fileName = "Logs.zip";
          } else if (this.nameFile === "Navision") {
            this.cmd = clientCommands.NavisionReportCopyFiles.name;
            this.countDown = clientCommands.NavisionReportCopyFiles.time;
            message =
              this.translate("waiting") + " " + this.translate("btnCopyFile");
            route = clientAPIs.NavisionReport.name;
            fileName = "Navision.zip";
          }
          else if (this.nameFile === "Reports") {
            this.cmd = clientCommands.ServerReportsCopyFiles.name;
            this.countDown = clientCommands.ServerReportsCopyFiles.time;
            message =
              this.translate("waiting") + " " + this.translate("btnCopyFile");
            route = clientAPIs.ServerReports.name;
            fileName = "Reports.zip";
          }

          else {
            this.cmd = clientCommands.CopyCertificate.name;
            this.countDown = clientCommands.CopyCertificate.time;
            message =
              this.translate("waiting") + " " + this.translate("btnCopyFile");
            route = clientAPIs.Certificate.name;
            fileName = "Certificates.zip";
          }
        }
        let objAux = {
          url: getRoute(
            this.IP,
            clientAPIs.Path.name +
            "/" +
            clientAPIs.PathDownloadFile.name +
            "/" +
            route
          ),
          message: message,
          name: fileName,
          Files: this.filesList,
          Location: "download",
        };
        this.processDownloadFile(objAux);
      } catch (e) {
        console.log("Error in sendRequestAxios() " + e);
      }
    },

    processDownloadFile(obj) {
      try {
        this.obj = obj;
        this.obj.isDownload = true;
        this.obj.method = "POST";
        this.showLoadingCMD = true;
        this.sendingCMDMessage = obj.message;
        //this.isAxios = true;
        this.sendRequest(obj);
      } catch (e) {
        console.log("Error in ProcessDownloadFile(CopyReport)" + e);
      }
    },

    sendRequest(obj = null) {
      try {
        if (this.isReport) {
          this.cmd = clientCommands.CashierCopyFiles.name;
          this.countDown = clientCommands.CashierCopyFiles.time;
          this.sendingCMDMessage =
            this.translate("waiting") + " " + this.translate("btnCopyFile");
        } else {
          if (this.nameFile === "Logs") {
            this.cmd = clientCommands.ServerLogsCopyFiles.name;
            this.countDown = clientCommands.ServerLogsCopyFiles.time;
            this.sendingCMDMessage =
              this.translate("waiting") + " " + this.translate("btnCopyFile");
          } else if (this.nameFile === "Navision") {
            this.cmd = clientCommands.NavisionReportCopyFiles.name;
            this.countDown = clientCommands.NavisionReportCopyFiles.time;
            this.sendingCMDMessage =
              this.translate("waiting") + " " + this.translate("btnCopyFile");
          } else if (this.nameFile === "Reports") {
            this.cmd = clientCommands.ServerReportsCopyFiles.name;
            this.countDown = clientCommands.ServerReportsCopyFiles.time;
            this.sendingCMDMessage =
              this.translate("waiting") + " " + this.translate("btnCopyFile");
          }
          else {
            this.cmd = clientCommands.CopyCertificate.name;
            this.countDown = clientCommands.CopyCertificate.time;
            this.sendingCMDMessage =
              this.translate("waiting") + " " + this.translate("btnCopyFile");
          }
        }

        if (obj == null) {
          this.obj = {
            Files: this.filesList,
            Location: this.penDriveSelected.Location,
          };
        }
        this.isAxios = false;
        this.showLoadingCMD = true;
      } catch (e) {
        console.log("Error in sendRequest() " + e);
      }
    },

    resendToQueue() {
      try {
        this.cmd = clientCommands.ResendToQueue.name;
        this.countDown = clientCommands.ResendToQueue.time;
        this.sendingCMDMessage =
          this.translate("waiting") + " " + this.translate("btnResend");
        this.obj = {
          Files: this.filesList,
          isReport: this.isReport,
        };
        this.isAxios = false;
        this.showLoadingCMD = true;
      } catch (e) {
        console.log("Error in resendToQueue() " + e);
      }
    },

    actionAfterCMDCashierCopyReport(data) {
      if (data.ok) {
        this.closeModal();
      }
    },

    getStyle(node) {
      try {
        const type = node.id.split("#");
        if (type[1].includes("Session")) {
          return "icons-lg icons-calendar icons-stack";
        } else if (node.id.includes("File")) {
          return "icons-lg icons-file icons-stack";
        } else if (node.id.includes("Report")) {
          return "icons-lg icons-folder icons-stack";
        }
      } catch (e) {
        console.log("Error in getStyleCopyReport() " + e);
      }
    },
    mountedDataFiles(checked = false) {

      try {
        const childrenFiles = [];
        let sessionList = {};
        let logList = {};
        this.dataCopy.FilesList.forEach((files) => {
          if (this.isReport) {
            const idSession = "Session Started at " + files.SessionName;
            sessionList = {
              id: idSession + "#" + "Sessions",
              title: "Session",
              name: idSession,
              parentId: 0xff,
              connect: true,
              children: this.mountedCategories(files, checked, this.isReport),
              state: {
                expanded: true,
                selected: false,
              },
            };
            childrenFiles.push(sessionList);
          } else {
            let name = ""
            //(this.nameFile === "Logs" ? "Logs " : " ") + files.Date;
            if (this.nameFile === "Logs") {
              name = 'Logs ' + files.Date;
            } else if (this.nameFile === "Navision") {
              name = 'Files ' + files.Date;
            } else {
              name = " " + files.Date
            }
            logList = {
              id: files.Date + "#" + this.nameFile,
              title: this.nameFile,
              name: name,
              parentId: 0xff,
              connect: true,
              children: this.mountedCategories(files, checked, this.isReport),
              state: {
                expanded: true,
                selected: false,
              },
            };
            childrenFiles.push(logList);
          }
        });
        this.files = [
          {
            id: 0xff + "#" + "Sessions",
            name: this.isReport ? "Sessions" : this.nameFile,
            parentId: null,
            children: childrenFiles,
            state: {
              expanded: true,
              selected: false,
              checked: checked,
            },
          },
        ];
      } catch (e) {
        console.log("Error in mountedDataFiles() " + e);
      }
    },
    mountedList(files, checked) {
      let children = [];
      try {
        let listLogs = {};
        files.forEach((log) => {
          listLogs = {
            id:
              log.FileName +
              "#" +
              (this.nameFile == "Logs" || this.nameFile == "Navision" ? "Log" : "Certificate"),
            title: log.FileName,
            name: log.Name,
            parentId: 0xff,
            connect: true,
            children: this.mountedList(log.List, checked, false),
            state: {
              expanded: true,
              selected: false,
              checked: checked,
            },
          };
          children.push(listLogs);
        });
      } catch (e) {
        console.log("Error in MountedList() " + e)
      }

      return children;
    },
    mountedCategories(files, checked, isReport) {
      let children = [];
      try {
        if (isReport) {
          let categoryList = {};
          files.Categories.forEach((category) => {
            const idCategory = "Report" + category.CategoryName;
            categoryList = {
              id: idCategory + "#" + "Report",
              title: category.CategoryName,
              name: category.CategoryName,
              parentId: 0xff,
              connect: true,
              children: this.mountedFiles(category, checked),
              state: {
                expanded: true,
                selected: false,
                checked: checked,
              },
            };
            children.push(categoryList);
          });
        } else {
          let listLogs = {};
          files.Logs.forEach((log) => {
            listLogs = {
              id:
                log.FileName +
                "#" +
                (this.nameFile == "Logs" || this.nameFile == "Navision" ? "Log" : "Certificate"),
              title: log.FileName,
              name: log.Name,
              parentId: 0xff,
              connect: true,
              children: this.mountedList(log.List, checked),
              state: {
                expanded: true,
                selected: false,
                checked: checked,
              },
            };
            children.push(listLogs);
          });
        }
      } catch (e) {
        console.log("Error in mountedCategories() " + e);
      }
      return children;
    },
    mountedFiles(category, checked) {
      let children = [];

      try {
        let filesList = {};
        category.Files.forEach((file) => {
          filesList = {
            id: file.FileName + "#" + "File",
            title: file.Name,
            name: file.Name,
            parentId: 0xff,
            connect: true,
            children: [],
            state: {
              expanded: true,
              selected: false,
              checked: checked,
            },
          };
          children.push(filesList);
        });
      } catch (e) {
        console.log("Error in mountedFiles() " + e);
      }
      return children;
    },
    moveAllRight() {
      this.mountedDataFiles(true);
      this.treeData = this.getTreeData();
      this.treeDataPenDrive = JSON.parse(JSON.stringify(this.treeData));
      //this.treeData = null;
    },
    moveAllLeft() {
      //this.treeData = JSON.parse(JSON.stringify(this.treeDataPenDrive));
      this.mountedDataFiles();
      this.treeData = this.getTreeData();
      this.treeDataPenDrive = null;
    },
    moveRight() {
      console.log(this.treeData);
    },

    refreshPenDrive() {
      this.$emit("RefreshPenDriveFiles");
      this.closeModal()
    },
  },
  created() {
    if (this.dataCopy.FilesList.length != 0) {
      this.mountedDataFiles();
      this.treeData = this.getTreeData();
    }
  },
  mounted() {
    this.$refs.tree.$on("node:selected", this.onNodeSelect);
  },
};
</script>

<style scoped src="@/css/styleModules.css">
.vue-list-picker {
  display: grid;
  padding-top: 140%;
}
</style>