
export const statusConfigValues = {
  Ok: 0,
  ShowLogin: 1,
  ShowPassword: 2,
  ShowLoginExecution: 3,
}

export const typeFile = {
  PDF: { id: 0, name: 'pdf' },
  XLS: { id: 1, name: 'xls' },
  HTML: { id: 2, name: 'html' },
  DOC: { id: 3, name: 'doc' },
}

export const typeFilter = {
  current: 'Current',
  bySession: 'bySession',
  byPeriod: 'byPeriod',
  byMachine: 'byMachine',
  byUser: "byUser",
  byCashier: "byCashier",
  Paid: "Paid",
  Opened: "Opened",
  Expired: "Expired",
}

export const classValue = {
  none: 0,
  approved: 1,
  warning: 2,
  error: 3,
  info: 4,
}

export const clientStatus =
  [
    {
      name: "All", value: 0
    },
    {
      name: "activeClients", value: 1
    },
    {
      name: "inactiveClients", value: 2
    },

  ]

export const wifiStrength = {
  strength0: 0,
  strength1: 1,
  strength2: 2,
  strength3: 3,
  strength4: 4,
}

export const typesModules = {
  prog: 0,
  multi: 1,
  mystery: 2,
  pigBoom: 3
}

export const loggedStatus = {
  NOT_LOGGED: 0,
  LOGGED: 1,
}

export const returnBlockMachines = {
  SERVER_ERROR: 'SERVER_ERROR',
  MACH_ERROR: 'MACH_ERROR',
  IN_BONUS: 'IN_BONUS',
}

export const typeCSVFilter = {
  Final: 0,
  Partial: 1,
  BySession: 2,
}

export const terminalOptions = {
  payment: 0,
  delete: 1,
  open: 2,
  moveTerminal: 3,
  none: 255,
}

export const sessionControl = {
  manual: 0,
  automatic: 1,
  semiAutomatic: 2,
}

export const ticketStatus = {
  Paid: 0,
  ToPay: 1,
}

export const machineOperation = {
  LogOff: 0,
  ForcedLogOff: 1,
  Restart: 2,
  Shutdown: 3,
}

export const globalType = {
  ClientOnly: 0,
  ServerOnly: 1,
  ClientAndServer: 2,
  None: 3,
}

export const permissionLevel = {
  Root: 5,
  Tech: 4,
  Manager: 3,
  Cashier: 2,
  Report: 1,
}

export const resetType ={
  PaymentNoConnection: 0,
  AdjustmentValue: 1,
}

export function getPermissionLevel(user) {
  if (user.Root) {
    return permissionLevel.Root
  } else if (user.Technician) {
    return permissionLevel.Tech
  } else if (user.Manager) {
    return permissionLevel.Manager
  } else if (user.Cashier) {
    return permissionLevel.Cashier
  } else if (user.ReportView) {
    return permissionLevel.Report
  }
}

export function
  getUserPermissions(countries, countryCode, page, userLogged, userAux) {
  let options = []
  const lvl = getPermissionLevel(userLogged)
  let option = {}
  if (lvl == permissionLevel.Root && (!userAux.Technician && !userAux.Cashier && !userAux.ReportView && !userAux.Manager)) {
    option = {
      name: page.translate("root_Option"),
      code: "root",
    }
    options.push(option)
  }

  if (lvl >= permissionLevel.Tech && !userAux.Root) {
    option = {
      name: page.translate("technician_Option"),
      code: "technician",
    }
    options.push(option)
  }

  /*
    if (countries.Mexico != countryCode ) {
      if (lvl >= permissionLevel.Cashier && !userAux.Root) {
        option = {
          name: page.translate("cashier_Option"),
          code: "cashier",
        }
        options.push(option)
      }
      if (lvl >= permissionLevel.Manager && !userAux.Root) {
        option = {
          name: page.translate("manager_Option"),
          code: "manager",
        }
        options.push(option)
      }
    }
  
    if (countries.Philippines == countryCode && !userAux.Root) {
      options.push({
        name: page.translate("reportView_Option"),
        code: "reportView",
      })
    }
  */
  return options;
}

export default { clientStatus, statusConfigValues, typeFile, typeFilter, classValue, typesModules, loggedStatus, returnBlockMachines, typeCSVFilter, terminalOptions, sessionControl, machineOperation, ticketStatus, globalType, getUserPermissions, wifiStrength, getPermissionLevel, resetType }
