
///* eslint @typescript-eslint/no-var-requires: "off" */
let isElectronVar = require('is-electron');
let w = null

export function changeScreen(width, height, isFullScream) {
    if (isFullScream) {
        //
    } else {
        if (w != null)
            w.setSize(width, height);
    }
}

export function topMost(flag) {
    if (w != null)
        w.setAlwaysOnTop(flag);
}

export function showMenuBar(flag) {
    if (w != null)
        w.setMenuBarVisibility(flag);
}

export function closeWindow() {
    if (w != null)
        w.close()
}
export function refresh() {
    if (w != null)
        w.reload()
}
export function maximize() {
    //  w.maximize()         
}
export function setFullScreen() {
    setInterval(() => {
        // w.setFullScreen(true);      
    }, 1000)
}
export function getWindow() {
    if (w != null)
        return w;
}

export function isElectron() {    
    if (isElectronVar()) {    
        console.log("Electron aww yeahhh !");
        const remote = require('electron').remote;
        w = remote.getCurrentWindow();
        return true
    } else {
        console.log("Running in other platform as a normal browser");
        return false
    }
    
}

export function getAddress(){    
    return window.location.host
}

export function getSize() {
    const screenSize = {
        width: window.innerWidth,
        height: window.innerHeight
    }
    return screenSize;
}

export function checkTouchScreen() {
    const isTouchScreen = 'ontouchstart' in window;
    return isTouchScreen
}

export function hideCursor() {
    // #LMP: Paulo Pache pediu para deixar cursor aparecendo
    //let css = '* { cursor: none !important; }';
    //w.webContents.insertCSS(css);
}


export function showCursor() {    
    let css = '* { cursor: default !important; }';
    w.webContents.insertCSS(css);
}

export function hideMenu() {
    if (w != null)
        w.setMenuBarVisibility(false)
}
export function showMenu() {
    if (w != null)
        w.setMenuBarVisibility(true)
}

export function getRoute(page){    
    return page.$route.name;
}

export default { changeScreen, closeWindow, refresh, maximize, setFullScreen, hideMenu, showMenu, getSize, topMost, showMenuBar, checkTouchScreen, isElectron, getAddress, getRoute }