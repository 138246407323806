import Vue from 'vue'
import Notifications from 'vue-notification'
import velocity      from 'velocity-animate'
Vue.use(Notifications, { velocity })
export default{
    message(group, title, text, type, duration) {
        
        Vue.notify({
            group: group,
            title: title,//'Important message',
            text: text,//'Hello user! This is a notification!',
            type:  type,//'error',
            duration: duration,
            
            
            });
    },

}
