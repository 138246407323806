const protobuf = require("protobufjs");
export function decodeProtoMessage(array) {
    try {        
        var buffer = new Uint8Array(array);
        var Type = protobuf.Type,
            Field = protobuf.Field;

        let message = new Type("message").add(new Field("CMD", 1, "string"));
        message.add(new Field("Data", 2, "string"));
        message.add(new Field("BinaryData", 3, "bytes"));
        return message.decode(buffer);
    } catch (e) {
        console.log("Error in decodeProtoMessage" + e)
        return null
    }
}

export function encodeProtoMessage(cmd, data, text, token) {
    try {        
        var Type = protobuf.Type,
        Field = protobuf.Field;

        let protoMessage = new Type("message").add(new Field("CMD", 1, "string"));
        protoMessage.add(new Field("Data", 2, "string"));
        protoMessage.add(new Field("BinaryData", 3, "bytes"));
        protoMessage.add(new Field("Token", 4, "string"));

        protoMessage.CMD = cmd;
        protoMessage.BinaryData = data;
        protoMessage.Data = text;
        protoMessage.Token = token;
        return protoMessage.encode(protoMessage).finish();
    } catch (e) {
        console.log("Error in encodeProtoMessage" + e)
        return null
    }
}

export default { decodeProtoMessage, encodeProtoMessage }