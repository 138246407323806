const maskara = {
    data(){
        return{          
            moneyDouble: {
                decimal: ',',
                thousands: '.',
                //prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
              },
              moneyInt: {
                decimal: ',',
                thousands: '.',
                //prefix: 'R$ ',
                //suffix: ' #',
                precision: 0,
                masked: false
              },
              moneyEsp: {
                decimal: ',',
                thousands: '.',
                //prefix: '€ ',
                suffix: ' €',
                precision: 0,
                masked: true
              },
        }
    }
}
export default maskara;