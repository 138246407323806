<template>
  <div class="searchFilter">
    <div v-if="isOperator">
      <section class="dropdown-wrapper">
        <div @click.prevent="isVisible = !isVisible" :class="isHeader ? 'selected-item-operator' : 'selected-item'" v-if="!fromDash">
          <span v-if="selected == ''"> {{ isOperator ? translate('lblSelectOperator') : translate('lblSelectOption') }} </span>
          <span v-else> {{ selected }}</span>
          &nbsp;&nbsp;&nbsp;
          <i class="icons icons-angle-up" v-if="isVisible" />
          <i class="icons icons-angle-down" v-else />
        </div>
        <div v-if="isVisible" :class="isHeader ? 'dropdown-popover-operator' : 'dropdown-popover'">
          <span style="color: #000;"> {{ translate('lblSelectOperator') }} </span>
          <br/><br/>
          <input id="lblSeachFilter" v-model="searchQuery" type="text" style="margin-bottom: 10px;" />
          <div class="options">
            <ul style="max-height: 350px !important;">
              <li @click.prevent="selectConfig(config)" v-for="config in listAux" :key="config">
                {{ config.name }}
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>    
    <div v-else>
      <section class="dropdown-wrapper-config">
        <div @click.prevent="isVisible = !isVisible" :class="'selected-item-config'"
          v-if="!fromDash" style="">
          <span v-if="selected == ''"> {{ translate('lblSelectOption') }} </span>
          <span v-else> {{ selected }} &nbsp;</span>
          <i class="icons icons-angle-up" v-if="isVisible" />
          <i class="icons icons-angle-down" v-else />
        </div>
        <div v-else :class="'selected-item-config'">
          <span v-if="selected == ''"> {{ translate('lblSelectOption') }} </span>
        </div>
        <div v-if="isVisible" :class="'dropdown-popover-config'">
          <input id="lblSeachFilter" v-model="searchQuery" type="text" style="margin-bottom: 5px;" />
          <div class="options-config">
            <ul>
              <li @click.prevent="selectConfig(config)" v-for="config in listAux" :key="config">
                {{ config.name }}
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
export default {
  computed: mapState([
    "lang",
  ]),
  name: "SearchFilter",
  data() {
    return {
      searchQuery: "",
      selectedItem: null,
      isVisible: false,
      listAux: [],
      intervalGetDatas: null,
    };
  },
  mixins: [en, es, nor, swe],
  props: {
    list: [],
    selected: null,
    isHeader: Boolean,
    isOperator: Boolean,
    fromDash: Boolean,
  },
  mounted() {
    this.listAux = JSON.parse(JSON.stringify(this.list));
    if (this.fromDash) {
      this.isVisible = true
    }
    clearInterval(this.intervalGetDatas);
    this.getDatas();
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    selectConfig(config) {
      this.selected = config.name;
      this.isVisible = false
      this.searchQuery = ""
      this.$emit("ActionAfterCMDSearchFilter", this.selected);
    },

    getDatas() {
      this.intervalGetDatas = setInterval(() => {
        //console.log("/getDatas()Dash");
        try {
          this.listAux = []
          if (this.list != null) {
            this.list.forEach(op => {

              if (op.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                this.listAux.push(op);
              }
            });
          }
        } catch (e) {
          console.log("Error in getDatas() SearchFilter " + e)
        }
      }, 100);
    },
  },
  watch: {
    searchQuery(_new) {
      this.listAux = [];
      this.list.forEach((aux) => {
        if (aux.name.toLowerCase().includes(_new.toLowerCase())) {
          this.listAux.push(aux);
        }
      });
    }, isVisible(val) {
      if (!val) {
        clearInterval(this.intervalGetDatas);
      }
      else {
        this.getDatas()
      }
    }
  },
};
</script>

<style scoped lang="scss">
/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.dropdown-wrapper {
  max-width: 500px;
  position: relative;
  margin: 0 auto;
  z-index: 1;

  .selected-item {
    height: 40px;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .selected-item-operator {
    height: 40px;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-popover {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    background-color: #fff;
    max-width: 100%;
    padding: 20px;

    input {
      width: 100%;
      height: 30px;
      border: 1px solid lightgray;
      font-size: 16px;
      padding-left: 5px;
    }

    .options {
      width: 100%;

      ul {
        list-style: none;
        text-align: left;
        padding-left: 0px;
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;

        li {
          width: 100%;
          border: 1px solid lightgray;
          padding: 10px;
          margin-top: 0px;
          background-color: #f1f1f1;
          cursor: pointer;

          &:hover {
            background-color: #78787a;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }

  .dropdown-popover-operator {
    position: absolute;
    border: 1px solid lightgray;
    top: 46px;
    left: -155px;
    right: 0;
    background-color: #fff;
    width: 500px;
    padding: 20px;

    input {
      width: 100%;
      height: 30px;
      border: 1px solid lightgray;
      font-size: 16px;
      padding-left: 5px;
    }
    
    .options {
      width: 100%;

      ul {
        list-style: none;
        text-align: left;
        padding-left: 0px;
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;

        li {
          width: 100%;
          border: 1px solid lightgray;
          padding: 10px;
          background-color: #f1f1f1;
          color: #000;
          cursor: pointer;

          &:hover {
            background-color: #78787a;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.dropdown-wrapper-config {
  max-width: 350px;
  position: relative;
  margin: 0 auto;
  z-index: 1;

  .selected-item-config {
    height: 40px;
    border: 2px solid lightgray;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-popover-config {
    position: absolute;
    border: 2px solid lightgray;
    top: 46px;
    left: 0;
    right: 0;
    background-color: #fff;
    max-width: 100%;
    padding: 20px;

    input {
      width: 100%;
      height: 30px;
      border: 2px solid lightgray;
      font-size: 16px;
      padding-left: 5px;
    }

    .options-config {
      width: 100%;

      ul {
        list-style: none;
        text-align: left;
        padding-left: 0px;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        border: 1px solid lightgray;

        li {
          width: 100%;
          border-bottom: 1px solid lightgray;
          padding: 10px;
          background-color: #f1f1f1;
          cursor: pointer;

          &:hover {
            background-color: #78787a;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>