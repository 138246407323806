<template>

  <body>
    <div style="align: center">
      <div class="" id="allCircle">
        <div class="circle" id="circle"></div>
        <div class="circle-small"></div>
        <div class="circle-big" id="circleBig"></div>
        <div class="circle-inner-inner"></div>
        <div class="circle-inner"></div>
      </div>
    </div>
  </body>
</template>

<script>
import { mapState } from "vuex";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
export default {
  computed: mapState(["lang"]),
  name: "Loading",
  mixins: [en, es, nor, swe],
  data: function () {
    return {
      style: this.setText(),
      intervalGetDatas: null,
      time: 500,
    };
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    setText() {
      try {
        const circleEx = document.getElementById("circleEx");
        if (circleEx != null) {
          let elCoordenadas = circleEx.getBoundingClientRect();
          const posY = elCoordenadas.y + 210 + "px";
          this.style = "top: " + posY + "; left: 51%;";
        }
      } catch (ex) {
        console.log("Error in setText() " + ex);
        console.log("/closed Loading()");
        clearInterval(this.intervalGetDatas);
      }
    },
  },
  beforeDestroy() {
    console.log("/closed Loading()");
    clearInterval(this.intervalGetDatas);
  },
  beforeCreate() {
    this.intervalGetDatas = setInterval(() => {
      try {
        this.setText();
        this.time = 1000;
      } catch (e) {
        console.log("Error in Loading() " + e);
      }
    }, this.time);
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.text {
  font-family: Arial;
  text-transform: uppercase;
  color: #888;
  animation: opaa 10s infinite;
  height: 130vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 190px;
  height: 190px;
  border: 10px inset rgb(255, 182, 72);
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  border-radius: 200px;
  -moz-animation: rotate-rev 10s infinite linear;
  -webkit-animation: rotate-rev 10s infinite linear;
  animation: rotate-rev 10s infinite linear;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}



.circle-small {
  width: 150px;
  height: 150px;
  border: 6px outset rgb(255, 182, 72);
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -81px;
  margin-top: -81px;
  border-radius: 156px;
  -moz-animation: rotate-rev 10s infinite linear;
  -webkit-animation: rotate-rev 10s infinite linear;
  animation: rotate-rev 10s infinite linear;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.circle-big {
  width: 210px;
  height: 210px;
  border: 25px outset rgb(255, 182, 72);
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -110px;
  margin-top: -110px;
  border-radius: 214px;
  -webkit-animation: rotate-rev 10s infinite linear;
  animation: rotate-rev 10s infinite linear;
}

.circle-inner-inner {
  width: 90px;
  height: 90px;
  background-color: rgb(128, 91, 35);
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  border-radius: 100px;
  -moz-animation: pulse 3s infinite ease-in;
  -webkit-animation: pulse 3s infinite ease-in;
  animation: pulse 3s infinite ease-in;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/*==============ANIMATIONS=================*/

/*==============ROTATE=====================*/

@-moz-keyframes rotate {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*==============ROTATE-REV=================*/

@-moz-keyframes rotate-rev {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes rotate-rev {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes rotate-rev {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

/*==============PULSE======================*/

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(0.1);
    opacity: 0.2;
  }

  50% {
    -moz-transform: scale(1);
    opacity: 0.8;
  }

  100% {
    -moz-transform: scale(0.1);
    opacity: 0.2;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.1);
    opacity: 0.2;
  }

  50% {
    -webkit-transform: scale(1);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(0.1);
    opacity: 0.2;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.1);
    opacity: 0.2;
  }

  50% {
    transform: scale(1);
    opacity: 0.8;
  }

  100% {
    transform: scale(0.1);
    opacity: 0.2;
  }
}
</style>
