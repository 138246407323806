<template>
    <div class="progressive">
        <div class="container-fluid">
            <div class="animated fadeIn">
                <div class="card">
                    <div class="card-body">
                        <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog"
                            aria-modal :can-cancel="false">
                            <template #default="props">
                                <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage"
                                    :JsonObject="objData" :countDown="countDown" @ActionAfterLoadingOperator="actionAfterLoadingOperator
                                        " :route="route">
                                </LoadingCMD>
                            </template>
                        </b-modal>
                        <b-field grouped>
                            <div class="col-lg-12 searchTerminal">
                                <b-input :placeholder="translate('lblSearchByName')" expanded type="search" icon="magnify"
                                    id="lblSearchByName" v-model="searchByName" @icon-right-click="searchByName = ''"
                                    icon-right="close-circle" icon-right-clickable />
                            </div>
                        </b-field>
                        <div style="overflow-y: auto; height: 100% !important; padding-left: 7px;">
                            <div class="stats">
                                <div class="stats col-lg-2" v-for="server in dataModule.Operator" :key="server"
                                    style="padding-left: 0px;">
                                    <button class="stat" style="min-width: 200px;"
                                        @click.stop="processShowEstablishmentData(server)"
                                        v-if="server.toLowerCase().includes(searchByName.toLowerCase())">
                                        <div>
                                            <div class="stat-icon" style="min-height: 50px;">
                                                <span class="stat-title" style="font-size: 1rem !important;">{{ server
                                                }}</span>
                                            </div>
                                        </div>
                                        <footer>
                                            <div class="col-lg-12">
                                                <br />
                                                <i class="icons-lg0 icons-user-shield" style="font-size: 3rem;" />
                                            </div>
                                        </footer>
                                    </button>
                                </div>
                                <div :class="'col-lg-' + (12 - 2 * dataModule.OperatorFilter.length % 12)"
                                    v-if="(12 - 2 * dataModule.OperatorFilter.length % 12) != 12" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- modal Operator -->
        <b-modal v-model="showOperatorModal" width="900px" trap-focus :destroy-on-hide="true" :can-cancel="false"
            aria-role="dialog" aria-modal>

            <template>
                <div class="modal-card" style="width: 100%; height: 650px">
                    <header class="modal-card-head">
                        <p class="modal-card-title">{{ this.name }}</p>
                        <button type="button" class="delete" @click="closeOperator()" />
                    </header>
                    <section class="modal-card-body">
                        <b-tabs class="block">
                            <b-tab-item label="Operator" icon-pack="icons" icon="icons-lg icons-user">
                                <b-table class="has-background-color-dark" :data="operatorEstablishment.dataProg"
                                    aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                                    aria-current-label="Current page" striped bordered>
                                    <template #empty>
                                        <div class="has-text-centered">
                                            <h1>
                                                <span class="tag is-danger">{{
                                                    translate("lblNoDataFound")
                                                }}</span>
                                            </h1>
                                        </div>
                                    </template>
                                    <b-table-column field="field" :label="translate('columnField')" v-slot="props"
                                        header-class="is-sticky-column-one" width="200">
                                        {{ props.row.field }}
                                    </b-table-column>
                                    <b-table-column field="value" :label="translate('columnValue')" v-slot="props"
                                        header-class="is-sticky-column-one" width="200">
                                        {{ props.row.value }}
                                    </b-table-column>
                                </b-table>
                            </b-tab-item>

                            <b-tab-item :label="translate('lblConfiguration')" icon-pack="icons" icon="icons-lg icons-file">
                                <b-table :data="operatorEstablishment.ServerInfo.Configuration" ref="table"
                                    aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                                    aria-current-label="Current page" striped>
                                    <template #empty>
                                        <div class="has-text-centered">
                                            <h1>
                                                <span class="tag is-danger">{{
                                                    translate("lblNoDataFound")
                                                }}</span>
                                            </h1>
                                        </div>
                                    </template>
                                    <b-table-column field="field" :label="translate('columnField')" v-slot="props"
                                        header-class="is-sticky-column-one" width="200">
                                        {{ props.row.Field }}
                                    </b-table-column>
                                    <b-table-column field="value" :label="translate('columnValue')" v-slot="props"
                                        header-class="is-sticky-column-one" width="200">
                                        <span :class="[
                                            { 'tag is-success': props.row.Status === 1 },
                                            { 'tag is-warning': props.row.Status === 2 },
                                            { 'tag is-danger': props.row.Status === 3 },
                                            { 'tag is-info': props.row.Status === 4 },
                                            { '': props.row.Status === 0 },
                                        ]" style="font-size: 13px !important;">
                                            {{ props.row.Value }}
                                        </span>
                                    </b-table-column>
                                </b-table>
                            </b-tab-item>

                            <b-tab-item :label="translate('btnDelete')" icon-pack="icons" icon="icons-lg icons-user-times"
                                v-if="!userLogged.Technician">
                                <section style="text-align: center; padding-top: 10px; padding-bottom: 10px;">
                                    {{ }}
                                    <article class="is-warning" style="background-color: #fffaeb">
                                        <section class="message-body" style="border-color: #ffe08a; color: #946c00">
                                            <div class="media">
                                                <div class="media-left">
                                                    <i class="icons icons-warning1" style="font-size: 45px" />
                                                </div>
                                                <div>
                                                    {{ translate('messageInfoDeleteOperator') }}
                                                </div>
                                            </div>
                                        </section>
                                    </article>
                                    <br />
                                    <div class="divConfiguration" style="display: flex; justify-content: center">
                                        <b-button class="button" icon-left="icons-1x icons-trash" icon-pack="icons"
                                            @click.prevent="processDeleteOperator(operatorEstablishment.Server)">
                                            <span>{{
                                                translate("btnDelete")
                                            }}</span>
                                        </b-button>
                                    </div>
                                </section>
                            </b-tab-item>
                        </b-tabs>
                    </section>
                </div>
            </template>
        </b-modal>
        <b-modal v-model="showAutentication" width="800px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
            :can-cancel="false" ref="modal-auth">
            <template #default="props">
                <Auth @close="props.close" route="/api/configuration/controls" :showLogin="true" :showPassword="true"
                    :showPin="false" local="Module/Operator" @AfterAuthOperator="afterAuthOperator" />
            </template>
        </b-modal>
    </div>
</template>
<script>
import LoadingCMD from "@/components/processCMD/LoadingCMD.vue";
import Auth from "@/components/auth/Auth.vue";
import { mapState } from "vuex";
import { clientCommands } from "@/js/ClientCommands.js";
import { countries } from "@/mixins/countryCode.js";
import "@/css/icons.css";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
export default {
    name: "Progressive",
    mixins: [en, es, nor, swe],
    components: { LoadingCMD, Auth },
    computed: mapState([
        "session",
        "communication",
        "dataModule",
        "currentTab",
        "hasControl",
        "resetToken",
        "lang",
        "userLogged",
        "globalType",
        "operatorName"
    ]),

    data: function () {
        return {
            name: "",
            searchByName: "",
            countries: countries,
            intervalGetDatas: null,
            showAvailable: false,
            stickyHeaders: true,
            showLoadingCMD: false,
            showLoadingMoveProgressiveCMD: false,
            cmd: "",
            countDown: 60,
            route: "",
            sendingCMDMessage: "",
            objData: {},
            selectOperator: "",
            showOperatorModal: false,
            operatorEstablishment: {
                dataProg: [],
                Server: {
                    Operator: ""
                },
                ServerInfo: { Configuration: [] }
            },
            operatorDelete: {},
            showAutentication: false,
        };
    },
    props: {
        nameTab: String,
    },

    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },

        sendCMD(cmd, message, time, isRestartCommunication = false, objData = null) {
            this.cmd = cmd;
            this.countDown = time;
            this.restartCommunication = isRestartCommunication;
            this.sendingCMDMessage = message;
            this.objData = objData
            this.showLoadingCMD = true;
        },

        processShowEstablishmentData(operator) {
            this.cmd = clientCommands.GetOperatorServer.name;
            this.objData = {
                Value: operator
            };
            this.countDown = clientCommands.GetOperatorServer.time;
            this.sendingCMDMessage =
                this.translate("waiting") + " " + this.translate("messageGetOperatorServer");
            this.route = "";
            this.showLoadingCMD = true;
        },

        processSelectOperator(name) {
            if (this.selectOperator == name) {
                this.selectOperator = ""
            } else {
                this.selectOperator = name
            }
        },

        closeOperator() {
            this.operatorEstablishment = {
                dataProg: [],
                Server: {
                    Operator: ""
                },
                ServerInfo: { Configuration: [] }
            },
                this.showOperatorModal = false;
        },

        actionAfterLoadingOperator(data) {
            switch (this.cmd) {
                case clientCommands.GetOperatorServer.name:
                    this.updateOperatorEstablishment(data.object);
                    this.showOperatorModal = true;

                    break;
                default:

                    break;
            }
        },

        updateOperatorEstablishment(operator) {
            this.operatorEstablishment = operator;
            console.log("updateOperatorData()");
            try {
                this.operatorEstablishment.dataProg = [
                    { field: this.translate("columnOperator"), value: operator.Server.Operator },
                    { field: this.translate("columnServerName"), value: operator.Server.ServerName },
                    { field: this.translate("columnShortName"), value: operator.Server.ShortName },
                    { field: this.translate("columnAddress"), value: operator.Server.Address },
                    { field: this.translate("columnCity"), value: operator.Server.City },
                ];
            } catch (e) {
                console.log("Error in updateOperatorEstablishment() " + e);
            }
        },

        getDatas() {
            this.$store.commit("setSelectedTab", "Operator");
            this.intervalGetDatas = setInterval(() => {
                if (this.currentTab != "Operator" &&
                    !this.completeLogin) {
                    console.log("/closed getDatas()");
                    clearInterval(this.intervalGetDatas);
                }

                this.dataModule.Operator.forEach((operator) => {
                    if (this.showOperatorModal) {
                        console.log("/mountedDataOperator()");
                        if (operator.Operator == this.operatorEstablishment.Server.Operator) {
                            this.updateOperatorEstablishment(operator);
                        }
                    }
                });
            }, 1000);
        },
        returnIcon(icon) {
            const ret = icon;
            return ret;
        },

        processDeleteOperator(operator) {
            this.operatorDelete = operator;
            this.showAutentication = true;
        },

        afterAuthOperator() {
            this.$buefy.dialog.confirm({
                title: this.translate("dialog_Title"),
                message: this.translate("messageDeleteOperator") + " [" + this.operatorDelete.Operator + "]",
                confirmText: this.translate("btnOk"),
                cancelText: this.translate("btnCancel"),
                type: "is-primary",
                hasIcon: true,

                onConfirm: () => this.processConfirmDeleteOperator(),

            });
        },
        processConfirmDeleteOperator() {
            this.$buefy.dialog.confirm({
                title: this.translate("dialog_Title"),
                message: this.translate("messageDisplayName2"),
                confirmText: this.translate("btnOk"),
                cancelText: this.translate("btnCancel"),
                type: "is-primary",
                hasIcon: true,

                onConfirm: () => (this.sendCMD(clientCommands.DeleteOperator.name, this.translate("waiting"), clientCommands.DeleteOperator.time, false, this.operatorDelete), this.showOperatorModal = false),

            });
        }
    },
    mounted() {
        this.name = "";
        this.ip = "";
    },

    watch: {
        //
    },
    async created() {
        //if (this.currentTab == "GlobalProgressive" || this.currentTab == "Terminals")
        //this.getDatas();
    },
    beforeDestroy() {
        console.log("/closed getDatas() Operator");
        clearInterval(this.intervalGetDatas);
    },
};
</script>
  
<style scoped src="@/css/styleModules.css"></style>
  