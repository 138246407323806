import moment from 'moment-timezone'
import 'moment/locale/pt-br'
import Vue from 'vue'

const install = function(Vue) {
    const locale = window.navigator.userLanguage || window.navigator.language;  
    window.moment = moment
    window.moment().tz(moment.tz.guess()).format();
    window.moment().locale(locale)

    Vue.prototype.moment = window.moment
}
Vue.use(install)