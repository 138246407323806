import Vue from "vue";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";

const emitter = new Vue({
  mixins: [en, es, nor, swe],

  methods: {
    translate(prop, lang) {
      return this[lang][prop];
    },
    translateName(name, lang) {
      let aux = "";
      try {
        switch (name) {
          //Financial
          case "Session Balance Total Report":
            aux = this.translate("reportSessionBalanceTot", lang);
            break;
          case "Session Report":
            aux = this.translate("reportSessionReport", lang);
            break;
          //Progressive
          case "Status":
            aux = this.translate("columnStatus", lang);
            break;
          case "Prize":
            aux = this.translate("reportPrize", lang);
            break;
          case "Pay out Summary Report":
            aux = this.translate("reportPayOutSummary", lang);
            break;
          case "Progressive Group Report":
            aux = this.translate("reportProgressiveGroupReport", lang);
            break;
          //Jackpot Global
          case "Status Report":
            aux = this.translate("reportStatusReport", lang);
            break;
          case "Prize Report":
            aux = this.translate("reportPrizeReport", lang);
            break;
          //Game
          case "Game List Report":
            aux = this.translate("reportGameListReport", lang);
            break;
          case "Game Group Report":
            aux = this.translate("reportGameGroupReport", lang);
            break;
          //Vouchers
          case "Issuance":
            aux = this.translate("reportIssuance", lang);
            break;
          case "Redemption":
            aux = this.translate("reportRedemption", lang);
            break;
          case "Unclaimed":
            aux = this.translate("reportUnclaimed", lang);
            break;
          case "Liability":
            aux = this.translate("lblLiability", lang);
            break;
          case "Cash Out":
            aux = this.translate("reportCashOut", lang);
            break;
          case "VoucherStatusReport":
            aux = this.translate("reportStatus", lang);
            break;
          //Terminal
          case "Game Terminal Report":
            aux = this.translate("reportGameTerminalReport", lang);
            break;
          case "Terminal Event Report":
            aux = this.translate("reportTerminalEventReport", lang);
            break;
          case "Game Terminal Counts Report":
            aux = this.translate("reportGameTerminalCountsReport", lang);
            break;
          case "Reconciliation Report":
            aux = this.translate("reportReconcilationReport", lang);
            break;
          case "Report Accounting Final":
            aux = this.translate("reportReportAccountingFinal", lang);
            break;
          case "Ram Clear Hist Report":
            aux = this.translate("reportRamClearHistReport", lang);
            break;
          case "Play Hist Report":
            aux = this.translate("reportPlayHistReport", lang);
            break;
          case "Bill in":
            aux = this.translate("reportBillIn", lang);
            break;
          //Gen Reports
          case "ServerReports":
            aux = this.translate("lblServerReports", lang);
            break;
          //Cashier
          case "Terminal Sales Report":
            aux = this.translate("reportTerminalSalesReport", lang);
            break;
          case "Report":
            aux = this.translate("reportReport", lang);
            break;
          case "Terminal Audit Report":
            aux = this.translate("reportTerminalAuditReport", lang);
            break;
          case "Audit Report":
            aux = this.translate("reportAuditReport", lang);
            break;
          case "Terminal Report":
            aux = this.translate("reportTerminalReport", lang);
            break;
          //Bonus
          case "Mystery Prize Report":
            aux = this.translate("reportMysteryPrizeReport", lang);
            break;
            case "Manny Bucks Prize Report":
            aux = this.translate("reportMannyBucksPrizeReport", lang);
            break;
          case "Bonus Prize Report":
            aux = this.translate("reportBonusPrizeReport", lang);
            break;

          default:
            console.log("Unknown Report [" + name + "]");
            break;
        }
        return aux;
      } catch (e) {
        console.log("Error in translateName() " + e);
        return aux;
      }
    },
  },
});

export default emitter;
