<template>
  <form action="javascript:void(0);" style="overflow-y: hidden">
    <div class="modal-card" style="width: 100%; height: 100%; overflow-y: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ translate("lblRequestFile") }}</p>
        <button v-if="isLicense" class="file-cta" @click.prevent="cancelLicenseReq()">
          <i class="icons-lg0 icons-key" /> <i
            style="font-family: Robot, sans-serif; font-size: 15px; color: black; font-style: normal;"> &nbsp; {{
              translate("msgCancelLicenseReq") }}</i>
        </button>
        &nbsp;
        &nbsp;
        &nbsp;
        <button type="button" class="delete" @click="closeModal()" />
      </header>
      <section class="modal-card-body">
        <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
          :can-cancel="false">
          <template #default="props">
            <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
              :JsonObject="obj" @ActionAfterCMDCopyFile="actionAfterCMDCopyFile">
            </LoadingCMD>
          </template>
        </b-modal>
        <b-steps v-model="activeStep" :has-navigation="false" animation="slide-prev">
          <b-step-item step="1" icon="icons-file-code" icon-pack="icons" :label="translate('stepLoadFile')" :clickable="false"
            :type="{ 'is-success': activeStep > 0 }">
            <Message v-if="listPenDrive.length == 0 && isElectronVar" type="is-warning"
              :message="translate('messageNoFiles')" />
            <div class="block" v-if="listConfig.length > 0">
              <b-radio v-model="fileSource" name="name" native-value="penDrive" v-if="isElectronVar">
                {{ translate("lblPenDrive") }}
              </b-radio>
              <b-radio v-model="fileSource" name="name" native-value="uploadFile" v-else>
                {{ translate("lblUploadFile") }}
              </b-radio>
              <b-radio v-model="fileSource" name="name" native-value="BigOs">
                BigOS
              </b-radio>
            </div>

            <b-field v-if="fileSource == 'penDrive' && isElectronVar">
              <button class="btn btn-outline-secondary" disabled>
                <i class="icons-lg0 icons-usb" />
              </button>
              &nbsp;
              <b-select :placeholder="translate('lblSelectDevice')" v-model="penDriveSelected" expanded>
                <option v-for="penDrive in listPenDrive" v-bind:value="penDrive" :key="penDrive">
                  {{ penDrive.Name }}
                </option>
              </b-select>
              &nbsp;
              <button class="btn btn-outline-secondary" @click.prevent="refreshPenDrive()">
                <i class="icons-lg0 icons-sync" />
              </button>
              &nbsp;
              <button v-if="isLicense" class="btn btn-outline-secondary" @click.prevent="cancelLicenseReq()">
                <i class="icons-lg0 icons-key" /> <i
                  style="font-family: Robot, sans-serif; font-size: 15px; color: black; font-style: normal;"> {{
                    translate("msgCancelLicenseReq") }}</i>
              </button>
            </b-field>
            <div class="form-group row">
              <div v-if="fileSource == 'penDrive'" class="col-lg-12">
                <div v-if="penDriveSelected != null">
                  <div style="text-align: center">
                    <span class="stat-title">{{
                      translate("availableFiles")
                    }}</span>
                  </div>
                  <div class="stat" style="100%">
                    <div class="terminal-tab" style="font-size: 5px !important">
                      <b-input :placeholder="translate('lblSearchByName')" expanded id="lblSearchByName" type="search"
                        icon="magnify" v-model="searchFile" v-if="penDriveSelected.FileDataList.length > 0" />

                      <Message type="is-warning" v-if="penDriveSelected.FileDataList.length == 0"
                        :message="translate('messageNoFiles')" :isList="false" />

                      <br />
                      <div style="
                          height: 100%;
                          overflow-y: hidden;
                          padding-left: 10px;
                        ">
                        <button style="
                            border-radius: 5px;
                            margin-right: 0.25%;
                            margin-top: 0.25%;
                            background-color: #ffffff;
                            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
                            border: 1px solid rgba(0, 0, 0, 0.2);
                            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
                            min-width: 200px;
                            max-width: 200px;
                            margin-bottom: 5px;
                            height: 100%;
                          " class="stat-terminal" v-for="file in penDriveSelected.FileDataList" :key="file.FileName"
                          :title="file.Name" data-toggle="tooltip" data-placement="top"
                          @click.prevent="checkShowDatabase(file)" v-show="file.Name.toLowerCase().includes(
                            searchFile.toLowerCase()
                          )
                            ">
                          <div class="stat-icon">
                            <div class="row" style="align-items: end !important">
                              <div style="
                          padding-left: 21px !important;
                          padding-bottom: 10px !important;
                          padding-top: 7px; !important;
                        ">
                                <i class="icons-lg icons-file" v-if="isConfig"></i>
                                <i class="icons-lg icons-key" v-if="isLicense"></i>
                                <i class="icons-lg icons-database" v-if="!isConfig && !isLicense"></i>
                              </div>

                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div style="min-height: 50px;
                                  width: 90%;">
                              <span class="stat-title-terminal" style="
                                  font-size: 11px !important;
                                  padding-left: 5px;
                                  padding-right: 5px;
                                ">
                                {{ file.Name }}
                              </span>
                            </div>
                          </div>
                          <br />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="stat" style="min-height: 465px" v-if="fileSource == 'uploadFile'">
                <Message v-if="showMessageError" type="is-warning" :message="messageError" />
                <div class="divConfiguration" style="display: flex; justify-content: center">
                  <b-field :class="{ 'has-name': !!uploadFile }">
                    <b-upload v-model="uploadFile" class="file-label" :accept="extensions" type="is-text">
                      <span class="file-cta" style="background-color: white">
                        <b-icon type="is-text" class="icons-1x icons-file_upload"></b-icon>
                        <span class="file-label">{{ loadFileTitle }}</span>
                      </span>
                    </b-upload>
                  </b-field>
                </div>
              </div>
              <div class="stat" style="min-height: 465px" v-if="fileSource == 'BigOs'">
                <div class="terminal-tab" style="font-size: 5px !important">
                  <SearchFilter :fromDash="false" :isOperator="false" :list="listConfig" :selected="configSelectedName"
                    @ActionAfterCMDSearchFilter="updateConfigName" />
                  <div v-if="false">
                    <b-form-input list="listConfigs" name="listConfig" id="listConfig" class="form-control"
                      v-model="configSelectedName" />
                    <datalist id="listConfigs">
                      <option v-for="config in listConfig" v-bind:value="config.name" :key="config.name">
                        {{ config.name }}
                      </option>
                    </datalist>
                  </div>
                  <br />
                  <br />
                  <b-table :data="configSelected.configs" ref="table" v-if="configSelected != null" :paginated="true"
                    :per-page="5" pagination-position="top" :pagination-simple="true" :pagination-rounded="true"
                    aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                    aria-current-label="Current page" :striped="true" custom-row-key="id" default-sort="login">
                    <template #empty>
                      <div class="has-text-centered">
                        <h1>
                          <span class="tag is-danger">{{
                            translate("lblNoDataFound")
                          }}</span>
                        </h1>
                      </div>
                    </template>

                    <b-table-column field="establishment" centered :label="translate('deckEstablishment_Title')"
                      header-class="is-sticky-column-one" width="200">
                      {{ configSelected.name }}
                    </b-table-column>
                    <b-table-column field="version" centered :label="translate('columnVersion')" v-slot="props"
                      header-class="is-sticky-column-one" sortable numeric width="200">
                      {{ props.row.version }}
                    </b-table-column>

                    <b-table-column field="actions" :label="translate('columnActions')" width="300" centered
                      v-slot="props" header-class="is-sticky-column-one">
                      <b-button type="is-danger" class="mr-2" :title="translate('btnDownloadFullConfig')"
                        v-if="props.row.version != 1 && props.row.isLast" @click.prevent="
                          downloadConfig(
                            configSelected.idBingo,
                            props.row.version,
                            true
                          )
                          ">
                        <i class="icons-black icons-lg1 icons-document-download"></i>
                      </b-button>
                      <b-button type="is-success" class="mr-2" :title="translate('btnDonwloadConfig')" @click.prevent="
                        downloadConfig(
                          configSelected.idBingo,
                          props.row.version,
                          false
                        )
                        ">
                        <i class="icons-black icons-lg1 icons-document-download"></i>
                      </b-button>
                    </b-table-column>
                  </b-table>
                </div>
              </div>
            </div>
          </b-step-item>
          <b-step-item step="2" icon="icons-file-code" icon-pack="icons" :label="translate('stepCredentials')" :clickable="false"
            :type="{ 'is-success': activeStep > 1 }" v-if="showDatabasePassword && !isConfig">
            <div class="auth-content">
              <div class="auth-modal">
                <div class="auth-title">
                  {{ translate("lblDatabasePassword") }}
                </div>

                <strong v-for="messageAux in message" :key="messageAux">
                  {{ messageAux }}
                  <br />
                </strong>
                <br />

                <b-field horizontal :label="translate('lblPassword')" :type="{ 'is-danger': errorName }" :message="[
                  { 'Password is too short': errorName },
                  {
                    'Password must have at least 8 characters': errorName,
                  },
                ]">
                  <b-input :value="password" v-model="password" type="password" password-reveal expanded
                    maxlength="20"></b-input>
                </b-field>
                <footer class="modal-card-foot" style="height: 20px">
                  <div style="padding-top: inherit">
                    <b-button style="margin-right: 10px" @click="closeModal()" :label="translate('btnCancel')"
                      icon-left=".icons-white icons-times" icon-pack="icons"></b-button>
                    <b-button type="is-primary" @click.prevent="processSendFile()" :label="translate('btnOk')"
                      icon-left=".icons-white icons-check" icon-pack="icons">
                    </b-button>
                  </div>
                </footer>
              </div>
            </div>
          </b-step-item>
        </b-steps>
      </section>
    </div>
  </form>
</template>

<script>
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import LoadingCMD from "@/components/processCMD/LoadingCMD";
import SearchFilter from "@/components/dropdown/SearchFilter.vue";
import Message from "@/components/message/Message.vue";
import { mapState } from "vuex";
import { clientCommands } from "@/js/ClientCommands.js";
//import Dropdown from "primevue/dropdown";

export default {
  name: "RequestFile",
  mixins: [en, es, nor, swe],
  components: { LoadingCMD /*Dropdown*/, SearchFilter, Message },
  computed: mapState(["lang", "establishmentName", "isElectronVar"]),
  props: {
    penDriveList: Object,
    isConfig: Boolean,
    showDatabasePassword: Boolean,
    extensions: String,
    cmdFromConfigurations: String,
  },
  data() {
    return {
      cmd: "",
      countDown: 10,
      showLoadingCMD: false,
      sendingCMDMessage: "",
      obj: {},
      penDriveSelected: null,
      searchFile: "",
      activeStep: 0,
      file: null,
      password: "",
      listConfig: [],
      configSelected: null,
      configSelectedName: "",
      fileSource: "",
      listPenDrive: [],
      BigOsObject: {},
      uploadFile: null,
      loadFileTitle: "",
      showMessageError: false,
      messageError: "",
      isLicense: false,
    };
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    closeModal() {
      this.$emit("close");
    },
    processSendFile(file) {
      if (file != undefined) {
        this.file = file;
      }
      this.processConfirmSendFile(this.file);
    },

    processConfirmSendFile(file) {
      var message = "";
      switch (this.cmdFromConfigurations) {
        case clientCommands.RequestConfig.name:
        case clientCommands.RequestBackup.name:
          message = this.isConfig
            ? this.translate("messageLoadConfig") + " [" + file.Name + "]"
            : this.translate("messageRestoreBackup");
          this.$buefy.dialog.confirm({
            title: this.translate("dialog_Title"),
            message: message,
            confirmText: this.translate("btnYes"),
            cancelText: this.translate("btnCancel"),
            type: "is-primary",
            hasIcon: true,
            onCancel: () => this.cancelFile(),
            onConfirm: () => this.confirmSendFile(file),
          });
          break;
        case clientCommands.RequestLicense.name:
          this.confirmSendFile(file);
          break;
      }
    },
    cancelFile() {
      this.uploadFile = null;
    },
    confirmSendFile(file) {
      if (this.fileSource == "penDrive") {
        this.sendFile(file);
      } else if (this.fileSource == "BigOs") {
        this.sendDownloadFile(file);
      } else {
        switch (this.cmdFromConfigurations) {
          case clientCommands.RequestConfig.name:
            /*getBase64(this.uploadFile).then((data) => {
              //console.log("Data: [ " + data);
              this.sendFileUpload(data);
            });*/
            this.sendFileUpload(this.uploadFile);
            break;
          case clientCommands.RequestBackup.name:
          case clientCommands.RequestLicense.name:
            this.sendFileUpload(this.uploadFile);
            break;
        }
      }
    },

    checkShowDatabase(file) {
      if (this.showDatabasePassword) {
        this.activeStep = 1;
        this.file = file;
      } else {
        this.processSendFile(file);
      }
    },
    downloadConfig(idBingo, version, isFullConfig) {
      try {
        const obj = {
          idBingo: idBingo,
          version: version,
          isFullConfig: isFullConfig,
          Name: this.configSelected.name,
          password: this.showDatabasePassword ? this.password : "",
        };
        if (this.showDatabasePassword) {
          this.activeStep = 1;
          this.file = obj;
        } else {
          this.processSendFile(obj);
        }
      } catch (e) {
        console.log("Error in sendFile downloadConfig() " + e);
      }
    },
    sendDownloadFile(obj) {
      this.$emit("ActionAfterChooseFileBigOs", obj);
      this.closeModal();
    },
    sendFileUpload(obj) {
      this.$emit("ActionAfterChooseFileUpload", obj, this.password);
      this.closeModal();
    },
    sendFile(file) {
      try {
        let sendFile = file.FileName;
        if (this.showDatabasePassword) {
          sendFile += "#" + this.password;
        }
        this.$emit("ActionAfterChooseFile", sendFile);
        this.closeModal();
      } catch (e) {
        console.log("Error in sendFile RequestFile() " + e);
      }
    },
    updateConfigName(name) {
      this.configSelectedName = name;
    },
    refreshPenDrive() {
      this.$emit("RefreshPenDriveFiles", this.cmdFromConfigurations);
      this.closeModal()
    },
    cancelLicenseReq() {
      const message = this.translate("msgCancelLicenseQuest");
      this.$buefy.dialog.confirm({
        title: this.translate("license_Title"),
        message: message,
        confirmText: this.translate("btnYes"),
        cancelText: this.translate("btnNo"),
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => {
          this.$emit("RefreshPenDriveFiles", clientCommands.CancelLicenseReq.name);
          this.closeModal()
        },
      });
    },
  },
  created() {
    this.fileSource = this.isElectronVar ? "penDrive" : "uploadFile";
  },
  mounted() {
    try {
      this.isLicense = false;
      switch (this.cmdFromConfigurations) {
        case clientCommands.RequestConfig.name:
          this.loadFileTitle = this.translate("btnLoadSettings");
          break;
        case clientCommands.RequestBackup.name:
          this.loadFileTitle = this.translate("lblUploadFile");
          break;
        case clientCommands.RequestLicense.name:
          this.isLicense = true;
          this.loadFileTitle = this.translate("lblUploadFile");
          break;
      }
      this.listPenDrive = [];
      this.penDriveList.forEach((element) => {
        if (element.Name != "BigOs") {
          this.listPenDrive.push(element);
        } else {
          this.listConfig = [];

          this.listConfig = JSON.parse(element.FileDataList[0].FileName);
          const establishmentName = this.establishmentName;
          this.listConfig.forEach((element) => {
            let version = element.configs[0];
            version.isLast = true;
            if (element.name == establishmentName) {
              this.configSelectedName = element.name;
              this.configSelected = element;
            }
          });
        }
      });
    } catch (e) {
      console.log("Error in created RequestFile()" + e);
    }
  },
  watch: {
    uploadFile: function (val) {
      if (val != null) {
        this.messageError = "";
        this.showMessageError = false;
        if (val.size <= 10000000) {
          switch (this.cmdFromConfigurations) {
            case clientCommands.RequestConfig.name:
              /*getBase64(this.uploadFile).then((data) => {
              //console.log("Data: [ " + data);
              this.sendFileUpload(data);
            });*/
              this.file = this.uploadFile;
              this.file.Name = this.file.name;
              this.processConfirmSendFile(this.uploadFile);
              break;
            case clientCommands.RequestBackup.name:
            case clientCommands.RequestLicense.name:
              if (this.showDatabasePassword) {
                this.activeStep = 1;
                this.file = this.uploadFile;
              } else {
                this.processConfirmSendFile(this.uploadFile);
              }
              break;
          }
        } else {
          console.log("File are larger than 10 mb");
          this.messageError =
            val.name + " " + this.translate("messageErrorFileSize");
          this.showMessageError = true;
        }
      } else {
        console.log("File is Null!!!");
      }
    },
    configSelectedName(val) {
      if (val == "") {
        this.configSelected = null;
      } else {
        this.configSelected = this.listConfig.find(
          (config) => config.name.toLowerCase() === val.toLowerCase()
        );
      }
    },
  },
};
</script>

<style scoped src="@/css/styleModules.css">
.vue-list-picker {
  display: grid;
  padding-top: 140%;
}
</style>