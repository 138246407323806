///* eslint @typescript-eslint/no-var-requires: "off" */

import Socket from "@/config/webSocket.js";

export function returnApp(page) {    
    try {
        resetStore(page)
        resetEventBus()             
        if (page.$route.path != "/app") {
            {
                page.$router.push("/app").catch((e) => {
                console.log("Error in Route->App()" + e);
              });
            }
          } 
    } catch (e) {
        console.log('Error in return App [' + e + "]")
    }
}

function resetEventBus() {
    Socket.$off("noConnection");
    Socket.$off("onConnection");
    //SocketDriver.$off("finishedCMDLogin", this.validateLogin);
}

function resetStore(page) {
    page.$store.commit("changeCompleteLogin", false);
    page.$store.commit("changeValidatingLogin", false);
    page.$store.commit("setSystemTime", null);
}

export default { returnApp }